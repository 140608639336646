import { getAnalytics, logEvent } from "firebase/analytics";
import { Menu, MoreHorizontal } from 'lucide-react';
import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import PullToRefresh from 'react-pull-to-refresh';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ConsultantHamburger from './ConsultantHamburger';
import NotificationBell from "./NotificationBell";
import Spinner from './Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReviewRequestModal from "./ui/ReviewRequestModal.js";

const EngagementManagement = () => {
  const [engagements, setEngagements] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userType, setUserType] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [user, setUser] = useState(null);

  // New state variables
  const [isMobile, setIsMobile] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isReviewRequestModalOpen, setIsReviewRequestModalOpen] = useState(false);
  const [selectedEngagement, setSelectedEngagement] = useState(null);
  const dropdownRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Detect mobile devices
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    // Close dropdown when clicking outside
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [dropdownRef]);

  useEffect(() => {
    const analytics = getAnalytics();
    
    // Log page view
    logEvent(analytics, 'page_view', {
      page_title: 'Engagement Management',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  
    // Check for success parameter
    const searchParams = new URLSearchParams(location.search);
    const success = searchParams.get('success');
  
    if (success === 'ica_signed') {
      toast.success('Independent Contractor Agreement signed successfully!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
  
      // Log signing success event
      logEvent(analytics, 'ica_signed', {
        location: 'engagement_management'
      });
  
      // Clean up URL
      window.history.replaceState({}, '', '/app/engagement-management');
    }
  
    fetchUserTypeAndEngagements();
  }, [location.search]); // Add location.search to dependency array

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const [year, month, day] = dateString.split('-');
    return `${month}-${day}-${year}`;
  };

  const fetchUserTypeAndEngagements = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");
      setUser(user);

      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('user_type_id')
        .eq('user_id', user.id)
        .single();

      if (userError) throw userError;

      setUserType(userData.user_type_id);

      const isConsultant = userData.user_type_id === 2;
      const isClient = userData.user_type_id === 4;

      if (isConsultant || isClient) {
        await fetchEngagements(user.id, isConsultant);
      } else {
        throw new Error("Invalid user type");
      }
    } catch (error) {
      console.error('Error fetching user type and engagements:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEngagements = async (userId, isConsultant) => {
    try {
      let query = supabase
        .from('engagements')
        .select(`
          id,
          start_date,
          end_date,
          contract_rate,
          status,
          consultant_id,
          client_id,
          ica_document_id,
          consultant_description,
          decision_maker_id,
          job_id,
          job:jobs(
            job_id,
            title,
            client_id,
            description
          ),
          client:clients(
            client_id,
            name
          ),
          reviews(
            id,
            rating,
            review_text,
            review_type,
            verification_status,
            relationship_details,
            created_at,
            reviewer:reviewer_id(
              name,
              photo,
              job_title,
              employer,
              login_email,
              work_email,
              phone
            )
          ),
          decision_maker:decision_maker_id(
            name,
            work_email,
            phone,
            job_title
          )
        `)
        .in('status', ['Active', 'Contract Accepted', 'Contract Offered', 'Executed']);
  
      if (isConsultant) {
        query = query.eq('consultant_id', userId);
      } else {
        query = query.eq('client_id', userId);
      }
  
      const { data, error } = await query;
      if (error) throw error;
      setEngagements(data);
    } catch (error) {
      console.error('Error fetching engagements:', error);
    }
  };

  const handleViewDocument = async (engagement) => {
    const analytics = getAnalytics();
    
    const docType = engagement.sow_document_id ? 'sow' : 'ica';
    const documentId = docType === 'sow' ? engagement.sow_document_id : engagement.ica_document_id;
    
    // Track click event
    logEvent(analytics, 'document_view_clicked', {
      document_type: docType,
      engagement_id: engagement.ids
    });
  
    if (!documentId) {
      alert('No document available for viewing');
      return;
    }
  
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.access_token) {
        throw new Error('No valid session found');
      }
  
      // Detect Safari
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  
      if (isSafari) {
        // For Safari, create a simple URL with the document ID
        const url = `${process.env.REACT_APP_SUPABASE_URL}/functions/v1/getDocumentViewingLink?documentId=${documentId}`;
        
        // Make the request in the same window for Safari
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${session.access_token}`
          }
        });
  
        if (!response.ok) {
          throw new Error('Failed to get document');
        }
  
        // Convert response to blob and create URL
        const blob = await response.blob();
        const blobUrl = window.URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
  
        // Clean up
        setTimeout(() => {
          window.URL.revokeObjectURL(blobUrl);
        }, 100);
      } else {
        // For other browsers, use POST method
        const response = await fetch(
          `${process.env.REACT_APP_SUPABASE_URL}/functions/v1/getDocumentViewingLink`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${session.access_token}`
            },
            body: JSON.stringify({
              documentId: documentId
            }),
          }
        );
  
        if (!response.ok) {
          throw new Error('Failed to get document');
        }
  
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
        
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
        }, 100);
      }
  
      // Track successful document open
      logEvent(analytics, 'document_view_opened', {
        document_type: docType,
        engagement_id: engagement.id,
        browser: isSafari ? 'safari' : 'other'
      });
  
    } catch (error) {
      console.error('Error viewing document:', error);
      
      // Track failed attempt
      logEvent(analytics, 'document_view_failed', {
        document_type: docType,
        engagement_id: engagement.id,
        error: error.message,
        browser: /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ? 'safari' : 'other'
      });
      
      alert('Failed to open document. Please try again.');
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = (engagementId) => {
    setActiveDropdown(activeDropdown === engagementId ? null : engagementId);
  };

  // Render Engagements in Table Format
  const renderEngagementsTable = () => (
    <div className="bg-white shadow-md rounded-lg">
      <table className="min-w-full table-auto">
        {/* Table Header */}
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">Job Title</th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">Start Date</th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">End Date</th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">Contract Rate</th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">Status</th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">Client Name</th>
            <th className="px-6 py-3"></th> {/* For Complete Onboarding button */}
            <th className="px-6 py-3"></th> {/* For More options */}
          </tr>
        </thead>
        {/* Table Body */}
        <tbody>
          {engagements.map((engagement) => (
            <tr
              key={engagement.id}
              onClick={() => navigate(`/app/engagement-details/${engagement.id}`)}
              className="cursor-pointer hover:bg-gray-50"
            >
              <td className="px-6 py-4 text-sm text-gray-700 text-left">
                {engagement.job ? engagement.job.title : 'No Job Title'}
              </td>
              <td className="px-6 py-4 text-sm text-gray-700 text-left">
                {engagement.start_date ? formatDate(engagement.start_date) : ''}
              </td>
              <td className="px-6 py-4 text-sm text-gray-700 text-left">
                {engagement.end_date ? formatDate(engagement.end_date) : ''}
              </td>
              <td className="px-6 py-4 text-sm text-gray-700 text-left">
                ${engagement.contract_rate}
              </td>
              <td className="px-6 py-4 text-sm text-gray-700 text-left">{engagement.status}</td>
              <td className="px-6 py-4 text-sm text-gray-700 text-left">
                {engagement.client ? engagement.client.name : 'No Client Name'}
              </td>
              <td className="px-6 py-4 text-sm text-gray-700 text-left">
                {/* Complete Onboarding button */}
                {userType === 2 && (
                  <Link
                    to={`/app/consultant-onboarding/${engagement.id}`}
                    className="fetch-button"
                    onClick={(e) => e.stopPropagation()}
                  >
                    Onboarding
                  </Link>
                )}
                {userType === 4 && (
                  <Link
                    to={`/app/client-onboarding-progress/${engagement.id}`}
                    className="fetch-button"
                    onClick={(e) => e.stopPropagation()}
                  >
                    View Onboarding Progress
                  </Link>
                )}
              </td>
              <td className="px-6 py-4 text-left text-sm text-gray-700 relative">
                {/* More options */}
                <MoreHorizontal
                  size={24}
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleDropdown(engagement.id);
                  }}
                />
                {activeDropdown === engagement.id && (
                  <div ref={dropdownRef} className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                    <div
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer text-left"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleViewDocument(engagement);
                      }}
                    >
                      📄 View Contract
                    </div>
                    <div
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer text-left"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedEngagement(engagement);
                        setIsReviewRequestModalOpen(true);
                      }}
                    >
                      {engagement.reviews?.length > 0 ? 
                        `⭐ View Reviews (${engagement.reviews.length})` : 
                        '✍️ Request Review'}
                    </div>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  // Render Engagements in Card Format (for Mobile)
  const renderEngagementsCards = () => (
    <div className="grid grid-cols-1 gap-4">
      {engagements.map((engagement) => (
        <div 
          key={engagement.id} 
          className="bg-white shadow-md rounded-lg p-4 relative hover:shadow-lg transition-shadow"
          onClick={() => navigate(`/app/engagement-details/${engagement.id}`)}
        >
          <div className="mb-2">
            <h2 className="text-xl font-bold text-gray-900 text-left">
              {engagement.job ? engagement.job.title : 'No Job Title'}
            </h2>
          </div>
          <div className="text-sm text-gray-500 mb-2 text-left">
            <p>Start Date: {engagement.start_date ? formatDate(engagement.start_date) : ''}</p>
            <p>End Date: {engagement.end_date ? formatDate(engagement.end_date) : ''}</p>
            <p>Contract Rate: ${engagement.contract_rate}</p>
            <p>Status: {engagement.status}</p>
            <p>Client: {engagement.client ? engagement.client.name : 'No Client Name'}</p>
          </div>
          <div className="mt-4 text-left">
            {/* Complete Onboarding button */}
            {userType === 2 && (
              <Link
                to={`/app/consultant-onboarding/${engagement.id}`}
                className="fetch-button"
                onClick={(e) => e.stopPropagation()}
              >
                Complete Onboarding
              </Link>
            )}
            {userType === 4 && (
              <Link
                to={`/app/client-onboarding-progress/${engagement.id}`}
                className="fetch-button"
                onClick={(e) => e.stopPropagation()}
              >
                View Onboarding Progress
              </Link>
            )}
          </div>
          <div className="absolute bottom-4 right-4">
            <MoreHorizontal 
              size={24} 
              className="cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                toggleDropdown(engagement.id);
              }}
            />
            {activeDropdown === engagement.id && (
              <div ref={dropdownRef} className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                <div
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer text-left"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleViewDocument(engagement);
                  }}
                >
                  📄 View Contract
                </div>
                <div
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer text-left"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedEngagement(engagement);
                    setIsReviewRequestModalOpen(true);
                  }}
                >
                  {engagement.reviews?.length > 0 ? 
                    `⭐ View Reviews (${engagement.reviews.length})` : 
                    '✍️ Request Review'}
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
  </div>
);

  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>Engagement Management | fetchConsultant</title>
        <meta name="description" content="Manage your active engagements on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ConsultantHamburger 
          user={user} 
          currentPage="engagements" 
          isOpen={isMenuOpen} 
          onClose={() => setIsMenuOpen(false)} 
        />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={toggleMenu} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold ml-4">Engagements</h1>
            </div>
            <div className="flex items-center">
              <NotificationBell />
            </div>
          </header>
          <main className="p-4">
            <PullToRefresh
              onRefresh={fetchUserTypeAndEngagements}
              className="pull-to-refresh"
              pullingContent={<span>Pull down to refresh...</span>}
              refreshingContent={<div className="flex items-center justify-center"><Spinner /> Refreshing...</div>}
            >
              {isLoading ? (
                <div>Loading engagements...</div>
              ) : engagements.length === 0 ? (
                <p>No active engagements found.</p>
              ) : (
                <>
                  {isMobile ? renderEngagementsCards() : renderEngagementsTable()}
                </>
              )}
            </PullToRefresh>
          </main>
        </div>
      </div>
      {selectedEngagement && (
      <ReviewRequestModal
        isOpen={isReviewRequestModalOpen}
        onClose={() => {
          setIsReviewRequestModalOpen(false);
          setSelectedEngagement(null);
        }}
        engagement={selectedEngagement}
      />
    )}
    </>
  );
};

export default EngagementManagement;