import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown, Check, Users, DollarSign, Clock } from 'lucide-react';
import Header from './ui/Header';
import Footer from './ui/Footer';

const Home = () => {
  return (
    <div className="min-h-screen bg-white">
      <Header />

      <main className="-mt-24">
        <section className="bg-indigo-700 text-white py-20">
          <div className="container mx-auto px-6 relative top-24 text-center">
            <h1 className="text-5xl font-bold mb-4 -mt-4">Revolutionizing Epic Consulting</h1>
            <p className="text-xl mb-8">Direct connections. Transparent pricing. Maximized earnings.</p>
            <div className="flex justify-center space-x-4">
              <Link to="/consultants" className="bg-white text-indigo-700 font-bold py-3 px-8 rounded-full text-lg hover:bg-gray-100 transition duration-300">
                For Consultants
              </Link>
              <Link to="/clients" className="bg-transparent border-2 border-white text-white font-bold py-3 px-8 rounded-full text-lg hover:bg-white hover:text-indigo-700 transition duration-300">
                For Clients
              </Link>
            </div>
            <div className="mt-12 mb-4">
              <ChevronDown className="mx-auto animate-bounce" size={32} />
            </div>
          </div>
        </section>

        <section className="py-20">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">Why Choose fetchConsultant?</h2>
            <div className="grid md:grid-cols-4 gap-8 text-left">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <DollarSign className="text-indigo-600 mb-4 mx-auto" size={32} />
                <h3 className="text-xl font-semibold mb-2 text-center">Maximized Earnings</h3>
                <p className="text-gray-600">Keep more of what you earn with our flat 4.5% platform fee (per party).</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <Users className="text-indigo-600 mb-4 mx-auto" size={32} />
                <h3 className="text-xl font-semibold mb-2 text-center">Direct Connections</h3>
                <p className="text-gray-600">Connect directly with healthcare organizations or top consultants.</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <Clock className="text-indigo-600 mb-4 mx-auto" size={32} />
                <h3 className="text-xl font-semibold mb-2 text-center">Rapid Deployment</h3>
                <p className="text-gray-600">From job posting to placement in days, not weeks.</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <Check className="text-indigo-600 mb-4 mx-auto" size={32} />
                <h3 className="text-xl font-semibold mb-2 text-center">Quality Assured</h3>
                <p className="text-gray-600">Maintain high standards through peer and client reviews.</p>
              </div>
            </div>
          </div>
        </section>

        <section id="how-it-works" className="py-20 bg-gray-100">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">How It Works</h2>
            <div className="max-w-3xl mx-auto text-left">
              <div className="flex items-center mb-6">
                <div className="bg-indigo-600 rounded-full p-3 text-white mr-4">1</div>
                <p className="text-lg"><span className="font-semibold">Sign Up:</span> Create your account as a consultant or client.</p>
              </div>
              <div className="flex items-center mb-6">
                <div className="bg-indigo-600 rounded-full p-3 text-white mr-4">2</div>
                <p className="text-lg"><span className="font-semibold">Complete Your Profile:</span> Add your skills, experience, and preferences.</p>
              </div>
              <div className="flex items-center mb-6">
                <div className="bg-indigo-600 rounded-full p-3 text-white mr-4">3</div>
                <p className="text-lg"><span className="font-semibold">Connect:</span> Post or apply for opportunities that match your needs.</p>
              </div>
              <div className="flex items-center">
                <div className="bg-indigo-600 rounded-full p-3 text-white mr-4">4</div>
                <p className="text-lg"><span className="font-semibold">Collaborate:</span> Work together on exciting healthcare IT projects.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="py-20">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">Success Stories</h2>
            <div className="grid md:grid-cols-2 gap-8 text-left">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <p className="text-gray-600 mb-4">"fetchConsultant helped me increase my earnings by 20% while giving me more control over my career."</p>
                <p className="font-semibold">- Jane Doe, Epic Consultant</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <p className="text-gray-600 mb-4">"We reduced our hiring time by 50% and found high-quality consultants quickly and efficiently."</p>
                <p className="font-semibold">- John Smith, Healthcare IT Director</p>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-gray-900 text-white py-20">
          <div className="container mx-auto px-6 text-center">
            <h2 className="text-3xl font-bold mb-4">Ready to Transform Healthcare IT Staffing?</h2>
            <p className="text-xl mb-8">Join fetchConsultant today and experience the future of Epic consulting.</p>
            <Link to="/app" className="bg-white text-indigo-700 font-bold py-3 px-8 rounded-full text-lg hover:bg-gray-100 transition duration-300">
              Get Started Now
            </Link>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default Home;