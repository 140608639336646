import React from 'react';

const ResponseViewer = ({ response, onConfirm, onEdit }) => (
    <div>
        <h3>Claude's Suggestion</h3>
        <pre>{response}</pre>
        <button onClick={onConfirm}>Confirm</button>
        <button onClick={onEdit}>Edit</button>
    </div>
);

export default ResponseViewer;