import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import BlogCard from './BlogCard';
import BlogSearch from './BlogSearch';
import { getAllPosts, getFeaturedPosts } from '../../utils/blogHelpers';
import Avatar from '../ui/Avatar';
import { Clock } from 'lucide-react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import Header from '../ui/Header';
import Footer from '../ui/Footer';

const BlogLanding = () => {
  const [posts, setPosts] = useState([]);
  const [featuredPosts, setFeaturedPosts] = useState([]);
  const [newestPost, setNewestPost] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('Latest Blogs');
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  // Categories for dropdown
  const categories = [
    'Latest Blogs',
    'Epic Implementation',
    'Healthcare IT',
    'Best Practices',
    'Industry News',
    'Career Tips'
  ];

  // Load posts
  useEffect(() => {
    const loadPosts = async () => {
      try {
        const [allPosts, featured] = await Promise.all([
          getAllPosts(),
          getFeaturedPosts()
        ]);
        
        // Set newest post (first post)
        setNewestPost(allPosts[0]);
        
        // Set featured posts (next 3 posts)
        setFeaturedPosts(featured);
        
        // Set remaining posts
        setPosts(allPosts.slice(1));
      } catch (err) {
        console.error('Error loading posts:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadPosts();
  }, []);

  // Newest Blog Hero Section
  const NewestBlogSection = () => (
    <section className="bg-indigo-700 text-white relative -mt-20">
      <div className="container mx-auto px-6">
        {/* Background image div - hidden on mobile */}
        <div 
          className="absolute top-0 right-0 w-[60%] h-full hidden md:block"
          style={{
            backgroundImage: `url(${newestPost?.image_url})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        />
        
        {/* Content */}
        <div className="grid grid-cols-12">
          <div className="col-span-12 md:col-span-5 bg-indigo-700 z-10 py-20">
            <div className="flex items-center text-sm font-medium mt-4 mb-2">
              <span className="mr-2">Newest Blog • </span>
              <Clock className="h-4 w-4 mr-1" />
              <span>{newestPost?.reading_time || '5 min read'}</span>
            </div>
            <h1 className="text-5xl font-bold mb-6 leading-tight">{newestPost?.title}</h1>
            <p className="text-xl mb-8 text-gray-100">{newestPost?.description}</p>
            <Link
              to={`/blog/${newestPost?.slug}`}
              className="inline-block bg-white text-indigo-700 px-4 py-2 rounded-full font-bold text-lg hover:bg-gray-100 transition duration-300 mb-8"
            >
              Read More
            </Link>
            <div className="flex items-center">
              <img
                src={newestPost?.author_photo || '/default-avatar.png'}
                alt={newestPost?.author_name}
                className="h-12 w-12 rounded-full border-2 border-white"
              />
              <div className="ml-4">
                <p className="text-sm text-gray-200">Written by</p>
                <p className="font-medium text-lg">{newestPost?.author_name}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  // Combined Blog Sections
  const BlogSections = () => {
    const totalPages = Math.ceil(filteredPosts.length / 6);
    const showPagination = totalPages > 1;

    return (
      <div className="bg-white">
        <div className="container mx-auto px-6 p-8 -mt-24 relative z-10">
          <div className="bg-white rounded-xl p-0 px-1">
            {/* Blog Title and Search */}
            <div className="flex justify-between p-1 items-center mb-8">
              <h2 className="text-2xl font-bold text-gray-900 pl-4">Blog</h2>
              <div className="w-96">
                <BlogSearch 
                  value={searchQuery} 
                  onChange={(value) => setSearchQuery(value)}
                />
              </div>
            </div>

            {/* Featured Blogs */}
            <h2 className="text-3xl font-bold mb-8">Featured Blogs</h2>
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-8 mb-8">
              {/* Left column - 50% width */}
              <div className="lg:col-span-2">
                {featuredPosts[0] && (
                  <Link
                    to={`/blog/${featuredPosts[0].slug}`}
                    className="block transition-shadow duration-300 hover:shadow-lg h-full"
                  >
                    <article className="bg-white overflow-hidden flex flex-col h-[600px]">
                      <div className="h-[60%]">
                        <img
                          src={featuredPosts[0].image_url || '/images/blog/default.jpg'}
                          alt={featuredPosts[0].title}
                          className="w-full h-full object-cover rounded-lg"
                        />
                      </div>
                      <div className="p-0 mt-5 h-[40%] flex flex-col">
                        {/* Reading Time */}
                        <div className="flex items-center text-sm text-gray-500 mb-2">
                          <Clock className="h-4 w-4 mr-1" />
                          {featuredPosts[0].reading_time || '5 min read'}
                        </div>

                        <div className="flex-1">
                          <h3 className="text-xl font-semibold text-gray-900 group-hover:text-indigo-600 line-clamp-2">
                            {featuredPosts[0].title}
                          </h3>
                          <p className="mt-2 text-base text-gray-500 line-clamp-2">
                            {featuredPosts[0].description}
                          </p>
                        </div>
                        <div className="mt-auto pt-4 border-gray-100">
                          <div className="flex items-center">
                            <Avatar
                              photo={featuredPosts[0].author_photo}
                              name={featuredPosts[0].author_name}
                              className="h-10 w-10"
                            />
                            <div className="ml-3">
                              <p className="text-xs text-gray-500">Written by</p>
                              <p className="text-sm font-medium text-gray-900">
                                {featuredPosts[0].author_name || 'Anonymous'}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>
                  </Link>
                )}
              </div>

              {/* Right columns - Combined cards - 50% width */}
              <div className="lg:col-span-2 flex flex-col justify-between h-[600px] space-y-8">
                {featuredPosts.slice(1, 3).map((post, index) => (
                  <Link
                    key={post.id}
                    to={`/blog/${post.slug}`}
                    className="block transition-shadow duration-300 hover:shadow-lg h-[calc(50%-1rem)]"
                  >
                    <article className="bg-white overflow-hidden flex h-full">
                      <div className="w-1/2">
                        <img
                          src={post.image_url || '/images/blog/default.jpg'}
                          alt={post.title}
                          className="w-full h-full object-cover rounded-lg"
                        />
                      </div>
                      <div className="w-1/2 p-0 px-4 mt-2 flex flex-col">
                        {/* Reading Time */}
                        <div className="flex items-center text-sm text-gray-500 mb-2">
                          <Clock className="h-4 w-4 mr-1" />
                          {post.reading_time || '5 min read'}
                        </div>

                        <div className="flex-1">
                          <h3 className="text-xl font-semibold text-gray-900 group-hover:text-indigo-600 line-clamp-2">
                            {post.title}
                          </h3>
                          <p className="mt-2 text-base text-gray-500 line-clamp-2">
                            {post.description}
                          </p>
                        </div>
                        <div className="mt-auto pt-4 border-gray-100">
                          <div className="flex items-center">
                            <Avatar
                              photo={post.author_photo}
                              name={post.author_name}
                              className="h-10 w-10"
                            />
                            <div className="ml-3">
                              <p className="text-xs text-gray-500">Written by</p>
                              <p className="text-sm font-medium text-gray-900">
                                {post.author_name || 'Anonymous'}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>
                  </Link>
                ))}
              </div>
            </div>

            {/* Main Blog List */}
            <div className="pt-8">
              <div className="flex justify-between items-center mb-8">
                <select
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  className="block w-84 pl-3 pr-10 py-2 text-base focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-3xl font-bold rounded-md border-none"
                >
                  {categories.map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {filteredPosts.slice((currentPage - 1) * 6, currentPage * 6).map((post) => (
                  <BlogCard key={post.id} post={post} />
                ))}
              </div>
            </div>

            {/* Pagination */}
            {showPagination && (
              <div className="mt-12 border-t border-gray-200 pt-6 flex items-center justify-between">
                <button 
                  className="flex items-center text-gray-600 hover:text-indigo-600"
                  disabled={currentPage === 1}
                >
                  <ChevronLeft className="h-5 w-5 mr-2" />
                  Previous
                </button>
                <div className="flex space-x-2">
                  {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                    <button
                      key={page}
                      className={`px-4 py-2 rounded-md ${
                        page === currentPage
                          ? 'bg-indigo-600 text-white'
                          : 'text-gray-600 hover:bg-gray-100'
                      }`}
                    >
                      {page}
                    </button>
                  ))}
                </div>
                <button 
                  className="flex items-center text-gray-600 hover:text-indigo-600"
                  disabled={currentPage === totalPages}
                >
                  Next
                  <ChevronRight className="h-5 w-5 ml-2" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  // Filter posts based on category and search
  const filteredPosts = posts.filter(post => {
    const matchesSearch = !searchQuery || 
      post.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      post.description.toLowerCase().includes(searchQuery.toLowerCase());
    
    const matchesCategory = selectedCategory === 'Latest Blogs' || 
      (post.categories && post.categories.includes(selectedCategory));
    
    return matchesSearch && matchesCategory;
  });

  // Call to Action Section
  const CallToAction = () => (
    <div className="bg-white py-8">
      <div className="container mx-auto px-4 md:px-6">
        <div className="max-w mx-auto">
          <div className="bg-gray-900 text-white rounded-lg p-6 md:p-8">
            <div className="flex flex-col items-center text-center lg:text-left lg:flex-row lg:justify-between space-y-6 lg:space-y-0">
              {/* Left Section: Headline and Button */}
              <div className="w-full lg:w-1/2">
                <h2 className="text-3xl lg:text-4xl font-bold mb-6">
                  Ready to Get Started
                </h2>
                <Link
                  to="/app/auth"
                  className="inline-block bg-white text-indigo-700 px-8 py-4 rounded-full font-bold text-lg hover:bg-gray-100 transition duration-300"
                >
                  Join Now
                </Link>
              </div>
  
              {/* Right Section: Supporting Text */}
              <div className="w-full lg:w-1/2 lg:text-right">
                <h3 className="text-xl font-semibold mb-2">Join fetchConsultant</h3>
                <p className="text-gray-300 text-lg">
                  Experience the future of Epic consulting.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="min-h-screen text-left bg-white flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
          <p className="mt-2 text-gray-600">Loading posts...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen text-left bg-white flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900">Error loading posts</h2>
          <p className="mt-2 text-gray-600">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white text-left">
      <Helmet>
        <title>Blog | fetchConsultant</title>
        <meta 
          name="description" 
          content="Insights, guides, and resources for healthcare IT consultants and organizations." 
        />
      </Helmet>

      <Header />
      <NewestBlogSection />
      <BlogSections />
      <CallToAction />
      <Footer />
    </div>
  );
};

export default BlogLanding;