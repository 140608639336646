// ClientOnboardingRequirements.js
import { getAnalytics, logEvent } from "firebase/analytics";
import { Edit2, Menu, Plus, Trash } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { supabase } from '../supabaseClient';
import ClientHamburger from './ClientHamburger';
import NotificationBell from "./NotificationBell";
import StatusBanner from './ui/StatusBanner';

const ClientOnboardingRequirements = () => {
  const [requirements, setRequirements] = useState([]);
  const [newRequirement, setNewRequirement] = useState({ name: '', is_mandatory: true, description: '' });
  const [loading, setLoading] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [savingStatus, setSavingStatus] = useState(null);
  const [documentFile, setDocumentFile] = useState(null);
  const [editingRequirement, setEditingRequirement] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [editFormData, setEditFormData] = useState({
    name: '',
    is_mandatory: true,
    description: '',
  });
  const [editDocumentFile, setEditDocumentFile] = useState(null);

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Client Onboarding Requirements',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUser();
  }, []);

  useEffect(() => {
    if (user?.employer) {
      fetchRequirements();
    }
  }, [user]);

  const fetchUser = async () => {
    try {
      const { data: { user: authUser } } = await supabase.auth.getUser();
      if (!authUser) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*, clients:employer(*)')
        .eq('user_id', authUser.id)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error.message);
    }
  };

  const fetchRequirements = async () => {
    try {
      const { data, error } = await supabase
        .from('onboarding_requirements')
        .select('*')
        .eq('client_id', user.employer)
        .order('created_at', { ascending: true });

      if (error) throw error;
      setRequirements(data);
    } catch (error) {
      console.error('Error fetching requirements:', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (requirement) => {
    setEditingRequirement(requirement);
    setEditFormData({
      name: requirement.requirement_name,
      is_mandatory: requirement.is_mandatory,
      description: requirement.description || '',
    });
  };

  const handleAddRequirement = async () => {
    if (!newRequirement.name.trim()) {
      setSavingStatus('error');
      return;
    }

    try {
      setSavingStatus('saving');
      const { data: { user: authUser } } = await supabase.auth.getUser();

      let documentUrl = null;
      if (documentFile) {
        const fileExt = documentFile.name.split('.').pop();
        const fileName = `${Date.now()}.${fileExt}`;
        const filePath = `${user.employer}/${fileName}`;

        const { error: uploadError } = await supabase.storage
          .from('onboarding_documents')
          .upload(filePath, documentFile);

        if (uploadError) throw uploadError;

        documentUrl = filePath;
      }

      const { data, error } = await supabase
        .from('onboarding_requirements')
        .insert({
          client_id: user.employer,
          requirement_name: newRequirement.name.trim(),
          is_mandatory: newRequirement.is_mandatory,
          description: newRequirement.description.trim(),
          document_url: documentUrl,
          requirement_type: 'client-specific',
          created_by: authUser.id
        })
        .select();

      if (error) throw error;

      setRequirements([...requirements, data[0]]);
      setNewRequirement({ name: '', is_mandatory: true, description: '' });
      setDocumentFile(null);
      setShowAddForm(false); // Hide form after successful save
      setSavingStatus('saved');

      const analytics = getAnalytics();
      logEvent(analytics, 'requirement_added', {
        client_id: user.employer
      });

      setTimeout(() => setSavingStatus(null), 2000);
    } catch (error) {
      console.error('Error adding requirement:', error.message);
      setSavingStatus('error');
    }
  };

  // Add new cancel handler
  const handleCancelAdd = () => {
    setShowAddForm(false);
    setNewRequirement({ name: '', is_mandatory: true, description: '' });
    setDocumentFile(null);
  };

  const getDocumentUrl = async (filePath) => {
    if (!filePath) return null;
    
    try {
      const { data: { signedUrl }, error } = await supabase.storage
        .from('onboarding_documents')
        .createSignedUrl(filePath, 3600); // 1 hour expiration
  
      if (error) {
        console.error('Error getting document URL:', error);
        return null;
      }
  
      return signedUrl;
    } catch (error) {
      console.error('Error getting document URL:', error);
      return null;
    }
  };

  const handleUpdateRequirement = async () => {
    if (!editFormData.name.trim()) {
      setSavingStatus('error');
      return;
    }
  
    try {
      setSavingStatus('saving');
  
      let documentUrl = null;
      if (editDocumentFile) {
        const fileExt = editDocumentFile.name.split('.').pop();
        const fileName = `${Date.now()}.${fileExt}`;
        const filePath = `${user.employer}/${fileName}`;
  
        const { error: uploadError } = await supabase.storage
          .from('onboarding_documents')
          .upload(filePath, editDocumentFile);
  
        if (uploadError) throw uploadError;
  
        documentUrl = filePath;
      }
  
      const updateData = {
        requirement_name: editFormData.name.trim(),
        is_mandatory: editFormData.is_mandatory,
        description: editFormData.description.trim(),
        ...(documentUrl && { document_url: documentUrl }),
      };
  
      const { error } = await supabase
        .from('onboarding_requirements')
        .update(updateData)
        .eq('id', editingRequirement.id);
  
      if (error) throw error;
  
      const analytics = getAnalytics();
      logEvent(analytics, 'requirement_updated', {
        client_id: user.employer
      });
  
      // Update local state
      setRequirements(requirements.map(req => 
        req.id === editingRequirement.id 
          ? { ...req, ...updateData }
          : req
      ));
      
      setEditingRequirement(null);
      setEditFormData({ name: '', is_mandatory: true, description: '' });
      setEditDocumentFile(null);
      setSavingStatus('saved');
  
      setTimeout(() => setSavingStatus(null), 2000);
    } catch (error) {
      console.error('Error updating requirement:', error.message);
      setSavingStatus('error');
    }
  };

  const handleDeleteRequirement = async (id) => {
    if (!window.confirm('Are you sure you want to delete this requirement?')) {
      return;
    }

    try {
      setSavingStatus('saving');
      const { error } = await supabase
        .from('onboarding_requirements')
        .delete()
        .eq('id', id);

      if (error) throw error;

      setRequirements(requirements.filter(req => req.id !== id));
      setSavingStatus('saved');

      const analytics = getAnalytics();
      logEvent(analytics, 'requirement_deleted', {
        client_id: user.employer
      });

      setTimeout(() => setSavingStatus(null), 2000);
    } catch (error) {
      console.error('Error deleting requirement:', error.message);
      setSavingStatus('error');
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="text-gray-500">Loading...</div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Onboarding Requirements | fetchConsultant</title>
        <meta 
          name="description" 
          content="Manage consultant onboarding requirements for your organization on fetchConsultant." 
        />
      </Helmet>

      <div className="min-h-screen bg-gray-100 flex">
        <ClientHamburger 
          user={user} 
          currentPage="onboardingRequirements" 
          isOpen={isMenuOpen} 
          onClose={() => setIsMenuOpen(false)} 
        />
        
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">Onboarding Requirements</h1>
            </div>
            <div className="flex items-center">
              <NotificationBell />
            </div>
          </header>

          <main className="flex-grow container mx-auto px-4 py-8">
            <div className="max-w-3xl mx-auto">
              <div className="mb-4">
                <StatusBanner
                  status={savingStatus}
                  message={
                    savingStatus === 'saving'
                      ? 'Saving changes...'
                      : savingStatus === 'saved'
                      ? 'Changes saved successfully'
                      : savingStatus === 'error'
                      ? 'Error saving changes'
                      : ''
                  }
                />
              </div>

              <div className="bg-white text-left rounded-lg shadow-sm p-6">
                <div className="mb-6">
                  <h2 className="text-lg font-semibold mb-4">Current Requirements</h2>
                  {requirements.length === 0 ? (
                    <p className="text-gray-500">No requirements added yet.</p>
                  ) : (
                    <ul className="space-y-3">
                      {requirements.map((req) => (
                        <li 
                          key={req.id} 
                          className="p-3 bg-gray-50 rounded-lg"
                        >
                          {editingRequirement?.id === req.id ? (
                            <div className="space-y-4">
                              <input
                                type="text"
                                value={editFormData.name}
                                onChange={(e) => setEditFormData({ ...editFormData, name: e.target.value })}
                                placeholder="Enter requirement name"
                                className="fetch-input"
                              />
                              <textarea
                                value={editFormData.description}
                                onChange={(e) => setEditFormData({ ...editFormData, description: e.target.value })}
                                placeholder="Enter requirement description"
                                className="fetch-textarea"
                              />
                              <div className="flex items-center">
                                <label className="inline-flex items-center cursor-pointer">
                                  <input
                                    type="checkbox"
                                    checked={editFormData.is_mandatory}
                                    onChange={(e) => setEditFormData({ ...editFormData, is_mandatory: e.target.checked })}
                                    className="fetch-checkbox"
                                  />
                                  <span className="ml-2">Mandatory requirement</span>
                                </label>
                              </div>
                              <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                  Upload New Document (Optional)
                                </label>
                                <input
                                  type="file"
                                  onChange={(e) => setEditDocumentFile(e.target.files[0])}
                                  className="fetch-input"
                                />
                              </div>
                              <div className="flex justify-end space-x-2">
                                <button
                                  type="button"
                                  onClick={() => setEditingRequirement(null)}
                                  className="inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                  >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  onClick={handleUpdateRequirement}
                                  className="inline-flex items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                  >
                                  Save Changes
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div className="flex items-start justify-between">
                              <div className="flex-1 cursor-pointer" onClick={() => handleEditClick(req)}>
                                <span className="font-medium">{req.requirement_name}</span>
                                <span className={`ml-2 text-sm ${req.is_mandatory ? 'text-red-600' : 'text-gray-500'}`}>
                                  ({req.is_mandatory ? 'Required' : 'Optional'})
                                </span>
                                <p className="text-sm text-gray-600 mt-1">{req.description}</p>
                                {req.document_url && (
                                  <a
                                    href="#"
                                    onClick={async (e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      const url = await getDocumentUrl(req.document_url);
                                      if (url) {
                                        window.open(url, '_blank');
                                      }
                                    }}
                                    className="text-indigo-600 hover:text-indigo-800 text-sm mt-1 block"
                                  >
                                    View Attached Document
                                  </a>
                                )}
                              </div>
                              <div className="flex space-x-2">
                                <button 
                                  onClick={() => handleEditClick(req)}
                                  className="text-indigo-600 hover:text-indigo-800"
                                >
                                  <Edit2 size={20} />
                                </button>
                                <button 
                                  onClick={() => handleDeleteRequirement(req.id)}
                                  className="text-red-600 hover:text-red-800"
                                >
                                  <Trash size={20} />
                                </button>
                              </div>
                            </div>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                <div>
                  {!showAddForm ? (
                    <button
                      onClick={() => setShowAddForm(true)}
                      className="inline-flex items-center text-brandPrimary hover:text-brandHover"
                    >
                      <Plus size={20} className="mr-2" />
                      Add New Requirement
                    </button>
                  ) : (
                    <div className="space-y-4">
                      <h2 className="text-lg font-semibold mb-4">Add New Requirement</h2>
                      <div>
                        <input
                          type="text"
                          value={newRequirement.name}
                          onChange={(e) => setNewRequirement({ ...newRequirement, name: e.target.value })}
                          placeholder="Enter requirement name"
                          className="fetch-input"
                        />
                      </div>
                      <div>
                        <textarea
                          value={newRequirement.description}
                          onChange={(e) => setNewRequirement({ ...newRequirement, description: e.target.value })}
                          placeholder="Enter requirement description"
                          className="fetch-textarea"
                        />
                      </div>
                      <div className="flex items-center">
                        <label className="inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            checked={newRequirement.is_mandatory}
                            onChange={(e) => setNewRequirement({ ...newRequirement, is_mandatory: e.target.checked })}
                            className="fetch-checkbox"
                          />
                          <span className="ml-2">Mandatory requirement</span>
                        </label>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Upload Associated Document (Optional)
                        </label>
                        <input
                          type="file"
                          onChange={(e) => setDocumentFile(e.target.files[0])}
                          className="fetch-input"
                        />
                      </div>
                      <div className="flex justify-end space-x-2">
                        <button
                          type="button"
                          onClick={handleCancelAdd}
                          className="inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          onClick={handleAddRequirement}
                          className="inline-flex items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                          disabled={!newRequirement.name.trim()}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ClientOnboardingRequirements;