import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Clock, Calendar, Tag, ChevronLeft, ChevronRight } from 'lucide-react';
import { getPostBySlug, getRelatedPosts } from '../../utils/blogHelpers';
import Avatar from '../ui/Avatar';
import Header from '../ui/Header';
import Footer from '../ui/Footer';

const BlogPost = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [carouselIndex, setCarouselIndex] = useState(0);

  useEffect(() => {
    const loadPost = async () => {
      try {
        const postData = await getPostBySlug(slug);
        const related = await getRelatedPosts(slug);
        setPost(postData);
        setRelatedPosts(related);
      } catch (err) {
        console.error('Error loading post:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadPost();
  }, [slug]);

  // Blog Carousel Navigation
  const handlePrevious = () => {
    setCarouselIndex(Math.max(0, carouselIndex - 1));
  };

  const handleNext = () => {
    setCarouselIndex(Math.max(0, Math.min(relatedPosts.length - 4, carouselIndex + 1)));
  };

  // Call to Action Section
  const CallToAction = () => (
    <div className="bg-white py-8">
      <div className="container mx-auto px-4 md:px-6">
        <div className="max-w mx-auto">
          <div className="bg-gray-900 text-white rounded-lg p-6 md:p-8">
            <div className="flex flex-col items-center text-center lg:text-left lg:flex-row lg:justify-between space-y-6 lg:space-y-0">
              {/* Left Section: Headline and Button */}
              <div className="w-full lg:w-1/2">
                <h2 className="text-3xl lg:text-4xl font-bold mb-6">
                  Ready to Get Started
                </h2>
                <Link
                  to="/app/auth"
                  className="inline-block bg-white text-indigo-700 px-8 py-4 rounded-full font-bold text-lg hover:bg-gray-100 transition duration-300"
                >
                  Join Now
                </Link>
              </div>
  
              {/* Right Section: Supporting Text */}
              <div className="w-full lg:w-1/2 lg:text-right">
                <h3 className="text-xl font-semibold mb-2">Join fetchConsultant</h3>
                <p className="text-gray-300 text-lg">
                  Experience the future of Epic consulting.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="min-h-screen text-left bg-white flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
          <p className="mt-2 text-gray-600">Loading post...</p>
        </div>
      </div>
    );
  }

  if (error || !post) {
    return (
      <div className="min-h-screen text-left bg-white flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900">Post not found</h2>
          <p className="mt-2 text-gray-600">
            {error || "The blog post you're looking for doesn't exist."}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen text-left bg-white">
      <Helmet>
        <title>{post.title} | fetchConsultant Blog</title>
        <meta name="description" content={post.description || ''} />
      </Helmet>

      <Header />

      {/* Hero Section with Full-width Image and Overlay Text */}
      <div className="relative w-full -mt-24 h-[600px]">
        <img
          src={post.image_url}
          alt={post.title}
          className="w-full h-full object-cover"
        />
        <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-12">
          <div className="container mx-auto">
            <div className="max-w-4xl">
              <h1 className="text-5xl font-bold text-white mb-6">{post.title}</h1>
              <div className="flex items-center text-white">
                <Avatar
                  photo={post.author_photo}
                  name={post.author_name}
                  className="h-12 w-12"
                />
                <div className="ml-4">
                  <p className="font-medium text-lg">{post.author_name}</p>
                  <div className="flex items-center space-x-4">
                    <span className="flex items-center">
                      <Calendar className="h-4 w-4 mr-1" />
                      {new Date(post.published_at).toLocaleDateString()}
                    </span>
                    <span>•</span>
                    <span className="flex items-center">
                      <Clock className="h-4 w-4 mr-1" />
                      {post.reading_time}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Two-column Content Section */}
      <div className="container mx-auto px-6 py-12">
        <div className="grid grid-cols-12 gap-8">
          {/* Left Column - Meta Information */}
          <div className="col-span-3">
            <div className="sticky top-24">
              <div className="mb-8">
                <h3 className="text-lg font-semibold mb-4">Categories</h3>
                <div className="flex flex-wrap gap-2">
                  {post.categories?.map(category => (
                    <span
                      key={category}
                      className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-indigo-100 text-indigo-800"
                    >
                      {category}
                    </span>
                  ))}
                </div>
              </div>
              {post.tags?.length > 0 && (
                <div>
                  <h3 className="text-lg font-semibold mb-4">Tags</h3>
                  <div className="flex flex-wrap gap-2">
                    {post.tags.map(tag => (
                      <span
                        key={tag}
                        className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
                      >
                        <Tag className="h-3 w-3 mr-1" />
                        {tag}
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Right Column - Main Content */}
          <div className="col-span-9">
            <div className="prose prose-indigo max-w-none">
              <div dangerouslySetInnerHTML={{ __html: post.content || '' }} />
            </div>
          </div>
        </div>
      </div>

      {/* Related Posts Carousel */}
      <div className="bg-white py-16">
        <div className="container mx-auto px-6">
          <div className="flex justify-between items-center mb-8">
            <h2 className="text-3xl font-bold">Related Posts</h2>
            <div className="flex space-x-2">
              <button
                onClick={handlePrevious}
                className="p-2 rounded-full bg-white shadow-md hover:bg-gray-50"
                disabled={carouselIndex === 0}
              >
                <ChevronLeft className="h-6 w-6" />
              </button>
              <button
                onClick={handleNext}
                className="p-2 rounded-full bg-white shadow-md hover:bg-gray-50"
                disabled={carouselIndex >= relatedPosts.length - 4}
              >
                <ChevronRight className="h-6 w-6" />
              </button>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {relatedPosts.slice(carouselIndex, carouselIndex + 4).map((post) => (
              <Link
                key={post.id}
                to={`/blog/${post.slug}`}
                className="block bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
              >
                <div className="h-48">
                  <img
                    src={post.image_url}
                    alt={post.title}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-semibold text-gray-900 mb-4">
                    {post.title}
                  </h3>
                  <div className="flex items-center">
                    <Avatar
                      photo={post.author_photo}
                      name={post.author_name}
                      className="h-8 w-8"
                    />
                    <div className="ml-3">
                      <p className="text-sm font-medium text-gray-900">
                        {post.author_name}
                      </p>
                      <div className="flex items-center text-sm text-gray-500">
                        <Clock className="h-4 w-4 mr-1" />
                        {post.reading_time}
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>

      <CallToAction />
      <Footer />
    </div>
  );
};

export default BlogPost;