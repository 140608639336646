import { getAnalytics, logEvent } from "firebase/analytics";
import { Menu, Upload, Github } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { supabase } from '../supabaseClient';
import SystemAdminHamburger from './SystemAdminHamburger';
import NotificationBell from './NotificationBell';

const SystemAdminClaude = () => {
  // ... existing state ...
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [githubRepo, setGithubRepo] = useState('');
  const [githubBranch, setGithubBranch] = useState('main');
  const [contextFiles, setContextFiles] = useState([]);
  const [githubAnalysis, setGithubAnalysis] = useState(null);
  const [allFiles, setAllFiles] = useState([]);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'System Admin Claude',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (session?.user) {
        setUser(session.user);
      }
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  };

  // Handle file selection
  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);

    // Read file contents
    files.forEach(file => {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const content = e.target.result;
        setContextFiles(prev => [...prev, {
          name: file.name,
          content: content,
          type: 'file'
        }]);
      };
      reader.readAsText(file);
    });
  };

  // Handle GitHub repo input
  const handleGithubRepo = async () => {
    try {
      setIsLoading(true);
      const { data: { session } } = await supabase.auth.getSession();
      
      const response = await fetch(`${supabaseUrl}/functions/v1/github-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session?.access_token}`
        },
        body: JSON.stringify({
          action: 'analyzeRepo',
          data: { 
            repo: githubRepo,
            branch: githubBranch,
            query: query // Include current query for context
          }
        })
      });
  
      if (!response.ok) {
        throw new Error('Failed to analyze GitHub repository');
      }
  
      const data = await response.json();
      setGithubAnalysis(data.reasoning);
      setAllFiles(data.allFiles);
      setSelectedFiles(data.files);
      setContextFiles(prev => [...prev, ...data.files]);
    } catch (error) {
      console.error('Error analyzing GitHub repo:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFetchFile = async (filePath) => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      
      const response = await fetch(`${supabaseUrl}/functions/v1/github-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session?.access_token}`
        },
        body: JSON.stringify({
          action: 'fetchFiles',
          data: { 
            repo: githubRepo,
            branch: githubBranch,
            filePaths: [filePath]
          }
        })
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch file content');
      }
  
      const { files } = await response.json();
      if (files && files.length > 0) {
        setSelectedFiles(prev => [...prev, files[0]]);
        setContextFiles(prev => [...prev, files[0]]);
      }
    } catch (error) {
      console.error('Error fetching file:', error);
      setError(error.message);
    }
  };
  
  // Modified handleAskClaude to include context
  const handleAskClaude = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
  
    // Add user message to history first
    setMessages(prev => [...prev, {
      role: 'user',
      content: query
    }]);
  
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.access_token) {
        throw new Error('No active session');
      }
  
      const response = await fetch(`${supabaseUrl}/functions/v1/claude-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.access_token}`
        },
        body: JSON.stringify({
          action: 'askClaude',
          data: {
            query,
            context: contextFiles,
            messages: messages // Send conversation history
          }
        })
      });
  
      console.log('Response received:', response.status);
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to get response from Claude');
      }
  
      const responseData = await response.json();
      console.log('Response data:', responseData);
  
      if (typeof responseData.data === 'string') {
        // Update both the immediate response and conversation history
        setResponse(responseData.data);
        setMessages(prev => [...prev, {
          role: 'assistant',
          content: responseData.data
        }]);
  
        // Save to database
        if (user?.id) {
          await supabase.from('code_changes').insert({
            query,
            suggestion: responseData.data,
            created_by: user.id,
            context: { 
              conversation_history: messages,
              files: contextFiles 
            }
          });
        }
  
        // Clear input after successful response
        setQuery('');
      } else {
        console.error('Unexpected response format:', responseData);
        throw new Error('Received unexpected response format from server');
      }
    } catch (error) {
      console.error('Error asking Claude:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Claude Assistant | fetchConsultant</title>
        <meta name="description" content="System admin Claude interface" />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <SystemAdminHamburger user={user} currentPage="claude" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">Claude Assistant</h1>
            </div>
            <NotificationBell />
          </header>
          
          <main className="p-6">
            <div className="bg-white text-left rounded-lg shadow p-6">
              {/* Context Section */}
              <div className="mb-6">
                <h2 className="text-lg font-semibold mb-4">Context</h2>
                
                {/* File Upload */}
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Upload Files
                  </label>
                  <div className="flex items-center space-x-2">
                    <input
                      type="file"
                      multiple
                      onChange={handleFileSelect}
                      className="hidden"
                      id="file-upload"
                    />
                    <label
                      htmlFor="file-upload"
                      className="cursor-pointer bg-gray-50 px-4 py-2 rounded-md border border-gray-300 flex items-center space-x-2"
                    >
                      <Upload size={16} />
                      <span>Select Files</span>
                    </label>
                  </div>
                </div>

                {/* GitHub Repository */}
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    GitHub Repository
                  </label>
                  <div className="flex items-center space-x-2">
                    <input
                      type="text"
                      value={githubRepo}
                      onChange={(e) => setGithubRepo(e.target.value)}
                      placeholder="owner/repository"
                      className="fetch-input w-10"
                    />
                    <input
                      type="text"
                      value={githubBranch}
                      onChange={(e) => setGithubBranch(e.target.value)}
                      placeholder="branch"
                      className="fetch-input w-32"
                    />
                    <button
                      onClick={handleGithubRepo}
                      className="bg-gray-800 text-white px-4 py-2 rounded-md flex items-center space-x-2"
                    >
                      <Github size={16} />
                      <span>Fetch</span>
                    </button>
                  </div>

                  {/* Add the new code here */}
                  {githubAnalysis && (
                    <div className="mt-4 p-4 bg-blue-50 rounded-md">
                      <h3 className="text-sm font-medium text-blue-900 mb-2">
                        Claude's Analysis
                      </h3>
                      <p className="text-sm text-blue-800">{githubAnalysis}</p>
                    </div>
                  )}

                  {allFiles.length > 0 && (
                    <div className="mt-4">
                      <h3 className="text-sm font-medium text-gray-700 mb-2">
                        All Repository Files
                      </h3>
                      <div className="max-h-60 overflow-y-auto bg-gray-50 p-2 rounded-md">
                        {allFiles.map((file, index) => (
                          <div key={index} className="flex items-center space-x-2 py-1">
                            <input
                              type="checkbox"
                              checked={selectedFiles.some(f => f.name === file)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  handleFetchFile(file);
                                } else {
                                  setSelectedFiles(files => files.filter(f => f.name !== file));
                                  setContextFiles(files => files.filter(f => f.name !== file));
                                }
                              }}
                              className="fetch-checkbox"
                            />
                            <span className="text-sm text-gray-600">{file}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>

                {/* Context Files List */}
                {contextFiles.length > 0 && (
                  <div className="mt-4">
                    <h3 className="text-sm font-medium text-gray-700 mb-2">
                      Context Files ({contextFiles.length})
                    </h3>
                    <div className="bg-gray-50 p-2 rounded-md">
                      {contextFiles.map((file, index) => (
                        <div key={index} className="flex items-center justify-between py-1">
                          <span className="text-sm text-gray-600">{file.name}</span>
                          <button
                            onClick={() => setContextFiles(files => files.filter((_, i) => i !== index))}
                            className="text-red-600 hover:text-red-800"
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              {response && (
              <div className="mt-6 space-y-4">
                {messages.map((message, index) => (
                  <div 
                    key={index} 
                    className={`p-4 rounded-lg ${
                      message.role === 'user' 
                        ? 'bg-blue-50 ml-12' 
                        : 'bg-gray-50 mr-12'
                    }`}
                  >
                    <p className="text-xs text-gray-500 mb-1">
                      {message.role === 'user' ? 'You' : 'Claude'}
                    </p>
                    <pre className="whitespace-pre-wrap font-sans text-gray-800">
                      {message.content}
                    </pre>
                  </div>
                ))}
              </div>
              )}
              
              <form onSubmit={handleAskClaude} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Ask Claude
                  </label>
                  <textarea
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    className="w-full p-2 border rounded-md"
                    rows={4}
                    placeholder="Enter your query..."
                  />
                </div>
                
                {error && (
                  <div className="text-red-600 text-sm">
                    {error}
                  </div>
                )}

                <button
                  type="submit"
                  disabled={isLoading || !query.trim()}
                  className={`bg-blue-600 text-white px-4 py-2 rounded-md ${
                    (isLoading || !query.trim()) ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'
                  }`}
                >
                  {isLoading ? 'Asking Claude...' : 'Ask Claude'}
                </button>
              </form>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default SystemAdminClaude;