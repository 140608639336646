import { getAnalytics, logEvent } from "firebase/analytics";
import { Calendar, ChevronLeft, Menu, Users, Briefcase, Building, Gift, X } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ConsultantHamburger from './ConsultantHamburger';
import NotificationBell from "./NotificationBell";

const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(amount);
};

const ReferralDetailModal = ({ isOpen, onClose, referral }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 max-w-2xl w-full mx-4 max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold">Referral Details</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        
        <div className="space-y-4">
          <div>
            <h4 className="font-medium text-gray-700">Referred User</h4>
            <p>{referral.referred_user?.name || 'Pending'}</p>
            <p className="text-sm text-gray-500">
              Type: {getReferralUserType(referral.referred_user?.user_type_id)}
            </p>
          </div>

          {referral.job && (
            <div>
              <h4 className="font-medium text-gray-700">Job</h4>
              <p>{referral.job.title}</p>
            </div>
          )}

          <div>
            <h4 className="font-medium text-gray-700">Earnings</h4>
            {referral.earnings?.map((earning, index) => (
              <div key={index} className="flex justify-between text-sm">
                <span>{earning.bonus_type.replace('_', ' ').toUpperCase()}</span>
                <span>{formatCurrency(earning.amount)}</span>
              </div>
            ))}
          </div>

          <div>
            <h4 className="font-medium text-gray-700">Status Timeline</h4>
            <div className="text-sm space-y-2">
              <div className="flex justify-between">
                <span>Referred</span>
                <span>{new Date(referral.created_at).toLocaleDateString()}</span>
              </div>
              {referral.bonus_paid_at && (
                <div className="flex justify-between">
                  <span>Bonus Paid</span>
                  <span>{new Date(referral.bonus_paid_at).toLocaleDateString()}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ReferralsDashboard = () => {
  const [earnings, setEarnings] = useState({
    oneTime: {
      consultant: 0,
      client: 0,
    },
    consultant: {
      tier1: 0,
      tier2: 0,
      tier3: 0,
    },
    client: {
      tier1: 0,
      tier2: 0,
      tier3: 0,
    },
    job: 0,
    total: 0
  });
  const [recentActivity, setRecentActivity] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dateRange, setDateRange] = useState({
    start: new Date(new Date().getFullYear(), 0, 1).toISOString().split('T')[0],
    end: new Date().toISOString().split('T')[0]
  });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [selectedReferral, setSelectedReferral] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Referral Dashboard',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUser();
  }, []);

  useEffect(() => {
    if (user) {
      fetchReferralData();
    }
  }, [user, dateRange]);

  const fetchUser = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error.message);
    }
  };

  const fetchReferralData = async () => {
    try {
      setIsLoading(true);
      const { data: { session } } = await supabase.auth.getSession();
      
      const response = await fetch(`${process.env.REACT_APP_SUPABASE_URL}/functions/v1/get-referral-earnings`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session?.access_token}`
        },
        body: JSON.stringify({
          userId: user.user_id,
          startDate: dateRange.start,
          endDate: dateRange.end
        })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch referral data');
      }

      const data = await response.json();
      setEarnings(data.earnings);
      setRecentActivity(data.recentActivity);

    } catch (error) {
      console.error('Error fetching referral data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const EarningsCard = ({ icon: Icon, title, tier1, tier2, tier3, total }) => (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="flex items-center mb-4">
        <Icon className="w-6 h-6 text-brandPrimary mr-2" />
        <h3 className="text-lg font-semibold">{title}</h3>
      </div>
      <div className="space-y-2">
        <div className="flex justify-between">
          <span>Direct (1%)</span>
          <span className="font-semibold">{formatCurrency(tier1)}</span>
        </div>
        <div className="flex justify-between">
          <span>Second Tier (0.5%)</span>
          <span className="font-semibold">{formatCurrency(tier2)}</span>
        </div>
        <div className="flex justify-between">
          <span>Third Tier (0.25%)</span>
          <span className="font-semibold">{formatCurrency(tier3)}</span>
        </div>
        <div className="border-t pt-2 mt-2">
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span className="text-brandPrimary">{formatCurrency(total)}</span>
          </div>
        </div>
      </div>
    </div>
  );

  const OneTimeBonusCard = () => (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="flex items-center mb-4">
        <Gift className="w-6 h-6 text-brandPrimary mr-2" />
        <h3 className="text-lg font-semibold">One-Time Bonuses</h3>
      </div>
      <div className="space-y-2">
        <div className="flex justify-between">
          <span>New Consultants</span>
          <span className="font-semibold">{formatCurrency(earnings.oneTime.consultant)}</span>
        </div>
        <div className="flex justify-between">
          <span>New Clients</span>
          <span className="font-semibold">{formatCurrency(earnings.oneTime.client)}</span>
        </div>
        <div className="border-t pt-2 mt-2">
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span className="text-brandPrimary">
              {formatCurrency(earnings.oneTime.consultant + earnings.oneTime.client)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  const handleRowClick = (referral) => {
    setSelectedReferral(referral);
    setIsModalOpen(true);
  };

  return (
    <>
      <Helmet>
        <title>Referral Earnings | fetchConsultant</title>
        <meta 
          name="description" 
          content="Track your referral earnings and bonuses from connecting healthcare organizations with Epic consultants." 
        />
        <meta name="keywords" content="referral earnings, Epic consulting, healthcare IT referrals, consultant referrals" />
      </Helmet>

      <div className="min-h-screen bg-gray-100 flex">
        <ConsultantHamburger user={user} currentPage="referrals" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <ChevronLeft className="h-6 w-6 text-brandPrimary cursor-pointer" onClick={() => navigate(-1)} />
              <h1 className="text-xl font-bold ml-4">Referral Earnings</h1>
            </div>
            <NotificationBell />
          </header>

          <main className="flex-grow container mx-auto px-4 py-8">
            {/* Date Range Filter */}
            <div className="mb-6 bg-white rounded-lg shadow p-4">
              <div className="flex items-center space-x-4">
                <Calendar className="text-gray-400" />
                <input
                  type="date"
                  value={dateRange.start}
                  onChange={(e) => setDateRange(prev => ({ ...prev, start: e.target.value }))}
                  className="fetch-input"
                />
                <span>to</span>
                <input
                  type="date"
                  value={dateRange.end}
                  onChange={(e) => setDateRange(prev => ({ ...prev, end: e.target.value }))}
                  className="fetch-input"
                />
              </div>
            </div>

            {/* Total Earnings */}
            <div className="mb-6 bg-white rounded-lg shadow p-6">
              <h2 className="text-2xl font-bold text-gray-900">
                Total Earnings: {formatCurrency(earnings.total)}
              </h2>
            </div>

            {/* Detailed Earnings Breakdown */}
            <div className="grid gap-6 mb-8 md:grid-cols-4">
              <OneTimeBonusCard />
              <EarningsCard
                icon={Users}
                title="Consultant Referrals"
                tier1={earnings.consultant.tier1}
                tier2={earnings.consultant.tier2}
                tier3={earnings.consultant.tier3}
                total={earnings.consultant.tier1 + earnings.consultant.tier2 + earnings.consultant.tier3}
              />

              <EarningsCard
                icon={Building}
                title="Client Referrals"
                tier1={earnings.client.tier1}
                tier2={earnings.client.tier2}
                tier3={earnings.client.tier3}
                total={earnings.client.tier1 + earnings.client.tier2 + earnings.client.tier3}
              />

              <div className="bg-white rounded-lg shadow-lg p-6">
                <div className="flex items-center mb-4">
                  <Briefcase className="w-6 h-6 text-brandPrimary mr-2" />
                  <h3 className="text-lg font-semibold">Job Referrals</h3>
                </div>
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <span>Direct (1%)</span>
                    <span className="font-semibold">{formatCurrency(earnings.job)}</span>
                  </div>
                  <div className="border-t pt-2 mt-2">
                    <div className="flex justify-between font-semibold">
                      <span>Total</span>
                      <span className="text-brandPrimary">{formatCurrency(earnings.job)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Recent Activity */}
            <div className="bg-white rounded-lg shadow">
              <div className="p-6">
                <h2 className="text-lg font-semibold text-gray-900 mb-4">Recent Activity</h2>
                {isLoading ? (
                  <div className="text-center py-4">Loading...</div>
                ) : recentActivity.length === 0 ? (
                  <div className="text-center py-4 text-gray-500">No recent referral activity</div>
                ) : (
                  <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Referred
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Type
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Job
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Status
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Bonus
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Date
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {recentActivity.map((activity) => (
                          <tr 
                            key={activity.id} 
                            className="hover:bg-gray-50 cursor-pointer"
                            onClick={() => handleRowClick(activity)}
                          >
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {activity.referred_user?.name || 'Pending'}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {activity.referral_type.charAt(0).toUpperCase() + activity.referral_type.slice(1)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {activity.job?.title || 'N/A'}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full
                                ${activity.status === 'accepted' ? 'bg-green-100 text-green-800' :
                                  activity.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                                  'bg-red-100 text-red-800'}`}>
                                {activity.status.charAt(0).toUpperCase() + activity.status.slice(1)}
                              </span>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {formatCurrency(activity.base_bonus_amount || 0 + activity.job_bonus_amount || 0)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {new Date(activity.created_at).toLocaleDateString()}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
      <ReferralDetailModal 
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        referral={selectedReferral}
      />
    </>
  );
};

// Helper function to get user type display name
const getReferralUserType = (userTypeId) => {
  switch (userTypeId) {
    case 2:
      return 'Consultant';
    case 4:
      return 'Client';
    default:
      return 'User';
  }
};

export default ReferralsDashboard;