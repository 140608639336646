import React from 'react';
import { Star, Check, AlertTriangle } from 'lucide-react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const SummaryStep = ({ 
  formData, 
  verificationType,
  detailsToVerify,
  onBack, 
  onSubmit, 
  isSubmitting,
  existingVerifications // new prop
}) => {
  const formatDate = (date) => {
    if (!date) return 'Present';
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const renderEmploymentDetails = () => {
    if (verificationType === 'job_history') {
      return (
        <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
          <div>
            <dt className="text-sm font-medium text-gray-500">Position</dt>
            <dd className="mt-1 text-sm text-gray-900">{detailsToVerify.job_title}</dd>
          </div>
          <div>
            <dt className="text-sm font-medium text-gray-500">Company</dt>
            <dd className="mt-1 text-sm text-gray-900">{detailsToVerify.company_name}</dd>
          </div>
          <div>
            <dt className="text-sm font-medium text-gray-500">Duration</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {formatDate(detailsToVerify.start_date)} - {formatDate(detailsToVerify.end_date)}
            </dd>
          </div>
          <div>
            <dt className="text-sm font-medium text-gray-500">Duration</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {detailsToVerify.work_type} • {detailsToVerify.location_type} • {detailsToVerify.job_type}
            </dd>
          </div>
          {detailsToVerify.job_type === 'consultant' && (
            <div>
              <dt className="text-sm font-medium text-gray-500">Consulting Firm</dt>
              <dd className="mt-1 text-sm text-gray-900">{detailsToVerify.firm_name}</dd>
            </div>
          )}
        </dl>
      );
    } else if (verificationType === 'engagement' && detailsToVerify) {
      const jobTitle = detailsToVerify.jobs?.title || detailsToVerify.job?.title;
      const clientName = detailsToVerify.clients?.name || detailsToVerify.client?.name;
      
      return (
        <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
          <div>
            <dt className="text-sm font-medium text-gray-500">Position</dt>
            <dd className="mt-1 text-sm text-gray-900">{jobTitle}</dd>
          </div>
          <div>
            <dt className="text-sm font-medium text-gray-500">Client</dt>
            <dd className="mt-1 text-sm text-gray-900">{clientName}</dd>
          </div>
          <div>
            <dt className="text-sm font-medium text-gray-500">Duration</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {formatDate(detailsToVerify.start_date)} - {formatDate(detailsToVerify.end_date)}
            </dd>
          </div>
          {/* Work Details - Always show for engagements */}
          <div>
            <dt className="text-sm font-medium text-gray-500">Work Details</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {detailsToVerify.work_type || 'Contract'} • {detailsToVerify.location_type || 'Remote'} • {detailsToVerify.job_type || 'Contract'}
            </dd>
          </div>
          {/* Always show firm name for engagements */}
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">Consulting Firm</dt>
            <dd className="mt-1 text-sm text-gray-900">{detailsToVerify.firm_name || 'fetchConsultant'}</dd>
          </div>
        </dl>
      );
    }
    return null;
  };

  const isFirstVerification = !existingVerifications?.length;

  return (
    <div className="p-6">
      <h2 className="text-xl font-semibold mb-6">
        Review and Submit
      </h2>

      {/* Verification Summary */}
      <div className="bg-white border rounded-lg mb-6">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
            Employment Verification
          </h3>
          
          <div className={`p-4 rounded-md ${
            formData.isVerified 
              ? 'bg-green-50' 
              : 'bg-yellow-50'
          }`}>
            <div className="flex">
              <div className="flex-shrink-0">
                {formData.isVerified ? (
                  <Check className="h-5 w-5 text-green-400" />
                ) : (
                  <AlertTriangle className="h-5 w-5 text-yellow-400" />
                )}
              </div>
              <div className="ml-3">
                <h3 className={`text-sm font-medium ${
                  formData.isVerified 
                    ? 'text-green-800' 
                    : 'text-yellow-800'
                }`}>
                  {formData.isVerified 
                    ? 'Employment Details Verified' 
                    : 'Employment Details Disputed'}
                </h3>
                {!formData.isVerified && formData.verificationNotes && (
                  <div className="mt-2 text-sm text-yellow-700">
                    <p>Dispute Notes:</p>
                    <p className="mt-1">{formData.verificationNotes}</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Existing Verifications */}
          {existingVerifications?.length > 0 && (
            <div className="mt-4 bg-blue-50 rounded-md p-4">
              <h4 className="text-sm font-medium text-blue-900">Previous Verifications</h4>
              <div className="mt-2 space-y-2">
                {existingVerifications.map((verification) => (
                  <div key={verification.id} className="text-sm text-blue-800">
                    ✓ Verified by {verification.reviewer?.name}
                    {verification.verified_at && 
                      ` on ${formatDate(verification.verified_at)}`}
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Employment Details */}
          <div className="mt-6">
            {renderEmploymentDetails()}
          </div>
        </div>
      </div>

      {/* Review Summary - Only show if rating or review provided */}
      {(formData.rating > 0 || formData.reviewText.trim()) && (
        <div className="bg-white border rounded-lg mb-6">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
              Review Details
            </h3>

            {formData.rating > 0 && (
              <div className="mb-4">
                <dt className="text-sm font-medium text-gray-500 mb-1">Rating</dt>
                <dd className="flex items-center">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <Star
                      key={star}
                      size={20}
                      className={star <= formData.rating
                        ? 'fill-yellow-400 text-yellow-400'
                        : 'fill-none text-gray-300'
                      }
                    />
                  ))}
                </dd>
              </div>
            )}

            {formData.reviewText.trim() && (
              <div>
                <dt className="text-sm font-medium text-gray-500 mb-1">Review</dt>
                <dd className="text-sm text-gray-900">
                  <ReactQuill
                    value={formData.reviewText}
                    readOnly={true}
                    theme="bubble"
                    modules={{ toolbar: false }}
                  />
                </dd>
              </div>
            )}

            <div className="mt-4 flex items-center">
              <dt className="text-sm font-medium text-gray-500 mr-2">Contact Sharing:</dt>
              <dd className="text-sm text-gray-900">
                {formData.shareContactInfo ? 'Enabled' : 'Disabled'}
              </dd>
            </div>
          </div>
        </div>
      )}

      {formData.isVerified ? (
        // VERIFIED mode
        <>
          {/* Show "As the first verifier..." only if it's the first verification */}
          {isFirstVerification && (
            <div className="bg-blue-50 rounded-lg p-4 mb-6">
              <p className="text-sm text-blue-700">
                As the first verifier, your approved description will be locked and used as 
                the reference for future verifications.
              </p>
            </div>
          )}
          {/* Standard confirmation text for verification */}
          <div className="bg-blue-50 rounded-lg p-4 mb-6">
            <p className="text-sm text-blue-700">
              By submitting, you confirm that all provided information is accurate to 
              the best of your knowledge.
            </p>
          </div>
        </>
      ) : (
        // DISPUTE mode
        <div className="bg-blue-50 rounded-lg p-4 mb-6">
          <p className="text-sm text-blue-700">
            By submitting this dispute, you are sending a notification with your 
            dispute details to the consultant. They may update the details and 
            resubmit for verification and review.
          </p>
        </div>
      )}

      {/* Navigation Buttons */}
      <div className="flex justify-between">
        <button
          type="button"
          onClick={onBack}
          disabled={isSubmitting}
          className="px-4 py-2 border rounded text-gray-600 hover:bg-gray-50"
        >
          Back
        </button>
        <button
          type="button"
          onClick={onSubmit}
          disabled={isSubmitting}
          className="px-6 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 disabled:bg-blue-300"
        >
          {isSubmitting ? 'Submitting...' : formData.isVerified ? 'Submit Verification' : 'Submit Dispute'}
        </button>
      </div>
    </div>
  );
};

SummaryStep.propTypes = {
  formData: PropTypes.shape({
    isVerified: PropTypes.bool,
    verificationNotes: PropTypes.string,
    rating: PropTypes.number.isRequired,
    reviewText: PropTypes.string.isRequired,
    shareContactInfo: PropTypes.bool.isRequired
  }).isRequired,
  verificationType: PropTypes.oneOf(['job_history', 'engagement']).isRequired,
  detailsToVerify: PropTypes.object.isRequired,
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  existingVerifications: PropTypes.array
};

export default SummaryStep;