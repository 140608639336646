import React, { useState } from 'react';

const ClaudeQuery = ({ onQuery }) => {
    const [query, setQuery] = useState('');

    const handleQuery = () => {
        onQuery(query);
        setQuery('');
    };

    return (
        <div>
            <input
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Ask Claude something..."
            />
            <button onClick={handleQuery}>Send</button>
        </div>
    );
};

export default ClaudeQuery;