import React, { useState } from 'react';
import FileExplorer from './FileExplorer.jsx';
import ClaudeQuery from './ClaudeQuery.jsx';
import ResponseViewer from './ResponseViewer.jsx';

const SystemAdminClaude = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [response, setResponse] = useState('');
    const [fileContent, setFileContent] = useState('');

    const fetchFileContent = async (fileName) => {
        const res = await fetch('http://localhost:8000/getFile', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ fileName }),
        });
        const data = await res.json();
        setFileContent(data.content);
    };

    const handleQuery = async (query) => {
        const res = await fetch('http://localhost:8000/queryClaude', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ query, fileContent }),
        });
        const data = await res.json();
        setResponse(data.suggestion);
    };

    const handleConfirm = async () => {
        await fetch('http://localhost:8000/updateFile', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ fileName: selectedFile, newContent: response }),
        });
        alert('Changes applied successfully!');
    };

    return (
        <div>
            <FileExplorer
                files={[{ name: 'App.js' }, { name: 'index.js' }]} // Replace with dynamic files
                onSelect={(file) => {
                    setSelectedFile(file);
                    fetchFileContent(file);
                }}
            />
            {selectedFile && <ClaudeQuery onQuery={handleQuery} />}
            {response && <ResponseViewer response={response} onConfirm={handleConfirm} />}
        </div>
    );
};

export default SystemAdminClaude;