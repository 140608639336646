import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronLeft, Menu, Upload, File, Info } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import PullToRefresh from 'react-pull-to-refresh';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ConsultantHamburger from './ConsultantHamburger';
import NotificationBell from "./NotificationBell";
import Spinner from './Spinner';

const ConsultantOnboarding = () => {
  const [requirements, setRequirements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [sortConfig, setSortConfig] = useState([
    { key: 'category', direction: 'asc' },
    { key: 'status', direction: 'asc' },
    { key: 'requirement_name', direction: 'asc' },
  ]);
  const { engagementId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Complete Onboarding',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    const checkMobile = () => setIsMobile(window.innerWidth <= 768);
    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    fetchRequirements();
  }, [engagementId, sortConfig]);

  const fetchRequirements = async () => {
    try {
      const { data: { user: authUser } } = await supabase.auth.getUser();
      if (!authUser) throw new Error("No authenticated user found");

      setUser(authUser);

      // Get engagement details to get client_id
      const { data: engagementData, error: engagementError } = await supabase
        .from('engagements')
        .select('client_id')
        .eq('id', engagementId)
        .single();

      if (engagementError) throw engagementError;

      // Fetch all applicable requirements with left join to status
      const { data: requirementsData, error: requirementsError } = await supabase
        .from('onboarding_requirements')
        .select(`
          *,
          clients (name),
          consultant_onboarding_status (
            id,
            status,
            uploaded_file_url,
            rejection_notes,
            updated_at
          )
        `)
        .or(`requirement_type.eq.global,client_id.eq.${engagementData.client_id}`)

      if (requirementsError) throw requirementsError;

      // Process requirements to add 'New' status where needed
      const processedRequirements = requirementsData.map(req => ({
        ...req,
        consultant_onboarding_status: req.consultant_onboarding_status?.length > 0 
          ? req.consultant_onboarding_status 
          : [{ status: 'New' }]
      }));

      // Sort requirements based on current sortConfig
      const sortedRequirements = sortRequirements(processedRequirements);
      setRequirements(sortedRequirements);
    } catch (error) {
      console.error('Error fetching requirements:', error);
    } finally {
      setLoading(false);
    }
  };

  const sortRequirements = (reqs) => {
    return [...reqs].sort((a, b) => {
      for (let config of sortConfig) {
        let aValue;
        let bValue;
  
        switch (config.key) {
          case 'category':
            aValue = a.requirement_type === 'global' ? 'fetchConsultant' : a.clients?.name || '';
            bValue = b.requirement_type === 'global' ? 'fetchConsultant' : b.clients?.name || '';
            break;
          case 'requirement_name':
            aValue = a.requirement_name || '';
            bValue = b.requirement_name || '';
            break;
          case 'status':
            // Handle 'New' status first
            const aStatus = a.consultant_onboarding_status[0]?.status || 'New';
            const bStatus = b.consultant_onboarding_status[0]?.status || 'New';
            if (aStatus === 'New' && bStatus !== 'New') return -1;
            if (bStatus === 'New' && aStatus !== 'New') return 1;
            aValue = aStatus;
            bValue = bStatus;
            break;
          default:
            aValue = a[config.key] || '';
            bValue = b[config.key] || '';
        }
  
        // Case-insensitive comparison
        aValue = aValue.toString().toLowerCase();
        bValue = bValue.toString().toLowerCase();
  
        if (aValue < bValue) return config.direction === 'asc' ? -1 : 1;
        if (aValue > bValue) return config.direction === 'asc' ? 1 : -1;
        // If equal, continue to the next sort key
      }
      return 0;
    });
  };

  const handleSort = (key) => {
    setSortConfig(prevConfig => {
      const existingConfig = prevConfig.find(config => config.key === key);
      let newDirection = 'asc';
  
      if (existingConfig) {
        // Toggle direction
        newDirection = existingConfig.direction === 'asc' ? 'desc' : 'asc';
        // Move this key to the front
        const newConfig = [{ key, direction: newDirection }, ...prevConfig.filter(config => config.key !== key)];
        return newConfig;
      } else {
        // Add new key to front with default 'asc' direction
        return [{ key, direction: 'asc' }, ...prevConfig];
      }
    });
  };

  const getSortIndicator = (key) => {
    const config = sortConfig.find(c => c.key === key);
    if (config) {
      return config.direction === 'asc' ? '▲' : '▼';
    }
    return '';
  };

  const handleFileUpload = async (requirementId, file, statusId = null) => {
    try {
      const fileExt = file.name.split('.').pop();
      const fileName = `${Date.now()}.${fileExt}`;
      const filePath = `${user.id}/${engagementId}/${fileName}`;
  
      const { error: uploadError } = await supabase.storage
        .from('onboarding_submissions')
        .upload(filePath, file);
  
      if (uploadError) throw uploadError;
  
      const statusData = {
        engagement_id: engagementId,
        requirement_id: requirementId,
        status: 'Pending Approval',
        uploaded_file_url: filePath  // Store the path instead of URL
      };
  
      if (statusId) {
        const { error } = await supabase
          .from('consultant_onboarding_status')
          .update(statusData)
          .eq('id', statusId);
        if (error) throw error;
      } else {
        const { error } = await supabase
          .from('consultant_onboarding_status')
          .insert([statusData]);
        if (error) throw error;
      }
  
      fetchRequirements();
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Error uploading file. Please try again.');
    }
  };
  
  // // Add a function to get signed URLs when needed
  // const getSubmissionUrl = async (filePath) => {
  //   if (!filePath) return null;
    
  //   try {
  //     const { data: { signedUrl }, error } = await supabase.storage
  //       .from('onboarding_submissions')
  //       .createSignedUrl(filePath, 3600); // 1 hour expiration
  
  //     if (error) {
  //       console.error('Error getting submission URL:', error);
  //       return null;
  //     }
  
  //     return signedUrl;
  //   } catch (error) {
  //     console.error('Error getting submission URL:', error);
  //     return null;
  //   }
  // };

  const renderTable = () => (
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <table className="min-w-full table-auto">
        <thead className="bg-gray-50">
          <tr>
            <th
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
              onClick={() => handleSort('category')}
            >
              Category {getSortIndicator('category')}
            </th>
            <th
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
              onClick={() => handleSort('requirement_name')}
            >
              Requirement {getSortIndicator('requirement_name')}
            </th>
            <th
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
              onClick={() => handleSort('status')}
            >
              Status {getSortIndicator('status')}
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y text-left divide-gray-200">
          {requirements.map((requirement) => (
            <tr key={requirement.id} className="hover:bg-gray-50">
              <td className="px-6 py-4 text-sm text-gray-900">
                {requirement.requirement_type === 'global' ? 'fetchConsultant' : requirement.clients?.name}
              </td>
              <td className="px-6 py-4 text-sm text-gray-900">
                {requirement.requirement_name}
                {requirement.is_mandatory && <span className="text-red-500 ml-1">*</span>}
              </td>
              <td className="px-6 py-4 text-sm text-gray-500">
                {requirement.consultant_onboarding_status[0]?.status || 'New'}
              </td>
              <td className="px-6 py-4 text-sm text-gray-500">
                <div className="flex items-center space-x-4">
                  <Info 
                    size={20} 
                    className={`cursor-pointer ${requirement.consultant_onboarding_status[0]?.status === 'Rejected' ? 'text-red-500' : 'text-gray-400'}`}
                    onClick={() => alert(
                      `${requirement.description}${
                        requirement.consultant_onboarding_status[0]?.status === 'Rejected' 
                          ? `\n\nRejection reason: ${requirement.consultant_onboarding_status[0].rejection_notes}`
                          : ''
                      }`
                    )}
                  />
                  {requirement.consultant_onboarding_status[0]?.status !== 'Approved' ? (
                    <label className="cursor-pointer text-indigo-600 hover:text-indigo-900">
                      <Upload size={20} />
                      <input
                        type="file"
                        className="hidden"
                        onChange={(e) => handleFileUpload(
                          requirement.id,
                          e.target.files[0],
                          requirement.consultant_onboarding_status[0]?.id
                        )}
                      />
                    </label>
                  ) : (
                    <Upload size={20} className="text-gray-400 cursor-not-allowed" />
                  )}
                  {requirement.consultant_onboarding_status[0]?.uploaded_file_url && (
                    <a
                      href={requirement.consultant_onboarding_status[0].uploaded_file_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      <File size={20} />
                    </a>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const renderCards = () => (
    <div className="space-y-4 text-left">
      {requirements.map((requirement) => (
        <div key={requirement.id} className="bg-white shadow rounded-lg p-4">
          <h3 className="text-lg font-medium text-gray-900">
            {requirement.requirement_name}
            {requirement.is_mandatory && <span className="text-red-500 ml-1">*</span>}
          </h3>
          <div className="text-sm text-gray-500 mb-1">
            {requirement.requirement_type === 'global' ? 'fetchConsultant' : requirement.clients?.name}
          </div>
          <p className="text-sm text-gray-500 mt-1">
            Status: {requirement.consultant_onboarding_status[0]?.status || 'New'}
          </p>
          <div className="mt-4 flex items-center space-x-4">
            <Info 
              size={20} 
              className={`cursor-pointer ${requirement.consultant_onboarding_status[0]?.status === 'Rejected' ? 'text-red-500' : 'text-gray-400'}`}
              onClick={() => alert(
                `${requirement.description}${
                  requirement.consultant_onboarding_status[0]?.status === 'Rejected' 
                    ? `\n\nRejection reason: ${requirement.consultant_onboarding_status[0].rejection_notes}`
                    : ''
                }`
              )}
            />
            {requirement.consultant_onboarding_status[0]?.status !== 'Approved' ? (
              <label className="cursor-pointer text-indigo-600 hover:text-indigo-900">
                <Upload size={20} />
                <input
                  type="file"
                  className="hidden"
                  onChange={(e) => handleFileUpload(
                    requirement.id,
                    e.target.files[0],
                    requirement.consultant_onboarding_status[0]?.id
                  )}
                />
              </label>
            ) : (
              <Upload size={20} className="text-gray-400 cursor-not-allowed" />
            )}
            {requirement.consultant_onboarding_status[0]?.uploaded_file_url && (
              <a
                href={requirement.consultant_onboarding_status[0].uploaded_file_url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-indigo-600 hover:text-indigo-900"
              >
                <File size={20} />
              </a>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <>
      <Helmet>
        <title>Complete Onboarding | fetchConsultant</title>
        <meta name="description" content="Complete your onboarding requirements and submit necessary documentation on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ConsultantHamburger user={user} currentPage="onboarding" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <ChevronLeft className="h-6 w-6 text-brandPrimary cursor-pointer" onClick={() => navigate(-1)} />
              <h1 className="text-xl font-bold ml-4">Complete Onboarding</h1>
            </div>
            <NotificationBell />
          </header>

          <main className="p-4">
            <PullToRefresh
              onRefresh={fetchRequirements}
              className="pull-to-refresh"
              pullingContent={<span className="text-left">Pull down to refresh...</span>}
              refreshingContent={<div className="flex items-center"><Spinner /> Refreshing...</div>}
            >
              {loading ? (
                <div className="text-left">Loading...</div>
              ) : requirements.length === 0 ? (
                <p className="text-left">No requirements found.</p>
              ) : (
                isMobile ? renderCards() : renderTable()
              )}
            </PullToRefresh>
          </main>
        </div>
      </div>
    </>
  );
};

export default ConsultantOnboarding;