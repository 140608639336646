import { getAnalytics, logEvent } from "firebase/analytics";
import { Menu, MoreHorizontal } from 'lucide-react';
import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import PullToRefresh from 'react-pull-to-refresh';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { supabase } from '../supabaseClient';
import ConsultantHamburger from './ConsultantHamburger';
import NotificationBell from "./NotificationBell";
import Spinner from './Spinner';

const Refer = () => {
  const [referrals, setReferrals] = useState([]);
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'desc' });
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Referrals',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);

    fetchUserAndReferrals();

    return () => window.removeEventListener('resize', checkMobile);
  }, [sortConfig]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [dropdownRef]);

  const fetchUserAndReferrals = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");
  
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();
  
      if (userError) throw userError;
      setUser(userData);
  
      // Fetch referrals
      const { data: fetchedReferrals, error: referralsError } = await supabase
        .from('referrals')
        .select(`
          id,
          referred_user_id,
          referral_type,
          status,
          created_at,
          referred_job,
          base_bonus_amount,
          job_bonus_amount,
          referred_user:referred_user_id (name, user_type_id),
          job:referred_job (title)
        `)
        .eq('referrer_id', user.id);
  
      if (referralsError) throw referralsError;
  
      // Fetch user_jobs separately
      const { data: userJobs, error: userJobsError } = await supabase
        .from('user_jobs')
        .select('job_id, user_id, status')
        .eq('referred_by', user.id);
  
      if (userJobsError) throw userJobsError;


      // Fetch referral tokens
      const { data: fetchedTokens, error: tokensError } = await supabase
        .from('referral_tokens')
        .select(`
          id,
          referral_type,
          status,
          created_at,
          referred_job,
          referred_email,
          referred_phone,
          job:referred_job (title)
        `)
        .eq('referrer_id', user.id);

      if (tokensError) throw tokensError;

    // Combine referrals with user_jobs data
    const combinedReferrals = fetchedReferrals.map(referral => {
      // Find a matching job in user_jobs
      const matchingJob = userJobs.find(job =>
        job.job_id === referral.referred_job && job.user_id === referral.referred_user_id
      );

      // Access the corresponding token data for email and phone from referral_tokens
      const matchingToken = fetchedTokens.find(token => token.referred_job === referral.referred_job);
      
      // Determine the job status based on the presence of job and user_job records
      let jobStatus;
      if (!referral.job) {
        jobStatus = 'N/A'; // No job associated
      } else if (!matchingJob) {
        jobStatus = 'Not Applied'; // Job exists, but no user_job record
      } else {
        jobStatus = matchingJob.status; // Both job and user_job exist
      }

      // Determine the display name
      const displayName = referral.referred_user 
        ? referral.referred_user.name 
        : (matchingToken?.referred_email || formatPhoneNumber(matchingToken?.referred_phone) || 'Not yet joined');

        const bonus = referral.referral_type === 'job' 
        ? referral.job_bonus_amount 
        : referral.base_bonus_amount;

      return {
        ...referral,
        displayName: displayName,
        jobStatus: jobStatus,
        bonus: bonus || 0
      };
    });

      // Sort the combined referrals
      const sortedReferrals = combinedReferrals.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
        if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      });

      setReferrals(sortedReferrals);
  
    } catch (error) {
      console.error('Error fetching user and referrals:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return null;
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  };

  const handleSort = (key) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });
  };

  const toggleDropdown = (referralId) => {
    setActiveDropdown(activeDropdown === referralId ? null : referralId);
  };

  const renderReferralsTable = () => (
    <div className="bg-white shadow-md rounded-lg">
      <table className="min-w-full table-auto">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
              <div onClick={() => handleSort('referral_type')} className="cursor-pointer">
                Type {sortConfig.key === 'referral_type' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </div>
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
              <div onClick={() => handleSort('displayName')} className="cursor-pointer">
                Referred {sortConfig.key === 'displayName' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </div>
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
              <div onClick={() => handleSort('status')} className="cursor-pointer">
                Status {sortConfig.key === 'status' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </div>
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
              <div onClick={() => handleSort('job.title')} className="cursor-pointer">
                Job {sortConfig.key === 'job.title' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </div>
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
              <div onClick={() => handleSort('jobStatus')} className="cursor-pointer">
                Job Status {sortConfig.key === 'jobStatus' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </div>
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
              <div onClick={() => handleSort('bonus')} className="cursor-pointer">
                Bonus {sortConfig.key === 'bonus' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </div>
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
              <div onClick={() => handleSort('created_at')} className="cursor-pointer">
                Referred {sortConfig.key === 'created_at' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </div>
            </th>
            <th className="px-6 py-3"></th>
          </tr>
        </thead>
        <tbody>
          {referrals.map((referral) => (
            <tr key={referral.id} className="hover:bg-gray-50">
              <td className="px-6 py-4 text-sm text-gray-700 text-left">
                {referral.referral_type.charAt(0).toUpperCase() + referral.referral_type.slice(1)}
              </td>
              <td className="px-6 py-4 text-sm text-gray-700 text-left">
                {referral.displayName}
              </td>
              <td className="px-6 py-4 text-sm text-left">
                <span className={`font-semibold ${
                  referral.status === 'accepted' ? 'text-green-600' :
                  referral.status === 'pending' ? 'text-yellow-600' :
                  'text-red-600'
                }`}>
                  {referral.status.charAt(0).toUpperCase() + referral.status.slice(1)}
                </span>
              </td>
              <td className="px-6 py-4 text-sm text-gray-700 text-left">
                {referral.job ? referral.job.title : 'N/A'}
              </td>
              <td className="px-6 py-4 text-sm text-gray-700 text-left">
              {referral.jobStatus}
              </td>
              <td className="px-6 py-4 text-sm text-gray-700 text-left">
                ${Math.floor(referral.bonus || 0)}
              </td>
              <td className="px-6 py-4 text-sm text-gray-700 text-left">
                {new Date(referral.created_at).toLocaleDateString()}
              </td>
              <td className="px-6 py-4 text-left text-sm text-gray-700 relative">
                <MoreHorizontal
                  size={24}
                  className="cursor-pointer"
                  onClick={() => toggleDropdown(referral.id)}
                />
                {activeDropdown === referral.id && (
                  <div ref={dropdownRef} className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                    {referral.referred_job && (
                      <div
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer text-left"
                        onClick={() => navigate(`/app/job/${referral.referred_job}`)}
                      >
                        View Job
                      </div>
                    )}
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const renderReferralsCards = () => (
    <div className="grid grid-cols-1 gap-4">
      {referrals.map((referral) => (
        <div 
          key={referral.id} 
          className="bg-white shadow-md rounded-lg p-4 relative hover:shadow-lg transition-shadow"
        >
          <div className="mb-2 flex justify-between items-center">
            <span className="bg-indigo-100 text-brandHover font-semibold px-2 py-1 rounded-md">
              {referral.referral_type.charAt(0).toUpperCase() + referral.referral_type.slice(1)}
            </span>
            <span className={`font-semibold ${
              referral.status === 'accepted' ? 'text-green-600' :
              referral.status === 'pending' ? 'text-yellow-600' :
              'text-red-600'
            }`}>
              {referral.status.charAt(0).toUpperCase() + referral.status.slice(1)}
            </span>
          </div>
          <h2 className="text-xl font-bold text-gray-900 text-left mb-2">
            {referral.displayName}
          </h2>
          <div className="text-sm text-gray-500 mb-2 text-left">
            <p>Referred: {new Date(referral.created_at).toLocaleDateString()}</p>
            {referral.job && <p>Job: {referral.job.title}</p>}
            <p>Job Status: {referral.jobStatus}</p>
            <p>Bonus: ${Math.floor(referral.bonus || 0)}</p>
            {!referral.isPending && referral.referred_user && (
              <p>Type: {referral.referred_user.user_type_id === 2 ? 'Consultant' : 
                        referral.referred_user.user_type_id === 4 ? 'Client' : 'User'}
              </p>
            )}
          </div>
          <div className="absolute bottom-4 right-4">
            <MoreHorizontal 
              size={24} 
              className="cursor-pointer"
              onClick={() => toggleDropdown(referral.id)}
            />
            {activeDropdown === referral.id && (
              <div ref={dropdownRef} className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                {referral.referred_job && (
                  <div
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer text-left"
                    onClick={() => navigate(`/app/job/${referral.referred_job}`)}
                  >
                    View Job
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <>
      <Helmet>
        <title>My Referrals | fetchConsultant</title>
        <meta name="description" content="View and manage your referrals on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ConsultantHamburger user={user} currentPage="referrals" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold ml-4 text-left">My Referrals</h1>
            </div>
            <div className="flex items-center">
              <button
                onClick={() => navigate('/app/solicit-refer')}
                className="mr-4 bg-brandPrimary text-white px-4 py-2 rounded-md hover:bg-brandHover transition duration-300"
              >
                Refer Someone
              </button>
              <NotificationBell />
            </div>
          </header>

          <main className="p-4">
            <PullToRefresh
              onRefresh={fetchUserAndReferrals}
              className="pull-to-refresh"
              pullingContent={<span>Pull down to refresh...</span>}
              refreshingContent={<div className="flex items-center justify-center"><Spinner /> Refreshing...</div>}
            >
              {isLoading ? (
                <div>Loading...</div>
              ) : (
                <>
                  {isMobile ? renderReferralsCards() : renderReferralsTable()}
                </>
              )}
            </PullToRefresh>
          </main>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Refer;