// src/utils/storageHelpers.js

import { supabase } from '../supabaseClient';

export const getStorageUrl = async (bucket, path) => {
  if (!path) return null;
  
  try {
    // First check if the path starts with http(s)
    if (path.startsWith('http')) {
      return path; // Return as-is if it's already a URL
    }

    const { data, error } = await supabase
      .storage
      .from(bucket)
      .createSignedUrl(path, 3600);

    if (error) {
      console.error('Storage error:', error);
      return null;
    }

    if (!data || !data.signedUrl) {
      console.error('No signed URL received');
      return null;
    }

    return data.signedUrl;
  } catch (error) {
    console.error('Detailed error in getStorageUrl:', {
      bucket,
      path,
      error: error.message,
      stack: error.stack
    });
    return null;
  }
};

// Cache version
const urlCache = new Map();

export const getCachedStorageUrl = async (bucket, path) => {
  if (!path) return null;

  // Handle already-complete URLs
  if (path.startsWith('http')) {
    return path;
  }
  
  const cacheKey = `${bucket}/${path}`;
  const cached = urlCache.get(cacheKey);
  
  if (cached && cached.expiry > Date.now()) {
    return cached.url;
  }
  
  try {
    const url = await getStorageUrl(bucket, path);
    
    if (url) {
      urlCache.set(cacheKey, {
        url,
        expiry: Date.now() + (3600 * 1000)
      });
    }
    
    return url;
  } catch (error) {
    console.error('Detailed error in getCachedStorageUrl:', {
      bucket,
      path,
      error: error.message,
      stack: error.stack
    });
    return null;
  }
};