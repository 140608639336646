import { getAnalytics, logEvent } from "firebase/analytics";
import { Menu, ChevronDown, ChevronRight, Users, Eye, GitPullRequest } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { supabase } from '../supabaseClient';
import NotificationBell from "./NotificationBell";
import SystemAdminHamburger from './SystemAdminHamburger';
import JobExpandedDetails from './ui/JobExpandedDetails';

const SystemAdminJobs = () => {
  const [jobs, setJobs] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [jobMetrics, setJobMetrics] = useState({
    totalJobs: 0,
    jobsByStatus: {},
    averageTimeToFill: 0,
    activeJobsWithApplicants: 0
  });
  const [filter, setFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [jobsPerPage] = useState(20);
  const [expandedJobId, setExpandedJobId] = useState(null);
  const [dateRange, setDateRange] = useState({ start: null, end: null });
  const [selectedClient, setSelectedClient] = useState('all');
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'System Admin Jobs',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchJobs();
    fetchJobMetrics();
    fetchClients();

    // Set up real-time subscription for job status updates
    const subscription = supabase
      .channel('job_status_changes')
      .on('postgres_changes', {
        event: '*',
        schema: 'public',
        table: 'jobs'
      }, handleJobStatusChange)
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [filter, searchTerm, currentPage, selectedClient, dateRange]);

  const fetchClients = async () => {
    try {
      const { data, error } = await supabase
        .from('clients')
        .select('client_id, name')
        .order('name');

      if (error) throw error;
      setClients(data);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const fetchJobs = async () => {
    setIsLoading(true);
    try {
      // Start building the query
      let query = supabase
        .from('jobs')
        .select(`
          *,
          clients (name)
        `);
  
      // Apply status filter
      if (filter !== 'all') {
        query = query.eq('status', filter);
      }
  
      // Apply client filter
      if (selectedClient !== 'all') {
        query = query.eq('client_id', selectedClient);
      }
  
      // Apply date range filter
      if (dateRange.start) {
        query = query.gte('created_at', dateRange.start);
      }
      if (dateRange.end) {
        query = query.lte('created_at', dateRange.end);
      }
  
      // Apply search filter - search in title and description
      if (searchTerm) {
        query = query.or(`title.ilike.%${searchTerm}%,description.ilike.%${searchTerm}%`);
      }
  
      // Apply ordering
      query = query.order('created_at', { ascending: false });
  
      // Execute the query
      const { data: jobsData, error: jobsError } = await query;
  
      if (jobsError) throw jobsError;
  
      // Fetch job metrics
      const { data: metricsData, error: metricsError } = await supabase
        .from('job_metrics')
        .select('*');
  
      if (metricsError) throw metricsError;
  
      // Merge jobs with their corresponding metrics
      const jobsWithMetrics = jobsData.map(job => {
        const metrics = metricsData.find(m => m.job_id === job.job_id) || {
          view_count: 0,
          applicant_count: 0,
          referral_count: 0,
        };
        return { ...job, metrics };
      });
  
      setJobs(jobsWithMetrics);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching jobs:', error.message);
      setIsLoading(false);
    }
  };

  const fetchJobMetrics = async () => {
    try {
      const { data: statusData, error: statusError } = await supabase
        .rpc('get_job_status_metrics');

      if (statusError) throw statusError;

      const { data: timeData, error: timeError } = await supabase
        .rpc('get_average_time_to_fill');

      if (timeError) throw timeError;

      setJobMetrics({
        jobsByStatus: statusData[0],
        averageTimeToFill: timeData[0].average_days,
        activeJobsWithApplicants: statusData[0].active_with_applicants || 0
      });
    } catch (error) {
      console.error('Error fetching job metrics:', error);
    }
  };

  const handleJobStatusChange = (payload) => {
    if (payload.eventType === 'UPDATE') {
      fetchJobs();
      fetchJobMetrics();
    }
  };

  const handleFilterChange = (e) => {
    const analytics = getAnalytics();
    logEvent(analytics, 'job_filter_change', {
      filter_type: 'status',
      new_value: e.target.value
    });
    
    setFilter(e.target.value);
    setCurrentPage(1);
  };

  const handleClientChange = (e) => {
    const analytics = getAnalytics();
    logEvent(analytics, 'job_filter_change', {
      filter_type: 'client',
      new_value: e.target.value
    });
    
    setSelectedClient(e.target.value);
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleDateRangeChange = (type, value) => {
    setDateRange(prev => ({
      ...prev,
      [type]: value
    }));
    setCurrentPage(1);
  };

  const toggleExpandJob = (jobId) => {
    const analytics = getAnalytics();
    logEvent(analytics, 'job_details_expanded', {
      job_id: jobId
    });
    
    setExpandedJobId(expandedJobId === jobId ? null : jobId);
  };

  const formatMetric = (value) => {
    if (typeof value === 'number') {
      return value.toLocaleString();
    }
    return '0';
  };

  return (
    <>
      <Helmet>
        <title>System Admin Jobs | fetchConsultant</title>
        <meta name="description" content="Manage and monitor job postings, applications, and metrics on fetchConsultant platform." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <SystemAdminHamburger currentPage="jobs" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">System Admin Jobs</h1>
            </div>
            <div className="flex items-center">
              <NotificationBell />
            </div>
          </header>

          <main className="flex-grow container mx-auto px-4 py-8">
            {/* Metrics Cards */}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
              <div className="bg-white p-6 rounded-lg shadow">
                <h2 className="text-xl font-semibold mb-2">Active Jobs</h2>
                <p className="text-3xl font-bold text-brandPrimary">
                  {formatMetric(jobMetrics.jobsByStatus?.active)}
                </p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow">
                <h2 className="text-xl font-semibold mb-2">Total Applications</h2>
                <p className="text-3xl font-bold text-brandPrimary">
                  {formatMetric(jobMetrics.activeJobsWithApplicants)}
                </p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow">
                <h2 className="text-xl font-semibold mb-2">Avg. Time to Fill</h2>
                <p className="text-3xl font-bold text-brandPrimary">
                  {formatMetric(jobMetrics.averageTimeToFill)} days
                </p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow">
                <h2 className="text-xl font-semibold mb-2">Jobs by Status</h2>
                <div className="text-sm">
                  {Object.entries(jobMetrics.jobsByStatus || {}).map(([status, count]) => (
                    <div key={status} className="flex justify-between items-center">
                      <span className="capitalize">{status}:</span>
                      <span className="font-semibold">{count}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Filters */}
            <div className="bg-white p-4 rounded-lg shadow mb-6">
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Status</label>
                  <select
                    value={filter}
                    onChange={handleFilterChange}
                    className="fetch-select"
                  >
                    <option value="all">All Statuses</option>
                    <option value="Active">Active</option>
                    <option value="Paused">Paused</option>
                    <option value="Filled">Filled</option>
                    <option value="Canceled">Canceled</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Client</label>
                  <select
                    value={selectedClient}
                    onChange={handleClientChange}
                    className="fetch-select"
                  >
                    <option value="all">All Clients</option>
                    {clients.map(client => (
                      <option key={client.client_id} value={client.client_id}>
                        {client.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">Date Range</label>
                  <div className="flex space-x-2">
                    <input
                      type="date"
                      value={dateRange.start || ''}
                      onChange={(e) => handleDateRangeChange('start', e.target.value)}
                      className="fetch-input"
                    />
                    <input
                      type="date"
                      value={dateRange.end || ''}
                      onChange={(e) => handleDateRangeChange('end', e.target.value)}
                      className="fetch-input"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Search</label>
                  <input
                    type="text"
                    placeholder="Search jobs..."
                    value={searchTerm}
                    onChange={handleSearch}
                    className="fetch-input"
                  />
                </div>
              </div>
            </div>

            {/* Jobs Table */}
            <div className="bg-white rounded-lg shadow overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Title
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Client
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Posted Date
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Views
                    </th>
                    <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Applicants
                    </th>
                    <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Referrals
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {isLoading ? (
                    <tr>
                      <td colSpan="7" className="px-6 py-4 text-center">
                        <div className="flex justify-center items-center">
                          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-brandPrimary"></div>
                        </div>
                      </td>
                    </tr>
                  ) : jobs.length === 0 ? (
                    <tr>
                      <td colSpan="7" className="px-6 py-4 text-center text-gray-500">
                        No jobs found
                      </td>
                    </tr>
                  ) : (
                    jobs.map(job => (
                      <React.Fragment key={job.job_id}>
                        <tr 
                          className="hover:bg-gray-50 cursor-pointer"
                          onClick={() => toggleExpandJob(job.job_id)}
                        >
                          <td className="px-6 py-4">
                            <div className="flex items-center">
                              {expandedJobId === job.job_id ? (
                                <ChevronDown className="h-5 w-5 mr-2" />
                              ) : (
                                <ChevronRight className="h-5 w-5 mr-2" />
                              )}
                              {job.title}
                            </div>
                          </td>
                          <td className="px-6 py-4">{job.clients.name}</td>
                          <td className="px-6 py-4">
                            {new Date(job.posted_at).toLocaleDateString()}
                          </td>
                          <td className="px-6 py-4">
                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full
                              ${job.status === 'Active' ? 'bg-green-100 text-green-800' :
                                job.status === 'Paused' ? 'bg-yellow-100 text-yellow-800' :
                                job.status === 'Filled' ? 'bg-blue-100 text-blue-800' :
                                'bg-red-100 text-red-800'}`}
                            >
                              {job.status}
                            </span>
                          </td>
                          <td className="px-6 py-4 text-center">
                            <div className="flex items-center justify-center">
                              <Eye className="h-4 w-4 mr-1" />
                              {job.metrics.view_count}
                            </div>
                          </td>
                          <td className="px-6 py-4 text-center">
                            <div className="flex items-center justify-center">
                              <Users className="h-4 w-4 mr-1" />
                              {job.metrics.applicant_count}
                            </div>
                          </td>
                          <td className="px-6 py-4 text-center">
                            <div className="flex items-center justify-center">
                              <GitPullRequest className="h-4 w-4 mr-1" />
                              {job.metrics.referral_count}
                            </div>
                          </td>
                        </tr>
                        {expandedJobId === job.job_id && (
                          <tr>
                            <td colSpan="7" className="bg-gray-50">
                              <JobExpandedDetails jobId={job.job_id} />
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))
                  )}
                </tbody>
              </table>
            </div>

            {/* Pagination */}
            <div className="mt-6 flex justify-between items-center">
              <div className="text-sm text-gray-700">
                Showing {(currentPage - 1) * jobsPerPage + 1} to{' '}
                {Math.min(currentPage * jobsPerPage, jobMetrics.totalJobs)} of{' '}
                {jobMetrics.totalJobs} jobs
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                  disabled={currentPage === 1}
                  className={`px-4 py-2 text-sm font-medium rounded-md
                    ${currentPage === 1
                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                      : 'bg-white text-gray-700 hover:bg-gray-50'}`}
                >
                  Previous
                </button>
                <button
                  onClick={() => setCurrentPage(prev => prev + 1)}
                  disabled={currentPage * jobsPerPage >= jobMetrics.totalJobs}
                  className={`px-4 py-2 text-sm font-medium rounded-md
                    ${currentPage * jobsPerPage >= jobMetrics.totalJobs
                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                      : 'bg-white text-gray-700 hover:bg-gray-50'}`}
                >
                  Next
                </button>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default SystemAdminJobs;