import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { X, Plus, Save, ArrowLeft } from 'lucide-react';
import { supabase } from '../../../supabaseClient.js';
import imageCompression from 'browser-image-compression';

const BlogEditor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    slug: '',
    description: '',
    content: '',
    categories: [],
    tags: [],
    is_featured: false,
    published_at: '',
    image_url: ''
  });

  // New category/tag input states
  const [newCategory, setNewCategory] = useState('');
  const [newTag, setNewTag] = useState('');

  useEffect(() => {
    if (id) {
      loadPost();
    }
  }, [id]);

  const loadPost = async () => {
    try {
      setLoading(true);
      const { data: post, error } = await supabase
        .from('blog_posts')
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;
      setFormData(post);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = async (event) => {
    try {
      const file = event.target.files[0];
      
      // Validate file type
      if (!file.type.startsWith('image/')) {
        throw new Error('Please upload an image file');
      }
  
      // Validate file size (max 5MB)
      const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes
      if (file.size > MAX_FILE_SIZE) {
        throw new Error('Image must be smaller than 5MB');
      }
  
      // Compression options for lossless compression
      const options = {
        maxSizeMB: 1, // Max file size in MB
        maxWidthOrHeight: 1920, // Max width/height while maintaining aspect ratio
        useWebWorker: true, // Use web worker for better performance
        initialQuality: 1.0, // Start with highest quality
        alwaysKeepResolution: true, // Maintain resolution
        fileType: file.type // Maintain original file type
      };
  
      // Show compression status
      const compressionStart = Date.now();
      console.log('Original file size:', (file.size / 1024 / 1024).toFixed(2), 'MB');
  
      // Compress the image
      const compressedFile = await imageCompression(file, options);
      
      console.log(
        'Compressed file size:', 
        (compressedFile.size / 1024 / 1024).toFixed(2), 
        'MB',
        'Compression took:', 
        (Date.now() - compressionStart), 
        'ms'
      );
  
      // Create slug-friendly version of post title
      const postSlug = formData.title
        ? formData.title.toLowerCase().replace(/[^a-z0-9]+/g, '-').trim('-')
        : 'untitled';
  
      const fileExt = file.name.split('.').pop();
      const timestamp = new Date().getTime();
      const fileName = `${postSlug}-${timestamp}.${fileExt}`;
  
      // Upload the compressed file
      const { error: uploadError } = await supabase.storage
        .from('blog_images')
        .upload(fileName, compressedFile);
  
      if (uploadError) throw uploadError;
  
      const { data: { publicUrl } } = supabase.storage
        .from('blog_images')
        .getPublicUrl(fileName);
  
      setFormData(prev => ({
        ...prev,
        image_url: publicUrl
      }));
    } catch (err) {
      console.error('Error uploading image:', err);
      alert(err.message || 'Failed to upload image');
    }
  };

  const handleEditorImageUpload = async (blobInfo) => {
    try {
      const file = blobInfo.blob();
      
      // Use the same compression logic as your featured image
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
        initialQuality: 1.0,
        alwaysKeepResolution: true,
        fileType: file.type
      };
  
      const compressedFile = await imageCompression(file, options);
      
      // Generate unique filename
      const timestamp = new Date().getTime();
      const fileExt = file.name?.split('.').pop() || 'jpg';
      const fileName = `content-${timestamp}.${fileExt}`;
  
      // Upload to Supabase storage
      const { error: uploadError, data } = await supabase.storage
        .from('blog_images')
        .upload(fileName, compressedFile);
  
      if (uploadError) throw uploadError;
  
      // Get public URL
      const { data: { publicUrl } } = supabase.storage
        .from('blog_images')
        .getPublicUrl(fileName);
  
      return publicUrl;
    } catch (err) {
      console.error('Error uploading image:', err);
      throw new Error('Failed to upload image');
    }
  };
  

  const generateSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/[^\w\s-]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-');
  };

  const handleTitleChange = (e) => {
    const title = e.target.value;
    setFormData(prev => ({
      ...prev,
      title,
      slug: generateSlug(title)
    }));
  };

  const addCategory = () => {
    if (newCategory && !formData.categories.includes(newCategory)) {
      setFormData(prev => ({
        ...prev,
        categories: [...prev.categories, newCategory]
      }));
      setNewCategory('');
    }
  };

  const removeCategory = (category) => {
    setFormData(prev => ({
      ...prev,
      categories: prev.categories.filter(c => c !== category)
    }));
  };

  const addTag = () => {
    if (newTag && !formData.tags.includes(newTag)) {
      setFormData(prev => ({
        ...prev,
        tags: [...prev.tags, newTag]
      }));
      setNewTag('');
    }
  };

  const removeTag = (tag) => {
    setFormData(prev => ({
      ...prev,
      tags: prev.tags.filter(t => t !== tag)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
  
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const content = editorRef.current.getContent();
  
      // Get user info for author fields
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('name, photo')
        .eq('user_id', user.id)
        .single();
  
      if (userError) throw userError;
  
      const postData = {
        ...formData,
        content,
        author_id: user.id,
        author_name: userData.name,
        author_photo: userData.photo,
        updated_at: new Date().toISOString()
      };
  
      if (!id) {
        postData.created_at = new Date().toISOString();
      }
  
      const { error } = await supabase
        .from('blog_posts')
        [id ? 'update' : 'insert'](postData)
        [id ? 'eq' : 'single']('id', id);
  
      if (error) throw error;
      navigate('/app/blog/admin');
    } catch (err) {
      setError(err.message);
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Header */}
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <button
              type="button"
              onClick={() => navigate('/app/blog/admin')}
              className="text-gray-600 hover:text-gray-900"
            >
              <ArrowLeft className="h-6 w-6" />
            </button>
            <h1 className="text-2xl font-bold text-gray-900">
              {id ? 'Edit Post' : 'New Post'}
            </h1>
          </div>
          <button
            type="submit"
            disabled={saving}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 disabled:opacity-50"
          >
            <Save className="h-5 w-5 mr-2" />
            {saving ? 'Saving...' : 'Save'}
          </button>
        </div>

        {error && (
          <div className="bg-red-50 border-l-4 border-red-400 p-4">
            <p className="text-red-700">{error}</p>
          </div>
        )}

        {/* Basic Info */}
        <div className="grid grid-cols-1 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Title
            </label>
            <input
              type="text"
              value={formData.title}
              onChange={handleTitleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Slug
            </label>
            <input
              type="text"
              value={formData.slug}
              onChange={(e) => setFormData(prev => ({ ...prev, slug: e.target.value }))}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Description
            </label>
            <textarea
              value={formData.description}
              onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
              rows={3}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              required
            />
          </div>

          {/* Featured Image */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Featured Image
            </label>
            <div className="mt-1 flex items-center space-x-4">
              {formData.image_url && (
                <img
                  src={formData.image_url}
                  alt="Featured"
                  className="h-32 w-32 object-cover rounded-md"
                />
              )}
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100"
              />
            </div>
          </div>

          {/* Categories */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Categories
            </label>
            <div className="mt-1 flex flex-wrap gap-2">
              {formData.categories.map(category => (
                <span
                  key={category}
                  className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800"
                >
                  {category}
                  <button
                    type="button"
                    onClick={() => removeCategory(category)}
                    className="ml-1 text-indigo-600 hover:text-indigo-900"
                  >
                    <X className="h-4 w-4" />
                  </button>
                </span>
              ))}
              <div className="flex items-center space-x-2">
                <input
                  type="text"
                  value={newCategory}
                  onChange={(e) => setNewCategory(e.target.value)}
                  placeholder="Add category"
                  className="border border-gray-300 rounded-md shadow-sm py-1 px-2 text-sm"
                />
                <button
                  type="button"
                  onClick={addCategory}
                  className="text-indigo-600 hover:text-indigo-900"
                >
                  <Plus className="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>

          {/* Tags */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Tags
            </label>
            <div className="mt-1 flex flex-wrap gap-2">
              {formData.tags.map(tag => (
                <span
                  key={tag}
                  className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
                >
                  {tag}
                  <button
                    type="button"
                    onClick={() => removeTag(tag)}
                    className="ml-1 text-gray-600 hover:text-gray-900"
                  >
                    <X className="h-4 w-4" />
                  </button>
                </span>
              ))}
              <div className="flex items-center space-x-2">
                <input
                  type="text"
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                  placeholder="Add tag"
                  className="border border-gray-300 rounded-md shadow-sm py-1 px-2 text-sm"
                />
                <button
                  type="button"
                  onClick={addTag}
                  className="text-indigo-600 hover:text-indigo-900"
                >
                  <Plus className="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>

          {/* Publishing Options */}
          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Publish Date
              </label>
              <input
                type="datetime-local"
                value={formData.published_at}
                onChange={(e) => setFormData(prev => ({ ...prev, published_at: e.target.value }))}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={formData.is_featured}
                onChange={(e) => setFormData(prev => ({ ...prev, is_featured: e.target.checked }))}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <label className="ml-2 block text-sm text-gray-900">
                Feature this post
              </label>
            </div>
          </div>

          {/* Content Editor */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Content
            </label>
            <Editor
              apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
              onInit={(evt, editor) => editorRef.current = editor}
              initialValue={formData.content}
              init={{
                height: 500,
                menubar: true, // Enable menu bar for more options
                plugins: [
                  'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                  'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                  'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                ],
                toolbar: 'undo redo | blocks | ' +
                  'bold italic forecolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'image | removeformat | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                // Add these configurations for image handling
                image_advtab: true,
                images_upload_handler: handleEditorImageUpload,
                automatic_uploads: true,
                file_picker_types: 'image',
                // Optional: Add image editing tools
                image_caption: true,
                image_class_list: [
                  { title: 'None', value: '' },
                  { title: 'Full Width', value: 'w-full' },
                  { title: 'Centered', value: 'mx-auto' },
                ],
                // Optional: Configure image dimensions
                image_dimensions: false,  // Disable manual dimension input
                // Add paste image support
                paste_data_images: true,
              }}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default BlogEditor;