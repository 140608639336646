import { getAnalytics, logEvent } from "firebase/analytics";
import { Menu, MoreHorizontal, Star } from 'lucide-react';
import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import PullToRefresh from 'react-pull-to-refresh';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ConsultantHamburger from './ConsultantHamburger';
import NotificationBell from "./NotificationBell";
import Spinner from './Spinner';
import { toast } from 'react-toastify';

const ReviewModal = ({ review, onClose }) => {
  if (!review) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-lg">
        <h2 className="text-xl font-bold mb-4">Review by {review.reviewer.name}</h2>
        <p className="text-left text-gray-700 mb-4">{review.review_text}</p>
        <button
          className="bg-brandPrimary text-white px-4 py-2 rounded-md hover:bg-brandHover transition duration-300"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [averageRatings, setAverageRatings] = useState({ overall: 0, peer: 0, manager: 0 });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [selectedReview, setSelectedReview] = useState(null); // Add state for the modal
  const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'desc' });
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const [stats, setStats] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Reviews',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUser();
  
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
  
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
  
  
    return () => {
      window.removeEventListener('resize', checkMobile);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [user, sortConfig, dropdownRef]);

    const fetchUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data, error } = await supabase
          .from('users')
          .select('*')
          .eq('user_id', user.id)
          .single();
        if (error) console.error('Error fetching user:', error);
        else setUser(data);
      }
    };

    const fetchReviews = async () => {
      if (!user) return;
      setIsLoading(true);
      try {
  
      // Fetch all reviews for the consultant
      const { data: reviewsData, error: reviewsError } = await supabase
        .from('reviews')
        .select(`
          *,
          reviewer:users!reviews_reviewer_id_fkey(
            name,
            photo,
            job_title,
            employer
          ),
          job_history:job_history_id (
            company_name,
            job_title,
            start_date,
            end_date
          ),
          engagement:engagement_id (
            job_title,
            client:client_id (
              name
            ),
            start_date,
            end_date
          )
        `)
        .eq('consultant_id', user.id)
        .order('created_at', { ascending: false });
  
      if (reviewsError) throw reviewsError;
  
      // Process reviews to include source information
      const processedReviews = reviewsData.map(review => ({
        ...review,
        source_name: review.job_history_id 
          ? review.job_history.company_name 
          : review.engagement?.client?.name,
        position_title: review.job_history_id
          ? review.job_history.job_title
          : review.engagement?.job_title,
        date_range: {
          start: review.job_history_id
            ? review.job_history.start_date
            : review.engagement?.start_date,
          end: review.job_history_id
            ? review.job_history.end_date
            : review.engagement?.end_date
        }
      }));
  
      setReviews(processedReviews);
  
      // Calculate review statistics
      if (processedReviews.length > 0) {
        // Overall stats
        const totalReviews = processedReviews.length;
        const avgRating = processedReviews.reduce((sum, review) => sum + review.rating, 0) / totalReviews;
  
        // Reviews by type
        const byType = {
          manager: processedReviews.filter(r => r.review_type === 'manager'),
          peer: processedReviews.filter(r => r.review_type === 'peer'),
          other: processedReviews.filter(r => r.review_type === 'other')
        };
  
        // Reviews by source
        const bySource = {
          job_history: processedReviews.filter(r => r.job_history_id !== null),
          engagement: processedReviews.filter(r => r.engagement_id !== null)
        };
  
        // Calculate averages for each category
        const getAvgRating = reviews => 
          reviews.length > 0 
            ? reviews.reduce((sum, r) => sum + r.rating, 0) / reviews.length 
            : 0;
  
        setStats({
          total: {
            count: totalReviews,
            average: avgRating
          },
          types: {
            manager: {
              count: byType.manager.length,
              average: getAvgRating(byType.manager)
            },
            peer: {
              count: byType.peer.length,
              average: getAvgRating(byType.peer)
            },
            other: {
              count: byType.other.length,
              average: getAvgRating(byType.other)
            }
          },
          sources: {
            job_history: {
              count: bySource.job_history.length,
              average: getAvgRating(bySource.job_history)
            },
            engagement: {
              count: bySource.engagement.length,
              average: getAvgRating(bySource.engagement)
            }
          },
          verified: processedReviews.filter(r => r.verified).length
        });
      }
  
    } catch (error) {
      console.error('Error fetching reviews:', error);
      toast.error('Failed to load reviews');
    } finally {
      setIsLoading(false);
    }
  };

const handleSort = (key) => {
  const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
  setSortConfig({ key, direction });
};

const toggleDropdown = (reviewId) => {
  setActiveDropdown(activeDropdown === reviewId ? null : reviewId);
};

const renderStars = (rating) => {
  const stars = [];
  const roundedRating = Math.round(rating * 2) / 2; // Round to nearest 0.5
  for (let i = 1; i <= 5; i++) {
    let starFill = Math.min(Math.max(roundedRating - (i - 1), 0), 1);
    if (starFill === 1) {
      stars.push(<Star key={i} size={20} fill="gold" stroke="gold" />);
    } else if (starFill > 0) {
      stars.push(
        <div key={i} className="relative">
          <Star size={20} fill="none" stroke="gold" />
          <div
            className="absolute top-0 left-0 overflow-hidden"
            style={{ width: `${starFill * 100}%` }}
          >
            <Star size={20} fill="gold" stroke="gold" />
          </div>
        </div>
      );
    } else {
      stars.push(<Star key={i} size={20} fill="none" stroke="gold" />);
    }
  }
  return stars;
};


const handleViewFullReview = (review) => {
  setSelectedReview(review); // Set the selected review for the modal
};

const closeReviewModal = () => {
  setSelectedReview(null); // Close the modal
};

const renderReviewsTable = () => (
  <div className="bg-white shadow-md rounded-lg">
    <table className="min-w-full table-auto">
      <thead className="bg-gray-100">
        <tr>
          <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
            <div onClick={() => handleSort('reviewer.name')} className="cursor-pointer">
              Reviewer {sortConfig.key === 'reviewer.name' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
            </div>
          </th>
          <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
            <div onClick={() => handleSort('review_type')} className="cursor-pointer">
              Type {sortConfig.key === 'review_type' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
            </div>
          </th>
          <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
            <div onClick={() => handleSort('rating')} className="cursor-pointer">
              Rating {sortConfig.key === 'rating' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
            </div>
          </th>
          <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
            <div onClick={() => handleSort('user_jobs.job.title')} className="cursor-pointer">
              Job {sortConfig.key === 'user_jobs.job.title' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
            </div>
          </th>
          <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
            <div onClick={() => handleSort('created_at')} className="cursor-pointer">
              Requested {sortConfig.key === 'created_at' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
            </div>
          </th>
          <th className="px-6 py-3"></th>
        </tr>
      </thead>
      <tbody>
        {reviews.map((review) => (
          <tr key={review.review_id} className="hover:bg-gray-50">
            <td className="px-6 py-4 text-sm text-gray-700 text-left">
              {review.reviewer.name}
            </td>
            <td className="px-6 py-4 text-sm text-gray-700 text-left">
              {review.review_type.charAt(0).toUpperCase() + review.review_type.slice(1)}
            </td>
            <td className="px-6 py-4 text-sm text-left">
              {review.status === 'completed' ? (
                <div className="flex items-center">
                  {renderStars(review.rating)}
                  <span className="ml-2">{review.rating.toFixed(1)}</span>
                </div>
              ) : (
                <span className="text-yellow-600">Pending</span>
              )}
            </td>
            <td className="px-6 py-4 text-sm text-gray-700 text-left">
              {review.user_jobs?.job?.title || 'N/A'}
            </td>
            <td className="px-6 py-4 text-sm text-gray-700 text-left">
              {new Date(review.created_at).toLocaleDateString()}
            </td>
            <td className="px-6 py-4 text-left text-sm text-gray-700 relative">
              <MoreHorizontal
                size={24}
                className="cursor-pointer"
                onClick={() => toggleDropdown(review.review_id)}
              />
              {activeDropdown === review.review_id && (
                <div ref={dropdownRef} className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                  <div
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer text-left"
                    onClick={() => handleViewFullReview(review)} // Show modal on click
                    >
                    View Full Review
                  </div>
                </div>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const renderReviewsCards = () => (
  <div className="grid grid-cols-1 gap-4">
    {reviews.map((review) => (
      <div 
        key={review.review_id} 
        className="bg-white shadow-md rounded-lg p-4 relative hover:shadow-lg transition-shadow"
      >
        <div className="mb-2 flex justify-between items-center">
          <span className="bg-indigo-100 text-brandHover font-semibold px-2 py-1 rounded-md">
            {review.review_type.charAt(0).toUpperCase() + review.review_type.slice(1)} Review
          </span>
          <span>{new Date(review.created_at).toLocaleDateString()}</span>
        </div>
        <h2 className="text-xl font-bold text-gray-900 text-left mb-2">
          {review.reviewer.name}
        </h2>
        {review.status === 'completed' ? (
          <div className="flex items-center mb-2">
            {renderStars(review.rating)}
            <span className="ml-2 font-bold">{review.rating.toFixed(1)}</span>
          </div>
        ) : (
          <div className="mb-2 text-yellow-600 font-semibold">Pending Review</div>
        )}
        <div className="text-sm text-gray-500 mb-2 text-left">
          {review.user_jobs?.job?.title && <p>Job: {review.user_jobs.job.title}</p>}
          {review.clientName && <p>Client: {review.clientName}</p>}
        </div>
        {review.status === 'completed' && (
          <>
            <p className="text-gray-700 mb-2 text-left">
              {review.review_text.length > 100 
                ? `${review.review_text.substring(0, 100)}...` 
                : review.review_text}
            </p>
            {review.review_text.length > 100 && (
              <button className="text-brandPrimary hover:underline text-sm">
                Show more
              </button>
            )}
          </>
        )}
        <div className="absolute bottom-4 right-4">
          <MoreHorizontal 
            size={24} 
            className="cursor-pointer"
            onClick={() => toggleDropdown(review.review_id)}
          />
          {activeDropdown === review.review_id && (
            <div ref={dropdownRef} className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
              <div
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer text-left"
                onClick={() => handleViewFullReview(review)} // Show modal on click
                >
                View Full Review
              </div>
            </div>
          )}
        </div>
      </div>
    ))}
  </div>
);



return (
  <>
    <Helmet>
      <title>My Reviews | fetchConsultant</title>
      <meta name="description" content="View your reviews and ratings on fetchConsultant." />
    </Helmet>
    <div className="min-h-screen bg-gray-100 flex">
      <ConsultantHamburger user={user} currentPage="reviews" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
      <div className="flex-1 flex flex-col md:ml-64">
        <header className="bg-white shadow-md p-4 flex items-center justify-between">
          <div className="flex items-center">
            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
              <Menu size={24} />
            </button>
            <h1 className="text-xl font-bold ml-4">My Reviews</h1>
          </div>
          <div className="flex items-center">
            <button
              onClick={() => navigate('/app/solicit-review')}
              className="mr-4 bg-brandPrimary text-white px-4 py-2 rounded-md hover:bg-brandHover transition duration-300"
            >
              Solicit Review
            </button>
            <NotificationBell />
          </div>
        </header>

        <main className="p-4">
          <PullToRefresh
            onRefresh={fetchReviews}
            className="pull-to-refresh"
            pullingContent={<span>Pull down to refresh...</span>}
            refreshingContent={<div className="flex items-center justify-center"><Spinner /> Refreshing...</div>}
          >
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              <>
                <div className="fetch-job-card text-center rounded-lg shadow-md p-6 mb-6">
                  <h2 className="text-2xl font-bold mb-4">Average Ratings</h2>
                  <div className="flex justify-between items-center mb-2">
                    <span className="text-left">Overall Rating:</span>
                    <div className="flex items-center">
                      <span className="mr-2">({averageRatings.overallCount})</span>
                      {renderStars(averageRatings.overall)}
                      <span className="ml-2 font-bold">{averageRatings.overall.toFixed(1)}</span>
                    </div>
                  </div>
                  <div className="flex justify-between items-center mb-2">
                    <span className="text-left">Manager Rating:</span>
                    <div className="flex items-center">
                      <span className="mr-2">({averageRatings.managerCount})</span>
                      {renderStars(averageRatings.manager)}
                      <span className="ml-2 font-bold">{averageRatings.manager.toFixed(1)}</span>
                    </div>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-left">Peer Rating:</span>
                    <div className="flex items-center">
                      <span className="mr-2">({averageRatings.peerCount})</span>
                      {renderStars(averageRatings.peer)}
                      <span className="ml-2 font-bold">{averageRatings.peer.toFixed(1)}</span>
                    </div>
                  </div>
                </div>
                {isMobile ? renderReviewsCards() : renderReviewsTable()}
              </>
            )}
          </PullToRefresh>
        </main>
      </div>
    </div>

    {/* Review Modal */}
    {selectedReview && <ReviewModal review={selectedReview} onClose={closeReviewModal} />}
  </>
);
};

export default Reviews;
