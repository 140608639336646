// src/components/ClientLanding.js

import React from 'react';
import { Link } from 'react-router-dom';
import { Users, Clock, DollarSign, CheckCircle } from 'lucide-react';
import Header from './ui/Header';
import Footer from './ui/Footer';

const ClientLanding = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Header />

      <main className="-mt-24">
        <section className="bg-indigo-700 text-white py-20">
          <div className="container mx-auto px-6 relative top-24 text-center ">
            <h1 className="text-5xl font-bold mb-4 -mt-4">Find Top Epic Consultants Fast</h1>
            <p className="text-xl mb-8">Streamline your hiring process and reduce costs with direct access to skilled professionals.</p>
            <Link to="/app" className="bg-white text-indigo-700 font-bold py-3 px-8 rounded-full text-lg hover:bg-gray-100 transition duration-300">
              Post a Job
            </Link>
            <div className="mt-24"></div>
          </div>
        </section>

        <section className="py-20">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">Why Choose fetchConsultant?</h2>
            <div className="grid md:grid-cols-3 gap-8 text-left">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <Users className="text-indigo-600 mb-4 mx-auto" size={32} />
                <h3 className="text-xl font-semibold mb-2 text-center">Quality Talent Pool</h3>
                <p className="text-gray-600">Access a curated network of experienced Epic consultants.</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <Clock className="text-indigo-600 mb-4 mx-auto" size={32} />
                <h3 className="text-xl font-semibold mb-2 text-center">Faster Hiring</h3>
                <p className="text-gray-600">Reduce time-to-hire with our streamlined process and direct consultant interactions.</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <DollarSign className="text-indigo-600 mb-4 mx-auto" size={32} />
                <h3 className="text-xl font-semibold mb-2 text-center">Cost Savings</h3>
                <p className="text-gray-600">Save on staffing costs with our transparent 4.5% platform fee (per party) fee structure.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-gray-100 py-20">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">How It Works</h2>
            <div className="max-w-3xl mx-auto text-left">
              <div className="flex items-center mb-6">
                <div className="bg-indigo-600 rounded-full p-3 text-white mr-4">1</div>
                <p className="text-lg"><span className="font-semibold">Post Your Job:</span> Describe your Epic consulting needs and requirements.</p>
              </div>
              <div className="flex items-center mb-6">
                <div className="bg-indigo-600 rounded-full p-3 text-white mr-4">2</div>
                <p className="text-lg"><span className="font-semibold">Review Applications:</span> Receive and evaluate applications from qualified consultants.</p>
              </div>
              <div className="flex items-center mb-6">
                <div className="bg-indigo-600 rounded-full p-3 text-white mr-4">3</div>
                <p className="text-lg"><span className="font-semibold">Interview and Select:</span> Conduct interviews and choose the best fit for your project.</p>
              </div>
              <div className="flex items-center">
                <div className="bg-indigo-600 rounded-full p-3 text-white mr-4">4</div>
                <p className="text-lg"><span className="font-semibold">Hire and Onboard:</span> Finalize the contract and begin your project with your new consultant.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="py-20">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">Benefits for Healthcare Organizations</h2>
            <div className="grid md:grid-cols-2 gap-8 text-left">
              <div className="flex items-start">
              <CheckCircle className="text-indigo-600 mr-4 flex-shrink-0" size={24} />
                <div>
                  <h3 className="text-xl font-semibold mb-2">Cost-Effective Staffing</h3>
                  <p className="text-gray-600">Reduce your staffing costs with our transparent pricing model and direct access to consultants.</p>
                </div>
              </div>
              <div className="flex items-start">
                <CheckCircle className="text-indigo-600 mr-4 flex-shrink-0" size={24} />
                <div>
                  <h3 className="text-xl font-semibold mb-2">Faster Time-to-Hire</h3>
                  <p className="text-gray-600">Fill your Epic consulting positions quickly with our streamlined hiring process.</p>
                </div>
              </div>
              <div className="flex items-start">
                <CheckCircle className="text-indigo-600 mr-4 flex-shrink-0" size={24} />
                <div>
                  <h3 className="text-xl font-semibold mb-2">Quality Assurance</h3>
                  <p className="text-gray-600">Access a network of vetted, experienced Epic consultants with verified skills and certifications.</p>
                </div>
              </div>
              <div className="flex items-start">
                <CheckCircle className="text-indigo-600 mr-4 flex-shrink-0" size={24} />
                <div>
                  <h3 className="text-xl font-semibold mb-2">Flexibility and Control</h3>
                  <p className="text-gray-600">Manage your hiring process directly, with the ability to communicate with consultants in real-time.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-gray-100 py-20">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">What Our Clients Say</h2>
            <div className="grid md:grid-cols-2 gap-8 text-left">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <p className="text-gray-600 mb-4">"fetchConsultant has revolutionized our Epic staffing process. We've reduced our time-to-hire by 50% and found high-quality consultants that perfectly fit our needs."</p>
                <p className="font-semibold">- Sarah Johnson, CIO at Metro Health System</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <p className="text-gray-600 mb-4">"The direct access to consultants and transparent pricing model have significantly reduced our staffing costs. fetchConsultant is now our go-to platform for Epic talent."</p>
                <p className="font-semibold">- Michael Lee, IT Director at Central Hospital</p>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-gray-900 text-white py-20">
          <div className="container mx-auto px-6 text-center">
            <h2 className="text-3xl font-bold mb-4">Ready to Transform Your Epic Staffing?</h2>
            <p className="text-xl mb-8">Join fetchConsultant today and experience the future of healthcare IT staffing.</p>
            <Link to="/app" className="bg-white text-indigo-700 font-bold py-3 px-8 rounded-full text-lg hover:bg-gray-100 transition duration-300">
              Post Your First Job
            </Link>
          </div>
        </section>
      </main>

      <Footer/>
    </div>
  );
};

export default ClientLanding;