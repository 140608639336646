import React, { useEffect } from 'react';
import { Star, Info } from 'lucide-react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const ReviewStep = ({
  formData,
  onChange,
  onBack,
  onNext,
  tokenData,
  verificationType, 
  detailsToVerify,
  existingVerifications
}) => {
  useEffect(() => {
    if (!formData.reviewType && tokenData?.review_type) {
      onChange({ reviewType: tokenData.review_type });
    }
    if (!formData.relationshipDetails && tokenData?.relationship_details) {
      onChange({ relationshipDetails: tokenData.relationship_details });
    }
  }, [tokenData, formData, onChange]);

  const handleRatingClick = (rating) => {
    onChange({ rating });
  };

  const ratingLabels = {
    1: 'Needs Improvement',
    2: 'Fair',
    3: 'Good',
    4: 'Very Good',
    5: 'Outstanding'
  };

  // Skip review step if verification was disputed
  if (!formData.isVerified) {
    onNext();
    return null;
  }

  const isFirstVerification = !existingVerifications?.length;

  return (
    <div className="p-6">
      <h2 className="text-xl font-semibold mb-6">
        Leave a Review {isFirstVerification ? '(Required)' : '(Optional)'}
      </h2>

      <div className="prose max-w-none mb-6">
        <p className="text-gray-600">
          Your review helps healthcare organizations make informed decisions.
          All reviews are confidential and only shared with potential employers.
          {verificationType === 'job_history'
            ? ' This review will be associated with previous work experience.'
            : ' This review will be associated with work through fetchConsultant.'}
        </p>
      </div>

      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700">
          Review Type
        </label>
        <div className="mt-2">
          <select
            value={formData.reviewType ?? tokenData?.review_type ?? ''}
            onChange={(e) => onChange({ reviewType: e.target.value })}
            className="fetch-select"
            required={isFirstVerification}
          >
            <option value="">Select Type</option>
            <option value="manager">Manager</option>
            <option value="peer">Peer</option>
            <option value="other">Other</option>
          </select>
          <p className="mt-1 text-sm text-gray-500">
            {formData.reviewType === 'manager'
              ? "Select this if you were their direct manager or supervisor"
              : formData.reviewType === 'peer'
              ? "Select this if you worked together as colleagues"
              : "Select this for any other professional relationship"}
          </p>
        </div>

        {formData.reviewType === 'other' && (
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">
              Please specify relationship
            </label>
            <input
              type="text"
              value={formData.relationshipDetails || ''}
              onChange={(e) => onChange({ relationshipDetails: e.target.value })}
              className="fetch-input mt-1"
              placeholder="e.g., Project Manager, Team Lead, etc."
              required={isFirstVerification}
            />
          </div>
        )}
      </div>

      {/* Rating Section */}
      <div className="mb-8">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Overall Rating {isFirstVerification && <span className="text-red-500">*</span>}
        </label>
        <div className="flex items-center space-x-2 mb-2">
          {[1, 2, 3, 4, 5].map((star) => (
            <button
              key={star}
              type="button"
              onClick={() => handleRatingClick(star)}
              className="focus:outline-none"
            >
              <Star
                size={32}
                className={`${
                  star <= formData.rating
                    ? 'fill-yellow-400 text-yellow-400'
                    : 'fill-none text-gray-300'
                } transition-colors hover:text-yellow-400`}
              />
            </button>
          ))}
        </div>
        {formData.rating > 0 && (
          <p className="text-sm text-gray-600">
            {ratingLabels[formData.rating]}
          </p>
        )}
      </div>

      {/* Review Text Section */}
      <div className="mb-8">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Write Your Review
          <div className="mt-1 text-sm font-normal text-gray-500">
            Share your experience working with them, including their strengths, skills, and professionalism
          </div>
        </label>
        <ReactQuill
          theme="snow"
          value={formData.reviewText}
          onChange={(content) => onChange({ reviewText: content })}
          className="h-32 mb-12"
          modules={{
            toolbar: [
              ['bold', 'italic', 'underline'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['clean']
            ]
          }}
        />
      </div>

      {/* Contact Info Sharing */}
      <div className="mb-8">
        <div className="flex items-start">
          <div className="flex items-center h-5">
            <input
              id="shareContact"
              type="checkbox"
              checked={formData.shareContactInfo}
              onChange={(e) => onChange({ shareContactInfo: e.target.checked })}
              className="fetch-checkbox"
            />
          </div>
          <div className="ml-3">
            <label htmlFor="shareContact" className="text-sm font-medium text-gray-700">
              Share my contact information
            </label>
            <p className="text-sm text-gray-500">
              Allow potential employers to contact you for reference checks
            </p>
          </div>
        </div>
      </div>

      {/* First Verification Notice */}
      {isFirstVerification && (
        <div className="bg-blue-50 rounded-lg p-4 mb-8">
          <div className="flex">
            <div className="flex-shrink-0">
              <Info className="h-5 w-5 text-blue-400" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-blue-700">
                As the first verifier, you must provide at least a rating to help establish credibility.
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Navigation Buttons */}
      <div className="flex justify-between">
        <button
          type="button"
          onClick={onBack}
          className="px-4 py-2 border rounded text-gray-600 hover:bg-gray-50"
        >
          Back
        </button>
        <button
          type="button"
          onClick={onNext}
          disabled={isFirstVerification && (!formData.rating || !formData.reviewType)}
          className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 disabled:opacity-50"
        >
          Next: Review Summary
        </button>
      </div>
    </div>
  );
};

ReviewStep.propTypes = {
  formData: PropTypes.shape({
    rating: PropTypes.number.isRequired,
    reviewText: PropTypes.string.isRequired,
    shareContactInfo: PropTypes.bool.isRequired,
    reviewType: PropTypes.string,
    relationshipDetails: PropTypes.string,
    isVerified: PropTypes.bool.isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  tokenData: PropTypes.object,
  verificationType: PropTypes.oneOf(['job_history', 'engagement']).isRequired,
  detailsToVerify: PropTypes.object,
  existingVerifications: PropTypes.array
};

export default ReviewStep;