import React, { useState } from 'react';
import { getAnalytics, logEvent } from "firebase/analytics";
import Modal from './Modal';
import { Star, MessageCircle, Calendar, Briefcase, MapPin, Building, Users } from 'lucide-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const ApplicantWorkHistoryModal = ({ isOpen, onClose, workHistory, onStartChat }) => {
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

  if (!workHistory) return null;

  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <Star
        key={index}
        size={20}
        className={index < rating ? "text-yellow-400 fill-yellow-400" : "text-gray-300"}
      />
    ));
  };

  const handleStartChat = (reviewerId) => {
    const analytics = getAnalytics();
    logEvent(analytics, 'start_chat_from_review', {
      reviewer_id: reviewerId,
      context: 'work_history_modal'
    });
    onStartChat(reviewerId);
  };

  const formatDate = (date) => {
    if (!date) return 'Present';
    return new Date(date).toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    });
  };

  const formatPhoneNumber = (phoneNumberString) => {
    if (!phoneNumberString) return '';
    // Remove all non-numeric characters
    const cleaned = phoneNumberString.replace(/\D/g, '');
    // Check if number has 10 digits
    if (cleaned.length !== 10) return phoneNumberString;
    // Format as (###) ###-####
    return `(${cleaned.slice(0,3)}) ${cleaned.slice(3,6)}-${cleaned.slice(6)}`;
  };

  const nextReview = () => {
    if (workHistory.reviews && currentReviewIndex < workHistory.reviews.length - 1) {
      setCurrentReviewIndex(currentReviewIndex + 1);
    }
  };
  
  const previousReview = () => {
    if (currentReviewIndex > 0) {
      setCurrentReviewIndex(currentReviewIndex - 1);
    }
  };

  // Get verification count
  const verifiedCount = workHistory.reviews?.filter(review => 
    review.verification_status === 'verified'
  ).length || 0;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Employment Details"
      size="lg"
    >
      <div className="p-6">
        {/* Verification Status Banner */}
        <div className={`rounded-lg p-4 mb-6 ${
          verifiedCount > 0 ? 'bg-green-50' : 'bg-gray-50'
        }`}>
          <div className="flex items-start">
            <div className="flex-shrink-0">
              {verifiedCount > 0 ? (
                <div className="h-6 w-6 text-green-400">
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
              ) : (
                <div className="h-6 w-6 text-gray-400">
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
              )}
            </div>
            <div className="ml-3">
              <h3 className={verifiedCount > 0 ? 'text-green-800' : 'text-gray-800'}>
                {verifiedCount > 0 
                  ? `Verified by ${verifiedCount} ${verifiedCount === 1 ? 'person' : 'people'}`
                  : 'Not yet verified'}
              </h3>
            </div>
          </div>
        </div>

        {/* Employment Details */}
        <div className="bg-white rounded-lg border border-gray-200 mb-6">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Position Details</h3>
            <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
              <div className="flex items-center">
                <Briefcase className="h-5 w-5 text-gray-400 mr-2" />
                <div>
                  <dt className="text-sm font-medium text-gray-500">Position</dt>
                  <dd className="mt-1 text-sm text-gray-900">{workHistory.job_title}</dd>
                </div>
              </div>
              
              <div className="flex items-center">
                <Building className="h-5 w-5 text-gray-400 mr-2" />
                <div>
                  <dt className="text-sm font-medium text-gray-500">
                    {workHistory.type === 'engagement' ? 'Client' : 'Company'}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">{workHistory.company_name}</dd>
                </div>
              </div>

              <div className="flex items-center">
                <Calendar className="h-5 w-5 text-gray-400 mr-2" />
                <div>
                  <dt className="text-sm font-medium text-gray-500">Duration</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    ${formatDate(workHistory.start_date)} - ${formatDate(workHistory.end_date)}`                  
                  </dd>
                </div>
              </div>

              <div className="flex items-center">
                <MapPin className="h-5 w-5 text-gray-400 mr-2" />
                <div>
                  <dt className="text-sm font-medium text-gray-500">Work Type</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {workHistory.type === 'engagement' ? 'contract' : workHistory.work_type || 'full-time'} • {workHistory.location_type || 'remote'} • {workHistory.job_type || 'consultant'}
                  </dd>
                </div>
              </div>

              {workHistory.type === 'engagement' && (
              <div className="flex items-center">
                <Users className="h-5 w-5 text-gray-400 mr-2" />
                <div>
                  <dt className="text-sm font-medium text-gray-500">Consulting Firm</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {workHistory.type === 'engagement' ? 'fetchConsultant' : workHistory.firm_name}
                  </dd>
                </div>
              </div>
              )}
            </dl>
          </div>
        </div>

        {/* Description */}
        <div className="bg-white rounded-lg border border-gray-200 mb-6">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Job Description</h3>
            <ReactQuill
              value={workHistory.context_description || workHistory.job_description}
              readOnly={true}
              theme="bubble"
              modules={{ toolbar: false }}
            />
          </div>
        </div>

        {/* Reviews Carousel */}
        {workHistory.reviews && workHistory.reviews.length > 0 && (
          <div className="bg-white rounded-lg border border-gray-200">
            <div className="px-4 py-5 sm:p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium text-gray-900">
                  Reviews ({workHistory.reviews.length})
                </h3>
                {workHistory.reviews.length > 1 && (
                <div className="flex space-x-2">
                  <button
                    onClick={previousReview}
                    disabled={currentReviewIndex === 0}
                    className="px-3 py-1 text-sm bg-gray-100 rounded disabled:opacity-50 hover:bg-gray-200"
                  >
                    Previous
                  </button>
                  <button
                    onClick={nextReview}
                    disabled={currentReviewIndex === workHistory.reviews.length - 1}
                    className="px-3 py-1 text-sm bg-gray-100 rounded disabled:opacity-50 hover:bg-gray-200"
                  >
                    Next
                  </button>
                  </div>
                )}
              </div>
              
              <div className="relative">
                {/* Current Review */}
                <div className="review-content">
                  <div className="flex justify-between items-start mb-2">
                    <div>
                      <div className="flex items-center gap-2">
                        <p className="font-medium">{workHistory.reviews[currentReviewIndex]?.reviewer?.name || 'Anonymous'}</p>
                        {workHistory.reviews[currentReviewIndex]?.reviewer?.linkedin_url && (
                          <a 
                            href={workHistory.reviews[currentReviewIndex]?.reviewer?.linkedin_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-brandPrimary hover:underline flex items-center"
                          >
                            <img 
                              src="/images/linkedin-logo.png" 
                              alt="LinkedIn" 
                              className="w-4 h-4"
                            />
                          </a>
                        )}
                      </div>
                      <p className="text-sm text-gray-600">
                        {workHistory.reviews[currentReviewIndex]?.reviewer?.job_title}
                      </p>
                      <p className="text-sm text-gray-600">
                        Reviewer Relationship: {
                          workHistory.reviews[currentReviewIndex]?.review_type === 'other' 
                            ? workHistory.reviews[currentReviewIndex]?.relationship_details
                            : workHistory.reviews[currentReviewIndex]?.review_type?.charAt(0).toUpperCase() + 
                            workHistory.reviews[currentReviewIndex]?.review_type?.slice(1)
                        }                        
                        {workHistory.reviews[currentReviewIndex]?.reviewer?.client?.name && 
                          ` at ${workHistory.reviews[currentReviewIndex].reviewer.client.name}`}
                      </p>
                      <p className="text-sm text-gray-500">
                        {workHistory.reviews[currentReviewIndex]?.created_at ? 
                          new Date(workHistory.reviews[currentReviewIndex].created_at).toLocaleDateString() 
                          : 'No date available'
                        }
                      </p>
                    </div>
                    <div className="flex">
                      {renderStars(workHistory.reviews[currentReviewIndex]?.rating || 0)}
                    </div>
                  </div>
                  <ReactQuill
                    value={workHistory.reviews[currentReviewIndex].review_text}
                    readOnly={true}
                    theme="bubble"
                    modules={{ toolbar: false }}
                  />
                </div>
              </div>

              {/* Contact Information */}
              {workHistory.reviews[currentReviewIndex]?.share_contact_info && (
                <div className="border-t pt-4 mt-4">
                  <h3 className="text-lg font-semibold mb-2">Contact Information</h3>
                    <div className="space-y-2 mb-4">
                      {(workHistory.reviews[currentReviewIndex]?.reviewer?.work_email || workHistory.reviews[currentReviewIndex]?.reviewer?.login_email) && (
                      <p className="text-sm">
                        <span className="font-medium">Email:</span>{' '}
                        <a 
                          href={`mailto:${workHistory.reviews[currentReviewIndex]?.reviewer?.work_email || workHistory.reviews[currentReviewIndex]?.reviewer?.login_email}`}
                          className="text-brandPrimary hover:underline"
                        >
                          {workHistory.reviews[currentReviewIndex]?.reviewer?.work_email || workHistory.reviews[currentReviewIndex]?.reviewer?.login_email}
                        </a>
                      </p>
                    )}
                    {workHistory.reviews[currentReviewIndex]?.reviewer?.phone && (
                      <p className="text-sm">
                        <span className="font-medium">Phone:</span>{' '}
                        <a 
                          href={`tel:${workHistory.reviews[currentReviewIndex]?.reviewer?.phone}`}
                          className="text-brandPrimary hover:underline"
                        >
                          {formatPhoneNumber(workHistory.reviews[currentReviewIndex]?.reviewer?.phone)}
                        </a>
                      </p>
                    )}
                  </div>
                  <button
                    onClick={() => handleStartChat(workHistory.reviews[currentReviewIndex]?.reviewer?.user_id)}
                    className="flex items-center justify-center w-full px-4 py-2 bg-brandPrimary text-white rounded-md hover:bg-brandHover transition duration-300"
                  >
                    <MessageCircle size={20} className="mr-2" />
                    Message Reviewer
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ApplicantWorkHistoryModal;