import { getAnalytics, logEvent } from "firebase/analytics";
import { Eye, Menu, MoreHorizontal, Download, Mail, DollarSign, Plus } from 'lucide-react';
import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { supabase } from '../supabaseClient';
import SystemAdminHamburger from './SystemAdminHamburger';
import NotificationBell from "./NotificationBell";
import DateRangePicker from './ui/DateRangePicker';
import Modal from './ui/Modal';
import InvoiceStatusBadge from './ui/InvoiceStatusBadge';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InvoicePayments from './InvoicePayments';
import InvoiceDetail from './InvoiceDetail';

const SystemAdminInvoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const dropdownRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: 'sent_date', direction: 'desc' });
  const [statusFilter, setStatusFilter] = useState('All');
  const [consultantFilter, setConsultantFilter] = useState('All');
  const [ownerFilter, setOwnerFilter] = useState('All');
  const [clientFilter, setClientFilter] = useState('All');
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
  const [searchTerm, setSearchTerm] = useState('');
  const [consultants, setConsultants] = useState([]);
  const [owners, setOwners] = useState([]);
  const [clients, setClients] = useState([]);
  const [isCreateInvoiceModalOpen, setIsCreateInvoiceModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showInvoiceDetail, setShowInvoiceDetail] = useState(false);
  const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'System Admin Invoices',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  
    fetchData();
    fetchInvoiceStats();

    const checkMobile = () => setIsMobile(window.innerWidth <= 768);
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, [statusFilter, sortConfig, consultantFilter, ownerFilter, clientFilter, dateRange, searchTerm]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const fetchData = async () => {
    try {
      const { data: { user: authUser } } = await supabase.auth.getUser();
      if (!authUser) throw new Error("No authenticated user found");

      const userResponse = await fetch(`${supabaseUrl}/functions/v1/user-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${(await supabase.auth.getSession()).data.session?.access_token}`
        },
        body: JSON.stringify({
          action: 'fetchUser',
          userId: authUser.id
        })
      });

      if (!userResponse.ok) throw new Error('Failed to fetch user data');
      const userData = await userResponse.json();
      setUser(userData.data);

      const invoicesResponse = await fetch(`${supabaseUrl}/functions/v1/invoice-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${(await supabase.auth.getSession()).data.session?.access_token}`
        },
        body: JSON.stringify({
          action: 'fetchAllInvoices',
          userId: authUser.id, 
          filters: {
            status: statusFilter,
            consultant: consultantFilter,
            owner: ownerFilter,
            client: clientFilter,
            dateRange,
            searchTerm,
            sortKey: sortConfig.key,
            sortDirection: sortConfig.direction
          }
        })
      });

      if (!invoicesResponse.ok) throw new Error('Failed to fetch invoices data');
      const invoicesData = await invoicesResponse.json();
      setInvoices(invoicesData.data);

      // Set filter options
      const uniqueConsultants = [...new Set(invoicesData.data.map(invoice => invoice.consultant_name))];
      const uniqueOwners = [...new Set(invoicesData.data.map(invoice => invoice.approved_by_name))];
      const uniqueClients = [...new Set(invoicesData.data.map(invoice => invoice.client_name))];
      setConsultants(uniqueConsultants);
      setOwners(uniqueOwners);
      setClients(uniqueClients);

      await fetchInvoiceStats();

    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const toggleDropdown = (invoiceId) => {
    setActiveDropdown(activeDropdown === invoiceId ? null : invoiceId);
  };

  const handleSort = (key) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const [invoiceStats, setInvoiceStats] = useState({
    totalOutstanding: 0,
    totalPaid: 0,
    countDraft: 0,
    countSent: 0,
    countPaid: 0,
    countPartial: 0,
    countOutstanding: 0
  });
  
  const fetchInvoiceStats = async () => {
    try {
      const { data: { user: authUser } } = await supabase.auth.getUser();

      const response = await fetch(`${supabaseUrl}/functions/v1/invoice-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${(await supabase.auth.getSession()).data.session?.access_token}`
        },
        body: JSON.stringify({
          action: 'getInvoiceStats',
          userId: authUser.id
        })
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch invoice stats');
      }
  
      const statsData = await response.json();
      console.log("statsData", statsData);
      setInvoiceStats(statsData.data);
  
    } catch (error) {
      console.error('Error fetching invoice stats:', error);
    }
  };

  const handlePaymentAdded = async () => {
    try {
      await fetchData(); // This will also call fetchInvoiceStats
      setIsPaymentModalOpen(false);
      setSelectedInvoice(null);
    } catch (error) {
      console.error('Error refreshing data after payment:', error);
    }
  };

  const handleCreateInvoice = async () => {
    try {
      const { data: { user: authUser } } = await supabase.auth.getUser();

      setIsSubmitting(true);
      const response = await fetch(`${supabaseUrl}/functions/v1/invoice-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${(await supabase.auth.getSession()).data.session?.access_token}`
        },
        body: JSON.stringify({
          action: 'createInvoice',
          userId: authUser.id,
          data: {
            clientId: selectedClient,
            startDate,
            endDate
          }
        })
      });
  
      if (!response.ok) throw new Error('Failed to create invoice');
  
      toast.success('Invoice created successfully');
      setIsCreateInvoiceModalOpen(false);
      fetchData(); // Refresh the invoice data
      
      // Reset form
      setSelectedClient('');
      setStartDate('');
      setEndDate('');
  
    } catch (error) {
      console.error('Error creating invoice:', error);
      toast.error('Failed to create invoice');
    } finally {
      setIsSubmitting(false);
    }
  };

  const InvoiceAging = ({ invoices }) => {
    const calculateAging = () => {
      const now = new Date();
      return {
        current: invoices.filter(inv => new Date(inv.due_date) > now).reduce((sum, inv) => sum + (inv.balance || 0), 0),
        '1-30': invoices.filter(inv => {
          const days = (now - new Date(inv.due_date)) / (1000 * 60 * 60 * 24);
          return days > 0 && days <= 30;
        }).reduce((sum, inv) => sum + (inv.balance || 0), 0),
        '31-60': invoices.filter(inv => {
          const days = (now - new Date(inv.due_date)) / (1000 * 60 * 60 * 24);
          return days > 30 && days <= 60;
        }).reduce((sum, inv) => sum + (inv.balance || 0), 0),
        '61-90': invoices.filter(inv => {
          const days = (now - new Date(inv.due_date)) / (1000 * 60 * 60 * 24);
          return days > 60 && days <= 90;
        }).reduce((sum, inv) => sum + (inv.balance || 0), 0),
        '90+': invoices.filter(inv => {
          const days = (now - new Date(inv.due_date)) / (1000 * 60 * 60 * 24);
          return days > 90;
        }).reduce((sum, inv) => sum + (inv.balance || 0), 0)
      };
    };
  
    const aging = calculateAging();
  
    return (
      <div className="grid grid-cols-5 gap-4 mb-6">
        <div className="bg-white p-4 rounded-lg shadow-sm">
          <div className="text-sm text-gray-500">Current</div>
          <div className="text-lg font-semibold">${aging.current.toFixed(2)}</div>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-sm">
          <div className="text-sm text-gray-500">1-30 Days</div>
          <div className="text-lg font-semibold">${aging['1-30'].toFixed(2)}</div>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-sm">
          <div className="text-sm text-gray-500">31-60 Days</div>
          <div className="text-lg font-semibold">${aging['31-60'].toFixed(2)}</div>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-sm">
          <div className="text-sm text-gray-500">61-90 Days</div>
          <div className="text-lg font-semibold">${aging['61-90'].toFixed(2)}</div>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-sm">
          <div className="text-sm text-gray-500">90+ Days</div>
          <div className="text-lg font-semibold text-red-600">${aging['90+'].toFixed(2)}</div>
        </div>
      </div>
    );
  };

  const handleDownloadInvoice = async (e, invoiceId) => {
    e.stopPropagation();
    try {
      const response = await fetch(`${supabaseUrl}/functions/v1/generate-invoice`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${(await supabase.auth.getSession()).data.session?.access_token}`
        },
        body: JSON.stringify({ invoiceId })
      });

      if (!response.ok) throw new Error('Failed to generate invoice');
      
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `invoice-${invoiceId}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

    } catch (error) {
      console.error('Error downloading invoice:', error);
    }
  };

  const handleSendInvoice = async (e, invoice) => {
    e.stopPropagation();
    try {
      const { data: { user: authUser } } = await supabase.auth.getUser();

      const response = await fetch(`${supabaseUrl}/functions/v1/send-invoice`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${(await supabase.auth.getSession()).data.session?.access_token}`
        },
        body: JSON.stringify({ 
          invoiceId: invoice.id,
          userId: authUser.id
        })
      });
  
      if (!response.ok) throw new Error('Failed to send invoice');
  
      const analytics = getAnalytics();
      logEvent(analytics, 'invoice_sent_manually', {
        invoice_id: invoice.id,
        client_id: invoice.client_id
      });
  
      // Refresh data
      fetchData();
  
    } catch (error) {
      console.error('Error sending invoice:', error);
    }
  };
  
  const handleResendEmail = async (e, invoiceId) => {  // Change parameter to full invoice object
    e.stopPropagation();
    try {
      const { data: { user: authUser } } = await supabase.auth.getUser();
      const response = await fetch(`${supabaseUrl}/functions/v1/send-invoice`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${(await supabase.auth.getSession()).data.session?.access_token}`
        },
        body: JSON.stringify({ 
          invoiceId: invoiceId,  // This needs to be the invoice ID
          userId: authUser.id
        })
      });
  
      if (!response.ok) {
        throw new Error('Failed to resend invoice');
      }
  
      const analytics = getAnalytics();
      logEvent(analytics, 'invoice_resent', {
        invoice_id: invoiceId
      });
  
      toast.success('Invoice resent successfully');
      await fetchData(); // Refresh all data
  
    } catch (error) {
      console.error('Error resending invoice:', error);
      toast.error('Failed to resend invoice');
    }
  };

  return (
    <>
      <Helmet>
        <title>System Admin Invoices | fetchConsultant</title>
        <meta name="description" content="Manage and track all invoices across the fetchConsultant platform." />
      </Helmet>

      <div className="min-h-screen bg-gray-100 flex">
        <SystemAdminHamburger user={user} currentPage="invoices" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">System Admin Invoices</h1>
            </div>
            <div className="flex items-center">
              <NotificationBell />
            </div>
          </header>

          <main className="p-4">
            <div className="mb-4 flex flex-wrap gap-4 items-center justify-between">
              <div className="flex flex-wrap gap-4 items-center">
                {/* Include all filters from ClientInvoices.js plus client filter */}
                <select
                  value={clientFilter}
                  onChange={(e) => setClientFilter(e.target.value)}
                  className="fetch-select"
                >
                  <option value="All">All Clients</option>
                  {clients.map(client => (
                    <option key={client} value={client}>{client}</option>
                  ))}
                </select>
                <select
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}
                  className="fetch-select"
                >
                  <option value="All">All Statuses</option>
                  <option value="Draft">Draft</option>
                  <option value="Sent">Sent</option>
                  <option value="Partial">Partial</option>
                  <option value="Paid">Paid</option>
                </select>

                <select
                  value={consultantFilter}
                  onChange={(e) => setConsultantFilter(e.target.value)}
                  className="fetch-select"
                >
                  <option value="All">All Consultants</option>
                  {consultants.map(consultant => (
                    <option key={consultant} value={consultant}>{consultant}</option>
                  ))}
                </select>

                <select
                  value={ownerFilter}
                  onChange={(e) => setOwnerFilter(e.target.value)}
                  className="fetch-select"
                >
                  <option value="All">All Owners</option>
                  {owners.map(owner => (
                    <option key={owner} value={owner}>{owner}</option>
                  ))}
                </select>

                <DateRangePicker
                  startDate={dateRange.startDate}
                  endDate={dateRange.endDate}
                  onChange={setDateRange}
                />

                <input
                  type="text"
                  placeholder="Search invoices..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="fetch-input"
                />
              </div>

              <button
                onClick={() => setIsCreateInvoiceModalOpen(true)}
                className="bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-md flex items-center"
              >
                <Plus size={20} className="mr-2" />
                Create Invoice
              </button>

              <div className="flex items-center space-x-4">
                <div className="text-sm font-medium">
                  {console.log("invoicestats", invoiceStats)}
                  <div>Total Outstanding: ${invoiceStats?.totalOutstanding?.toFixed(2) || '0.00'}</div>
                  <div>Total Paid: ${invoiceStats?.totalPaid?.toFixed(2) || '0.00'}</div>
                </div>
                <div className="text-sm font-medium">
                  <div>Draft: {invoiceStats?.countDraft || 0}</div>
                  <div>Sent: {invoiceStats?.countSent || 0}</div>
                  <div>Paid: {invoiceStats?.countPaid || 0}</div>
                  <div>Partial: {invoiceStats?.countPartial || 0}</div>
                </div>
              </div>
            </div>

            <InvoiceAging invoices={invoices} />

            {invoices.length === 0 ? (
              <p className="text-gray-600 text-center">No invoices found.</p>
            ) : isMobile ? (
              <div className="grid grid-cols-1 gap-4">
                {invoices.map((invoice) => (
                  <div 
                    key={invoice.id} 
                    className="bg-white shadow-md rounded-lg p-4 relative"
                    onClick={() => {
                      setSelectedInvoice(invoice);
                      setShowInvoiceDetail(true);
                    }}
                  >
                    <div className="flex justify-between items-start mb-2">
                      <div>
                        <h3 className="font-medium">{invoice.invoice_number}</h3>
                        <p className="text-sm text-gray-500">{new Date(invoice.sent_date).toLocaleDateString()}</p>
                      </div>
                      <InvoiceStatusBadge status={invoice.status} />
                    </div>
                    <div className="text-sm">
                      <p>Consultant: {invoice.consultant_name}</p>
                      <p>Amount: ${invoice.amount.toFixed(2)}</p>
                    </div>
                    <div className="absolute top-4 right-4 overflow-visible">
                      <MoreHorizontal 
                        size={20} 
                        className="cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleDropdown(invoice.id);
                        }}
                      />
                      {activeDropdown === invoice.id && (
                        <div
                          className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50 overflow-visible"
                          ref={dropdownRef}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div 
                            className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center cursor-pointer"
                            onClick={(e) => handleDownloadInvoice(e, invoice.id)}
                          >
                            <Download size={16} className="mr-2" />
                            Download PDF
                          </div>
                          {invoice.pdf_url && (
                            <a
                              href={invoice.pdf_url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                            >
                              <Eye size={16} className="mr-2" />
                              View PDF
                            </a>
                          )}
                          {invoice.status === 'Draft' && (
                            <div 
                              className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center cursor-pointer"
                              onClick={(e) => handleSendInvoice(e, invoice)}
                            >
                              <Mail size={16} className="mr-2" />
                              Send Invoice
                            </div>
                          )}
                            {(invoice.status === 'Sent' || invoice.status === 'Partial') && (
                            <>
                              <div 
                                className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center cursor-pointer"
                                onClick={(e) => handleResendEmail(e, invoice.id)}
                              >
                                <Mail size={16} className="mr-2" />
                                Resend Email
                              </div>
                              {invoice.status !== 'Paid' && (
                                <div 
                                  className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedInvoice(invoice);
                                    setIsPaymentModalOpen(true);
                                  }}
                                >
                                  <DollarSign size={16} className="mr-2" />
                                  Record Payment
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="bg-white shadow-md rounded-lg overflow-hidden">
                <table className="min-w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th onClick={() => handleSort('invoice_number')} className="cursor-pointer">
                        Invoice Number {sortConfig.key === 'invoice_number' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                      </th>
                      <th onClick={() => handleSort('sent_date')} className="cursor-pointer">
                        Date {sortConfig.key === 'sent_date' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                      </th>
                      <th onClick={() => handleSort('due_date')} className="cursor-pointer">
                        Due Date {sortConfig.key === 'due_date' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                      </th>
                      <th onClick={() => handleSort('consultant_name')} className="cursor-pointer">
                        Consultant {sortConfig.key === 'consultant_name' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                      </th>
                      <th onClick={() => handleSort('approved_by_name')} className="cursor-pointer">
                        Owner {sortConfig.key === 'approved_by_name' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                      </th>
                      <th onClick={() => handleSort('status')} className="cursor-pointer">
                        Status {sortConfig.key === 'status' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                      </th>
                      <th onClick={() => handleSort('purchase_order')} className="cursor-pointer">
                        PO Number {sortConfig.key === 'purchase_order' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                      </th>
                      <th onClick={() => handleSort('total_hours')} className="cursor-pointer text-right">
                        Hours {sortConfig.key === 'total_hours' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                      </th>
                      <th onClick={() => handleSort('amount')} className="cursor-pointer text-right">
                        Amount {sortConfig.key === 'amount' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                      </th>
                      <th onClick={() => handleSort('total_paid')} className="cursor-pointer text-right">
                        Paid {sortConfig.key === 'total_paid' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                      </th>
                      <th onClick={() => handleSort('balance')} className="cursor-pointer text-right">
                        Balance {sortConfig.key === 'balance' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoices.map((invoice) => (
                      <tr 
                        key={invoice.id}
                        className="hover:bg-gray-50 cursor-pointer"
                        onClick={() => {
                          setSelectedInvoice(invoice);
                          setShowInvoiceDetail(true);
                        }}
                      >
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">{invoice.invoice_number}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-500">
                            {new Date(invoice.sent_date).toLocaleDateString()}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-500">
                            {new Date(invoice.due_date).toLocaleDateString()}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">{invoice.consultant_name}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">{invoice.approved_by_name}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <InvoiceStatusBadge status={invoice.status} />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">{invoice.purchase_order}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right">
                          <div className="text-sm text-gray-900">{invoice.total_hours.toFixed(2)}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right">
                          <div className="text-sm text-gray-900">${(invoice.amount || 0).toFixed(2)}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right">
                          <div className="text-sm text-gray-900">${(invoice.total_paid || 0).toFixed(2)}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right">
                          <div className="text-sm text-gray-900">${(invoice.balance || 0).toFixed(2)}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium relative">
                          <MoreHorizontal 
                            size={20} 
                            className="ml-auto cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleDropdown(invoice.id);
                            }}
                          />
                          {activeDropdown === invoice.id && (
                            <div
                              className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50 overflow-visible"
                              ref={dropdownRef}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <div 
                                className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center cursor-pointer"
                                onClick={(e) => handleDownloadInvoice(e, invoice.id)}
                              >
                                <Download size={16} className="mr-2" />
                                Download PDF
                              </div>
                              {invoice.pdf_url && (
                                <a
                                  href={invoice.pdf_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                                >
                                  <Eye size={16} className="mr-2" />
                                  View PDF
                                </a>
                              )}
                              {invoice.status === 'Draft' && (
                                <div 
                                  className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center cursor-pointer"
                                  onClick={(e) => handleSendInvoice(e, invoice)}
                                >
                                  <Mail size={16} className="mr-2" />
                                  Send Invoice
                                </div>
                              )}
                                {(invoice.status === 'Sent' || invoice.status === 'Partial') && (
                                <>
                                  <div 
                                    className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center cursor-pointer"
                                    onClick={(e) => handleResendEmail(e, invoice.id)}
                                  >
                                    <Mail size={16} className="mr-2" />
                                    Resend Email
                                  </div>
                                  {invoice.status !== 'Paid' && (
                                    <div 
                                      className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center cursor-pointer"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedInvoice(invoice);
                                        setIsPaymentModalOpen(true);
                                      }}
                                    >
                                      <DollarSign size={16} className="mr-2" />
                                      Record Payment
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </main>
        </div>
      </div>

      <Modal
        isOpen={isCreateInvoiceModalOpen}
        onClose={() => setIsCreateInvoiceModalOpen(false)}
        title="Create Invoice"
      >
        <div className="p-6">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Client
              </label>
              <select
                value={selectedClient}
                onChange={(e) => setSelectedClient(e.target.value)}
                className="fetch-select mt-1"
              >
                <option value="">Select Client</option>
                {clients.map(client => (
                  <option key={client.client_id} value={client.client_id}>{client.name}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Date Range
              </label>
              <div className="grid grid-cols-2 gap-4">
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="fetch-input mt-1"
                />
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  className="fetch-input mt-1"
                />
              </div>
            </div>
          </div>

          <div className="mt-6 flex justify-end space-x-4">
            <button
              onClick={() => setIsCreateInvoiceModalOpen(false)}
              className="px-4 py-2 border border-gray-300 rounded-md"
            >
              Cancel
            </button>
            <button
              onClick={handleCreateInvoice}
              className="px-4 py-2 bg-green-600 text-white rounded-md"
              disabled={!selectedClient || !startDate || !endDate}
            >
              Create Invoice
            </button>
          </div>
        </div>
      </Modal>
      {selectedInvoice && (
        <Modal
          isOpen={isPaymentModalOpen}
          onClose={() => {
            setIsPaymentModalOpen(false);
            setSelectedInvoice(null);
          }}
          title="Record Payment"
        >
          <InvoicePayments
            invoice={selectedInvoice}
            onPaymentAdded={handlePaymentAdded}
          />
        </Modal>
      )}
      {selectedInvoice && (
        <Modal
          isOpen={showInvoiceDetail}
          onClose={() => {
            setShowInvoiceDetail(false);
            setSelectedInvoice(null);
          }}
          title={`Invoice ${selectedInvoice.invoice_number}`}
          size="lg"  // Make modal larger
        >
          <InvoiceDetail 
            invoice={selectedInvoice}
            onPaymentAdded={() => {
              fetchData();
              setShowInvoiceDetail(false);
              setSelectedInvoice(null);
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default SystemAdminInvoices;