import React from 'react';

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, type = 'timesheet' }) => {
  if (!isOpen) return null;
  
  const getModalContent = () => {
    switch (type) {
      case 'workHistory':
        return {
          title: "Delete Work History Entry?",
          buttonText: "Delete Entry",
          warningText: "If you delete this work history entry, any associated reviews will also be deleted. Are you sure you want to proceed?"
        };
      case 'timesheet':
      default:
        return {
          title: "Delete time entries from this timesheet?",
          buttonText: "Delete time entries",
          warningText: ""
        };
    }
  };

  const { title, buttonText, warningText } = getModalContent();
  
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-md w-80">
        <h2 className="text-lg font-semibold mb-4">{title}</h2>
        {warningText && <p className="text-gray-600 mb-4 text-sm">{warningText}</p>}
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 border rounded text-gray-700"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-600 text-white rounded"
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;