import React from 'react';
import { CheckCircle, Clock, StarIcon } from 'lucide-react';
import PropTypes from 'prop-types';

const WelcomeStep = ({ 
  consultantName, 
  onNext,
  existingVerifications 
}) => {
  const verifiedCount = existingVerifications?.length || 0;

  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold mb-4">
        Verify {consultantName}'s Work History
      </h2>

      {verifiedCount > 0 ? (
        <div className="bg-blue-50 p-4 rounded-lg mb-6">
          <p className="text-blue-800">
            These details have already been verified by {verifiedCount} {verifiedCount === 1 ? 'person' : 'people'}.
            Your additional verification will help build trust in this work history.
          </p>
        </div>
      ) : (
        <div className="bg-blue-50 p-4 rounded-lg mb-6">
          <p className="text-blue-800">
            You will be the first to verify these details. Thank you for helping maintain accuracy
            in professional work histories.
          </p>
        </div>
      )}

      <div className="prose max-w-none mb-8">
        <p className="text-gray-600">
          Your verification helps maintain high standards in healthcare IT consulting. 
          All verifications and reviews are shared with potential employers to help them 
          make informed decisions.
        </p>
      </div>

      {/* Process Overview */}
      <div className="bg-gray-50 rounded-lg p-6 mb-8">
        <h3 className="text-lg font-medium mb-4">What to Expect</h3>
        <div className="space-y-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <CheckCircle className="h-6 w-6 text-blue-500" />
            </div>
            <div className="ml-3">
              <p className="text-gray-900 font-medium">Review & Verify</p>
              <p className="text-gray-500">
                Review employment details and confirm their accuracy
              </p>
            </div>
          </div>
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <Clock className="h-6 w-6 text-blue-500" />
            </div>
            <div className="ml-3">
              <p className="text-gray-900 font-medium">Quick Process</p>
              <p className="text-gray-500">
                The entire process takes just a few minutes
              </p>
            </div>
          </div>
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <StarIcon className="h-6 w-6 text-blue-500" />
            </div>
            <div className="ml-3">
              <p className="text-gray-900 font-medium">Optional Review</p>
              <p className="text-gray-500">
                Optionally leave a rating and review about their work
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Important Notes */}
      <div className="bg-blue-50 rounded-lg p-4 mb-8">
        <h4 className="text-blue-800 font-medium mb-2">Important Notes:</h4>
        <ul className="text-blue-700 text-sm space-y-2">
          <li>• Your verification helps ensure accuracy of work history</li>
          <li>• Reviews are only shared with potential employers</li>
          <li>• You can optionally share your contact info for reference checks</li>
        </ul>
      </div>

      {/* Action Button */}
      <div className="flex justify-end">
        <button
          onClick={onNext}
          className="px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
        >
          Begin Verification
        </button>
      </div>
    </div>
  );
};

WelcomeStep.propTypes = {
  consultantName: PropTypes.string.isRequired,
  onNext: PropTypes.func.isRequired,
  existingVerifications: PropTypes.array
};

export default WelcomeStep;