// src/components/WorkHistoryManagement.js
import { getAnalytics, logEvent } from "firebase/analytics";
import { Menu, Pencil, SquarePlus, Trash2, RefreshCw, Star } from 'lucide-react';
import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ConsultantHamburger from './ConsultantHamburger';
import NotificationBell from './NotificationBell';
import AddEditJobHistory from './ui/AddEditJobHistory';
import DeleteConfirmationModal from './ui/DeleteConfirmationModal';
import DisputeDetailsModal from './ui/DisputeDetailsModal';
import WorkHistoryWelcomeModal from './ui/WorkHistoryWelcomeModal';
import StatusBanner from './ui/StatusBanner';
import { toast } from 'react-toastify';
import ReviewRequestModal from './ui/ReviewRequestModal';

const WorkHistoryManagement = () => {
  const [jobHistory, setJobHistory] = useState([]);
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: 'start_date', direction: 'desc' });
  const [statusMessage, setStatusMessage] = useState(null);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const location = useLocation();
  
  // Modal states
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDisputeModalOpen, setIsDisputeModalOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isReviewRequestModalOpen, setIsReviewRequestModalOpen] = useState(false);

  // First, define checkMobile before the useEffect
  const checkMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  // Then update your useEffect
  useEffect(() => {
    // Analytics tracking
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Work History Management',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
    
    // Initial data fetching and checks
    fetchUser();
    checkMobile();
    
    // Welcome modal logic
    const hasSeenWelcome = localStorage.getItem('hasSeenWorkHistoryWelcome');
    if (!hasSeenWelcome || location.state?.fromOnboarding) {
      setShowWelcomeModal(true);
      localStorage.setItem('hasSeenWorkHistoryWelcome', 'true');
    }

    // Add window resize listener
    window.addEventListener('resize', checkMobile);
    
    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, [location.state?.fromOnboarding]); // Keep the same dependency array

  const fetchUser = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error.message);
    }
  };
  const fetchJobDetails = useCallback(async () => {
    try {
      setLoading(true);
      
      // First fetch job history with reviews
      const { data: jobData, error: jobError } = await supabase
        .from('job_history')
        .select(`
          *,
          consultant_id,
          reviews (
            id,
            rating,
            review_text,
            review_type,
            relationship_details,
            share_contact_info,
            created_at,
            verification_status,
            verified_at,
            verification_notes,
            reviewer_id
          )
        `)
        .eq('consultant_id', user.user_id)
        .order('start_date', { ascending: false });

      if (jobError) throw jobError;

      // For each job, fetch reviewer details from reviewer_details view
      const jobsWithReviewerDetails = await Promise.all(
        jobData.map(async (job) => {
          if (job.reviews && job.reviews.length > 0) {
            const reviewerIds = job.reviews.map(r => r.reviewer_id);
            
            const { data: reviewerData, error: reviewerError } = await supabase
              .from('reviewer_details')
              .select('*')
              .in('reviewer_id', reviewerIds);

            if (reviewerError) throw reviewerError;

            // Map reviewer details to reviews
            const reviewsWithDetails = job.reviews.map(review => ({
              ...review,
              reviewer: reviewerData.find(r => r.reviewer_id === review.reviewer_id)
            }));

            return {
              ...job,
              reviews: reviewsWithDetails
            };
          }
          return job;
        })
      );

      setJobHistory(jobsWithReviewerDetails);
  
    } catch (error) {
      console.error('Error fetching job history:', error);
      toast.error('Failed to load work history');
    } finally {
      setLoading(false);
    }
  }, [user?.user_id]);

  useEffect(() => {
    if (user) {
      fetchJobDetails();
    }
  }, [user, fetchJobDetails, sortConfig]);

  const handleSort = (key) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const formatDate = (date) => {
    if (!date) return 'Present';
    return new Date(date).toLocaleDateString();
  };


  const handleVerifyNow = (job) => {
    setSelectedJob(job);
    setIsAddModalOpen(true);
  };

  const handleRequestReview = (job) => {
    setSelectedJob(job);
    setIsReviewRequestModalOpen(true);
  };
  
  const handleResendVerification = async (job) => {
    try {
      const pendingReviews = job.reviews?.filter(r => r.verification_status === 'pending') || [];
      
      for (const review of pendingReviews) {
        const { error } = await supabase.functions.invoke('send-review-request', {
          body: {
            reviewerEmail: review.reviewer.work_email,
            reviewerPhone: review.reviewer.phone,
            reviewType: review.review_type,
            type: job.type,  // 'job_history' or 'engagement'
            consultantId: job.consultant_id,
            jobHistoryId: job.type === 'job_history' ? job.id : null,
            engagementId: job.type === 'engagement' ? job.id : null,
            senderName: user.name
          }
        });
  
        if (error) throw error;
      }
  
      toast.success('Verification requests resent successfully');
      
      const analytics = getAnalytics();
      logEvent(analytics, 'verification_request_resent', {
        job_id: job.id,
        type: job.type
      });
  
    } catch (error) {
      console.error('Error resending verification:', error);
      toast.error('Failed to resend verification requests');
    }
  };

  const getVerificationStatus = (job) => {
    // Check for disputed reviews first
    const disputedReviews = job.reviews?.filter(review => 
      review.verification_status === 'disputed'
    ) || [];

    if (disputedReviews.length > 0) {
      return (
        <button
          onClick={() => {
            setSelectedJob({
              ...job,
              disputed_reviews: disputedReviews // Pass disputed reviews to modal
            });
            setIsDisputeModalOpen(true);
          }}
          className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800 hover:bg-red-200"
        >
          Disputed ({disputedReviews.length})
        </button>
      );
    }

    const verifiedReviews = job.reviews?.filter(review => 
      review.verification_status === 'verified'
    ) || [];
    const verifiedCount = verifiedReviews.length;
    const pendingReviews = job.reviews?.filter(review => 
      review.verification_status === 'pending'
    ) || [];
  
    if (verifiedCount > 0) {
      return (
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-blue-600 text-white">
          Verified ({verifiedCount})
        </span>
      );
    }
  
    if (pendingReviews.length > 0) {
      return (
        <div className="flex items-center space-x-2">
          <span className="px-2 py-1 rounded-full text-yellow-600 bg-yellow-50 text-sm font-medium">
            Pending ({pendingReviews.length})
          </span>
          <button
            onClick={() => handleResendVerification(job)}
            className="text-gray-400 hover:text-gray-600"
            title="Resend verification request"
          >
            <RefreshCw size={16} />
          </button>
        </div>
      );
    }
  
    return (
      <button
        onClick={() => handleVerifyNow(job)}
        className="text-blue-600 hover:text-blue-800 text-sm font-medium"
      >
        Verify Now
      </button>
    );
  };

  const VerifiedJobBadge = ({ verificationCount }) => (
    <div className="group relative inline-flex items-center">
      <span className="text-green-600 text-sm">✓ Verified ({verificationCount})</span>
      <div className="absolute bottom-full mb-2 hidden group-hover:block w-48 bg-gray-800 text-white text-xs rounded p-2">
        Verified work history entries cannot be edited to maintain integrity of reviewer verifications.
      </div>
    </div>
  );

  const renderTable = () => (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th 
              onClick={() => handleSort('company_name')}
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
            >
              Company
            </th>
            <th 
              onClick={() => handleSort('job_title')}
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
            >
              Title
            </th>
            <th 
              onClick={() => handleSort('start_date')}
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
            >
              Duration
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Verification
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Reviews
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white text-left divide-y divide-gray-200">
          {jobHistory.map((job) => (
            <tr key={job.id} className="relative hover:bg-gray-50 group">
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {job.company_name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {job.job_title}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {formatDate(job.start_date)} - {formatDate(job.end_date)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {getVerificationStatus(job)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {job.reviews?.length > 0 ? (
                  <button 
                    onClick={() => {
                      setSelectedJob(job);
                      setIsReviewRequestModalOpen(true);
                    }}
                    className="flex items-center space-x-2 text-blue-600 hover:text-blue-800"
                  >
                    <div className="flex">
                      {[...Array(Math.round(job.reviews[0].rating))].map((_, i) => (
                        <Star 
                          key={i}
                          size={16}
                          className="fill-yellow-400 text-yellow-400"
                        />
                      ))}
                    </div>
                    <span className="text-sm">({job.reviews.length})</span>
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setSelectedJob(job);
                      setIsAddModalOpen(true);
                    }}
                    className="text-blue-600 hover:text-blue-800 text-sm"
                  >
                    Request Review
                  </button>
                )}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                {/* Only show edit button if no verified reviews exist */}
                {!job.reviews?.some(review => review.verification_status === 'verified') && (
                  <button
                    onClick={() => {
                      setSelectedJob(job);
                      setIsAddModalOpen(true);
                    }}
                    className="text-indigo-600 hover:text-indigo-900 mr-4"
                  >
                    <Pencil size={16} />
                  </button>
                )}
                {/* Request another review */}
                {job.reviews?.some(review => review.verification_status === 'verified') && (
                  <button
                    onClick={() => {
                      setSelectedJob(job);
                      setIsReviewRequestModalOpen(true);
                    }}
                  className="text-indigo-600 hover:text-indigo-900 mr-4"
                >
                  <SquarePlus size={16} />
                </button>
                )}
                <button
                  onClick={() => {
                    setSelectedJob(job);
                    setIsDeleteModalOpen(true);
                  }}
                  className="text-red-600 hover:text-red-900"
                >
                  <Trash2 size={16} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const renderReviewsCards = () => (
    <div className="grid grid-cols-1 gap-4">
      {jobHistory.map((job) => {
        const verifiedReviews = job.reviews?.filter(review => 
          review.verification_status === 'verified'
        ) || [];
        const verifiedCount = verifiedReviews.length;
        const avgRating = job.reviews?.length > 0
          ? job.reviews.reduce((sum, r) => sum + r.rating, 0) / job.reviews.length
          : 0;
  
        return (
          <div 
            key={job.id} 
            className="bg-white shadow-md rounded-lg p-4 relative hover:shadow-lg transition-shadow"
          >
            <div className="mb-2">
              <h2 className="text-xl font-bold text-gray-900 text-left">
                {job.job_title}
              </h2>
            </div>
            
            <div className="text-sm text-gray-500 mb-2 text-left">
              <p className="mb-1">Company: {job.company_name}</p>
              <p className="mb-1">Duration: {formatDate(job.start_date)} - {formatDate(job.end_date)}</p>
              <p className="mb-1">Type: {job.job_type} {job.firm_name ? `(${job.firm_name})` : ''}</p>
            </div>
  
            <div className="flex justify-between items-center mb-3">
              {/* Verification Status */}
              <div>
                {verifiedCount > 0 ? (
                  <span className="px-2 py-1 rounded-full text-blue-600 bg-blue-50 text-sm font-medium">
                    Verified ({verifiedCount})
                  </span>
                ) : (
                  <span className="px-2 py-1 rounded-full text-gray-600 bg-gray-50 text-sm font-medium">
                    Unverified
                  </span>
                )}
              </div>
  
              {/* Rating Display */}
              {job.reviews?.length > 0 && (
                <div 
                  className="flex items-center space-x-1 cursor-pointer hover:opacity-80"
                  onClick={() => {
                    // Add click handler to show reviews
                    setSelectedJob(job);
                    setIsReviewRequestModalOpen(true);
                  }}
                >
                  <div className="flex">
                    {[...Array(5)].map((_, i) => (
                      <Star
                        key={i}
                        size={16}
                        className={i < Math.round(avgRating) 
                          ? "text-yellow-400 fill-yellow-400" 
                          : "text-gray-300"
                        }
                      />
                    ))}
                  </div>
                  <span className="text-sm text-gray-600">
                    ({job.reviews.length})
                  </span>
                </div>
              )}
            </div>
  
            <div className="flex justify-between items-center">
              {/* Request Review Button */}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedJob(job);
                  if (job.reviews?.length > 0) {
                    setIsReviewRequestModalOpen(true);
                  } else {
                    setIsAddModalOpen(true);
                  }
                }}
                className="text-blue-600 hover:text-blue-800 cursor-pointer text-sm font-medium"
              >
                {job.reviews?.length > 0 ? 'Request Another Review' : 'Request Review'}
              </button>
  
              {/* Action Buttons */}
              <div className="flex space-x-3">
                {/* Only show edit button if no verified reviews exist */}
                {!job.reviews?.some(review => review.verification_status === 'verified') && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedJob(job);
                      setIsAddModalOpen(true);
                    }}
                    className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                  >
                    <Pencil size={16} />
                  </button>
                )}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedJob(job);
                    setIsDeleteModalOpen(true);
                  }}
                  className="text-red-600 hover:text-red-900 cursor-pointer"
                >
                  <Trash2 size={16} />
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );

  return (
    <>
      <Helmet>
        <title>Work History Management | fetchConsultant</title>
        <meta 
          name="description" 
          content="Manage and verify your professional work history as an Epic consultant. Get employment verification from previous managers to enhance your profile." 
        />
        <meta name="keywords" content="work history, employment verification, Epic consultant, healthcare IT, professional experience" />
        <meta property="og:title" content="Work History Management | fetchConsultant" />
        <meta 
          property="og:description" 
          content="Manage and verify your professional work history as an Epic consultant. Get employment verification from previous managers to enhance your profile." 
        />
      </Helmet>

      {/* Main Layout */}
      <div className="min-h-screen bg-gray-100 flex">
        <ConsultantHamburger 
          user={user} 
          currentPage="work history" 
          isOpen={isMenuOpen} 
          onClose={() => setIsMenuOpen(false)} 
        />
        
        <div className="flex-1 flex flex-col md:ml-64">
          {/* Header */}
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">Work History</h1>
            </div>
            <NotificationBell />
          </header>

          {/* Main Content */}
          <main className="flex-grow container mx-auto px-4 py-8">
            <StatusBanner 
              status={statusMessage?.type}
              message={statusMessage?.message}
            />

            {/* Action Buttons */}
            <div className="mb-6 flex justify-between items-center">
              <button
                onClick={() => {
                  setSelectedJob(null);
                  setIsAddModalOpen(true);
                }}
                className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
              >
                Add Job
              </button>
            </div>

            {/* Job History Table/Cards */}
            {loading ? (
              <div className="text-center py-4">Loading...</div>
            ) : jobHistory.length === 0 ? (
              <div className="text-center py-4 text-gray-500">
                No work history entries yet
              </div>
            ) : isMobile ? (
              <div className="space-y-4">
                {renderReviewsCards()}
              </div>
            ) : (
              renderTable()
            )}
          </main>
        </div>
      </div>

{/* Modals */}

<AddEditJobHistory
  isOpen={isAddModalOpen}
  onClose={() => {
    setIsAddModalOpen(false);
    setSelectedJob(null);
  }}
  jobData={selectedJob}
  onSave={() => {
    fetchJobDetails();
    setIsAddModalOpen(false);
    setSelectedJob(null);
  }}
  onRequestReview={handleRequestReview}
/>

<DeleteConfirmationModal
  isOpen={isDeleteModalOpen}
  onClose={() => {
    setIsDeleteModalOpen(false);
    setSelectedJob(null);
  }}
  onConfirm={async () => {
    try {
      const { error } = await supabase
        .from('job_history')
        .delete()
        .eq('id', selectedJob.id);

      if (error) throw error;

      const analytics = getAnalytics();
      logEvent(analytics, 'work_history_deleted', {
        job_id: selectedJob.id
      });

      toast.success('Work history entry deleted');
      fetchJobDetails();
    } catch (error) {
      console.error('Error deleting entry:', error);
      toast.error('Failed to delete entry');
    } finally {
      setIsDeleteModalOpen(false);
      setSelectedJob(null);
    }
  }}
  type="workHistory"
/>

<WorkHistoryWelcomeModal
  isOpen={showWelcomeModal}
  onClose={() => {
    setShowWelcomeModal(false);
    const analytics = getAnalytics();
    logEvent(analytics, 'welcome_modal_closed', {
      page: 'work_history_management'
    });
  }}
/>

{selectedJob && (
  <>
    <ReviewRequestModal
      isOpen={isReviewRequestModalOpen}
      onClose={() => {
        setIsReviewRequestModalOpen(false);
        setSelectedJob(null);
      }}
      jobHistory={selectedJob}
    />
    
    <DisputeDetailsModal
      isOpen={isDisputeModalOpen}
      onClose={() => {
        setIsDisputeModalOpen(false);
        setSelectedJob(null);
      }}
      jobData={selectedJob}
      type="job_history"
    />
  </>
)}
    </>
  );
};

export default WorkHistoryManagement;