import React from 'react';
import Modal from './Modal';
import { Link } from 'react-router-dom';

const WorkHistoryWelcomeModal = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Welcome to Work History Management"
      size="lg"
    >
      <div className="p-6 space-y-6">
        <div className="space-y-4">
          <p className="text-gray-700">
            We've analyzed your resume and imported your work history. Now, take a moment to:
          </p>
          
          <div className="bg-blue-50 p-4 rounded-md">
            <h3 className="text-lg font-semibold text-blue-900 mb-2">
              Enhance Your Profile & Earn
            </h3>
            <ul className="list-disc pl-5 space-y-2 text-blue-800">
              <li>Review each position by clicking the pencil icon</li>
              <li>Add manager information for employment verification</li>
              <li>Get verified reviews from former managers</li>
              <li>Increase your chances of landing interviews</li>
            </ul>
          </div>

          <div className="bg-green-50 p-4 rounded-md">
            <h3 className="text-lg font-semibold text-green-900 mb-2">
              Bonus: Build Passive Income
            </h3>
            <p className="text-green-800 mb-3">
              When managers you add join fetchConsultant, you can earn:
            </p>
            <ul className="list-disc pl-5 space-y-2 text-green-800">
              <li>1% of revenue from their job postings</li>
              <li>0.5% from jobs posted by people they refer</li>
              <li>0.25% from third-tier connections</li>
            </ul>
          </div>

          <div className="mt-6">
            <Link 
              to="/blog/build-passive-income-through-your-healthcare-it-network"
              className="text-blue-600 hover:text-blue-800 font-medium"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more about building passive income through your network →
            </Link>
          </div>
        </div>

        <div className="flex justify-end space-x-3 mt-6 pt-6 border-t">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Got it, let's start
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default WorkHistoryWelcomeModal;