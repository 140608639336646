import { getAnalytics, logEvent } from "firebase/analytics";
import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import { supabase } from '../../supabaseClient';

const AddRowModal = ({ isOpen, onClose, onAddRow }) => {
  const [selectedEngagement, setSelectedEngagement] = useState('');
  const [selectedTask, setSelectedTask] = useState('');
  const [newTaskTitle, setNewTaskTitle] = useState('');
  const [isNewTaskBillable, setIsNewTaskBillable] = useState(true);
  const [activeEngagements, setActiveEngagements] = useState([]);
  const [existingTasks, setExistingTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isOpen) {
      fetchActiveEngagements();
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedEngagement) {
      fetchExistingTasks();
    }
  }, [selectedEngagement]);

  const fetchActiveEngagements = async () => {
    try {
      setIsLoading(true);
      const { data: { user } } = await supabase.auth.getUser();

      const { data, error } = await supabase
        .from('engagements')
        .select(`
          id,
          job:job_id (
            title,
            client:client_id (
              name
            )
          )
        `)
        .eq('consultant_id', user.id)
        .eq('status', 'Active');

      if (error) throw error;

      setActiveEngagements(data);
      if (data.length === 1) {
        setSelectedEngagement(data[0].id); // Auto-select if only one project
      }
    } catch (error) {
      console.error('Error fetching engagements:', error);
      setError('Failed to load engagements');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchExistingTasks = async () => {
    try {
      setIsLoading(true);
      const { data, error } = await supabase
        .from('timesheet_tasks')
        .select('*')
        .eq('engagement_id', selectedEngagement);

      if (error) throw error;
      setExistingTasks(data);
    } catch (error) {
      console.error('Error fetching tasks:', error);
      setError('Failed to load tasks');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const selectedEngagementData = activeEngagements.find(e => e.id === selectedEngagement);
  
      if (!selectedEngagementData) {
        throw new Error('Selected engagement data is undefined.');
      }
  
      let selectedTaskData;
  
      if (selectedTask === 'new') {
        if (!newTaskTitle.trim()) {
          throw new Error('New task title is required.');
        }
  
        // Create the new task inline
        const { data: { user } } = await supabase.auth.getUser();
  
        const { data, error } = await supabase
          .from('timesheet_tasks')
          .insert({
            engagement_id: selectedEngagement,
            title: newTaskTitle,
            is_billable: isNewTaskBillable,
            created_by: user.id, // Log the user who created the task
          })
          .select()
          .single();
  
        if (error) {
          throw new Error('Failed to create new task.');
        }
  
        // Set the newly created task as the selected task
        selectedTaskData = data;
  
        // Optionally add the new task to the existingTasks list for future use
        setExistingTasks((prev) => [...prev, data]);
        setNewTaskTitle('');
      } else {
        // Fetch the selected existing task data
        selectedTaskData = existingTasks.find(t => t.id === selectedTask);
  
        if (!selectedTaskData) {
          throw new Error('Selected task data is undefined.');
        }
      }
  
      // Prepare the row data to be added
      const rowData = {
        engagement: selectedEngagementData,
        task: selectedTaskData,
        projectTitle: `${selectedEngagementData.job.title} (${selectedEngagementData.job.client.name})`,
        taskTitle: selectedTaskData.title,
        is_billable: selectedTaskData.is_billable,
      };
  
      onAddRow(rowData); // Pass the row data to the parent component
      handleClose(); // Close the modal
    } catch (error) {
      console.error('Error adding row:', error);
      setError(error.message || 'Failed to add row');
    }
  };

  const handleClose = () => {
    setSelectedEngagement('');
    setSelectedTask('');
    setNewTaskTitle('');
    setIsNewTaskBillable(true);
    setError(null);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} title="Add Timesheet Entry" size="md">
      {error && <div className="mb-4 bg-red-50 p-4 text-sm text-red-700">{error}</div>}

      <div className="space-y-4">
        <div>
          <label className="fetch-label">Project</label>
          <select 
            className="fetch-select"
            value={selectedEngagement}
            onChange={(e) => setSelectedEngagement(e.target.value)}
            disabled={isLoading || activeEngagements.length === 1}
          >
            <option value="">Select a project</option>
            {activeEngagements.map(engagement => (
              <option key={engagement.id} value={engagement.id}>
                {engagement.job.title} ({engagement.job.client.name})
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="fetch-label">Task</label>
          <select
            className="fetch-select mb-4"
            value={selectedTask}
            onChange={(e) => setSelectedTask(e.target.value)}
            disabled={isLoading}
          >
            <option value="">Select a task</option>
            {existingTasks.map(task => (
              <option key={task.id} value={task.id}>
                {task.title} {task.is_billable ? '(Billable)' : '(Non-billable)'}
              </option>
            ))}
            <option value="new">+ Create New Task</option>
          </select>
        </div>

        {selectedTask === 'new' && (
          <div className="space-y-2 bg-gray-50 p-4">
            <label className="fetch-label">New Task Name</label>
            <input
              type="text"
              className="fetch-input"
              value={newTaskTitle}
              onChange={(e) => setNewTaskTitle(e.target.value)}
              placeholder="Enter task name"
              disabled={isLoading}
            />
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                id="billable"
                checked={isNewTaskBillable}
                onChange={(e) => setIsNewTaskBillable(e.target.checked)}
                className="fetch-checkbox"
                disabled={isLoading}
              />
              <label htmlFor="billable" className="text-sm text-gray-700">
                Billable Task
              </label>
            </div>
          </div>
        )}

        <div className="flex justify-end space-x-2">
          <button onClick={handleClose} className="fetch-button bg-gray-500 hover:bg-gray-600">
            Cancel
          </button>
          <button
            onClick={selectedTask === 'new' ? handleSubmit : handleSubmit}
            disabled={!selectedEngagement || !selectedTask}
            className="fetch-button"
          >
            {selectedTask === 'new' ? 'Create Task' : 'Add Row'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddRowModal;