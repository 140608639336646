import { getAnalytics, logEvent } from "firebase/analytics";
import { Info } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import { supabase } from '../../supabaseClient';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import PropTypes from 'prop-types';

const Tooltip = ({ content, children }) => (
  <div className="group relative inline-block">
    {children}
    <div className="absolute hidden group-hover:block bg-gray-800 text-white text-sm rounded p-2 z-10 w-64 -left-20 top-full mt-1">
      {content}
    </div>
  </div>
);

const tooltips = {
  jobTitle: "Your official job title during this employment period",
  companyName: "The name of the company or organization you worked for",
  startDate: "When you started this position",
  endDate: "When you left this position, or leave blank if current",
  jobType: "Whether you were an employee of this orgnization or a consultant",
  workType: "Full-time or part-time position",
  locationType: "Remote, hybrid, or onsite work arrangement",
  jobDescription: "Describe your responsibilities and achievements. Use bullet points for better readability."
};

const AddEditJobHistory = ({ isOpen, onClose, jobData, onSave, onRequestReview }) => {
  const [formData, setFormData] = useState({
    job_title: '',
    company_name: '',
    start_date: '',
    end_date: '',
    job_type: '',
    work_type: '',
    location_type: '',
    firm_name: '',
    job_description: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dateWarning, setDateWarning] = useState(null);

  useEffect(() => {
    if (jobData) {
      setFormData({
        job_title: jobData.job_title || '',
        company_name: jobData.company_name || '',
        start_date: jobData.start_date
          ? new Date(jobData.start_date).toISOString().split('T')[0]
          : '',
        end_date: jobData.end_date
          ? new Date(jobData.end_date).toISOString().split('T')[0]
          : '',
        job_type: jobData.job_type || '',
        work_type: jobData.work_type || '',
        location_type: jobData.location_type || '',
        firm_name: jobData.firm_name || '',
        job_description: jobData.job_description || ''
      });
    } else {
      setFormData({
        job_title: '',
        company_name: '',
        start_date: '',
        end_date: '',
        job_type: '',
        work_type: '',
        location_type: '',
        firm_name: '',
        job_description: ''
      });
    }
  }, [jobData, isOpen]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setError(null);

    if (name === 'start_date' || name === 'end_date') {
      validateDates(
        name === 'start_date' ? value : formData.start_date,
        name === 'end_date' ? value : formData.end_date
      );
    }
  };

  const validateDates = (start, end) => {
    if (start && end) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      
      if (endDate < startDate) {
        setDateWarning('End date cannot be before start date');
        return false;
      }
    }
    setDateWarning(null);
    return true;
  };

  const validateJobData = () => {
    if (!formData.job_title.trim()) {
      setError('Job title is required');
      return false;
    }
    if (!formData.company_name.trim()) {
      setError('Company name is required');
      return false;
    }
    if (!formData.start_date) {
      setError('Start date is required');
      return false;
    }
    if (!formData.work_type) {
      setError('Work type is required');
      return false;
    }
    if (!formData.location_type) {
      setError('Location type is required');
      return false;
    }
    if (formData.job_type === 'consultant' && !formData.firm_name.trim()) {
      setError('Firm name is required for roles where you were a consultant');
      return false;
    }
    return validateDates(formData.start_date, formData.end_date);
  };

  const handleNext = async () => {
    if (validateJobData()) {
      try {
        // Save the job data first
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) throw new Error('No authenticated user found');

        const jobEntry = {
          ...formData,
          consultant_id: user.id,
          end_date: formData.end_date || null
        };

        let response;
        if (jobData) {
          // Updating existing job
          response = await supabase
            .from('job_history')
            .update(jobEntry)
            .eq('id', jobData.id)
            .select()
            .single();
        } else {
          // Creating new job
          response = await supabase
            .from('job_history')
            .insert(jobEntry)
            .select()
            .single();
        }

        if (response.error) throw response.error;

        // Log the event
        const analytics = getAnalytics();
        logEvent(
          analytics,
          jobData ? 'job_history_updated' : 'job_history_added',
          {
            job_title: formData.job_title,
            company_name: formData.company_name
          }
        );

        // Close current modal and trigger review request
        onSave();
        onRequestReview(response.data);
      } catch (error) {
        console.error('Error saving job history:', error);
        setError(error.message);
      }
    }
  };

  const handleSkip = async () => {
    if (validateJobData()) {
      setIsLoading(true);
      setError(null);
  
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) throw new Error('No authenticated user found');
  
        const jobEntry = {
          ...formData,
          consultant_id: user.id,
          end_date: formData.end_date || null
        };
  
        let response;
        if (jobData) {
          response = await supabase
            .from('job_history')
            .update(jobEntry)
            .eq('id', jobData.id)
            .select();
        } else {
          response = await supabase
            .from('job_history')
            .insert(jobEntry)
            .select();
        }
  
        if (response.error) throw response.error;
  
        const analytics = getAnalytics();
        logEvent(
          analytics,
          jobData ? 'job_history_updated' : 'job_history_added',
          {
            job_title: formData.job_title,
            company_name: formData.company_name
          }
        );
  
        onSave();
        onClose();
      } catch (error) {
        console.error('Error saving job history:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={jobData ? `Edit ${jobData.job_title}` : 'Add Job History'}
        size="lg"
      >
        <div className="p-6">
          <div className="mb-6 bg-blue-50 p-4 rounded-md">
            <h4 className="text-blue-800 font-medium mb-2">Why Add Work History?</h4>
            <p className="text-blue-700 text-sm">
              Verified work history significantly improves your chances of landing contracts. Ensure your details are accurate and then add reviewer information to request verification and a review. 
              Plus, if your reviewer is new to fetchConsultant, you'll receive a referral bonus! Learn more <a href="https://fetchconsultant.com/blog/build-passive-income-through-your-healthcare-it-network" target="_blank" rel="noopener noreferrer">here</a>.
            </p>
          </div>
          {error && (
            <div className="mb-4 bg-red-50 p-4 rounded-md text-sm text-red-700">
              {error}
            </div>
          )}

          <form className="space-y-4">
            <div>
              <label className="fetch-label flex items-center">
                Job Title<span className="text-red-500">*</span>
                <Tooltip content={tooltips.jobTitle}>
                  <Info className="ml-2 h-4 w-4 text-gray-400" />
                </Tooltip>
              </label>
              <input
                type="text"
                name="job_title"
                required
                className="fetch-input"
                value={formData.job_title}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label className="fetch-label flex items-center">
                Company Name<span className="text-red-500">*</span>
                <Tooltip content={tooltips.companyName}>
                  <Info className="ml-2 h-4 w-4 text-gray-400" />
                </Tooltip>
              </label>
              <input
                type="text"
                name="company_name"
                required
                className="fetch-input"
                value={formData.company_name}
                onChange={handleInputChange}
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="fetch-label flex items-center">
                  Start Date<span className="text-red-500">*</span>
                  <Tooltip content={tooltips.startDate}>
                    <Info className="ml-2 h-4 w-4 text-gray-400" />
                  </Tooltip>
                </label>
                <input
                  type="date"
                  name="start_date"
                  required
                  className="fetch-input"
                  value={formData.start_date}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="fetch-label flex items-center">
                  End Date
                  <Tooltip content={tooltips.endDate}>
                    <Info className="ml-2 h-4 w-4 text-gray-400" />
                  </Tooltip>
                </label>
                <input
                  type="date"
                  name="end_date"
                  className="fetch-input"
                  value={formData.end_date || ''}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="grid grid-cols-3 gap-4">
              <div>
                <label className="fetch-label flex items-center">
                  Job Type<span className="text-red-500">*</span>
                  <Tooltip content={tooltips.jobType}>
                    <Info className="ml-2 h-4 w-4 text-gray-400" />
                  </Tooltip>
                </label>
                <select
                  name="job_type"
                  className="fetch-select"
                  value={formData.job_type}
                  onChange={handleInputChange}
                >
                  <option value="">Select Type</option>
                  <option value="employee">Employee</option>
                  <option value="consultant">Consultant</option>
                </select>
              </div>

              <div>
                <label className="fetch-label flex items-center">
                  Work Type<span className="text-red-500">*</span>
                  <Tooltip content={tooltips.workType}>
                    <Info className="ml-2 h-4 w-4 text-gray-400" />
                  </Tooltip>
                </label>
                <select
                  name="work_type"
                  className="fetch-select"
                  value={formData.work_type}
                  onChange={handleInputChange}
                >
                  <option value="">Select Type</option>
                  <option value="full-time">Full Time</option>
                  <option value="part-time">Part Time</option>
                </select>
              </div>

              <div>
                <label className="fetch-label flex items-center">
                  Location Type<span className="text-red-500">*</span>
                  <Tooltip content={tooltips.locationType}>
                    <Info className="ml-2 h-4 w-4 text-gray-400" />
                  </Tooltip>
                </label>
                <select
                  name="location_type"
                  className="fetch-select"
                  value={formData.location_type}
                  onChange={handleInputChange}
                >
                  <option value="">Select Type</option>
                  <option value="remote">Remote</option>
                  <option value="hybrid">Hybrid</option>
                  <option value="onsite">Onsite</option>
                </select>
              </div>
            </div>

            {formData.job_type === 'consultant' && (
              <div>
                <label className="fetch-label">Firm Name<span className="text-red-500">*</span></label>
                <input
                  type="text"
                  name="firm_name"
                  value={formData.firm_name}
                  onChange={handleInputChange}
                  className="fetch-input"
                />
              </div>
            )}

            <div>
              <label className="fetch-label flex items-center">
                Job Description
                <Tooltip content={tooltips.jobDescription}>
                  <Info className="ml-2 h-4 w-4 text-gray-400" />
                </Tooltip>
              </label>
              <ReactQuill
                value={formData.job_description}
                onChange={(content) => setFormData(prev => ({
                  ...prev,
                  job_description: content
                }))}
                theme="snow"
                className="h-32 mb-12"
              />
            </div>

            {dateWarning && (
              <div className="text-sm text-red-500 mt-2">{dateWarning}</div>
            )}

            <div className="flex justify-end space-x-3 mt-6">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 border rounded text-gray-600"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleSkip}
                className="px-4 py-2 border rounded text-gray-600"
              >
                Save Without Reviewer Info
              </button>
              <button
                type="button"
                onClick={handleNext}
                disabled={isLoading}
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              >
                Next: Add Reviewer Info
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

AddEditJobHistory.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  jobData: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onRequestReview: PropTypes.func.isRequired // Add this new prop type
};

export default AddEditJobHistory;