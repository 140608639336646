import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronLeft, Menu, MoreHorizontal } from 'lucide-react';
import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import PullToRefresh from 'react-pull-to-refresh';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ClientHamburger from './ClientHamburger';
import NotificationBell from "./NotificationBell";
import Spinner from './Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ClientEngagementManagement = () => {
  const [engagements, setEngagements] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'start_date', direction: 'desc' });
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [showPOModal, setShowPOModal] = useState(false);
  const [currentEngagementForPO, setCurrentEngagementForPO] = useState(null);
  const [newPO, setNewPO] = useState('');

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [dropdownRef]);

  useEffect(() => {
    const analytics = getAnalytics();
    
    // Log page view
    logEvent(analytics, 'page_view', {
      page_title: 'Client Engagements',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  
    // Check for success parameter
    const searchParams = new URLSearchParams(location.search);
    const success = searchParams.get('success');
  
    if (success === 'sow_signed') {
      toast.success('Statement of Work signed successfully!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
  
      // Log signing success event
      logEvent(analytics, 'sow_signed', {
        location: 'client_engagements'
      });
  
      // Clean up URL
      window.history.replaceState({}, '', '/app/client-engagements');
    }
  
    fetchUserAndEngagements();
  }, [sortConfig, location.search]); // Add location.search to dependency array

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const [year, month, day] = dateString.split('-');
    return `${month}-${day}-${year}`;
  };
  
  const fetchUserAndEngagements = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('*, employer')
        .eq('user_id', user.id)
        .single();

      if (userError) throw userError;
      setUser(userData);

      await fetchEngagements(userData.employer);
    } catch (error) {
      console.error('Error fetching user:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEngagements = async (employerId) => {
    try {
      const { data, error } = await supabase
        .from('engagements')
        .select(`
          id,
          start_date,
          end_date,
          contract_rate,
          status,
          consultant_id,
          client_id,
          sow_document_id,
          job_title,
          job_description,
          user_job_id,
          purchase_order,
          decision_maker:decision_maker_id(
            name,
            work_email,
            job_title
          ),
          job:jobs(
            job_id,
            created_by,
            owner:users!jobs_created_by_fkey(
              name,
              login_email,
              work_email
            )
          ),
          consultant:users!consultant_id(name)
        `)
        .eq('client_id', employerId)
        .in('status', ['Active', 'Contract Accepted', 'Offered', 'Executed'])
        .order(sortConfig.key, { ascending: sortConfig.direction === 'asc' });

      if (error) throw error;
      setEngagements(data);
    } catch (error) {
      console.error('Error fetching engagements:', error.message);
    }
  };

  const toggleDropdown = (engagementId) => {
    setActiveDropdown(activeDropdown === engagementId ? null : engagementId);
  };

  const handleSort = (key) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const handleExtendContract = (engagement) => {
    const analytics = getAnalytics();
    logEvent(analytics, 'extend_contract_initiated', {
      engagement_id: engagement.id,
      consultant_id: engagement.consultant_id,
      job_title: engagement.job?.title
    });
  
    navigate('/app/client-offer-contract/' + engagement.user_job_id, {
      state: {
        isExtension: true,
        existingEngagement: engagement
      }
    });
  };

  const handleViewDocument = async (engagement) => {
    const analytics = getAnalytics();
    
    const docType = engagement.sow_document_id ? 'sow' : 'ica';
    const documentId = docType === 'sow' ? engagement.sow_document_id : engagement.ica_document_id;
    
    // Track click event
    logEvent(analytics, 'document_view_clicked', {
      document_type: docType,
      engagement_id: engagement.id
    });
  
    if (!documentId) {
      alert('No document available for viewing');
      return;
    }
  
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.access_token) {
        throw new Error('No valid session found');
      }
  
      // Detect Safari
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  
      if (isSafari) {
        // For Safari, create a simple URL with the document ID
        const url = `${process.env.REACT_APP_SUPABASE_URL}/functions/v1/getDocumentViewingLink?documentId=${documentId}`;
        
        // Make the request in the same window for Safari
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${session.access_token}`
          }
        });
  
        if (!response.ok) {
          throw new Error('Failed to get document');
        }
  
        // Convert response to blob and create URL
        const blob = await response.blob();
        const blobUrl = window.URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
  
        // Clean up
        setTimeout(() => {
          window.URL.revokeObjectURL(blobUrl);
        }, 100);
      } else {
        // For other browsers, use POST method
        const response = await fetch(
          `${process.env.REACT_APP_SUPABASE_URL}/functions/v1/getDocumentViewingLink`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${session.access_token}`
            },
            body: JSON.stringify({
              documentId: documentId
            }),
          }
        );
  
        if (!response.ok) {
          throw new Error('Failed to get document');
        }
  
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
        
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
        }, 100);
      }
  
      // Track successful document open
      logEvent(analytics, 'document_view_opened', {
        document_type: docType,
        engagement_id: engagement.id,
        browser: isSafari ? 'safari' : 'other'
      });
  
    } catch (error) {
      console.error('Error viewing document:', error);
      
      // Track failed attempt
      logEvent(analytics, 'document_view_failed', {
        document_type: docType,
        engagement_id: engagement.id,
        error: error.message,
        browser: /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ? 'safari' : 'other'
      });
      
      alert('Failed to open document. Please try again.');
    }
  };

  const renderEngagementsTable = () => (
    <div className="bg-white shadow-md rounded-lg">
      <table className="min-w-full table-auto">
        <thead className="bg-gray-50">
          <tr>
          <th onClick={() => handleSort('consultant.name')} className="px-6 py-3 text-left text-sm font-medium text-gray-700 cursor-pointer">
              Consultant {sortConfig.key === 'consultant.name' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('job.title')} className="px-6 py-3 text-left text-sm font-medium text-gray-700 cursor-pointer">
              Job Title {sortConfig.key === 'job.title' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('start_date')} className="px-6 py-3 text-left text-sm font-medium text-gray-700 cursor-pointer">
              Start Date {sortConfig.key === 'start_date' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('end_date')} className="px-6 py-3 text-left text-sm font-medium text-gray-700 cursor-pointer">
              End Date {sortConfig.key === 'end_date' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">Contract Rate</th>
            <th onClick={() => handleSort('status')} className="px-6 py-3 text-left text-sm font-medium text-gray-700 cursor-pointer">
              Status {sortConfig.key === 'status' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('job.owner.name')} className="px-6 py-3 text-left text-sm font-medium text-gray-700 cursor-pointer">
              Owner {sortConfig.key === 'job.owner.name' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
            </th>
            <th className="px-6 py-3"></th>
            <th className="px-6 py-3"></th>
          </tr>
        </thead>
        <tbody>
          {engagements.map((engagement) => (
            <tr
              key={engagement.id}
              className="cursor-pointer hover:bg-gray-50"
            >
              <td className="px-6 py-4 text-sm text-gray-700 text-left">
                {engagement.consultant?.name}
              </td>
              <td className="px-6 py-4 text-sm text-gray-700 text-left">
                {engagement.job_title || 'No Title'}
              </td>
              <td className="px-6 py-4 text-sm text-gray-700 text-left">
                {engagement.start_date ? formatDate(engagement.start_date) : ''}
              </td>
              <td className="px-6 py-4 text-sm text-gray-700 text-left">
                {engagement.end_date ? formatDate(engagement.end_date) : ''}
              </td>
              <td className="px-6 py-4 text-sm text-gray-700 text-left">${engagement.contract_rate}</td>
              <td className="px-6 py-4 text-sm text-gray-700 text-left">{engagement.status}</td>
              <td className="px-6 py-4 text-sm text-gray-700 text-left">
                {engagement.job?.owner?.name || 'Unknown'}
              </td>
              <td className="px-6 py-4 text-sm text-gray-700 text-left">
                <Link
                  to={`/app/client-onboarding-progress/${engagement.id}`}
                  className="fetch-button"
                  onClick={(e) => e.stopPropagation()}
                >
                  Onboarding
                </Link>
              </td>
              <td className="px-6 py-4 text-right relative">
                <MoreHorizontal
                  size={24}
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleDropdown(engagement.id);
                  }}
                />
                {activeDropdown === engagement.id && (
                  <div ref={dropdownRef} className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg text-left z-10">
                    <div 
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleViewDocument(engagement);
                      }}
                    >
                      📄 View Contract
                    </div>
                    <div 
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleExtendContract(engagement);
                      }}
                    >
                      🔄 Extend Contract
                    </div>
                    <div 
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        setCurrentEngagementForPO(engagement);
                        setNewPO(engagement.purchase_order || '');
                        setShowPOModal(true);
                      }}
                    >
                    💲 {engagement.purchase_order ? 'Change Purchase Order' : 'Set Purchase Order'}
                    </div>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const renderEngagementsCards = () => (
    <div className="grid grid-cols-1 gap-4">
      {engagements.map((engagement) => (
        <div 
          key={engagement.id} 
          className="bg-white shadow-md rounded-lg p-4 relative hover:shadow-lg transition-shadow"
        >
          <div className="mb-2">
            <h2 className="text-xl font-bold text-gray-900">
              {engagement.job_title || 'No Title'}
            </h2>
          </div>
          <div className="text-sm text-gray-500 mb-2">
            <p>Consultant: {engagement.consultant?.name}</p>
            <p>Start Date: {engagement.start_date ? formatDate(engagement.start_date) : ''}</p>
            <p>End Date: {engagement.end_date ? formatDate(engagement.end_date) : ''}</p>
            <p>Contract Rate: ${engagement.contract_rate}</p>
            <p>Status: {engagement.status}</p>
            <p>Owner: {engagement.job?.owner?.name || 'Unknown'}</p>
          </div>
          <div className="mt-4">
            <Link
              to={`/app/client-onboarding-progress/${engagement.id}`}
              className="fetch-button"
              onClick={(e) => e.stopPropagation()}
            >
              View Onboarding Progress
            </Link>
          </div>
          <div className="absolute top-4 right-4">
            <MoreHorizontal 
              size={24} 
              className="cursor-pointer"
              onClick={() => toggleDropdown(engagement.id)}
            />
              {activeDropdown === engagement.id && (
                <div ref={dropdownRef} className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg text-left z-10">
                  <div 
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleViewDocument(engagement);
                    }}
                  >
                    📄 View Contract
                  </div>
                  <div 
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleExtendContract(engagement);
                    }}
                  >
                    🔄 Extend Contract
                  </div>
                  <div 
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setCurrentEngagementForPO(engagement);
                      setNewPO(engagement.purchase_order || '');
                      setShowPOModal(true);
                    }}
                  >
                    💲 {engagement.purchase_order ? 'Change Purchase Order' : 'Set Purchase Order'}
                  </div>
                </div>
              )}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>Client Engagements | fetchConsultant</title>
        <meta name="description" content="View and manage your organization's active engagements on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ClientHamburger user={user} currentPage="clientEngagements" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
          <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <ChevronLeft className="h-6 w-6 text-brandPrimary cursor-pointer" onClick={() => navigate(-1)} />
              <h1 className="text-xl font-bold ml-4">Organization Engagements</h1>
            </div>
            <div className="flex items-center">
              <NotificationBell />
            </div>
          </header>
          <main className="p-4">
            <PullToRefresh
              onRefresh={fetchUserAndEngagements}
              className="pull-to-refresh"
              pullingContent={<span>Pull down to refresh...</span>}
              refreshingContent={<div className="flex items-center justify-center"><Spinner /> Refreshing...</div>}
            >
              {isLoading ? (
                <div>Loading engagements...</div>
              ) : engagements.length === 0 ? (
                <p>No active engagements found.</p>
              ) : (
                isMobile ? renderEngagementsCards() : renderEngagementsTable()
              )}
            </PullToRefresh>
            {showPOModal && currentEngagementForPO && (
              <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
                <div className="bg-white p-6 rounded-md shadow-md">
                  <h2 className="text-xl font-semibold mb-4">
                    {currentEngagementForPO.purchase_order ? 'Change Purchase Order' : 'Set Purchase Order'}
                  </h2>

                  {currentEngagementForPO.purchase_order && (
                    <p className="mb-2 text-gray-700">
                      Current Purchase Order: {currentEngagementForPO.purchase_order}
                    </p>
                  )}

                  <input 
                    type="text"
                    value={newPO}
                    onChange={(e) => setNewPO(e.target.value)}
                    className="fetch-input w-full mb-4"
                    placeholder="Enter new purchase order..."
                  />
                  <div className="flex justify-end space-x-2">
                    <button
                      onClick={() => setShowPOModal(false)}
                      className="bg-gray-300 hover:bg-gray-400 text-gray-900 px-3 py-2 rounded-md"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={async () => {
                        const { error } = await supabase
                          .from('engagements')
                          .update({ purchase_order: newPO })
                          .eq('id', currentEngagementForPO.id);

                        if (error) {
                          alert('Error updating purchase order');
                        } else {
                          fetchEngagements(user.employer);
                          setShowPOModal(false);
                        }
                      }}
                      className="bg-blue-600 hover:bg-blue-500 text-white px-3 py-2 rounded-md"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            )}
          </main>
        </div>
      </div>
    </>
  );
};

export default ClientEngagementManagement;