import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
  <footer className="bg-indigo-700 text-white py-8 text-left">
    <div className="container mx-auto px-6">
      <div className="flex flex-wrap justify-between">
        <div className="w-full md:w-1/4 mb-6 md:mb-0">
          <h3 className="text-lg font-semibold mb-2">fetchConsultant</h3>
          <p className="text-sm">Revolutionizing Epic consulting connections.</p>
        </div>
        <div className="w-full md:w-1/4 mb-6 md:mb-0">
          <h3 className="text-lg font-semibold mb-2">Quick Links</h3>
          <ul className="text-sm">
            <li><Link to="/consultants" className="hover:text-indigo-400">For Consultants</Link></li>
            <li><Link to="/clients" className="hover:text-indigo-400">For Clients</Link></li>
            <li><Link to="/blog" className="hover:text-indigo-400">Blog</Link></li>
          </ul>
        </div>
        <div className="w-full md:w-1/4 mb-6 md:mb-0">
          <h3 className="text-lg font-semibold mb-2">Contact</h3>
          <p className="text-sm">Email: info@fetchconsultant.com</p>
        </div>
        <div className="w-full md:w-1/4">
          <h3 className="text-lg font-semibold mb-2">Follow Us</h3>
          <div className="flex space-x-4">
            <a href="https://x.com/fetchconsultant" target="_blank" rel="noopener noreferrer" className="hover:text-indigo-400">
              <img src="/images/x-logo.png" alt="X logo" className="w-6 h-6" />
            </a>
            <a href="https://linkedin.com/company/fetchconsultant" target="_blank" rel="noopener noreferrer" className="hover:text-indigo-400">
              <img src="/images/linkedin-logo.png" alt="LinkedIn logo" className="w-6 h-6" />
            </a>
          </div>
        </div>
      </div>
      <div className="border-t border-gray-300 mt-6 pt-8 text-sm text-left">
        <p>&copy; 2025 fetchConsultant. All rights reserved.</p>
      </div>
    </div>
  </footer>
);

export default Footer;