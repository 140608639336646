// src/components/ui/CustomCertificationsInput.tsx
import React, { useState } from 'react';
import { Plus as PlusIcon, X as XIcon } from 'lucide-react/dist/esm/icons';

interface CustomCertification {
  id?: number;
  name: string;
}

interface CustomCertificationsInputProps {
  certifications: CustomCertification[];
  onChange: (certifications: CustomCertification[]) => void;
}

const CustomCertificationsInput: React.FC<CustomCertificationsInputProps> = ({
  certifications,
  onChange
}: CustomCertificationsInputProps) => {
  const [newCertification, setNewCertification] = useState('');

  const handleAdd = () => {
    if (newCertification.trim()) {
      onChange([
        ...certifications,
        { name: newCertification.trim() }
      ]);
      setNewCertification('');
    }
  };

  const handleRemove = (index: number) => {
    const updatedCertifications = certifications.filter((_, i) => i !== index);
    onChange(updatedCertifications);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAdd();
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-start space-x-2">
        <div className="flex-grow">
          <input
            type="text"
            value={newCertification}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewCertification(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Enter certification or qualification"
            className="fetch-input w-full"
          />
        </div>
        <button
          type="button"
          onClick={handleAdd}
          className="fetch-button-secondary px-3 py-2"
        >
          <PlusIcon size={20} />
        </button>
      </div>

      <div className="space-y-2">
        {certifications.map((cert, index) => (
          <div
            key={index}
            className="flex items-center justify-between p-2 bg-gray-50 rounded-md"
          >
            <span className="text-gray-700">{cert.name}</span>
            <button
              type="button"
              onClick={() => handleRemove(index)}
              className="text-gray-400 hover:text-red-500 transition-colors"
            >
              <XIcon size={20} />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomCertificationsInput;