import { getAnalytics, logEvent } from "firebase/analytics";
import { Menu, DollarSign } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { supabase } from '../supabaseClient';
import NotificationBell from "./NotificationBell";
import SystemAdminHamburger from './SystemAdminHamburger';

const BonusModal = ({ user, onClose, onSave }) => {
  const [bonusAmount, setBonusAmount] = useState(user?.custom_referral_bonus || '');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      const { error } = await supabase
        .from('users')
        .update({ 
          custom_referral_bonus: bonusAmount ? parseFloat(bonusAmount) : null 
        })
        .eq('user_id', user.user_id);

      if (error) throw error;

      const analytics = getAnalytics();
      logEvent(analytics, 'update_referral_bonus', {
        user_id: user.user_id,
        bonus_amount: bonusAmount
      });

      onSave();
    } catch (err) {
      setError('Failed to update bonus amount');
      console.error('Error updating bonus:', err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <h3 className="text-lg font-semibold mb-4">Set Custom Referral Bonus</h3>
        {error && (
          <div className="mb-4 text-red-600 text-sm">{error}</div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Bonus Amount ($)
            </label>
            <input
              type="number"
              value={bonusAmount}
              onChange={(e) => setBonusAmount(e.target.value)}
              className="fetch-input"
              placeholder="Enter amount"
              step="0.01"
              min="0"
            />
            <p className="mt-1 text-sm text-gray-500">
              Leave empty to use default bonus amount
            </p>
          </div>
          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-600 hover:text-gray-800"
              disabled={isSubmitting}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-brandPrimary text-white rounded hover:bg-brandHover"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Saving...' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const SystemAdminUsers = () => {
  const [users, setUsers] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userMetrics, setUserMetrics] = useState({
    totalUsers: 0,
    usersByType: {},
    growthRate: 0
  });
  const [filter, setFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(20);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showBonusModal, setShowBonusModal] = useState(false);

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'System Admin Users',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUsers();
    fetchUserMetrics();
  }, [filter, searchTerm, currentPage]);

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      let query = supabase
        .from('users')
        .select(`
          *,
          user_types (name),
          clients:employer(client_id,name)
        `, { count: 'exact' });

      // Conditionally apply filters based on 'filter' state
      if (filter !== 'all') {
        const userTypeIds = {
          new: [1],
          consultants: [2],
          unverified_clients: [3],
          clients: [4],
          // Add other user types as needed
        };

        const selectedUserTypeIds = userTypeIds[filter];

        if (selectedUserTypeIds) {
          query = query.in('user_type_id', selectedUserTypeIds);
        } else {
          query = query.eq('user_type_id', filter);
        }
      }

      if (searchTerm) {
        query = query.or(`name.ilike.%${searchTerm}%,login_email.ilike.%${searchTerm}%`);
      }

      const { data, error, count } = await query
        .range((currentPage - 1) * usersPerPage, currentPage * usersPerPage - 1)
        .order('created_at', { ascending: false });

      if (error) throw error;

      setUsers(data);
      setUserMetrics(prevMetrics => ({
        ...prevMetrics,
        totalUsers: count
      }));
    } catch (error) {
      console.error('Error fetching users:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBonusEdit = (user, e) => {
    e.stopPropagation();
    setSelectedUser(user);
    setShowBonusModal(true);
  };

  const handleBonusSave = async () => {
    setShowBonusModal(false);
    setSelectedUser(null);
    await fetchUsers();
  };

  const fetchUserMetrics = async () => {
    try {
      const { data, error } = await supabase
        .from('users')
        .select('user_type_id, created_at');

      if (error) throw error;

      const usersByType = data.reduce((acc, user) => {
        acc[user.user_type_id] = (acc[user.user_type_id] || 0) + 1;
        return acc;
      }, {});

      const totalUsers = data.length;
      const lastMonthUsers = data.filter(user =>
        new Date(user.created_at) > new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
      ).length;

      const growthRate = ((lastMonthUsers / totalUsers) * 100).toFixed(2);

      setUserMetrics({
        totalUsers,
        usersByType,
        growthRate
      });
    } catch (error) {
      console.error('Error fetching user metrics:', error.message);
    }
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page when search term changes
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderTableHeader = () => (
    <tr>
      <th className="px-6 py-3 text-left">Name</th>
      <th className="px-6 py-3 text-left">Email</th>
      <th className="px-6 py-3 text-left">User Type</th>
      <th className="px-6 py-3 text-left">Employer</th>
      <th className="px-6 py-3 text-left">Created At</th>
      <th className="px-6 py-3 text-left">Referral Bonus</th>
      <th className="px-6 py-3 text-left">Actions</th>
    </tr>
  );

  const renderTableRow = (user) => (
    <tr key={user.user_id}>
      <td className="px-6 py-4 text-left">{user.name}</td>
      <td className="px-6 py-4 text-left">{user.login_email}</td>
      <td className="px-6 py-4 text-left">{user.user_types?.name}</td>
      <td className="px-6 py-4 text-left">{user.clients ? user.clients.name : 'N/A'}</td>
      <td className="px-6 py-4 text-left">{new Date(user.created_at).toLocaleDateString()}</td>
      <td className="px-6 py-4 text-left">
        {user.custom_referral_bonus ? `$${user.custom_referral_bonus}` : 'Default'}
      </td>
      <td className="px-6 py-4 text-left">
        <button
          onClick={(e) => handleBonusEdit(user, e)}
          className="text-brandPrimary hover:text-brandHover"
          title="Edit Referral Bonus"
        >
          <DollarSign className="h-5 w-5" />
        </button>
      </td>
    </tr>
  );

  return (
    <>
      <Helmet>
        <title>System Admin Users | fetchConsultant</title>
        <meta name="description" content="Manage and monitor user data and referral bonuses on fetchConsultant platform." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <SystemAdminHamburger currentPage="users" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">System Admin Users</h1>
            </div>
            <div className="flex items-center">
              <NotificationBell />
            </div>
          </header>
          <main className="flex-grow container mx-auto px-4 py-8">
            {/* User Metrics Cards */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
              <div className="bg-white p-6 rounded-lg shadow">
                <h2 className="text-xl font-semibold mb-2">Total Users</h2>
                <p className="text-3xl font-bold">{userMetrics.totalUsers}</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow">
                <h2 className="text-xl font-semibold mb-2">Growth Rate</h2>
                <p className="text-3xl font-bold">{userMetrics.growthRate}%</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow">
                <h2 className="text-xl font-semibold mb-2">Users by Type</h2>
                <ul>
                  {Object.entries(userMetrics.usersByType).map(([typeId, count]) => (
                    <li key={typeId}>Type {typeId}: {count}</li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Filters and Search */}
            <div className="flex flex-col md:flex-row md:justify-between items-center mb-6">
              <div className="flex items-center mb-4 md:mb-0">
                <label htmlFor="filter" className="mr-2">Filter:</label>
                <select
                  id="filter"
                  value={filter}
                  onChange={handleFilterChange}
                  className="border border-gray-300 rounded px-2 py-1"
                >
                  <option value="all">All</option>
                  <option value="new">New</option>
                  <option value="unverified_clients">Unverified Clients</option>
                  <option value="clients">Clients</option>
                  <option value="consultants">Consultants</option>
                  {/* Add more filter options as needed */}
                </select>
              </div>
              <div className="flex items-center"> 
                <input
                  type="text"
                  placeholder="Search by name or email"
                  value={searchTerm}
                  onChange={handleSearch}
                  className="border border-gray-300 rounded px-2 py-1"
                />
              </div>
            </div>

            {/* Users Table */}
            <div className="bg-white rounded-lg shadow overflow-x-auto">
              <table className="min-w-full">
                <thead>
                  {renderTableHeader()}
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="7" className="text-center py-4">Loading...</td>
                    </tr>
                  ) : users.length > 0 ? (
                    users.map(user => renderTableRow(user))
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center py-4">No users found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {/* Pagination */}
            <div className="flex justify-center mt-6">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="px-4 py-2 mr-2 bg-gray-200 rounded"
              >
                Previous
              </button>
              <span className="px-4 py-2">Page {currentPage}</span>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={users.length < usersPerPage}
                className="px-4 py-2 ml-2 bg-gray-200 rounded"
              >
                Next
              </button>
            </div>
          </main>
        </div>
      </div>
      {showBonusModal && (
        <BonusModal
          user={selectedUser}
          onClose={() => {
            setShowBonusModal(false);
            setSelectedUser(null);
          }}
          onSave={handleBonusSave}
        />
      )}
    </>
  );
};

export default SystemAdminUsers;