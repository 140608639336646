import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

const VerificationStep = ({ 
  jobDetails, 
  formData, 
  onChange, 
  onBack, 
  onNext,
  existingVerifications ,
  verificationType
}) => {
  const handleVerificationChange = (isVerified) => {
    onChange({ isVerified });
  };

  const formatDate = (date) => {
    if (!date) return 'Present';
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const handleNext = () => {
    if (formData.isVerified === null) {
      toast.error('Please indicate whether you verify this employment');
      return;
    }
    
    if (!formData.isVerified && !formData.verificationNotes.trim()) {
      toast.error('Please provide notes explaining what needs to be corrected');
      return;
    }

    onNext();
  };

  const handleBack = () => {
    if (typeof onBack === 'function') {
      onBack();
    } else {
      console.error('onBack function is not defined');
    }
  };

  const isFirstVerification = !existingVerifications?.length;

  return (
    <div className="p-6">
      <h2 className="text-xl font-semibold mb-6">
        Employment Verification
      </h2>

      {/* Previous Verifications Status */}
      {existingVerifications?.length > 0 ? (
        <div className="bg-blue-50 p-4 rounded-lg mb-6">
          <h3 className="font-medium text-blue-900 mb-2">Previous Verifications</h3>
          {existingVerifications.map(verification => (
            <div key={verification.id} className="text-blue-800 mb-1 text-sm">
              ✓ Verified by {verification.reviewer?.name}
              {verification.reviewer?.job_title && ` (${verification.reviewer.job_title})`}
              {verification.verified_at && ` on ${formatDate(verification.verified_at)}`}
            </div>
          ))}
          <p className="mt-3 text-sm text-blue-700">
            Please review and confirm these employment details.
          </p>
        </div>
      ) : (
        <div className="bg-blue-50 p-4 rounded-lg mb-6">
          <p className="text-blue-800">
            You will be the first to verify these employment details.
            Your verification will be used as reference for future verifications.
          </p>
        </div>
      )}

      {/* Employment Details */}
      <div className="bg-white rounded-lg border border-gray-200 mb-6">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Position Details</h3>
          <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
            <div>
              <dt className="text-sm font-medium text-gray-500">Position</dt>
              <dd className="mt-1 text-sm text-gray-900">{jobDetails.job_title}</dd>
            </div>
            
            <div>
              <dt className="text-sm font-medium text-gray-500">
                {verificationType === 'job_history' ? 'Company' : 'Client'}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">{jobDetails.company_name || jobDetails.client?.name}</dd>
            </div>

            <div>
              <dt className="text-sm font-medium text-gray-500">Duration</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {formatDate(jobDetails.start_date)} - {formatDate(jobDetails.end_date)}
              </dd>
            </div>

            <div>
              <dt className="text-sm font-medium text-gray-500">Work Type</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {jobDetails.work_type} • {jobDetails.location_type} • {jobDetails.job_type}
              </dd>
            </div>

            {jobDetails.firm_name && (
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Consulting Firm</dt>
                <dd className="mt-1 text-sm text-gray-900">{jobDetails.firm_name}</dd>
              </div>
            )}

          </dl>
        </div>
      </div>

      {/* Description */}
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Job Description
        </label>
        <div className="bg-gray-50 rounded-lg p-4">
          <ReactQuill
            value={jobDetails.description || jobDetails.consultant_description}
            readOnly
            theme="bubble"
            modules={{ toolbar: false }}
          />
        </div>
      </div>

      {/* Verification Form */}
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700">
          Do you verify this employment information is accurate?
        </label>
        <div className="mt-4 flex space-x-4">
          <button
            type="button"
            onClick={() => handleVerificationChange(true)}
            className={`px-4 py-2 rounded-md ${
              formData.isVerified === true
                ? 'bg-green-600 text-white'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
          >
            Yes, I Verify
          </button>
          <button
            type="button"
            onClick={() => handleVerificationChange(false)}
            className={`px-4 py-2 rounded-md ${
              formData.isVerified === false
                ? 'bg-red-600 text-white'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
          >
            No, Information is Incorrect
          </button>
        </div>
      </div>

      {/* Verification Notes - Only show if "No" is selected */}
      {formData.isVerified === false && (
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Please explain what needs to be corrected
            <span className="text-red-500 ml-1">*</span>
          </label>
          <textarea
            value={formData.verificationNotes}
            onChange={(e) => onChange({ verificationNotes: e.target.value })}
            className="fetch-textarea"
            rows={4}
            placeholder="Please explain what information needs to be corrected..."
            required
          />
        </div>
      )}

      {/* Description Lock Notice for First Verification */}
      {isFirstVerification && formData.isVerified && (
        <div className="bg-blue-50 p-4 rounded-lg mb-6">
          <p className="text-sm text-blue-700">
            As the first verifier, the job description you verify will be locked and used 
            as the reference for future verifications.
          </p>
        </div>
      )}

      {/* Navigation Buttons */}
      <div className="flex justify-between pt-4">
        <button
          type="button"
          onClick={handleBack}
          className="px-4 py-2 border rounded text-gray-600"
        >
          Back
        </button>
        <button
          type="button"
          onClick={handleNext}
          disabled={formData.isVerified === null}
          className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
};

VerificationStep.propTypes = {
  jobDetails: PropTypes.shape({
    job_title: PropTypes.string,
    company_name: PropTypes.string,
    client: PropTypes.shape({
      name: PropTypes.string
    }),
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    work_type: PropTypes.string,
    job_type: PropTypes.string,
    firm_name: PropTypes.string,
    location_type: PropTypes.string,
    description: PropTypes.string,
    consultant_description: PropTypes.string
  }).isRequired,
  formData: PropTypes.shape({
    isVerified: PropTypes.bool,
    verificationNotes: PropTypes.string
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  existingVerifications: PropTypes.array,
  verificationType: PropTypes.oneOf(['job_history', 'engagement'])
};

export default VerificationStep;