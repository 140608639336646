import { getAnalytics, logEvent } from "firebase/analytics";
import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import { Info, Star } from 'lucide-react';
import { supabase } from '../../supabaseClient';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Tooltip = ({ content, children }) => (
  <div className="group relative inline-block">
    {children}
    <div className="absolute hidden group-hover:block bg-gray-800 text-white text-sm rounded p-2 z-10 w-64 -left-20 top-full mt-1">
      {content}
    </div>
  </div>
);

const formatDate = (date) => {
  if (!date) return 'Present';
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

const ReviewRequestModal = ({ 
  isOpen, 
  onClose, 
  engagement,
  jobHistory,
  onSubmit 
}) => {
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    title: '',
    reviewType: '',
    relationshipType: ''
  });
  const [localDescription, setLocalDescription] = useState('');
  const [isDescriptionLocked, setIsDescriptionLocked] = useState(false);
  const [isRequestingAnother, setIsRequestingAnother] = useState(false);

  useEffect(() => {
    if (engagement) {
      setLocalDescription(engagement.consultant_description || engagement.job?.description || '');
      setIsDescriptionLocked(engagement.reviews?.some(r => r.verification_status === 'verified'));
      
      if (!isRequestingAnother && engagement.decision_maker) {
        const hasDecisionMakerReview = engagement.reviews?.some(review => 
          review.reviewer?.work_email === engagement.decision_maker.work_email
        );

        if (!hasDecisionMakerReview) {
          setFormData({
            name: engagement.decision_maker.name || '',
            email: engagement.decision_maker.work_email || '',
            phone: engagement.decision_maker.phone || '',
            title: engagement.decision_maker.job_title || '',
            reviewType: 'manager',
            relationshipType: ''
          });
        }
      }
    } else if (jobHistory) {
      setLocalDescription(jobHistory.job_description || '');
      setIsDescriptionLocked(jobHistory.reviews?.some(r => r.verification_status === 'verified'));
    }
  }, [engagement, jobHistory, isRequestingAnother]);

  const formatPhoneNumber = (value) => {
    const phoneNumber = value.replace(/[^\d]/g, '');
    if (phoneNumber.length < 4) return phoneNumber;
    if (phoneNumber.length < 7) return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const reviews = engagement?.reviews || jobHistory?.reviews || [];
  const isVerified = engagement?.reviews?.some(r => r.verification_status === 'verified') ||
  jobHistory?.reviews?.some(r => r.verification_status === 'verified');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email && !formData.phone) {
      toast.error("Please provide either an email address or phone number");
      return;
    }

    const existingReview = reviews.find(review => 
      (formData.email && (review.reviewer?.work_email === formData.email || 
                          review.reviewer?.login_email === formData.email)) ||
      (formData.phone && review.reviewer?.phone === formData.phone?.replace(/\D/g, ''))
    );

    if (existingReview) {
      toast.error("This person has already provided a review.");
      return;
    }

    const analytics = getAnalytics();

    try {
      // Update description if not verified
      if (!isVerified) {
        if (engagement) {
          const { error: updateError } = await supabase
            .from('engagements')
            .update({ consultant_description: localDescription })
            .eq('id', engagement.id);

          if (updateError) throw updateError;
        } else if (jobHistory) {
          const { error: updateError } = await supabase
            .from('job_history')
            .update({ job_description: localDescription })
            .eq('id', jobHistory.id);

          if (updateError) throw updateError;
        }
      }

      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");
      
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('name, user_id')
        .eq('user_id', user.id)
        .single();
      
      if (userError) throw userError;

      const requestBody = {
        reviewerEmail: formData.email || null,
        reviewerPhone: formData.phone ? formData.phone.replace(/\D/g, '') : null,
        reviewType: formData.reviewType,
        type: engagement ? 'engagement' : 'job_history',
        consultantId: engagement?.consultant_id || jobHistory?.consultant_id || userData.user_id,
        senderName: userData.name,
        relationshipType: formData.reviewType === 'other' ? formData.relationshipType : undefined,
        job_history_id: jobHistory?.id || null,
        engagement_id: engagement?.id || null,
        verification_type: engagement ? 'engagement' : 'job_history',
        verification_status: 'pending',
        consultant_description: engagement?.consultant_description || jobHistory?.job_description
      };

      const { error: requestError } = await supabase.functions.invoke('send-review-request', {
        body: requestBody
      });

      if (requestError) throw requestError;

      logEvent(analytics, 'review_requested', {
        context_type: engagement ? 'engagement' : 'job_history',
        review_type: formData.reviewType
      });

      toast.success('Review request sent successfully');
      onClose();
    } catch (error) {
      console.error('Error requesting review:', error);
      toast.error(error.message);
    }
  };

  const renderReviewCarousel = () => (
    <div className="mb-6">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-medium">Reviews ({reviews.length})</h3>
        {reviews.length > 1 && (
        <div className="flex space-x-2">
          <button
            disabled={currentReviewIndex === 0}
            onClick={() => setCurrentReviewIndex(prev => prev - 1)}
            className="px-2 py-1 text-sm bg-gray-100 rounded disabled:opacity-50"
          >
            Previous
          </button>
          <button
            disabled={currentReviewIndex === reviews.length - 1}
            onClick={() => setCurrentReviewIndex(prev => prev + 1)}
            className="px-2 py-1 text-sm bg-gray-100 rounded disabled:opacity-50"
            >
              Next
            </button>
          </div>
        )}
      </div>

      {reviews[currentReviewIndex] && (
        <div className="bg-gray-50 p-4 rounded-lg">
          <div className="flex items-center justify-between mb-2">
            <div>
              <p className="font-medium">{reviews[currentReviewIndex].reviewer.name}</p>
              <p className="text-sm text-gray-600">{reviews[currentReviewIndex].reviewer.job_title}</p>
              <p className="text-sm text-gray-600">
                Reviewer Relationship: {
                  reviews[currentReviewIndex].review_type === 'other' 
                    ? reviews[currentReviewIndex].relationship_details
                    : reviews[currentReviewIndex].review_type?.charAt(0).toUpperCase() + 
                      reviews[currentReviewIndex].review_type?.slice(1)
                }
                {reviews[currentReviewIndex].reviewer.client?.name && 
                  ` at ${reviews[currentReviewIndex].reviewer.client.name}`}
              </p>
            </div>
            <div className="flex">
              {[...Array(5)].map((_, i) => (
                <Star
                  key={i}
                  size={20}
                  className={i < reviews[currentReviewIndex].rating ? "text-yellow-400 fill-yellow-400" : "text-gray-300"}
                />
              ))}
            </div>
          </div>
          <ReactQuill
            value={reviews[currentReviewIndex].review_text}
            readOnly={true}
            theme="bubble"
            modules={{ toolbar: false }}
          />
          <p className="text-sm text-gray-500 mt-2">
            {new Date(reviews[currentReviewIndex].created_at).toLocaleDateString()}
          </p>
        </div>
      )}
    </div>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setIsRequestingAnother(false);
      }}
      title={reviews.length > 0 && !isRequestingAnother ? "Reviews" : "Request Review"}
      size="lg"
    >
      <div className="p-6">
        {/* Show verification banner only if there are verified reviews */}
        {reviews?.some(r => r.verification_status === 'verified') && (
          <div className="bg-green-50 border border-green-200 rounded-lg p-4 mb-6">
            <div className="flex items-start">
              <div className="flex-shrink-0">
                <svg className="h-6 w-6 text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
              </div>
              <div className="ml-3">
                <h3 className="text-green-800 font-medium">Employment Verified</h3>
                <p className="text-green-700 text-sm mt-1">
                  This employment record has been verified on {formatDate(reviews.find(r => r.verification_status === 'verified').created_at)}
                </p>
              </div>
            </div>
          </div>
        )}

        {/* Info banner - show only when requesting a new review */}
        {(!reviews?.length || isRequestingAnother) && (
          <div className="bg-blue-50 p-4 rounded-md mb-6">
            <h4 className="text-blue-800 font-medium mb-2">{reviews.length === 0 ? "Why Add Reviewer Information?" : "Why Add Additional Reviewers?"}</h4>
            <p className="text-blue-700 text-sm">
              {reviews.length === 0 ? (
                // Message for first reviewer
                "Verified work history significantly improves your chances of landing contracts. Plus, if your reviewer is new to fetchConsultant, you'll receive a referral bonus! Learn more "
              ) : (
                // Message for additional reviewers  
                "Each additional reviewer gives hiring managers even more confidence in your capacity. And don't forget you'll earn referral bonuses for each new user to fetchConsultant! Learn more "
              )}
              <a href="https://fetchconsultant.com/blog/build-passive-income-through-your-healthcare-it-network" target="_blank" rel="noopener noreferrer">here</a>.
            </p>
          </div>
        )}
        {reviews.length > 0 && !isRequestingAnother && (
          <>
            {renderReviewCarousel()}
            <button
              onClick={() => setIsRequestingAnother(true)}
              className="w-full mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              Request Another Review
            </button>
          </>
        )}

        {(isRequestingAnother || reviews.length === 0) && (
          <form onSubmit={handleSubmit} className="space-y-4">
            {engagement && (
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  {engagement ? 'Job Description' : 'Work History Description'}
                </label>
                <ReactQuill
                  value={localDescription}
                  onChange={setLocalDescription}
                  theme="snow"
                  readOnly={isDescriptionLocked}
                />
                <p className="mt-1 text-sm text-gray-500">
                  {isDescriptionLocked 
                    ? "The details including the description have already been verified and can no longer be changed."
                    : "Edit this description to better reflect your role and responsibilities"}
                </p>
              </div>
            )}

            <div>
              <label className="fetch-label flex items-center">
                Name
                <Tooltip content="Full name of the person providing the review">
                  <Info className="ml-2 h-4 w-4 text-gray-400" />
                </Tooltip>
              </label>
              <input
                type="text"
                required
                className="fetch-input"
                value={formData.name}
                onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
              />
            </div>

            <div>
              <label className="fetch-label flex items-center">
                Email
                <Tooltip content="Their work email address for verification">
                  <Info className="ml-2 h-4 w-4 text-gray-400" />
                </Tooltip>
              </label>
              <input
                type="email"
                required={!formData.phone}
                className="fetch-input"
                value={formData.email}
                onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
              />
            </div>

            <div>
              <label className="fetch-label flex items-center">
                Phone
                <Tooltip content="Their work phone number for verification">
                  <Info className="ml-2 h-4 w-4 text-gray-400" />
                </Tooltip>
              </label>
              <input
                type="tel"
                required={!formData.email}
                className="fetch-input"
                value={formData.phone}
                onChange={(e) => setFormData(prev => ({ ...prev, phone: formatPhoneNumber(e.target.value) }))}
                placeholder="(###) ###-####"
              />
            </div>

            <div>
              <label className="fetch-label flex items-center">
                Title
                <Tooltip content="Their job title during your engagement">
                  <Info className="ml-2 h-4 w-4 text-gray-400" />
                </Tooltip>
              </label>
              <input
                type="text"
                required
                className="fetch-input"
                value={formData.title}
                onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
              />
            </div>

            <div>
              <label className="fetch-label flex items-center">
                Review Type
                <Tooltip content="Select the type of professional relationship">
                  <Info className="ml-2 h-4 w-4 text-gray-400" />
                </Tooltip>
              </label>
              <select
                required
                className="fetch-select"
                value={formData.reviewType}
                onChange={(e) => setFormData(prev => ({ ...prev, reviewType: e.target.value }))}
              >
                <option value="">Select Type</option>
                <option value="manager">Manager</option>
                <option value="peer">Peer</option>
                <option value="other">Other</option>
              </select>
            </div>

            {formData.reviewType === 'other' && (
              <div>
                <label className="fetch-label flex items-center">
                  Please specify relationship
                  <Tooltip content="Describe how you worked together">
                    <Info className="ml-2 h-4 w-4 text-gray-400" />
                  </Tooltip>
                </label>
                <input
                  type="text"
                  required
                  className="fetch-input"
                  value={formData.relationshipType}
                  onChange={(e) => setFormData(prev => ({ 
                    ...prev, 
                    relationshipType: e.target.value 
                  }))}
                />
              </div>
            )}

            <div className="flex justify-end space-x-3 pt-4">
              <button
                type="button"
                onClick={() => {
                  if (reviews.length > 0) {
                    setIsRequestingAnother(false);
                  } else {
                    onClose();
                  }
                }}
                className="px-4 py-2 border rounded text-gray-600"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              >
                Send Request
              </button>
            </div>
          </form>
        )}
      </div>
    </Modal>
  );
};

export default ReviewRequestModal;