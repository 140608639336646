import { getAnalytics, logEvent } from "firebase/analytics";
import { getMessaging, onMessage } from "firebase/messaging";
import { Menu, MoreHorizontal } from 'lucide-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import PullToRefresh from 'react-pull-to-refresh';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { supabase } from '../supabaseClient';
import ConsultantHamburger from './ConsultantHamburger';
import NotificationBell from './NotificationBell';
import NotificationPermissionWarning from './NotificationPermissionWarning';
import Spinner from './Spinner';

const ConsultantDashboard = () => {
  const [jobs, setJobs] = useState([]);
  const [user, setUser] = useState(null);  // Store user directly in this component
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [filters, setFilters] = useState({
    epicCertifications: [],
    travel: [],
  });
  const [epicApplications, setEpicApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: 'posted_at', direction: 'desc' });
  const observer = useRef();
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data: userData, error } = await supabase
          .from('users')
          .select('*')
          .eq('user_id', user.id)
          .single();
        if (!error) {
          setUser(userData);
        } else {
          console.error('Error fetching user data:', error);
        }
      }
    };
    fetchUser();
  }, []);

  // Trigger job fetch after user data is available
  useEffect(() => {
    if (!user || !user.user_id) {
      console.log('User not yet loaded, skipping job fetch');
      return;
    }
    console.log('Fetching jobs for user:', user);
    fetchJobs();
  }, [page, filters, searchTerm, sortConfig, user]);

  const fetchJobs = async () => {
    console.log("fetchJobs - user:", user);
    
    if (!user || !user.user_id) {
      console.log('User data not yet available or user_id is missing');
      return;
    }
  
    try {
      setLoading(true);
  
      let query = supabase
        .from('jobs')
        .select(`
          *,
          clients (name),
          jobs_epic_certifications!left (epic_application_id),
          user_jobs!left (user_id),
          referrals!left (referred_job, referred_user_id)
        `)
        .eq('status', 'Active')
        .order(sortConfig.key, { ascending: sortConfig.direction === 'asc' })
        .range(page * 10, (page + 1) * 10 - 1);
  
      if (filters.epicCertifications.length > 0) {
        const epicCertificationIds = filters.epicCertifications.map(c => c.value);
        query = query.in('jobs_epic_certifications.epic_application_id', epicCertificationIds);
      }
  
      if (filters.travel.length > 0) {
        query = query.in('travel', filters.travel);
      }
  
      if (searchTerm) {
        query = query.or(`title.ilike.%${searchTerm}%,description.ilike.%${searchTerm}%,experience.ilike.%${searchTerm}%`);
      }
  
      const { data, error } = await query;
  
      if (error) throw error;
  
      // Debug: Log raw data
      console.log('Raw data from query:', data);
  
      const jobsWithApplicationAndReferralStatus = data.map(job => {
        // Debug: Log user_jobs for each job
        console.log(`Job ${job.job_id} user_jobs:`, job.user_jobs);
        
        const hasApplied = job.user_jobs.some(uj => uj.user_id === user.user_id);
        const isReferred = job.referrals.some(referral => referral.referred_user_id === user.user_id);
        
        // Debug: Log comparison values
        console.log(`Checking job ${job.job_id}:`);
        console.log('User ID to match:', user.user_id);
        console.log('Has applied:', hasApplied);
        
        return {
          ...job,
          hasApplied,
          isReferred,
        };
      });
  
      // Filter out jobs the user has already applied to
      const filteredJobs = jobsWithApplicationAndReferralStatus.filter(job => !job.hasApplied);

      // Debug: Log filtered jobs
      console.log('Filtered jobs:', filteredJobs);
  
      if (page === 0) {
        setJobs(filteredJobs);
      } else {
        setJobs(prevJobs => [...prevJobs, ...filteredJobs]);
      }
  
      setHasMore(jobsWithApplicationAndReferralStatus.length === 10);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching jobs:', error.message);
      setLoading(false);
    }
  };
  
  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Consultant Dashboard',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchEpicApplications();
    checkMobile();

    const messaging = getMessaging();
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log('Received foreground message:', payload);
      if (payload.notification && payload.data && payload.data.url) {
        toast.info(
          <div>
            <p>{payload.notification.body}</p>
            <button 
              onClick={() => navigate(payload.data.url)}
              className="bg-brandPrimary text-white px-4 py-2 rounded mt-2"
            >
              View Job
            </button>
          </div>,
          {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    });

    window.addEventListener('resize', checkMobile);

    return () => {
      unsubscribe();
      window.removeEventListener('resize', checkMobile);
    };
  }, [navigate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const checkMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const fetchEpicApplications = async () => {
    try {
      const { data, error } = await supabase
        .from('epic_applications')
        .select('epic_application_id, name')
        .order('name');

      if (error) throw error;
      setEpicApplications(data.map(app => ({ value: app.epic_application_id, label: app.name })));
    } catch (error) {
      console.error('Error fetching Epic applications:', error.message);
    }
  };

  const handleRefresh = async () => {
    setIsRefreshing(true);
    setJobs([]);
    setPage(0);
    await fetchJobs();
    setIsRefreshing(false);
  };

  const handleFilterChange = (name, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
    setJobs([]);
    setPage(0);
  };

  const toggleDropdown = (jobId) => {
    setActiveDropdown(activeDropdown === jobId ? null : jobId);
  };
  // no longer showing jobs that have been applied to so this isn't necessary anymore
  const handleApplyOrUpdate = (e, jobId, hasApplied) => {
    e.stopPropagation();
    navigate(`/app/apply-job/${jobId}`);
  };

  const handleRefer = (e, jobId) => {
    e.stopPropagation();
    navigate('/app/solicit-refer', { state: { jobId } });
  };

  const handleJobClick = (jobId) => {
    trackJobView(jobId);
    navigate(`/app/job/${jobId}`);
  };

  const trackJobView = async (jobId) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { error } = await supabase
        .from('job_views')
        .insert({
          job_id: jobId,
          user_id: user.id,
          viewed_at: new Date().toISOString()
        });

      if (error) throw error;
    } catch (error) {
      console.error('Error tracking job view:', error.message);
    }
  };

  const lastJobElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  const formatTimeAgo = (date) => {
    const now = new Date();
    const postedDate = new Date(date);
    const diffInSeconds = Math.floor((now - postedDate) / 1000);
  
    if (diffInSeconds < 60) return `${diffInSeconds} second${diffInSeconds === 1 ? '' : 's'} ago`;
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) return `${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`;
    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) return `${diffInHours} hour${diffInHours === 1 ? '' : 's'} ago`;
    const diffInDays = Math.floor(diffInHours / 24);
    return `${diffInDays} day${diffInDays === 1 ? '' : 's'} ago`;
  };

  const handleSort = (key) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  return (
    <>
      <Helmet>
        <title>Consultant Dashboard | fetchConsultant</title>
        <meta name="description" content="View and apply to the latest Epic consulting opportunities on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ConsultantHamburger user={user} currentPage="dashboard" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">Consultant Dashboard</h1>
            </div>
            <div className="flex items-center">
              <NotificationBell />
            </div>
          </header>
          <NotificationPermissionWarning />
          <main className="flex-grow container mx-auto px-4 py-8">
            <PullToRefresh
              onRefresh={handleRefresh}
              className="pull-to-refresh"
              pullingContent={<span>Pull down to refresh...</span>}
              refreshingContent={<div className="flex items-center justify-center"><Spinner /> Refreshing...</div>}
            >
              <div className="mb-4 flex flex-col md:flex-row justify-between items-start md:items-center">
                <div className="w-full md:w-1/3 mb-4 md:mb-0">
                  <input
                    type="text"
                    placeholder="Search jobs..."
                    className="fetch-input w-full"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div className="flex flex-wrap items-center">
                  <div className="mr-4 mb-2 md:mb-0">
                    <Select
                      isMulti
                      name="epicCertifications"
                      options={epicApplications}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(selectedOptions) => handleFilterChange('epicCertifications', selectedOptions)}
                      value={filters.epicCertifications}
                      placeholder="Epic Certifications"
                      styles={{
                        option: (provided) => ({
                          ...provided,
                          textAlign: 'left'
                        })
                      }}
                    />
                  </div>
                  <div className="mr-4 mb-2 md:mb-0">
                    <Select
                      isMulti
                      name="travel"
                      options={[
                        { value: '100% Remote', label: '100% Remote' },
                        { value: 'Hybrid', label: 'Hybrid' },
                        { value: '100% Onsite', label: '100% Onsite' },
                      ]}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(selectedOptions) => handleFilterChange('travel', selectedOptions.map(option => option.value))}
                      value={filters.travel.map(value => ({ value, label: value }))}
                      placeholder="Travel"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          minWidth: '150px',  // Adjust the width as per your need
                          textAlign: 'left',
                        }),
                        menu: (provided) => ({
                          ...provided,
                          minWidth: '150px',  // This controls the dropdown menu width
                        }),
                        option: (provided) => ({
                          ...provided,
                          textAlign: 'left',
                        }),
                      }}
                    />
                  </div>
                </div>
              </div>

              {isMobile ? (
                <div className="grid grid-cols-1 gap-4">
                  {jobs.map((job, index) => (
                    <div 
                      key={job.job_id} 
                      className="bg-white text-left shadow-md rounded-lg p-4 relative hover:shadow-lg transition-shadow cursor-pointer"
                      onClick={() => handleJobClick(job.job_id)}
                      ref={index === jobs.length - 1 ? lastJobElementRef : null}
                    >
                      {job.isReferred && (
                        <div className="absolute top-0 left-0">
                          <span className="bg-brandPrimary text-white text-xs font-semibold px-3 py-1 rounded-tr-lg rounded-bl-lg">
                            Referred
                          </span>
                        </div>
                      )}
                      <div className="mt-2 mb-2">
                        <h2 className="text-xl font-bold text-gray-900">{job.title}</h2>
                        <p className="text-sm text-gray-600">{job.clients.name}</p>
                      </div>
                      <div className="text-sm text-gray-500 mb-2">
                        <p>Posted: {formatTimeAgo(job.posted_at)}</p>
                        <p>Travel: {job.travel}</p>
                        <p>Duration: {job.duration}</p>
                      </div>
                      <div className="absolute bottom-4 right-4">
                        <MoreHorizontal 
                          size={24} 
                          className="cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleDropdown(job.job_id);
                          }}
                        />
                        {activeDropdown === job.job_id && (
                          <div className="absolute right-0 text-left mt-2 w-48 bg-white rounded-md shadow-lg z-10 cursor-pointer">
                            <div className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={(e) => handleApplyOrUpdate(e, job.job_id, job.hasApplied)}>
                              {job.hasApplied ? 'Update Application' : 'Apply'}
                            </div>
                            <div className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={(e) => handleRefer(e, job.job_id)}>
                              Refer
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="bg-white text-left shadow-md rounded-lg">
                  <table className="min-w-full table-auto">
                    <thead className="bg-gray-100">
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('title')}>
                          Title {sortConfig.key === 'title' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('clients.name')}>
                          Client {sortConfig.key === 'clients.name' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('posted_at')}>
                          Posted {sortConfig.key === 'posted_at' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('travel')}>
                          Travel {sortConfig.key === 'travel' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('duration')}>
                          Duration {sortConfig.key === 'duration' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </th>
                        <th className="px-6 py-3"></th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {jobs.map((job, index) => (
                        <tr 
                          key={job.job_id} 
                          className="hover:bg-gray-50 cursor-pointer"
                          onClick={() => handleJobClick(job.job_id)}
                          ref={index === jobs.length - 1 ? lastJobElementRef : null}
                        >
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              {job.isReferred && (
                                <span className="bg-brandPrimary text-white text-xs font-semibold px-2 py-1 rounded mr-2">
                                  Referred
                                </span>
                              )}
                              <div className="text-sm text-gray-700">{job.title}</div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-700">{job.clients.name}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-700">{formatTimeAgo(job.posted_at)}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-700">{job.travel}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-700">{job.duration}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium relative">
                            <MoreHorizontal 
                              size={24} 
                              className="cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleDropdown(job.job_id);
                              }}
                            />
                            {activeDropdown === job.job_id && (
                              <div
                                className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10 cursor-pointer"
                                ref={dropdownRef}
                              >
                                <div className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={(e) => handleApplyOrUpdate(e, job.job_id, job.hasApplied)}>
                                  {job.hasApplied ? 'Update Application' : 'Apply'}
                                </div>
                                <div className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={(e) => handleRefer(e, job.job_id)}>
                                  Refer
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              {loading && <p className="text-center mt-4">Loading...</p>}
            </PullToRefresh>
          </main>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ConsultantDashboard;