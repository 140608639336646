// src/components/ui/PhotoModal.js
import React from 'react';

const PhotoModal = ({ isOpen, onClose, photoUrl, name }) => {
  if (!isOpen) return null;

// In PhotoModal.js
return (
    <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      {/* Background overlay with lower z-index */}
      <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" onClick={onClose}></div>
  
      {/* Modal panel with higher z-index */}
      <div className="flex items-center justify-center min-h-screen p-4 relative z-50">
        <div className="bg-white rounded-lg overflow-hidden shadow-xl max-w-4xl w-full mx-auto">
          <div className="p-6">
            <img
              src={photoUrl}
              alt={`${name}'s photo`}
              className="w-full h-auto rounded-lg"
            />
          </div>
          <div className="bg-gray-50 px-4 py-3 flex justify-end">
            <button
              type="button"
              className="rounded-md border border-gray-300 px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhotoModal;