import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronLeft, Upload } from 'lucide-react';
import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { requestNotificationPermission } from '../firebase/fcm';
import { supabase } from '../supabaseClient';
import { MultiSelect } from "react-multi-select-component";
import { getStorageUrl } from '../utils/storageHelpers';

// --- PDF Imports
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import pdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry';
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const ConsultantOnboardingProfessional = () => {
  const [formData, setFormData] = useState({
    linkedin_url: '',
    former_epic: false,
    current_project_end_date: null,
  });
  const [resume, setResume] = useState(null);
  const [resumeName, setResumeName] = useState('');
  const [epicApplications, setEpicApplications] = useState([]);
  const [selectedEpicCertifications, setSelectedEpicCertifications] = useState([]);
  const [formValid, setFormValid] = useState(false);
  const [completionMessage, setCompletionMessage] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const isEditMode = location.state?.isEditMode || false;
  const [resumeUploadStatus, setResumeUploadStatus] = useState({
    status: null, // null, 'uploading', 'parsing', 'success', 'error'
    message: '',
    details: '' // Additional details for user feedback
  });
  const [resumeUrl, setResumeUrl] = useState(null);

  const fetchEpicApplications = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('epic_applications')
        .select('epic_application_id, name')
        .order('name');

      if (error) throw error;
      return data.map(app => ({ value: app.epic_application_id, label: app.name }));
    } catch (error) {
      console.error('Error fetching Epic applications:', error.message);
      return [];
    }
  }, []);

  const fetchUserData = useCallback(async (epicApps) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");
  
      const { data, error } = await supabase
        .from('users')
        .select(`
          linkedin_url,
          former_epic,
          current_project_end_date,
          resume,
          user_epic_certifications (epic_application_id)
        `)
        .eq('user_id', user.id)
        .single();
  
      if (error) throw error;
  
      setFormData({
        linkedin_url: data.linkedin_url || 'https://www.linkedin.com/in/',
        former_epic: data.former_epic || false,
        current_project_end_date: data.current_project_end_date || '',
      });
  
      if (data.resume) {
        const fileExt = data.resume.split('.').pop();
        try {
          const signedUrl = await getStorageUrl('resumes', data.resume);
          setResume(data.resume);
          setResumeName(`Resume.${fileExt}`);
          setResumeUrl(signedUrl);
        } catch (err) {
          console.error('Error getting resume URL:', err);
        }
      }
  
      const userCertifications = data.user_epic_certifications.map(cert => ({
        value: cert.epic_application_id,
        label: epicApps.find(app => app.value === cert.epic_application_id)?.label
      }));
  
      setSelectedEpicCertifications(userCertifications);
    } catch (error) {
      console.error('Error fetching user data:', error.message);
    }
  }, []);

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: isEditMode ? 'Edit Profile - Professional' : 'Consultant Onboarding - Professional',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    const initializeData = async () => {
      const epicApps = await fetchEpicApplications();
      setEpicApplications(epicApps);
      if (isEditMode) {
        await fetchUserData(epicApps);
      }
    };

    initializeData();
  }, [isEditMode, fetchEpicApplications, fetchUserData]);

  useEffect(() => {
    // Extract the LinkedIn profile path (everything after /in/)
    const linkedinPath = formData.linkedin_url.replace('https://www.linkedin.com/in/', '').replace('/', '');

    // Form is valid if LinkedIn is filled AND resume parsing is complete (if resume was uploaded)
    const isFormValid = 
      linkedinPath.length >= 5 &&
      formData.linkedin_url.startsWith('https://www.linkedin.com/in/') &&
      (!resumeUploadStatus.status || resumeUploadStatus.status === 'success' || resumeUploadStatus.status === 'error');
    setFormValid(isFormValid);
  }, [formData, resumeUploadStatus.status]);

  // --- PDF extraction
  const extractTextFromPDF = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;

    let text = '';
    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const content = await page.getTextContent();
      text += content.items.map((item) => item.str).join(' ');
    }
    return text;
  };

  const handleResumeUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Array to store timeout IDs so we can clear them
    const timeouts = [];

    try {
      // Validate file type
      if (
        ![
          'application/pdf',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ].includes(file.type)
      ) {
        throw new Error('Please upload a PDF or Word document');
      }

      // Validate file size (10MB)
      if (file.size > 10 * 1024 * 1024) {
        throw new Error('File size should not exceed 10MB');
      }

      // Initial status message
      setResumeUploadStatus({
        status: 'uploading',
        message: 'Starting upload...',
      });

      // Store timeout IDs so we can clear them later
      timeouts.push(
        setTimeout(() => {
          setResumeUploadStatus({
            status: 'uploading',
            message: 'Processing file...',
          });
        }, 3000),

        setTimeout(() => {
          setResumeUploadStatus({
            status: 'uploading',
            message: 'Almost there...',
          });
        }, 6000)
      );
  
      // Get authenticated user
      const {
        data: { user: authUser },
      } = await supabase.auth.getUser();
      if (!authUser) throw new Error('No authenticated user found');
  
      // Upload file to storage
      const fileExt = file.name.split('.').pop();
      const fileName = `${Math.random()}.${fileExt}`;
      const filePath = `${authUser.id}/${fileName}`;

      const { data: uploadData, error: uploadError } = await supabase.storage
        .from('resumes')
        .upload(filePath, file);

      if (uploadError) throw uploadError;

      // Replace this part - get signed URL instead of public URL
      const { data: { signedUrl }, error: urlError } = await supabase.storage
        .from('resumes')
        .createSignedUrl(filePath, 3600); // 1 hour expiration

      if (urlError) throw urlError;
  
      // Clear previous timeouts before starting new ones
      timeouts.forEach(timeout => clearTimeout(timeout));
      timeouts.length = 0;

      // Start parsing phase messages
      setResumeUploadStatus({
        status: 'parsing',
        message: 'Beginning resume analysis...',
      });

      // Store new timeout IDs
      timeouts.push(
        setTimeout(() => {
          setResumeUploadStatus({
            status: 'parsing',
            message: 'Extracting work history...',
          });
        }, 6000),

        setTimeout(() => {
          setResumeUploadStatus({
            status: 'parsing',
            message: 'Finalizing analysis...',
          });
        }, 12000)
      );
  
      // Extract text based on file type
      let extractedText = '';
      if (file.type === 'application/pdf') {
        extractedText = await extractTextFromPDF(file);
      }
        
      // Send to parse-resume function
      const { data: parseData, error: parseError } = await supabase.functions.invoke('parse-resume', {
        body: {
          resumeContent: extractedText, // Send the extracted text for PDFs
          fileType: file.type,
          consultantId: authUser.id,
          resumeUrl: signedUrl,
          resumePath: filePath
        },
      });
  
      if (parseError) throw parseError;
  
      // Clear all timeouts before setting success
      timeouts.forEach(timeout => clearTimeout(timeout));
      timeouts.length = 0;

      // If parse-resume completed successfully, update state
      setResume(filePath);
      setResumeName(`Resume.${fileExt}`);
      setResumeUploadStatus({
        status: 'success',
        message: 'Resume processed successfully!',
      });
  
      // Log success to analytics
      const analytics = getAnalytics();
      logEvent(analytics, 'resume_upload_success', {
        fileType: file.type,
        parseSuccess: true,
      });
  
    } catch (error) {
      // Clear all timeouts on error
      timeouts.forEach(timeout => clearTimeout(timeout));
      timeouts.length = 0;

      console.error('Error handling resume:', error);
      setResumeUploadStatus({
        status: 'error',
        message: error.message,
      });
  
      // Log error to analytics
      const analytics = getAnalytics();
      logEvent(analytics, 'resume_upload_error', {
        error: error.message,
        fileType: file?.type,
      });
    }
};

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    let newValue = value;

    // Handle LinkedIn duplication
    if (name === "linkedin_url" && value.includes("https://www.linkedin.com/in/")) {
      newValue = value.replace("https://www.linkedin.com/in/", "");
    }
    // Handle empty date -> null
    if (type === "date" && value === "") {
      newValue = null;
    }

    setFormData(prevData => ({
      ...prevData,
      [name]: name === "linkedin_url" ? `https://www.linkedin.com/in/${newValue}` : newValue
    }));
  };

  const handleEpicCertificationChange = (selected) => {
    setSelectedEpicCertifications(selected);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formValid) return;
    
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");
  
      const { error } = await supabase.rpc('update_user_profile', {
        p_user_id: user.id,
        p_linkedin_url: formData.linkedin_url,
        p_former_epic: formData.former_epic,
        p_resume: resume,
        p_current_project_end_date: formData.current_project_end_date || null
      });

      if (error) throw error;

      // Update epic certifications
      await supabase
        .from('user_epic_certifications')
        .delete()
        .eq('user_id', user.id);

      if (selectedEpicCertifications.length > 0) {
        await supabase
          .from('user_epic_certifications')
          .insert(selectedEpicCertifications.map(cert => ({
            user_id: user.id,
            epic_application_id: cert.value
          })));
      }

      if (!isEditMode) {
        // Set default notification settings
        await supabase.from('notification_settings').insert([
          {
            user_id: user.id,
            notification_type: 'all_epic_certifications',
            notification_options: { email: true, push: true, text: true }
          },
          {
            user_id: user.id,
            notification_type: 'user_specific',
            notification_options: { email: true, push: true, text: true }
          }
        ]);

        // Request notification permission
        await requestNotificationPermission();
      }

      // Navigate
      if (isEditMode) {
        navigate('/app/consultant-dashboard');
      } else {
        navigate('/app/work-history-management', {
          state: { 
            fromOnboarding: true,
            resumeParsed: resumeUploadStatus.status === 'success'
          }
        });
      }

    } catch (error) {
      console.error('Error updating user info:', error.message);
      alert(`Error updating profile: ${error.message}`);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex justify-center items-center">
      <Helmet>
        <title>{isEditMode ? 'Edit Profile - Professional Information' : 'Consultant Onboarding - Professional Information'} | fetchConsultant</title>
        <meta name="description" content={isEditMode ? "Update your professional profile as a healthcare IT consultant on fetchConsultant." : "Complete your professional profile as a healthcare IT consultant on fetchConsultant. Connect with top healthcare organizations and find exciting Epic consulting opportunities."} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Consultant Onboarding - Professional Information | fetchConsultant" />
        <meta property="og:description" content="Complete your professional profile as a healthcare IT consultant on fetchConsultant. Connect with top healthcare organizations and find exciting Epic consulting opportunities." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://fetchconsultant.com/app/consultant-onboarding-professional" />
      </Helmet>

      <div className="flex flex-col md:flex-row w-full max-w-7xl shadow-lg">
        {/* Left section */}
        <div className="bg-white p-4 sm:p-8 w-full md:w-1/2 flex flex-col justify-start sm:justify-center items-center min-h-screen sm:min-h-0 overflow-y-auto">
          <header className="fetch-header w-full">
            <ChevronLeft className="fetch-back-button" onClick={() => navigate(-1)} />
            <h1 className="fetch-page-title">
              {isEditMode ? 'Edit Profile' : 'Consultant Onboarding'}
            </h1>
          </header>

          <main className="fetch-container w-full">
            <div className="fetch-card">
              {!isEditMode && (
                <div className="mb-6 text-center">
                  <p className="fetch-secondary-text">Step 2 of 2</p>
                  <div className="fetch-progress-bar">
                    <div className="fetch-progress-bar-fill" style={{ width: '100%' }}></div>
                  </div>
                </div>
              )}

              <form onSubmit={handleSubmit} className="fetch-form">
                <div>
                  <label htmlFor="linkedin_url" className="fetch-label">
                    LinkedIn URL<span className="fetch-required">*</span>
                  </label>
                  <input
                    type="url"
                    id="linkedin_url"
                    name="linkedin_url"
                    value={formData.linkedin_url}
                    onChange={handleInputChange}
                    required
                    className="fetch-input"
                    placeholder="shanesadams"
                  />
                </div>

                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="former_epic"
                    name="former_epic"
                    checked={formData.former_epic}
                    onChange={handleInputChange}
                    className="fetch-checkbox"
                  />
                  <label htmlFor="former_epic" className="fetch-checkbox-label">
                    Former Epic Employee
                  </label>
                </div>

                <div>
                  <label htmlFor="resume" className="fetch-label">
                    {resumeName ? 'Update Resume (PDF or Word)' : 'Upload Resume (PDF or Word)'}
                  </label>
                  <div className="mt-1 flex flex-col items-start">
                    <input
                      type="file"
                      id="resume"
                      accept=".pdf,.doc,.docx"
                      onChange={handleResumeUpload}
                      className="hidden"
                    />
                    <label
                      htmlFor="resume"
                      className="file-input-button"
                    >
                      <Upload className="w-5 h-5 mr-2" />
                      {resumeName ? 'Update Resume' : 'Upload Resume'}
                    </label>
                    {(resume instanceof File ? resume.name : resumeName) && (
                      <span className="text-sm text-gray-600 mt-2">
                        {resume instanceof File ? (
                          resume.name
                        ) : (
                          <a 
                            href={resumeUrl} 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className="text-blue-600 hover:text-blue-800"
                          >
                            {resumeName}
                          </a>
                        )}
                      </span>
                    )}

                    {resumeUploadStatus.status && (
                      <div
                        className={`mt-4 p-4 rounded-md ${
                          resumeUploadStatus.status === 'error' ? 'bg-red-50' :
                          resumeUploadStatus.status === 'success' ? 'bg-green-50' :
                          'bg-blue-50'
                        }`}
                      >
                        <div className="flex items-center">
                          {(resumeUploadStatus.status === 'uploading' || resumeUploadStatus.status === 'parsing') && (
                            <div className="mr-3">
                              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-blue-600"></div>
                            </div>
                          )}
                          <div className="flex flex-col">
                            <p
                              className={`text-sm font-medium ${
                                resumeUploadStatus.status === 'error'
                                  ? 'text-red-700'
                                  : resumeUploadStatus.status === 'success'
                                  ? 'text-green-700'
                                  : 'text-blue-700'
                              }`}
                            >
                              {resumeUploadStatus.message}
                            </p>
                            {(resumeUploadStatus.status === 'uploading' || resumeUploadStatus.status === 'parsing') && (
                              <p className="text-xs text-gray-500 mt-1">
                                Please wait for resume processing to complete before proceeding
                              </p>
                            )}
                            {resumeUploadStatus.status === 'success' && (
                              <p className="text-xs text-gray-500 mt-1">
                                Resume processed successfully. You can now proceed to the next step.
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div>
                  <label className="fetch-label mb-2">Epic Certifications</label>
                  <MultiSelect
                    options={epicApplications}
                    value={selectedEpicCertifications}
                    onChange={handleEpicCertificationChange}
                    labelledBy="Select Epic Certifications"
                    className="fetch-multiselect"
                    hasSelectAll={false}
                  />
                </div>

                <div>
                  <label htmlFor="current_project_end_date" className="fetch-label">
                    Current Project End Date
                  </label>
                  <input
                    type="date"
                    id="current_project_end_date"
                    name="current_project_end_date"
                    value={formData.current_project_end_date || ''}
                    onChange={handleInputChange}
                    className="fetch-input"
                  />
                </div>

                <div>
                  <button
                    type="submit"
                    className={`fetch-button ${!formValid ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={!formValid || resumeUploadStatus.status === 'uploading' || resumeUploadStatus.status === 'parsing'}
                  >
                    {(resumeUploadStatus.status === 'uploading' || resumeUploadStatus.status === 'parsing') 
                      ? 'Processing Resume...' 
                      : 'Finish'}
                  </button>
                </div>
              </form>
            </div>
          </main>
        </div>

        {/* Right section */}
        <div className="bg-brandPrimary text-white p-10 w-full md:w-1/2 flex flex-col justify-between overflow-y-auto">
          <div className="flex flex-col mt-2 justify-center">
            <h2 className="text-center text-3xl mt-2 font-extrabold">Why fetchConsultant</h2>
          </div>
          <div className="flex flex-col justify-center flex-grow p-8 pt-16">
            <div className="flex justify-center">
              <ul className="space-y-6 flex flex-col">
                {/* marketing / bullet points */}
                <li>
                  <div className="flex items-start">
                    <svg className="h-6 w-6 mr-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-.01 2a5 5 0 10-4-2.9 4.978 4.978 0 003.5-1.1 5.024 5.024 0 002.5 1.4 5 5 0 104.5-6.9A5 5 0 0012 18z" />
                    </svg>
                    <h3 className="text-lg text-left font-semibold">Maximize Your Earnings</h3>
                  </div>
                  <p className="text-left ml-10">Keep more of what you earn with our flat 4.5% platform fee (per party) and 1099 status for enhanced 401(k) savings.</p>
                </li>
                <li>
                  <div className="flex items-start mt-2">
                    <svg className="h-6 w-6 mr-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <h3 className="text-lg text-left font-semibold">Instant Job Alerts</h3>
                  </div>
                  <p className="text-left ml-10">Receive real-time notifications for jobs matching your skills and preferences.</p>
                </li>
                <li>
                  <div className="flex items-start mt-2">
                    <svg className="h-6 w-6 mr-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12v4m0-4v-2a4 4 0 00-8 0v2a2 2 0 100 4v1m0-5v-2a4 4 0 018 0v2" />
                    </svg>
                    <h3 className="text-lg text-left font-semibold">Fair Compensation</h3>
                  </div>
                  <p className="text-left ml-10">One transparent rate between you and the client. No more lowball offers from middlemen.</p>
                </li>
                <li>
                  <div className="flex items-start mt-2">
                    <svg className="h-6 w-6 mr-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12v4m0-4v-2a4 4 0 00-8 0v2a2 2 0 100 4v1m0-5v-2a4 4 0 018 0v2" />
                    </svg>
                    <h3 className="text-lg text-left font-semibold">Seamless Interview Process</h3>
                  </div>
                  <p className="text-left ml-10">Integrated Zoom meetings and scheduling tools connect you with potential clients quickly.</p>
                </li>
              </ul>
            </div>
          </div>

          {completionMessage && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
              <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                <h3 className="text-lg font-medium leading-6 text-gray-900">{completionMessage.title}</h3>
                <div className="mt-2 px-7 py-3">
                  <p className="text-sm text-gray-500">{completionMessage.body}</p>
                  {completionMessage.warning && (
                    <p className="text-sm text-yellow-600 mt-2">{completionMessage.warning}</p>
                  )}
                </div>
                <div className="items-center px-4 py-3">
                  {completionMessage.showSettings && (
                    <button
                      onClick={() => navigate('/app/consultant-settings')}
                      className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
                    >
                      Go to Settings
                    </button>
                  )}
                  <button
                    onClick={() => navigate('/app/consultant-dashboard')}
                    className="mt-3 px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-300"
                  >
                    Go to Dashboard
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConsultantOnboardingProfessional;