import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { UserContext } from '../UserContext';
import { getAnalytics, logEvent } from "firebase/analytics";
import { format, isToday, isYesterday, differenceInDays, parseISO } from 'date-fns';
import { Search, Plus, Paperclip, Send, Menu } from 'lucide-react';
import ClientHamburger from './ClientHamburger';
import ConsultantHamburger from './ConsultantHamburger';
import NotificationBell from './NotificationBell';
import Avatar from './ui/Avatar.js';
import { getStorageUrl } from '../utils/storageHelpers';

const Chats = () => {
  const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [showNewChat, setShowNewChat] = useState(false);
  const [availableUsers, setAvailableUsers] = useState([]);
  const { user } = useContext(UserContext);
  const messagesEndRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();
  const newChatUserId = location.state?.newChatUserId;

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Chats',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    if (user) {
      fetchChats();
      fetchAvailableUsers();
    }
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, [user]);

  useEffect(() => {
    if (selectedChat) {
      fetchMessages(selectedChat.id);
      subscribeToNewMessages(selectedChat.id);
    }
    return () => {
      supabase.removeAllChannels();
    };
  }, [selectedChat]);


  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (newChatUserId) {
      startNewChat(newChatUserId);
    }
  }, [newChatUserId]);

  const checkMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const fetchChats = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      const accessToken = session?.access_token;
  
      const response = await fetch(`${supabaseUrl}/functions/v1/chat-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          action: 'fetchChats',
          userId: user.user_id
        })
      });
  
      if (!response.ok) throw new Error('Failed to fetch chats');
      const { data } = await response.json();
      setChats(data);
    } catch (error) {
      console.error('Error fetching chats:', error);
    }
  };

  const fetchMessages = async (chatId) => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      const accessToken = session?.access_token;
  
      const response = await fetch(`${supabaseUrl}/functions/v1/message-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          action: 'fetchMessages',
          chatId
        })
      });

      if (!response.ok) throw new Error('Failed to fetch messages');
      const { data } = await response.json();
      setMessages(data);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };
  const fetchAvailableUsers = async () => {
    const { data: { session } } = await supabase.auth.getSession();
    const accessToken = session?.access_token;

    try {
      const response = await fetch(`${supabaseUrl}/functions/v1/chat-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          action: 'fetchAvailableUsers',
          userId: user.user_id,
          data: {
            userTypeId: user.user_type_id
          }
        })
      });
  
      if (!response.ok) throw new Error('Failed to fetch available users');
      const { data } = await response.json();
      setAvailableUsers(data);
    } catch (error) {
      console.error('Error fetching available users:', error);
    }
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() || !selectedChat) return;

    const { data: { session } } = await supabase.auth.getSession();
    const accessToken = session?.access_token;

    try {
      const response = await fetch(`${supabaseUrl}/functions/v1/message-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          action: 'sendMessage',
          chatId: selectedChat.id,
          userId: user.user_id,
          content: newMessage
        })
      });

      if (!response.ok) throw new Error('Failed to send message');
      const { data } = await response.json();

      setNewMessage('');
      setMessages([...messages, data]);

      const analytics = getAnalytics();
      logEvent(analytics, 'send_message', {
        chat_id: selectedChat.id,
      });
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const subscribeToNewMessages = (chatId) => {
    const channel = supabase
      .channel(`chat:${chatId}`)
      .on('postgres_changes', { 
        event: 'INSERT', 
        schema: 'public', 
        table: 'messages',
        filter: `chat_id=eq.${chatId}`
      }, payload => {
        setMessages(current => [...current, payload.new]);
      })
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Add file validation
    const maxSize = 10 * 1024 * 1024; // 50MB
    if (file.size > maxSize) {
      alert('File size exceeds 10MB limit');
      return;
    }

    // Allowed file types
    const allowedTypes = ['image/', 'video/', 'application/pdf'];
    if (!allowedTypes.some(type => file.type.startsWith(type))) {
      alert('Invalid file type. Please upload an image, video, or PDF file.');
      return;
    }

    const { data: { session } } = await supabase.auth.getSession();
    const accessToken = session?.access_token;

    try {
      // Create FormData
      const formData = new FormData();
      formData.append('file', file);
      formData.append('chatId', selectedChat.id);
      formData.append('userId', user.user_id);
      formData.append('fileName', file.name);
      formData.append('action', 'uploadFile');

      console.log('Uploading file:', {
        fileName: file.name,
        fileType: file.type,
        fileSize: file.size,
        chatId: selectedChat.id,
        userId: user.user_id
      });

      const response = await fetch(`${supabaseUrl}/functions/v1/file-operations`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
        body: formData
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(`Upload failed: ${responseData.error || 'Unknown error'}`);
      }

      setMessages([...messages, responseData.data]);
      
      const analytics = getAnalytics();
      logEvent(analytics, 'send_file', {
        chat_id: selectedChat.id,
        file_type: file.type
      });
    } catch (error) {
      console.error('Error uploading file:', error);
      alert(`Failed to upload file: ${error.message}`);
    }
  };

  const getValidFileUrl = async (filePath) => {
    if (!filePath) return null;
    return getStorageUrl('chat_files', filePath);
  };

  const startNewChat = async (recipientId) => {
    const { data: { session } } = await supabase.auth.getSession();
    const accessToken = session?.access_token;

    try {
      const response = await fetch(`${supabaseUrl}/functions/v1/chat-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          action: 'startNewChat',
          userId: user.user_id,
          data: { recipientId }
        })
      });

      if (!response.ok) throw new Error('Failed to start chat');
      const { data: chatData } = await response.json();

      setShowNewChat(false);
      setSelectedChat(chatData);
      setChats(prevChats => [chatData, ...prevChats.filter(chat => chat.id !== chatData.id)]);
    } catch (error) {
      console.error('Error starting new chat:', error);
    }
  };

  const markMessagesAsRead = async (chatId) => {
    const { data: { session } } = await supabase.auth.getSession();
    const accessToken = session?.access_token;

    try {
      const response = await fetch(`${supabaseUrl}/functions/v1/chat-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          action: 'markMessagesAsRead',
          userId: user.user_id,
          data: { chatId }
        })
      });

      if (!response.ok) throw new Error('Failed to mark messages as read');

      setChats(prevChats => 
        prevChats.map(chat => 
          chat.id === chatId ? { ...chat, unreadCount: 0 } : chat
        )
      );

      if (selectedChat && selectedChat.id === chatId) {
        setMessages(prevMessages => 
          prevMessages.map(msg => ({ 
            ...msg, 
            read_by: msg.read_by.includes(user.user_id) ? msg.read_by : [...msg.read_by, user.user_id] 
          }))
        );
      }
    } catch (error) {
      console.error('Error marking messages as read:', error);
    }
  };
  
  const handleChatSelect = (chat) => {
    setSelectedChat(chat);
    if (chat.unreadCount > 0) {
      markMessagesAsRead(chat.id);
    }
  };

  const formatMessageTimestamp = (timestamp) => {
    const date = parseISO(timestamp);
    if (isToday(date)) {
      return `Today ${format(date, 'h:mm a')}`;
    } else if (isYesterday(date)) {
      return `Yesterday ${format(date, 'h:mm a')}`;
    } else if (differenceInDays(new Date(), date) < 7) {
      return format(date, 'EEEE h:mm a'); // Shows day name and time
    }
    return format(date, 'EEE, MMM d h:mm a'); // Shows abbreviated day, month, date and time
  };
  
  const getReadStatus = (message) => {
    if (message.sender_id === user.user_id && message.read_by.length > 1) {
      return `Read ${format(parseISO(message.read_by[1]?.timestamp || message.created_at), 'h:mm a')}`;
    }
    return null;
  };
  
  const isNewDay = (currentMessage, previousMessage) => {
    if (!previousMessage) return true;
    
    const currentDate = parseISO(currentMessage.created_at);
    const previousDate = parseISO(previousMessage.created_at);
    
    return format(currentDate, 'yyyy-MM-dd') !== format(previousDate, 'yyyy-MM-dd');
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {user.user_type_id === 4 ? (
        <ClientHamburger user={user} currentPage="chats" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
      ) : (
        <ConsultantHamburger user={user} currentPage="chats" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
      )}
      
      <div className="flex-1 flex flex-col md:ml-64">
        <header className="bg-white p-4 flex justify-between items-center">
          <div className="flex items-center">
            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
              <Menu size={24} />
            </button>
            <h1 className="text-xl font-bold">Chat</h1>
          </div>
          <div className="flex items-center">
            <NotificationBell />
          </div>
        </header>

        <div className="flex-1 flex overflow-hidden">
          {/* Chat list */}
          <div className={`${isMobile && selectedChat ? 'hidden' : 'w-full md:w-1/3'} bg-white border-r overflow-y-auto`}>
            <div className="p-4 text-left">
              <div className="flex items-center mb-4">
                <div className="relative flex-1">
                  <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                  <input
                    type="text"
                    placeholder="Search"
                    className="w-full pl-10 pr-4 py-2 rounded-full bg-gray-100 text-sm"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <button
                  className="ml-2 p-2 bg-brandPrimary text-white rounded-full hover:bg-brandHover transition duration-300"
                  onClick={() => setShowNewChat(true)}
                >
                  <Plus size={20} />
                </button>
              </div>
              {chats.filter(chat => 
                (chat.otherParticipant?.name || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (chat.lastMessage && chat.lastMessage.content.toLowerCase().includes(searchTerm.toLowerCase()))
              ).map((chat) => (
                <div  
                  key={chat.id} 
                  className={`flex items-center p-3 cursor-pointer hover:bg-gray-50 ${selectedChat && selectedChat.id === chat.id ? 'bg-gray-100' : ''}`}
                  onClick={() => handleChatSelect(chat)}
                >
                  <Avatar
                    photo={chat.otherParticipant?.photo}
                    name={chat.otherParticipant?.name}
                    className="w-12 h-12 mr-3 flex-shrink-0"
                  />
                  <div className="flex-1 min-w-0">
                    <h3 className="font-semibold truncate">{chat.otherParticipant?.name || 'Loading...'}</h3>
                    {chat.lastMessage && (
                      <p className="text-sm text-gray-500 truncate max-w-[180px]">{chat.lastMessage.content}</p>
                    )}
                  </div>
                  <div className="text-xs text-gray-400 flex flex-col items-end flex-shrink-0 ml-2">
                    {chat.lastMessage && format(parseISO(chat.lastMessage.created_at), 'h:mm a')}
                    {chat.unreadCount > 0 && (
                      <div className="mt-1 bg-brandPrimary text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                        {chat.unreadCount}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Chat room */}
          <div className={`${isMobile && !selectedChat ? 'hidden' : 'flex-1'} flex flex-col`}>
            {selectedChat ? (
              <>
                <div className="bg-white p-4 border-b flex items-center justify-between">
                  <div className="flex items-center">
                    {isMobile && (
                      <button onClick={() => setSelectedChat(null)} className="mr-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                        </svg>
                      </button>
                    )}
                    <Avatar
                      photo={selectedChat.otherParticipant?.photo}
                      name={selectedChat.otherParticipant?.name}
                      className="w-10 h-10 mr-3"
                    />
                    <h2 className="font-semibold">{selectedChat.otherParticipant?.name}</h2>
                  </div>
                </div>
                <div className="flex-1 text-leftoverflow-y-auto p-4">
                  {messages.map((message, index) => {
                    const isFromCurrentUser = message.sender_id === user.user_id;
                    const readStatus = getReadStatus(message);
                    const showTimestamp = isNewDay(message, messages[index - 1]);

                    return (
                      <div key={message.id}>
                        {showTimestamp && (
                          <div className="text-center my-4">
                            <span className="text-xs text-gray-500 bg-white px-2 py-1 rounded-full">
                              {formatMessageTimestamp(message.created_at)}
                            </span>
                          </div>
                        )}
                        <div className={`flex mb-4 ${isFromCurrentUser ? 'justify-end' : 'justify-start'}`}>
                          <div className="flex flex-col">
                            <span className="text-xs text-gray-500 mb-1">
                              {format(parseISO(message.created_at), 'h:mm a')}
                            </span>
                            <div 
                              className={`max-w-xs lg:max-w-md xl:max-w-lg ${
                                isFromCurrentUser ? 'bg-brandPrimary text-white' : 'bg-gray-200'
                              } rounded-lg p-2 text-sm`}
                            >
                              {message.file_path ? (
                                <a 
                                  href="#" 
                                  onClick={async (e) => {
                                    e.preventDefault();
                                    const validUrl = await getStorageUrl('chat_files', message.file_path);
                                    if (validUrl) {
                                      window.open(validUrl, '_blank');
                                    }
                                  }}
                                  className="text-blue-500 underline"
                                >
                                  {message.content}
                                </a>
                              ) : (
                                <p>{message.content}</p>
                              )}
                            </div>
                            {readStatus && (
                              <span className="text-xs text-gray-500 mt-1 text-right">
                                {readStatus}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div ref={messagesEndRef} />
                </div>
                <div className="bg-white p-4 border-t">
                  <form onSubmit={sendMessage} className="flex items-center">
                    <label htmlFor="file-upload" className="mr-2 text-gray-500 cursor-pointer">
                      <Paperclip size={20} />
                    </label>
                    <input
                      id="file-upload"
                      type="file"
                      className="hidden"
                      onChange={handleFileUpload}
                      accept="image/*,video/*,application/pdf"
                    />
                    <input
                      type="text"
                      placeholder="Type a message"
                      className="flex-1 px-4 py-2 rounded-full bg-gray-100"
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                    />
                    <button
                      type="submit"
                      className="p-2 bg-brandPrimary text-white rounded-full hover:bg-brandHover transition duration-300"
                    >
                      <Send size={20} />
                    </button>
                  </form>
                </div>
              </>
            ) : (
              <div className="flex-1 flex items-center justify-center text-gray-500">
                Select a chat or start a new conversation
              </div>
            )}
          </div>
        </div>
      </div>

      {/* New chat modal */}
      {showNewChat && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-96">
            <h3 className="text-lg font-semibold mb-4">Start New Chat</h3>
            <div className="max-h-64 overflow-y-auto">
              {availableUsers.map((user) => (
                <div 
                  key={user.user_id} 
                  className="flex items-center p-2 hover:bg-gray-100 cursor-pointer rounded-md"
                  onClick={() => startNewChat(user.user_id)}
                >
                  <Avatar
                    photo={user.photo}
                    name={user.name}
                    className="w-10 h-10 mr-3"
                  />
                  <span>{user.name}</span>
                </div>
              ))}
            </div>
            <button 
            className="mt-4 w-full bg-gray-200 text-gray-800 py-2 rounded-md hover:bg-gray-300 transition duration-300"
            onClick={() => setShowNewChat(false)}
        >
            Cancel
        </button>
        </div>
    </div>
    )}
</div>
);
};

export default Chats;