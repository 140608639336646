import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { getAnalytics, logEvent } from "firebase/analytics";
import NotificationBell from "./NotificationBell";
import ClientHamburger from './ClientHamburger';
import { Menu, Star } from 'lucide-react';

const ClientCreateInterviewNote = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const editNote = location.state?.note;
  const consultantId = location.state?.consultantId;
  const consultantName = location.state?.consultantName;

  const [note, setNote] = useState('');
  const [noteType, setNoteType] = useState('General');
  const [feedbackScore, setFeedbackScore] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [interviews, setInterviews] = useState([]);
  const [selectedInterviewId, setSelectedInterviewId] = useState('');
  const [consultants, setConsultants] = useState([]);
  const [selectedConsultantId, setSelectedConsultantId] = useState('');
  const interviewTemplate = `<h2>General</h2><ul><li><br></li><li><br></li></ul><p><br></p><h2><strong>Positives</strong></h2><ul><li><br></li><li><br></li></ul><p><br></p><h2><strong>Negatives</strong></h2><ul><li><br></li><li><br></li></ul><p><br></p><h2><strong>Next Steps</strong></h2><ul><li><br></li><li><br></li></ul><p><br></p>`;


  // Debounce function to limit fetching
  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  // Fetch user
  const fetchUser = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error.message);
    }
  };

  // Fetch consultants
  const fetchConsultants = async () => {
    try {
      if (consultants.length > 0) return; // Avoid fetching if consultants already loaded
      const { data, error } = await supabase
        .from('users')
        .select('user_id, name')
        .eq('user_type_id', 2);

      if (error) throw error;
      setConsultants(data);
      
      if (consultantName && !consultantId) {
        const matchingConsultant = data.find(c => c.name === consultantName);
        if (matchingConsultant) {
          setSelectedConsultantId(matchingConsultant.user_id);
        }
      }
    } catch (error) {
      console.error('Error fetching consultants:', error);
    }
  };

  // Fetch interviews with debounce
  const fetchInterviews = debounce(async () => {
    try {
      if (interviews.length > 0) return; // Avoid fetching if interviews already loaded
      const { data: { user } } = await supabase.auth.getUser();
      let query = supabase
        .from('interviews')
        .select(`
          id,
          interview_time,
          user_job:user_jobs (
            job:jobs (title),
            consultant:users!user_jobs_user_id_fkey (user_id, name)
          )
        `)
        .eq('user_job.job.created_by', user.id)
        .order('interview_time', { ascending: false });

      if (selectedConsultantId) {
        query = query.eq('user_job.user_id', selectedConsultantId);
      }

      const { data, error } = await query;
      if (error) throw error;
      
      const validInterviews = data.filter(interview => interview.user_job && interview.user_job.job);
      setInterviews(validInterviews);
    } catch (error) {
      console.error('Error fetching interviews:', error.message);
    }
  }, 300); // 300ms delay for debounce

  // Consolidated useEffect to fetch all data
  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchUser();
        await fetchConsultants();

        if (selectedConsultantId || consultantId) {
          await fetchInterviews();
          // Set noteType based on interviews existence
          if (interviews.length > 0) {
            setNoteType('Interview');
          }
        }

        if (editNote) {
          setNote(editNote.content);
          setNoteType(editNote.note_type);
          setFeedbackScore(editNote.feedback_score);
          setSelectedInterviewId(editNote.interview_id);
          setSelectedConsultantId(editNote.consultant_id);
        } else if (consultantId) {
          setSelectedConsultantId(consultantId);
        }

        const analytics = getAnalytics();
        logEvent(analytics, 'page_view', {
          page_title: consultantName ? `Create Note for ${consultantName}` : 'Create Interview Note',
          page_location: window.location.href,
          page_path: window.location.pathname,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [editNote, selectedConsultantId, consultantId, consultantName]);

  useEffect(() => {
    if (noteType === 'Interview') {
      setNote(interviewTemplate);
    } else if (!editNote) {  // Only clear if not editing existing note
      setNote('');
    }
  }, [noteType, editNote]);

  // Create array of note types based on interviews
  const getNoteTypes = () => {
    const baseTypes = ['General'];
    return interviews.length > 0 ? [...baseTypes, 'Interview'] : baseTypes;
  };

  const saveNote = async () => {
    setIsSaving(true);
    try {
      const { data: { user } } = await supabase.auth.getUser();

      let consultantId = null;
      if (noteType === 'Interview' && selectedInterviewId) {
        const selectedInterview = interviews.find(i => i.id === selectedInterviewId);
        consultantId = selectedInterview?.user_job?.consultant?.user_id;
      }

      const noteData = {
        notewriter_id: user.id,
        content: note,
        note_type: noteType,
        feedback_score: feedbackScore,
        interview_id: noteType === 'Interview' ? selectedInterviewId : null,
        consultant_id: selectedConsultantId,
        updated_at: new Date()
      };

      let result;
      if (editNote) {
        const { data, error } = await supabase
          .from('interview_notes')
          .update(noteData)
          .eq('id', editNote.id);
        result = { data, error };
      } else {
        noteData.created_at = new Date();
        const { data, error } = await supabase
          .from('interview_notes')
          .insert(noteData);
        result = { data, error };
      }

      if (result.error) throw result.error;
      navigate('/app/client-interview-notes');
    } catch (error) {
      console.error('Error saving note:', error);
    } finally {
      setIsSaving(false);
    }
  };

return (
  <>
    <Helmet>
      <title>{consultantName ? `Create Note for ${consultantName}` : 'Create Interview Note'} | fetchConsultant</title>
      <meta name="description" content={`Create a new interview note for ${consultantName || 'a candidate'} on fetchConsultant.`} />
    </Helmet>
    <div className="min-h-screen bg-gray-100 flex">
      <ClientHamburger user={user} currentPage="interviews" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
      <div className="flex-1 flex flex-col md:ml-64">
        <header className="bg-white p-4 flex justify-between items-center">
          <div className="flex items-center">
            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
              <Menu size={24} />
            </button>
            <h1 className="text-xl font-bold">{consultantName ? `Create Note for ${consultantName}` : 'Create Interview Note'}</h1>
          </div>
          <NotificationBell />
        </header>
        
        <main className="flex-grow container mx-auto px-4 py-8">
          <div className="bg-white text-left shadow rounded-lg p-6">
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">Consultant</label>
              <select 
                value={selectedConsultantId}
                onChange={(e) => setSelectedConsultantId(e.target.value)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                disabled={!!consultantId}
              >
                <option value="">Select a consultant</option>
                {consultants.map((consultant) => (
                  <option key={consultant.user_id} value={consultant.user_id}>
                    {consultant.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">Note Type</label>
              <select
                value={noteType}
                onChange={(e) => setNoteType(e.target.value)}
                className="fetch-select mt-1"
              >
                <option value="">Select Note Type</option>
                {getNoteTypes().map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>

              {noteType === 'Interview' && interviews.length > 0 && (
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">Associated Interview</label>
                  <select 
                    value={selectedInterviewId}
                    onChange={(e) => setSelectedInterviewId(e.target.value)}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    <option value="">Select an interview</option>
                    {interviews.map((interview) => (
                      <option key={interview.id} value={interview.id}>
                        {interview.user_job.job.title} - {interview.user_job.consultant.name} ({new Date(interview.interview_time).toLocaleString()})
                      </option>
                    ))}
                  </select>
                </div>
              )}

              <div className="mb-4"> {/* Increased margin-bottom */}
                <label className="block text-sm font-medium text-gray-700 mb-2">Note Content</label>
                <div className="relative" style={{ height: '300px' }}> {/* Added container with fixed height */}
                  <ReactQuill
                    theme="snow"
                    value={note}
                    onChange={setNote}
                    style={{ height: '250px' }} // Fixed height for editor
                    className="mb-4"
                  />
                </div>
              </div>

              {noteType === 'Interview' && (
                <div className="mb-6 relative z-10"> {/* Added z-index */}
                  <label className="block text-sm font-medium text-gray-700 mb-2">Overall Rating</label>
                  <div className="flex items-center">
                    {[1, 2, 3, 4, 5].map((score) => (
                      <button
                        key={score}
                        onClick={() => setFeedbackScore(score)}
                        className="mr-2 focus:outline-none"
                      >
                        <Star
                          size={24}
                          className={`${
                            feedbackScore >= score ? 'text-yellow-400' : 'text-gray-300'
                          }`}
                          fill={feedbackScore >= score ? 'currentColor' : 'none'}
                        />
                      </button>
                    ))}
                  </div>
                </div>
              )}
              
              <button
                onClick={saveNote}
                disabled={isSaving || !note.trim()}
                className="w-full bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                {isSaving ? 'Saving...' : (editNote ? 'Update' : 'Save') + ' Note'}
              </button>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ClientCreateInterviewNote;