import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'lucide-react';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
  
    return (
      <header className="sticky top-0 z-50">
        <div className="container mx-auto px-6">
          <div className={`bg-white shadow-sm px-6 py-3 ${isMenuOpen ? 'md:rounded-b-lg' : 'rounded-b-lg'}`}>
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <button
                  className="md:hidden mr-4"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                  <Menu size={24} />
                </button>
                <Link to="/" className="text-2xl font-bold text-indigo-600 hover:text-indigo-700">
                  fetchConsultant
                </Link>
              </div>
              
              {/* Mobile Menu */}
              {isMenuOpen && (
                <div className="absolute top-full left-6 right-6 bg-white shadow-lg md:hidden rounded-b-lg">
                  <div className="flex flex-col p-4 text-left">
                    <Link to="/consultants" className="py-2 px-2 rounded-lg text-gray-600 hover:text-gray-900 hover:bg-gray-100">For Consultants</Link>
                    <Link to="/clients" className="py-2 px-2 rounded-lg text-gray-600 hover:text-gray-900 hover:bg-gray-100">For Clients</Link>
                    <Link to="/blog" className="py-2 px-2 rounded-lg text-gray-600 hover:text-gray-900 hover:bg-gray-100">Blog</Link>
                  </div>
                </div>
              )}
  
              {/* Desktop Menu */}
              <div className="hidden md:flex space-x-4">
                <Link to="/consultants" className="text-gray-600 hover:text-gray-900">For Consultants</Link>
                <Link to="/clients" className="text-gray-600 hover:text-gray-900">For Clients</Link>
                <Link to="/blog" className="text-gray-600 hover:text-gray-900">Blog</Link>
              </div>
  
              <div>
                <Link to="/app" className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-full transition duration-300">
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  };
  
  export default Header;