import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronLeft, Menu } from 'lucide-react';
import React, { useRef, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ConsultantHamburger from './ConsultantHamburger';
import NotificationBell from './NotificationBell';

const ConsultantApplyTermsAndConditions = () => {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const scrollRef = useRef(null);
  const { jobId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const returnUrl = new URLSearchParams(location.search).get('returnUrl');

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Consultant Apply Terms and Conditions',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error.message);
      setError(`Error fetching user: ${error.message}`);
    }
  };

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      setIsScrolledToBottom(true);
    }
  };

  const handleAcceptTerms = async () => {
    setIsLoading(true);
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      // Fetch existing application
      const { data: existingApplication, error: fetchError } = await supabase
        .from('user_jobs')
        .select('*')
        .eq('user_id', user.id)
        .eq('job_id', jobId)
        .single();

      if (fetchError) {
        throw fetchError;
      }

      if (!existingApplication) {
        throw new Error("No existing application found");
      }

      // Update only the terms_and_conditions field
      const { error: updateError } = await supabase
        .from('user_jobs')
        .update({ terms_and_conditions: true })
        .eq('user_id', user.id)
        .eq('job_id', jobId);

      if (updateError) throw updateError;

      const analytics = getAnalytics();
      logEvent(analytics, 'terms_accepted', {
        job_id: jobId,
      });

      setIsLoading(false);
      navigate(returnUrl || '/app/consultant-dashboard');
    } catch (error) {
      setIsLoading(false);
      console.error('Error accepting terms and conditions:', error);
      setError(`Error accepting terms and conditions: ${error.message}`);
    }
  };

  // Rest of your component code...

  return (
    <>
      <Helmet>
        <title>Consultant Terms of Service | fetchConsultant</title>
        <meta name="description" content="Review and accept the consultant terms of service for applying to jobs on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ConsultantHamburger user={user} currentPage="terms" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white p-4 flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <ChevronLeft className="h-6 w-6 text-brandPrimary cursor-pointer" onClick={() => navigate(-1)} />
              <h1 className="text-xl font-bold ml-4">Consultant Terms of Service</h1>
            </div>
            <NotificationBell />
          </header>
          
          <main className="flex-grow container text-left mx-auto px-4 py-8">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <div
                ref={scrollRef}
                onScroll={handleScroll}
                className="max-h-[60vh] overflow-y-auto mb-6"
              >
              <p className="text-gray-600 mb-4">Last Updated: October 16, 2024</p>

              <h3 className="text-xl font-semibold mb-4">1. Acceptance of Consultant Terms</h3>
              <p className="text-gray-600 mb-4">
                These Consultant Terms of Service (“Consultant Terms”) are in addition to the General Terms of Service and specifically govern your use of the fetchConsultant platform (“Platform”) to apply for contracts or job opportunities. By applying to any position, you agree to these Consultant Terms. If you do not agree, you may not apply for any opportunities.
              </p>

              <h3 className="text-xl font-semibold mb-4">2. Intent to Enter Agreement</h3>
              <p className="text-gray-600 mb-4">
                <strong>Engagement:</strong> By applying for a position through the Platform, you acknowledge your intent to enter into an agreement with fetchConsultant if you are selected. This agreement governs the terms of your engagement for the services you will provide.
              </p>
              <p className="text-gray-600 mb-4">
                <strong>Agreement Type:</strong> Depending on the nature of the contract, the agreement will specify whether you are engaged as a 1099 independent contractor or through a corp-to-corp arrangement. These details will be finalized in the agreement entered into after selection.
              </p>

              <h3 className="text-xl font-semibold mb-4">3. Non-Compete</h3>
              <p className="text-gray-600 mb-4">
                <strong>No Conflicting Obligations:</strong> By applying for any position through the Platform, you represent and warrant that you are not currently bound by any non-compete, non-solicitation, or similar agreement that would preclude or limit your ability to work with fetchConsultant, the prospective client or provide the services described in the job posting.
              </p>
              <p className="text-gray-600 mb-4">
                <strong>Disclosure of Restrictions:</strong> If you become aware of any such obligations or restrictions, you agree to notify fetchConsultant immediately. You acknowledge that such restrictions may affect your eligibility for the opportunity and that fetchConsultant reserves the right to withdraw the offer or terminate any resulting agreement.
              </p>

              <h3 className="text-xl font-semibold mb-4">4. Qualifications</h3>
              <p className="text-gray-600 mb-4">
                <strong>Accuracy of Information:</strong> You represent that all information provided in your profile, application, and any other submissions through the Platform is accurate, complete, and truthful.
              </p>
              <p className="text-gray-600 mb-4">
                <strong>Qualifications:</strong> By applying to any position, you confirm that you have the necessary skills, experience, and qualifications required to perform the services described in the job posting or contract.
              </p>

              <h3 className="text-xl font-semibold mb-4">5. Fees and Payment</h3>
              <p className="text-gray-600 mb-4">
                <strong>Compensation:</strong> Upon selection for a contract, your compensation will be specified in the agreement with fetchConsultant. You agree that all payments for services rendered will be governed by the terms of that agreement.
              </p>
              <p className="text-gray-600 mb-4">
                <strong>Payment Terms:</strong> Payment will be made according to the schedule outlined in the agreement. Timely and accurate submission of deliverables and invoices may be required to ensure prompt payment.
              </p>

              <h3 className="text-xl font-semibold mb-4">6. Prohibited Actions</h3>
              <p className="text-gray-600 mb-4">
                <strong>Off-Platform Engagement:</strong> You agree that any engagement or contract that results from an application on the Platform will be conducted exclusively through the Platform. You are prohibited from engaging with clients introduced via the Platform outside of the Platform, and doing so may lead to termination of your account. fetchConsultant reserves the right to seek damages for such violations.
              </p>
              <p className="text-gray-600 mb-4">
                <strong>Software Abuse:</strong> You shall not reverse-engineer, modify, tamper with, or otherwise abuse any part of the Platform’s software or code.
              </p>

              <h3 className="text-xl font-semibold mb-4">7. Confidentiality</h3>
              <p className="text-gray-600 mb-4">
                <strong>Client Information:</strong> You agree to keep confidential any proprietary information or details regarding clients, contracts, or job opportunities obtained through the Platform. This information is to be used solely for the purpose of performing services as outlined in the contract.
              </p>
              <p className="text-gray-600 mb-4">
                <strong>Platform Confidentiality:</strong> You are also prohibited from sharing any non-public details about the Platform, including its functionalities, business processes, or software, with third parties without the explicit written consent of fetchConsultant.
              </p>

              <h3 className="text-xl font-semibold mb-4">8. Privacy</h3>
              <p className="text-gray-600 mb-4">
                <strong>Personal Information:</strong> Any personal information collected from you during your use of the Platform will be handled in accordance with fetchConsultant’s Privacy Policy. By using the Platform, you agree to the terms of this Privacy Policy.
              </p>

              <h3 className="text-xl font-semibold mb-4">9. Termination</h3>
              <p className="text-gray-600 mb-4">
                <strong>Breach of Terms:</strong> fetchConsultant reserves the right to suspend or terminate your access to the Platform if you violate these Consultant Terms or engage in behavior that is harmful to the Platform or its users. This includes, but is not limited to, any prohibited actions outlined in these Terms.
              </p>

              <h3 className="text-xl font-semibold mb-4">10. Governing Law</h3>
              <p className="text-gray-600 mb-4">
                These Consultant Terms are governed by and construed in accordance with the laws of the state of Texas, without regard to its conflict of law provisions.
              </p>

              <h3 className="text-xl font-semibold mb-4">11. Acceptance</h3>
              <p className="text-gray-600 mb-4">
                By clicking “Accept” or applying for any position on the Platform, you acknowledge that you have read, understood, and agree to be bound by these Consultant Terms. Your continued use of the Platform after any updates to these Terms constitutes acceptance of such changes.
              </p>
            </div>
            {error && <div className="text-red-500 mb-4">{error}</div>}
            <button
              onClick={handleAcceptTerms}
              disabled={!isScrolledToBottom}
              className={`fetch-button ${!isScrolledToBottom ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              Accept
            </button>
          </div>
          </main>
        </div>
      </div>
    </>
  );
};
export default ConsultantApplyTermsAndConditions;