import { useState, useEffect } from 'react';
import Modal from './ui/Modal';
import { supabase } from '../supabaseClient';
import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronDown, ChevronUp } from 'lucide-react';
import PropTypes from 'prop-types';

const AssessCandidate = ({ applicant, formattedReviews = [], formattedResponses = [], formattedInterviewNotes = [] }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [assessment, setAssessment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expandedSections, setExpandedSections] = useState({ summary: true });
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [hasAssessment, setHasAssessment] = useState(!!applicant.fetchai_score);
  const [localFetchAIScore, setLocalFetchAIScore] = useState(applicant.fetchai_score);

  const openModal = () => {
    setIsLoading(true);
    setLoadingProgress(0);

    // If we have a stored assessment (either from props or local state), use that
    if (localFetchAIScore && applicant.fetchai_assessment) {
      try {
        const parsedAssessment = JSON.parse(applicant.fetchai_assessment);
        setAssessment(parsedAssessment);
        setIsLoading(false);
      } catch (error) {
        console.error('Error parsing stored assessment:', error);
        setError('Error loading stored assessment');
        setIsLoading(false);
      }
    } else {
      // Only make the LLM call if we don't have stored data
      assessCandidate();
    }
    
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setExpandedSections({ summary: true }); // Reset expanded sections on close
  };

  const toggleSection = (sectionKey) => {
    setExpandedSections(prev => ({
      ...prev,
      [sectionKey]: !prev[sectionKey]
    }));
  };

  // Add validation at the start of the component
  useEffect(() => {
    if (!applicant) {
      console.error('AssessCandidate: No applicant data provided');
      return;
    }

    console.group('AssessCandidate Props Validation');
    console.log('Applicant data present:', !!applicant);
    console.log('Formatted reviews:', formattedReviews);
    console.log('Formatted responses:', formattedResponses);
    console.log('Formatted interview notes:', formattedInterviewNotes);
    console.groupEnd();
  }, [applicant, formattedReviews, formattedResponses, formattedInterviewNotes]);

  const assessCandidate = async () => {
    let intervalId;
    try {
      console.group('Assess Candidate Process');
      console.log('Starting assessment for:', applicant.users?.name);
      
      const analytics = getAnalytics();
      logEvent(analytics, 'assess_candidate', {
        user_job_id: applicant.user_job_id,
      });

      setLoadingProgress(20);

      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        console.error('No active session found');
        throw new Error("No active session");
      }

      setLoadingProgress(40);

      // Increment loading progress by 1% every 2 seconds
      const intervalId = setInterval(() => {
        setLoadingProgress((prevProgress) => {
          if (prevProgress < 80) {
            return prevProgress + 1;
          } else {
            clearInterval(intervalId);
            return prevProgress;
          }
        });
      }, 2000);
      
      // Prepare request body with all formatted data
      const requestBody = {
        applicantData: {
          name: String(applicant.users?.name || ''),
          formerEpic: Boolean(applicant.users?.former_epic),
          jobTitle: String(applicant.job?.title || ''),
          jobDescription: String(applicant.job?.description || ''),
          about: String(applicant.users?.about || ''),
          consultantNotes: String(applicant.consultant_notes || ''),
          city: String(applicant.users?.city || ''),
          state: String(applicant.users?.state || ''),
          consultant_rate: Number(applicant.consultant_rate || 0),
          current_project_end_date: applicant.users?.current_project_end_date || null,
          available_start_date: applicant.available_start_date || null,
          epic_certifications: Array.isArray(applicant.users?.user_epic_certifications) 
            ? applicant.users.user_epic_certifications.map(cert => cert.epic_applications.name)
            : [],
          average_rating: Number(applicant.users?.average_rating || 0),
          average_peer_rating: Number(applicant.users?.average_peer_rating || 0),
          average_manager_rating: Number(applicant.users?.average_manager_rating || 0),
          workHistory: applicant.work_history?.map(job => ({
            type: job.type,
            job_title: job.job_title,
            company_name: job.company_name,
            start_date: job.start_date,
            end_date: job.end_date,
            description: job.description,
            skills_used: job.skills_used,
            reviews: job.reviews?.map(review => ({
              rating: review.rating,
              review_text: review.review_text,
              review_type: review.review_type,
              verification_status: review.verification_status
            }))
          })) || [],
          education: applicant.users?.education || [],
          skills: applicant.users?.skills || [],
          additional_certifications: applicant.users?.additional_certifications || [],
          formattedReviews: formattedReviews,
          formattedResponses: formattedResponses,
          formattedInterviewNotes: formattedInterviewNotes,
          video_responses: applicant.video_responses || {},
          response_type: String(applicant.response_type || 'text'),
          job: applicant.job || {}
        },
        userJobId: String(applicant.user_job_id)
      };

      // Add debug logging
      console.group('Assessment Data Check');
      console.log('Work History Records:', applicant.work_history?.length || 0);
      console.log('Full Work History Data:', applicant.work_history);
      console.log('Full Request Body:', JSON.stringify(requestBody, null, 2));
      console.groupEnd();

      const response = await supabase.functions.invoke('llm-assess-candidate', {
        body: requestBody,
      });

      console.log('Edge Function Response:', {
        data: response.data,
        error: response.error,
        status: response.status,
        statusText: response.statusText
      });
  
      // Check for non-200 status codes
      if (response.status && response.status !== 200) {
        throw new Error(`Edge Function returned status ${response.status}: ${response.statusText}`);
      }
  
      // Check for error property
      if (response.error) {
        console.error('Edge Function error details:', {
          error: response.error,
          status: response.status,
          statusText: response.statusText,
          body: response.data
        });
        throw new Error(`Edge Function error: ${response.error.message || 'Unknown error'}`);
      }

      setLoadingProgress(80);
      
      if (!response.data) {
        throw new Error('No data received from Edge Function');
      }
      
      const result = response.data;
      console.log('Processing assessment result:', result);
      
      setAssessment(result);
      setLoadingProgress(100);
      setLocalFetchAIScore(result.score);
      setHasAssessment(true);

      console.log('Assessment completed successfully');
      console.groupEnd();

    } catch (error) {
      console.error('Detailed error:', error);
      setError(error.message || 'Failed to assess candidate');
      setLoadingProgress(0);
    } finally {
      setIsLoading(false);
      console.groupEnd();
      clearInterval(intervalId);//how do i fix this? 
    }
  };

  const renderSection = (sectionKey, section) => {
    const isExpanded = expandedSections[sectionKey];
    return (
      <div key={sectionKey} className="border-b last:border-b-0">
        <button
          onClick={() => toggleSection(sectionKey)}
          className="w-full flex justify-between items-center py-3 px-4 hover:bg-gray-50 focus:outline-none"
        >
          <h3 className="text-lg font-medium text-gray-900">{section.title}</h3>
          {isExpanded ? <ChevronUp className="h-5 w-5 text-gray-500" /> : <ChevronDown className="h-5 w-5 text-gray-500" />}
        </button>
        
        {isExpanded && (
          <div className="px-4 pb-4">
            <p className="text-gray-700 whitespace-pre-wrap">{section.content}</p>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <button
        onClick={openModal}
        className="inline-flex items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
      >
        {localFetchAIScore ? "View fetchAI Assessment" : "Assess with fetchAI"}
      </button>

      <Modal 
        isOpen={isModalOpen} 
        onClose={closeModal} 
        title="fetchAI Candidate Assessment"
      >
        <div className="p-4">
          {isLoading ? (
            <div className="space-y-4">
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div 
                  className="bg-blue-600 h-2.5 rounded-full transition-all duration-500"
                  style={{ width: `${loadingProgress}%` }}
                ></div>
              </div>
              <p className="text-center text-gray-600">
                Analyzing candidate information...
              </p>
            </div>
          ) : error ? (
            <div className="text-red-500">{error}</div>
          ) : assessment ? (
            <div className="space-y-4">
              <div className="bg-gray-50 p-4 rounded-lg flex items-center justify-between">
                <span className="text-lg font-medium">Overall Score:</span>
                <span className="text-3xl font-bold text-blue-600">
                  {assessment.score}/10
                </span>
              </div>

              <div className="divide-y divide-gray-200">
                {Object.entries(assessment.sections).map(([key, section]) => 
                  renderSection(key, section)
                )}
              </div>
            </div>
          ) : (
            <p>No assessment data available.</p>
          )}
          
          <div className="mt-6 flex justify-end">
            <button
              onClick={closeModal}
              className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500"
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

AssessCandidate.propTypes = {
  applicant: PropTypes.shape({
    users: PropTypes.shape({
      name: PropTypes.string,
      former_epic: PropTypes.bool,
      about: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      user_epic_certifications: PropTypes.array,
      average_rating: PropTypes.number,
      average_peer_rating: PropTypes.number,
      average_manager_rating: PropTypes.number,
      current_project_end_date: PropTypes.string,
      education: PropTypes.array,
      skills: PropTypes.array,
      additional_certifications: PropTypes.array
    }),
    job: PropTypes.object,
    user_job_id: PropTypes.string.isRequired,
    consultant_notes: PropTypes.string,
    consultant_rate: PropTypes.number,
    available_start_date: PropTypes.string,
    video_responses: PropTypes.object,
    response_type: PropTypes.string,
    fetchai_score: PropTypes.number,
    fetchai_assessment: PropTypes.string,
    work_history: PropTypes.array
  }).isRequired,
  formattedReviews: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    rating: PropTypes.number,
    text: PropTypes.string,
    reviewer: PropTypes.string,
    date: PropTypes.string
  })),
  formattedResponses: PropTypes.arrayOf(PropTypes.shape({
    question: PropTypes.string,
    response: PropTypes.string,
    type: PropTypes.string
  })),
  formattedInterviewNotes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    content: PropTypes.string,
    feedbackScore: PropTypes.number,
    writer: PropTypes.string,
    date: PropTypes.string,
    weight: PropTypes.number
  }))
};

export default AssessCandidate;