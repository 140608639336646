import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronLeft, Info, Upload } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import imageCompression from 'browser-image-compression';
import Avatar from './ui/Avatar';

const ConsultantOnboardingPersonal = () => {
  const [formData, setFormData] = useState({
    name: '',
    about: '',
    phone: '',
    city: '',
    state: '',
  });
  const [photo, setPhoto] = useState(null);
  const [photoName, setPhotoName] = useState('');
  const [formValid, setFormValid] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isEditMode = location.state?.isEditMode || false;
  const [showTooltip, setShowTooltip] = useState(false);
  const [states, setStates] = useState([]);


  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: isEditMode ? 'Edit Profile - Personal' : 'Consultant Onboarding - Personal',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUserData();
  }, [isEditMode]);

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const { data, error } = await supabase.rpc('get_enum_values', {
          enum_name: 'state_enum',
        });
        if (error) {
          console.error('Error fetching state enums:', error.message);
        } else {
          setStates(data || []); // `data` is an array of state abbreviations
        }
      } catch (err) {
        console.error('Error:', err.message);
      }
    };
  
    fetchStates();
  }, []);

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const fetchUserData = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('name, about, phone, city, state, photo')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;

      setFormData({
        name: data.name || '',
        about: data.about || '',
        phone: formatPhoneNumber(data.phone ? data.phone.toString() : '') || '',
        city: data.city || '',
        state: data.state || '',
      });
      setPhoto(data.photo || null);
      setPhotoName(data.photo ? data.photo.split('/').pop() : '');
    } catch (error) {
      console.error('Error fetching user data:', error.message);
    }
  };
  
  useEffect(() => {
    // Ensure that all formData fields are strings before trimming
    const isFormValid = (formData.name || '').trim() !== '' &&
                        String(formData.phone || '').trim() !== '' &&
                        (formData.city || '').trim() !== '' &&
                        (formData.state || '').trim() !== '';
  
    setFormValid(isFormValid);
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === 'phone') {
      const formattedPhone = formatPhoneNumber(value);
      setFormData(prevData => ({
        ...prevData,
        [name]: formattedPhone
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };

  const unformatPhoneNumber = (value) => {
    return value.replace(/\D/g, '');
  };

  const handlePhotoUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
  
    try {
      // File validation
      if (!['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
        throw new Error('Please upload a valid image file (JPEG, PNG, or GIF)');
      }
      if (file.size > 10 * 1024 * 1024) {
        throw new Error('File size should not exceed 10MB');
      }
  
      // Compression options for lossless compression
      const options = {
        maxSizeMB: 1, // Max file size in MB
        maxWidthOrHeight: 1920, // Max width/height while maintaining aspect ratio
        useWebWorker: true, // Use web worker for better performance
        initialQuality: 1.0, // Start with highest quality
        alwaysKeepResolution: true, // Maintain resolution
        fileType: file.type // Maintain original file type
      };
  
      const compressedFile = await imageCompression(file, options);
      
      // Get user and format file path
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("Not authenticated");
  
      const fileExt = file.name.split('.').pop();
      const fileName = `${Math.random()}.${fileExt}`;
      const filePath = `${user.id}/${fileName}`;
  
      // Upload to Supabase
      const { error: uploadError } = await supabase.storage
        .from('photos')
        .upload(filePath, compressedFile);
  
      if (uploadError) throw uploadError;
  
      // Set the photo path and name in state
      setPhoto(filePath);
      setPhotoName(fileName);
  
    } catch (error) {
      console.error('Photo upload error:', error);
      alert('Failed to upload photo: ' + error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");
  
      const phoneNumber = unformatPhoneNumber(formData.phone);
      const phoneNumberAsNumber = phoneNumber ? parseInt(phoneNumber, 10) : null;
  
      const { error } = await supabase.rpc('update_user_profile', {
        p_user_id: user.id,
        p_name: formData.name,
        p_about: formData.about,
        p_phone: phoneNumberAsNumber,
        p_city: formData.city,
        p_state: formData.state,
        p_photo: photo // Use the photo path from state
      });
  
      if (error) throw error;
  
      if (isEditMode) {
        navigate('/app/consultant-edit-profile-professional', { state: { isEditMode: true } });
      } else {
        navigate('/app/consultant-onboarding-professional');
      }
    } catch (error) {
      console.error('Error updating user info:', error.message);
      alert('Failed to update profile: ' + error.message);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex justify-center items-center">
    <Helmet>
      <title>{isEditMode ? 'Edit Profile - Personal Information' : 'Consultant Onboarding - Personal Information'} | fetchConsultant</title>
      <meta name="description" content={isEditMode ? "Update your personal profile as a healthcare IT consultant on fetchConsultant." : "Complete your personal profile as a healthcare IT consultant on fetchConsultant. Connect with top healthcare organizations and find exciting Epic consulting opportunities."} />
      <meta name="description" content="Complete your personal profile as a healthcare IT consultant on fetchConsultant. Connect with top healthcare organizations and find exciting Epic consulting opportunities." />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="robots" content="index, follow" />
      <meta property="og:title" content="Consultant Onboarding - Personal Information | fetchConsultant" />
      <meta property="og:description" content="Complete your personal profile as a healthcare IT consultant on fetchConsultant. Connect with top healthcare organizations and find exciting Epic consulting opportunities." />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://fetchconsultant.com/app/consultant-onboarding-personal" />
      <meta property="og:image" content="https://fetchconsultant.com/app/path-to-image.jpg" />
    </Helmet>
      {/* Split screen */}
      <div className="flex flex-col md:flex-row w-full max-w-7xl shadow-lg">
  
        {/* Left section */}
        <div className="bg-white p-4 sm:p-8 w-full md:w-1/2 flex flex-col justify-start sm:justify-center items-center min-h-screen sm:min-h-0 overflow-y-auto">
          <header className="fetch-header w-full">
            <ChevronLeft className="fetch-back-button" onClick={() => navigate(-1)} />
            <h1 className="fetch-page-title">{isEditMode ? 'Edit Profile' : 'Consultant Onboarding'}</h1>
            </header>
              <main className="fetch-container w-full">
                <div className="fetch-card">
                  {!isEditMode && (
                    <div className="mb-6 text-center">
                      <p className="fetch-secondary-text">Step 1 of 2</p>
                      <div className="fetch-progress-bar">
                        <div className="fetch-progress-bar-fill" style={{ width: '50%' }}></div>
                      </div>
                    </div>
                  )}
                  <form onSubmit={handleSubmit} className="fetch-form">
                    <div>
                      <label htmlFor="name" className="fetch-label flex items-center relative group">Name<span className="fetch-required">*</span>
                        <Info
                          className="ml-2 text-blue-500 cursor-pointer"
                          size={18}
                          onMouseEnter={() => setShowTooltip(true)}
                          onMouseLeave={() => setShowTooltip(false)}
                          onClick={() => setShowTooltip(!showTooltip)}
                        />
                        {showTooltip && (
                          <div className="tooltip">
                            Your profile is strictly confidential. Limited info is only shared when applying for a position.
                          </div>
                        )}
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                        className="fetch-input"
                      />
                    </div>
                    <div>
                      <label htmlFor="about" className="fetch-label flex items-center relative group">About
                        <Info
                            className="ml-2 text-blue-500 cursor-pointer"
                            size={18}
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                            onClick={() => setShowTooltip(!showTooltip)}
                          />
                          {showTooltip && (
                            <div className="tooltip">
                              This will be shared when you apply for a position. Keep this high-level as you will have a place to share position-specific qualities when applying. 
                            </div>
                          )}
                      </label>
                      <textarea
                        id="about"
                        name="about"
                        value={formData.about}
                        onChange={handleInputChange}
                        rows="4"
                        className="fetch-textarea"
                      ></textarea>
                    </div>
                    <div>
                      <label htmlFor="photo" className="fetch-label flex items-center relative group">
                        {photoName ? 'Update Photo' : 'Upload Photo'}
                        <Info
                            className="ml-2 text-blue-500 cursor-pointer"
                            size={18}
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                            onClick={() => setShowTooltip(!showTooltip)}
                          />
                          {showTooltip && (
                            <div className="tooltip">
                              Your photo is shared when you apply for a position or when chatting with other users.
                            </div>
                          )}
                      </label>
                      <div className="mt-1 flex flex-col items-start">
                        <input
                          type="file"
                          id="photo"
                          accept="image/*"
                          onChange={handlePhotoUpload}
                          className="hidden"
                        />
                        <label
                          htmlFor="photo"
                          className="file-input-button"
                        >
                          <Upload className="w-5 h-5 mr-2" />
                          {photoName ? 'Update Photo' : 'Upload Photo'}
                        </label>
                        {photoName && (
                          <div className="mt-2">
                            <Avatar 
                              className="h-16 w-16" 
                              photo={photo} 
                              name={formData.name}
                              clickable={true}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <label htmlFor="phone" className="fetch-label flex items-center relative group">Phone Number<span className="fetch-required">*</span>
                        <Info
                            className="ml-2 text-blue-500 cursor-pointer"
                            size={18}
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                            onClick={() => setShowTooltip(!showTooltip)}
                          />
                          {showTooltip && (
                            <div className="tooltip">
                              Your phone number is only shared with the client once you accept a contract. Otherwise, its use is limited to optional text notifications.
                            </div>
                          )}
                      </label>
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        placeholder="(###) ###-####"
                        maxLength="14"
                        required
                        className="fetch-input"
                      />
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label htmlFor="city" className="fetch-label flex items-center relative group">City<span className="fetch-required">*</span>
                          <Info
                            className="ml-2 text-blue-500 cursor-pointer"
                            size={18}
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                            onClick={() => setShowTooltip(!showTooltip)}
                          />
                          {showTooltip && (
                            <div className="tooltip">
                              City and state are shared when applying for a position.
                            </div>
                          )}
                        </label>
                        <input
                          type="text"
                          id="city"
                          name="city"
                          value={formData.city}
                          onChange={handleInputChange}
                          required
                          className="fetch-input"
                        />
                      </div>
                      <div>
                        <label htmlFor="state" className="fetch-label">State<span className="fetch-required">*</span></label>
                        <select
                          id="state"
                          name="state"
                          value={formData.state}
                          onChange={handleInputChange}
                          required
                          className="fetch-select"
                        >
                          <option value="">Select State</option>
                          {states.map((state) => (
                            <option key={state} value={state}>
                              {state}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className={`fetch-button ${!formValid ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={!formValid}
                      >
                        Next
                      </button>
                    </div>
                  </form>
                </div>
              </main>
            </div>
            {/* Right section */}
            <div className="bg-brandPrimary text-white p-10 w-full md:w-1/2 flex flex-col justify-between overflow-y-auto">
              <div className="flex flex-col mt-2 justify-center">
                <h2 className="text-center text-3xl mt-2 font-extrabold">Why fetchConsultant</h2>
              </div>
              <div className="flex flex-col justify-center flex-grow p-8 pt-16"> {/* Add padding to account for the absolute title */}
                <div className="flex justify-center">
                  <ul className="space-y-6 flex flex-col">
                      <li>
                        <div className="flex items-start">
                          <svg className="h-6 w-6 mr-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-.01 2a5 5 0 10-4-2.9 4.978 4.978 0 003.5-1.1 5.024 5.024 0 002.5 1.4 5 5 0 104.5-6.9A5 5 0 0012 18z" />
                          </svg>
                          <h3 className="text-lg text-left font-semibold">Maximize Your Earnings</h3>
                        </div>
                        <p className="text-left ml-10">Keep more of what you earn with our flat 4.5% platform fee (per party) and 1099 status for enhanced 401(k) savings.</p>
                      </li>
                      <li>
                        <div className="flex items-start mt-2">
                          <svg className="h-6 w-6 mr-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                          </svg>
                          <h3 className="text-lg text-left font-semibold">Instant Job Alerts</h3>
                        </div>
                        <p className="text-left ml-10">Receive real-time notifications for jobs matching your skills and preferences.</p>
                      </li>
                      <li>
                        <div className="flex items-start mt-2">
                          <svg className="h-6 w-6 mr-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12v4m0-4v-2a4 4 0 00-8 0v2a2 2 0 100 4v1m0-5v-2a4 4 0 018 0v2" />
                          </svg>
                          <h3 className="text-lg text-left font-semibold">No More Spam</h3>
                        </div>
                        <p className="text-left ml-10">Your information is private on fetchConsultant and only shared with a client when you apply.</p>
                      </li>
                      <li>
                        <div className="flex items-start mt-2">
                          <svg className="h-6 w-6 mr-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12v4m0-4v-2a4 4 0 00-8 0v2a2 2 0 100 4v1m0-5v-2a4 4 0 018 0v2" />
                          </svg>
                          <h3 className="text-lg text-left font-semibold">Seamless Interview Process</h3>
                        </div>
                        <p className="text-left ml-10">Integrated Zoom meetings and scheduling tools connect you with potential clients quickly.</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      };

    export default ConsultantOnboardingPersonal;