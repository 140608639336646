// src/components/AuthCallback.js

import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { createUserRecord, getUserType } from '../supabaseAuth.js';
import { supabase } from '../supabaseClient.js';
import { getAnalytics, logEvent } from "firebase/analytics";

const AuthCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Auth Callback',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    const handleAuthCallback = async () => {
      console.log('Starting auth callback');
      const { email, referralToken } = location.state || {};
      console.log('Email from location state:', email);
      
      const { data: { session }, error } = await supabase.auth.getSession();
    
      if (error) {
        console.error('Error getting session:', error.message);
        navigate('/app/auth');
        return;
      }
    
      if (session && session.user) {
        try {
          let userTypeId = await getUserType(session.user.id);
          console.log('Current userTypeId:', userTypeId);
    
          if (!userTypeId) {
            console.log('New user - creating user record');
            const referralTokenFromStorage = localStorage.getItem('pending_referral_token');
            const finalReferralToken = referralToken || referralTokenFromStorage;
            console.log('Retrieved referral token for new user:', finalReferralToken);
    
            await createUserRecord(session.user.id, email || session.user.email, finalReferralToken);
            console.log('User record created, clearing referral token');
    
            if (finalReferralToken) {
              localStorage.removeItem('pending_referral_token');
              console.log('Referral token cleared from localStorage');
            }
    
            if (session.user.email_confirmed_at) {
              const response = await fetch(`${process.env.REACT_APP_SUPABASE_URL}/functions/v1/user-operations`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${session.access_token}`
                },
                body: JSON.stringify({
                  action: 'updateUser',
                  userId: session.user.id,
                  data: {
                    user_type_id: 1,
                    email_verified_at: session.user.email_confirmed_at
                  }
                })
              });
    
              if (!response.ok) {
                throw new Error('Failed to update user type');
              }
            }
    
            userTypeId = await getUserType(session.user.id);
          }

          // Store email in sessionStorage for ConsultantOnboardingPersonal
          if (email) {
            sessionStorage.setItem('onboarding_email', email);
          }

          // Route based on user type with email in state
          if (userTypeId === 0 && !session.user.email_confirmed_at) {
            navigate('/app/verify-email', { state: { email } });
          } else {
            switch (userTypeId) {
              case 0:
                navigate('/app/verify-email', { state: { email } });
                break;
              case 1:
                navigate('/app/client-or-consultant', { state: { email } });
                break;
              case 2:
                navigate('/app/consultant-dashboard', { state: { email } });
                break;
              case 3:
                navigate('/app/client-onboarding-personal', { state: { email } });
                break;
              case 4:
                navigate('/app/client-dashboard', { state: { email } });
                break;
              case 5:
                navigate('/app/system-admin-dashboard', { state: { email } });
                break;
              default:
                navigate('/app/client-or-consultant', { state: { email } });
            }
          }
        } catch (error) {
          console.error('Error handling auth callback:', error.message);
          navigate('/app/auth');
        }
      } else {
        navigate('/app/auth');
      }
    };

    handleAuthCallback();
  }, [navigate, location]);

  return <div className="flex items-center justify-center min-h-screen">
    <div className="text-center">
      <h1 className="text-xl font-semibold mb-2">Loading</h1>
    </div>
  </div>;
};

export default AuthCallback;