import React from 'react';
import { Link } from 'react-router-dom';
import { Clock } from 'lucide-react';
import PropTypes from 'prop-types';
import Avatar from '../ui/Avatar';

const BlogCard = ({ post, featured = false }) => {
  return (
    <Link 
      to={`/blog/${post.slug}`}
      className="block transition-shadow duration-300 hover:shadow-lg h-full"
    >
      <article 
        className={`bg-white overflow-hidden flex flex-col h-[450px]
          ${featured ? 'border-2 border-indigo-500' : ''}`}
      >
        {/* Image */}
        <div className="h-[200px]">
          <img
            src={post.image_url || '/images/blog/default.jpg'}
            alt={post.title}
            className="w-full h-full object-cover rounded-lg"
          />
          {featured && (
            <div className="absolute top-4 right-4">
              <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-indigo-100 text-indigo-800">
                Featured
              </span>
            </div>
          )}
        </div>

        {/* Content */}
        <div className="h-[200px] p-0 mt-5 flex flex-col">
          {/* Reading Time */}
          <div className="flex items-center text-sm text-gray-500 mb-2">
            <Clock className="h-4 w-4 mr-1" />
            {post.reading_time || '5 min read'}
          </div>

          {/* Title and Description Container */}
          <div className="min-h-0 flex-1">
            <h3 className="text-xl font-semibold text-gray-900 group-hover:text-indigo-600 line-clamp-2">
              {post.title}
            </h3>
            <p className={`mt-2 text-base text-gray-500 ${
              post.title.length > 50 ? 'line-clamp-1' : 'line-clamp-2'
            }`}>
              {post.description}
            </p>
          </div>

          {/* Meta information - fixed at bottom */}
          <div className="pt-4 border-gray-100">
            <div className="flex items-center">
              <Avatar
                photo={post.author_photo}
                name={post.author_name}
                className="h-10 w-10"
              />
              <div className="ml-3">
                <p className="text-xs text-gray-500">Written by</p>
                <p className="text-sm font-medium text-gray-900">
                  {post.author_name || 'Anonymous'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </Link>
  );
};

BlogCard.propTypes = {
  post: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    author_name: PropTypes.string,
    author_photo: PropTypes.string,
    published_at: PropTypes.string,
    reading_time: PropTypes.string,
    image_url: PropTypes.string,
  }).isRequired,
  featured: PropTypes.bool
};

export default BlogCard;