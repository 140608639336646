import { getAnalytics, logEvent } from "firebase/analytics";
import { Menu, MoreHorizontal } from 'lucide-react';
import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { supabase } from '../supabaseClient';
import ClientHamburger from './ClientHamburger';
import NotificationBell from './NotificationBell';
import CalendarLink from "./ui/CalendarLink.jsx";

const ClientInterviews = () => {
  const [interviews, setInterviews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'interview_time', direction: 'asc' });
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  // Mobile screen size detection
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Close dropdown on clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [dropdownRef]);

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Client Interviews',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
    fetchUserAndInterviews();
  }, [sortConfig]);

  const fetchUserAndInterviews = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      setUser(user);
      await fetchInterviews(user.id);
    } catch (error) {
      console.error('Error fetching user and interviews:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchInterviews = async (userId) => {
    try {
      const { data, error } = await supabase
      .from('interviews')
      .select(`
        id,
        interview_time,
        is_second_interview,
        zoom_meeting_link,
        ics_data,
        user_job:user_jobs!inner (
          user_job_id,  
          user_id,
          job:jobs!inner (
            title,
            client:clients!inner (name),
            created_by
          ),
          consultant:users!user_jobs_user_id_fkey!inner (name)
        ),
        interview_notes (id)
      `)
      .eq('user_job.job.created_by', userId)
      .order(sortConfig.key, { ascending: sortConfig.direction === 'asc' });
  
      if (error) throw error;
  
      // Filter out any interviews with incomplete data
      const validInterviews = data.filter(interview => 
        interview.user_job && 
        interview.user_job.job && 
        interview.user_job.job.client &&
        interview.user_job.consultant
      );
  
      if (validInterviews.length !== data.length) {
        console.warn(`Filtered out ${data.length - validInterviews.length} interviews due to incomplete data`);
      }
  
      setInterviews(validInterviews);
    } catch (error) {
      console.error('Error fetching interviews:', error.message);
    }
  };

  const handleOfferContract = async (userJobId) => {
    try {
      if (!userJobId) {
        throw new Error("User job ID is undefined");
      }
      const { error: updateError } = await supabase
        .from('user_jobs')
        .update({ status: 'Contract Offered' })
        .eq('user_job_id', userJobId);
  
      if (updateError) throw updateError;
  
      const { error: insertError } = await supabase
        .from('engagements')
        .insert({
          user_job_id: userJobId,
          status: 'Pending Acceptance',
        });
  
      if (insertError) throw insertError;
  
      fetchInterviews(user.id); // Pass the user ID
      alert('Contract has been offered to the consultant.');
    } catch (error) {
      console.error('Error offering contract:', error.message);
      alert('Failed to offer contract. Please try again.');
    }
  };
  
  const handleRejectApplicant = async (userJobId) => {
    try {
      if (!userJobId) {
        throw new Error("User job ID is undefined");
      }
      const { error } = await supabase
        .from('user_jobs')
        .update({ status: 'Rejected' })
        .eq('user_job_id', userJobId);
  
      if (error) throw error;
  
      fetchInterviews(user.id); // Pass the user ID
      alert('Applicant has been rejected.');
    } catch (error) {
      console.error('Error rejecting applicant:', error.message);
      alert('Failed to reject applicant. Please try again.');
    }
  };

  const handleViewNotes = (interview) => {
    navigate('/app/client-interview-notes', { 
      state: { 
        consultantId: interview.user_job.consultant.user_id,
        consultantName: interview.user_job.consultant.name
      } 
    });
  };

  const handleSort = (key) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });
  };

  const toggleDropdown = (interviewId) => {
    setActiveDropdown(activeDropdown === interviewId ? null : interviewId);
  };

  const renderInterviewsTable = () => (
    <>
      <div className="bg-white shadow-md rounded-lg">
        <table className="min-w-full table-auto">
          {/* Table Header */}
          <thead className="bg-gray-100">
            <tr>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-700 w-1/5">
                <div onClick={() => handleSort('user_job.job.title')} className="cursor-pointer">
                  Job Title {sortConfig.key === 'user_job.job.title' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                </div>
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-700 w-1/5">
                <div onClick={() => handleSort('interview_time')} className="cursor-pointer">
                  Date {sortConfig.key === 'interview_time' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                </div>
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-700 w-1/5">
                <div onClick={() => handleSort('user_job.consultant.name')} className="cursor-pointer">
                  Consultant {sortConfig.key === 'user_job.consultant.name' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                </div>
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-700 w-1/5">
                <div onClick={() => handleSort('is_second_interview')} className="cursor-pointer">
                  Round {sortConfig.key === 'is_second_interview' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                </div>
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-700 w-1/5">
                Zoom
              </th>
              <th className="px-6 py-3"></th>
            </tr>
          </thead>

          {/* Table Body */}
          <tbody>
            {interviews.map((interview) => (
              <tr key={interview.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 text-sm text-left text-gray-700 w-1/5">
                  {interview.user_job.job.title}
                </td>
                <td className="px-6 py-4 text-sm text-left text-gray-700 w-1/5">
                  {new Date(interview.interview_time).toLocaleString([], {
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true, // For 24-hour format (set to true for 12-hour format)
                  })}
                </td>
                <td className="px-6 py-4 text-sm text-left text-gray-700 w-1/5">
                  {interview.user_job.consultant.name}
                </td>
                <td className="px-6 py-4 text-sm text-left text-gray-700 w-1/5">
                  {interview.is_second_interview ? 'Second Interview' : 'First Interview'}
                </td>
                <td className="px-6 py-4 text-sm text-left text-gray-700 w-1/5">
                  <a 
                    href={interview.zoom_meeting_link} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="fetch-button"
                  >
                    Join
                  </a>
                </td>

                {/* MoreHorizontal Menu */}
                <td className="px-6 py-4 text-left w-1/5 relative">
                  <MoreHorizontal
                    size={24}
                    className="cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleDropdown(interview.id);
                    }}
                  />
                  {activeDropdown === interview.id && (
                    <div
                      ref={dropdownRef}
                      className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10 cursor-pointer"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={() => navigate(`/app/applicant/${interview.user_job.user_job_id}`)}
                      >
                        🧑‍💼  Applicant Details
                      </div>
                      {/* View Notes */}
                      <div
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={() => handleViewNotes(interview)}
                      >
                        📝  View Notes
                      </div>
                      <CalendarLink icsData={interview.ics_data} />
                      {/* Request Second Interview */}
                      {!interview.is_second_interview && new Date(interview.interview_time) <= new Date() && (
                        <div
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={() => navigate(`/app/request-second-interview/${interview.user_job.user_job_id}`)}
                        >
                          🔄  Request Second Interview
                        </div>
                      )}
                      {/* Offer Contract */}
                        {new Date(interview.interview_time) <= new Date() && (
                          <>
                            <div
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              onClick={() => navigate('/app/client-offer-contract/' + interview.user_job.user_job_id)}
                            >
                              📄  Offer Contract
                            </div>

                            {/* Reject Applicant */}
                            <div
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              onClick={() => handleRejectApplicant(interview.user_job.user_job_id)}
                            >
                              ❌  Reject Applicant
                            </div>
                          </>
                        )}
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );

  const renderInterviewsCards = () => (
    <div className="grid grid-cols-1 gap-4">
      {interviews.map((interview) => (
        <div 
          key={interview.id} 
          className="bg-white text-left shadow-md rounded-lg p-4 relative hover:shadow-lg transition-shadow cursor-pointer"
        >
          <div className="mb-2">
            <h2 className="text-xl font-bold text-gray-900">{interview.user_job.job.title}</h2>
          </div>
          <div className="text-sm text-gray-500 mb-2">
            <p>Date: {new Date(interview.interview_time).toLocaleString([], {
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true, // Set to true for 12-hour format
            })}</p>
            <p>Consultant: {interview.user_job.consultant.name}</p>
            <p>Status: {interview.is_second_interview ? 'Second Interview' : 'First Interview'}</p>
          </div>

          {/* Join Button */}
          <div className="mt-4">
            <a 
              href={interview.zoom_meeting_link} 
              target="_blank" 
              rel="noopener noreferrer"
              className="inline-block px-4 py-2 bg-brandPrimary text-white rounded-lg hover:bg-brandDark transition-colors"
            >
              Join
            </a>
          </div>

          {/* MoreHorizontal Menu at the bottom-right */}
          <div className="absolute bottom-4 right-4">
            <MoreHorizontal 
              size={24} 
              className="cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                toggleDropdown(interview.id);
              }}
            />
            {activeDropdown === interview.id && (
              <div
                ref={dropdownRef}
                className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10 cursor-pointer"
                onClick={(e) => e.stopPropagation()}
              >
                <div
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  onClick={() => navigate(`/app/applicant/${interview.user_job.user_job_id}`)}
                >
                  🧑‍💼  Applicant Details
                </div>
                {/* View Notes */}
                <div
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  onClick={() => handleViewNotes(interview)}
                >
                  📝  View Notes
                </div>
                <CalendarLink icsData={interview.ics_data} />
                {/* Offer Contract */}
                {new Date(interview.interview_time) <= new Date() && (
                    <>
                      <div
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={() => navigate('/app/client-offer-contract/' + interview.user_job.user_job_id)}
                        >
                        📄  Offer Contract
                      </div>

                      {/* Reject Applicant */}
                      <div
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={() => handleRejectApplicant(interview.user_job.user_job_id)}
                      >
                        ❌  Reject Applicant
                      </div>
                    </>
                  )}

                {/* Request Second Interview */}
                {!interview.is_second_interview && new Date(interview.interview_time) <= new Date() && (
                  <div
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    onClick={() => navigate(`/app/request-second-interview/${interview.user_job.user_job_id}`)}
                  >
                    🔄  Request Second Interview
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <>
      <Helmet>
        <title>Interviews | fetchConsultant</title>
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ClientHamburger user={user} currentPage="interviews" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold ml-4">Interviews</h1>
            </div>
            <NotificationBell />
          </header>

          <main className="p-4">
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              <>
                {/* Conditional rendering for mobile (cards) vs non-mobile (table) */}
                {isMobile ? renderInterviewsCards() : renderInterviewsTable()}
              </>
            )}
          </main>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ClientInterviews;