import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronLeft, Menu, MoreHorizontal, Video } from 'lucide-react';
import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import PullToRefresh from 'react-pull-to-refresh';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { supabase } from '../supabaseClient';
import ConsultantHamburger from './ConsultantHamburger';
import NotificationBell from "./NotificationBell";
import Spinner from './Spinner';
import CalendarLink from "./ui/CalendarLink.jsx";

const ConsultantInterviews = () => {
  const [interviews, setInterviews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'interview_time', direction: 'asc' });
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [dropdownRef]);

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Consultant Interviews',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
    fetchUserAndInterviews();
  }, [sortConfig]);

  const fetchUserAndInterviews = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      setUser(user);
      await fetchInterviews(user.id);
    } catch (error) {
      console.error('Error fetching user and interviews:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchInterviews = async (userId) => {
    try {
      const { data, error } = await supabase
        .from('interviews')
        .select(`
          id,
          interview_time,
          is_second_interview,
          zoom_meeting_link,
          ics_data,
          user_job:user_jobs!inner (
            user_id,
            job_id,
            job:jobs!inner (
              title,
              client:clients!inner (name)
            )
          )
        `)
        .eq('user_job.user_id', userId)
        .order(sortConfig.key, { ascending: sortConfig.direction === 'asc' });
  
      if (error) throw error;
  
      // Filter out any interviews with incomplete data
      const validInterviews = data.filter(interview => 
        interview.user_job && 
        interview.user_job.job && 
        interview.user_job.job.client
      );
  
      if (validInterviews.length !== data.length) {
        console.warn(`Filtered out ${data.length - validInterviews.length} interviews due to incomplete data`);
      }
  
      setInterviews(validInterviews);
    } catch (error) {
      console.error('Error fetching interviews:', error.message);
    }
  };

  const handleSort = (key) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });
  };

  const toggleDropdown = (interviewId) => {
    setActiveDropdown(activeDropdown === interviewId ? null : interviewId);
  };

  const renderInterviewsTable = () => (
    <div className="bg-white shadow-md rounded-lg">
      <table className="min-w-full table-auto">
        {/* Table Header */}
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
              <div onClick={() => handleSort('user_job.job.title')} className="cursor-pointer">
                Job Title {sortConfig.key === 'user_job.job.title' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </div>
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
              <div onClick={() => handleSort('interview_time')} className="cursor-pointer">
                Date {sortConfig.key === 'interview_time' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </div>
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
              <div onClick={() => handleSort('user_job.job.client.name')} className="cursor-pointer">
                Client {sortConfig.key === 'user_job.job.client.name' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </div>
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
              <div onClick={() => handleSort('is_second_interview')} className="cursor-pointer">
                Status {sortConfig.key === 'is_second_interview' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </div>
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">Zoom</th>
            <th className="px-6 py-3"></th>
          </tr>
        </thead>
        {/* Table Body */}
        <tbody>
          {interviews.map((interview) => (
            <tr key={interview.id} className="hover:bg-gray-50">
              <td className="px-6 py-4 text-sm text-gray-700 text-left">{interview.user_job.job.title}</td>
              <td className="px-6 py-4 text-sm text-gray-700 text-left">
                {new Date(interview.interview_time).toLocaleString([], {
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true,
                })}
              </td>
              <td className="px-6 py-4 text-sm text-gray-700 text-left">{interview.user_job.job.client.name}</td>
              <td className="px-6 py-4 text-sm text-gray-700 text-left">
                {interview.is_second_interview ? 'Second Interview' : 'First Interview'}
              </td>
              <td className="px-6 py-4 text-sm text-gray-700 text-left">
                <a 
                  href={interview.zoom_meeting_link} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="fetch-button"
                >
                  <Video className="inline-block mr-2" size={16} />
                  Join
                </a>
              </td>
              <td className="px-6 py-4 text-left text-sm text-gray-700 relative">
                <MoreHorizontal
                  size={24}
                  className="cursor-pointer"
                  onClick={() => toggleDropdown(interview.id)}
                />
                {activeDropdown === interview.id && (
                  <div ref={dropdownRef} className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                    <div
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer text-left"
                      onClick={() => navigate(`/app/job/${interview.user_job.job_id}`)}
                    >
                      📄 Job Details
                    </div>
                    <CalendarLink icsData={interview.ics_data} />
                    {/* Feedback */}
                    {new Date(interview.interview_time).getTime() <= new Date().getTime() && (
                      <>
                        <div
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer text-left"
                          onClick={() => navigate(`/app/interview-feedback/${interview.id}`)}
                        >
                          📝 Provide Feedback
                        </div>
                        <div
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer text-left"
                          onClick={() => navigate(`/app/view-feedback/${interview.id}`)}
                        >
                          👁️ View Feedback
                        </div>
                      </>
                    )}
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const renderInterviewsCards = () => (
    <div className="grid grid-cols-1 gap-4">
      {interviews.map((interview) => (
        <div 
          key={interview.id} 
          className="bg-white shadow-md rounded-lg p-4 relative hover:shadow-lg transition-shadow"
        >
          <div className="mb-2">
            <h2 className="text-xl font-bold text-gray-900 text-left">{interview.user_job.job.title}</h2>
          </div>
          <div className="text-sm text-gray-500 mb-2 text-left">
            <p>Date: {new Date(interview.interview_time).toLocaleString([], {
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
            })}</p>
            <p>Client: {interview.user_job.job.client.name}</p>
            <p>Status: {interview.is_second_interview ? 'Second Interview' : 'First Interview'}</p>
          </div>
          <div className="mt-4 text-left">
            <a 
              href={interview.zoom_meeting_link} 
              target="_blank" 
              rel="noopener noreferrer"
              className="fetch-button"
            >
              <Video className="inline-block mr-2" size={16} />
              Join
            </a>
          </div>
          <div className="absolute bottom-4 right-4">
            <MoreHorizontal 
              size={24} 
              className="cursor-pointer"
              onClick={() => toggleDropdown(interview.id)}
            />
            {activeDropdown === interview.id && (
              <div ref={dropdownRef} className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                <div
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer text-left"
                  onClick={() => navigate(`/app/job/${interview.user_job.job_id}`)}
                >
                  📄 Job Details
                </div>
                <CalendarLink icsData={interview.ics_data} />
                {/* Feedback */}
                {new Date(interview.interview_time).getTime() <= new Date().getTime() && (
                  <>
                    <div
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer text-left"
                      onClick={() => navigate(`/app/interview-feedback/${interview.user_job_id}`)}
                    >
                      📝 Provide Feedback
                    </div>
                    <div
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer text-left"
                      onClick={() => navigate(`/app/view-feedback/${interview.user_job_id}`)}
                    >
                      👁️ View Feedback
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <>
      <Helmet>
        <title>Interviews | fetchConsultant</title>
        <meta name="description" content="View and manage your interviews on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ConsultantHamburger user={user} currentPage="interviews" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <ChevronLeft className="h-6 w-6 text-brandPrimary cursor-pointer" onClick={() => navigate('/app/consultant-dashboard')} />
              <h1 className="text-xl font-bold ml-4 text-left">Interviews</h1>
            </div>
            <NotificationBell />
          </header>

          <main className="p-4">
            <PullToRefresh
              onRefresh={fetchUserAndInterviews}
              className="pull-to-refresh"
              pullingContent={<span>Pull down to refresh...</span>}
              refreshingContent={<div className="flex items-center justify-center"><Spinner /> Refreshing...</div>}
            >
              {isLoading ? (
                <div>Loading...</div>
              ) : (
                <>
                  {isMobile ? renderInterviewsCards() : renderInterviewsTable()}
                </>
              )}
            </PullToRefresh>
          </main>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ConsultantInterviews;