import { getAnalytics, logEvent } from "firebase/analytics";
import debounce from 'lodash/debounce';
import { Info, Menu, Plus, Trash2 } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { supabase } from '../supabaseClient';
import ClientHamburger from './ClientHamburger';
import NotificationBell from './NotificationBell';
import { MultiSelect } from "react-multi-select-component";
import GeneratedDescriptionModal from './ui/GeneratedDescriptionModal';
import CustomCertificationsInput from './ui/CustomCertificationsInput.tsx';
import JobTypeModal from './ui/JobTypeModal.tsx';

const AddJob = () => {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const [jobStatus, setJobStatus] = useState(null);
  const [responseType, setResponseType] = useState('video'); // Default to text response
  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState('');
  const [formData, setFormData] = useState({
    job_id: jobId || '',
    client_id: '',
    title: '',
    description: '',
    ideal_start_date: '',
    duration: '',
    extension_possibility: '',
    travel: '',
    city: '',
    state: '',
    experience: '',
    education: '',
    referral_bonus: '',
    status: 'Draft',
    terms_and_conditions: false,
    niche: 'epic',
    employment_type: 'consultant',
    custom_niche_name: '',
    salary_range: ''
  });
  const [epicApplications, setEpicApplications] = useState([]);
  const [selectedEpicCertifications, setSelectedEpicCertifications] = useState([]);
  const [formValid, setFormValid] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [generatedDescription, setGeneratedDescription] = useState("");   
  const [isGeneratingQuestion, setIsGeneratingQuestion] = useState(false);
  const [selectedPeoplesoftModules, setSelectedPeoplesoftModules] = useState([]);
  const [selectedSalesforceCertifications, setSelectedSalesforceCertifications] = useState([]);
  const [peoplesoftModules, setPeoplesoftModules] = useState([]);
  const [salesforceCertifications, setSalesforceCertifications] = useState([]);
  const [customCertifications, setCustomCertifications] = useState([]);
  const [jobType, setJobType] = useState({
    niche: '',
    employmentType: '',
    customNicheName: ''
  });
  const [showJobTypeModal, setShowJobTypeModal] = useState(!jobId);  // Show on new job creation

  // Add this useEffect for mobile detection
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Check on mount
    checkMobile();

    // Add resize listener
    window.addEventListener('resize', checkMobile);

    // Cleanup
    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []); // Empty dependency array since we only want this to run once on mount

  // Modify the fetchInitialData function inside the useEffect
  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: jobId ? 'Edit Job' : 'Post Contract',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    const fetchInitialData = async () => {
      try {
        setIsLoading(true);
        setErrorMessage('');
        const { data: { session } } = await supabase.auth.getSession();
        if (!session) throw new Error("No active session");
        
        // Get user data first
        const userResponse = await fetch(`${supabaseUrl}/functions/v1/user-operations`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${session.access_token}`
          },
          body: JSON.stringify({
            action: 'fetchUser',
            userId: session.user.id,
          })
        });
  
        if (!userResponse.ok) {
          const errorData = await userResponse.json();
          throw new Error(errorData.error);
        }
  
        const userData = await userResponse.json();
        setUser(userData.data);
  
        // Only fetch job details if editing existing job
        if (jobId) {
          const jobResponse = await fetch(`${supabaseUrl}/functions/v1/job-form-operations`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${session.access_token}`
            },
            body: JSON.stringify({
              action: 'fetchJobDetails',
              userId: session.user.id,
              jobId
            })
          });
  
          if (!jobResponse.ok) {
            const errorData = await jobResponse.json();
            throw new Error(errorData.error);
          }
  
          const { data: jobData } = await jobResponse.json();
          console.log('Received job data:', jobData);
        
          // Set job type first
          setJobType({
            niche: jobData.niche || 'epic',
            employmentType: jobData.employment_type || 'consultant',
            customNicheName: jobData.custom_niche_name || ''
          });
        
          // Set response type and questions
          setResponseType(jobData.response_type || 'video');
          setQuestions(jobData.video_questions || []);
          
          // Set job status
          setJobStatus(jobData.status);
        
          // Set form data with explicit null handling for each field
          const formattedData = {
            job_id: jobData.job_id || '',
            client_id: jobData.client_id || '',
            title: jobData.title || '',
            description: jobData.description || '',
            ideal_start_date: jobData.ideal_start_date || '',
            duration: jobData.duration || '',
            extension_possibility: jobData.extension_possibility || '',
            travel: jobData.travel || '',
            city: jobData.city || null,
            state: jobData.state || null,
            experience: jobData.experience || '',
            education: jobData.education || null,
            referral_bonus: jobData.referral_bonus || 0,
            status: jobData.status || 'Draft',
            terms_and_conditions: jobData.terms_and_conditions || false,
            niche: jobData.niche || 'epic',
            employment_type: jobData.employment_type || 'consultant',
            custom_niche_name: jobData.custom_niche_name || '',
            salary_range: jobData.salary_range || ''
          };
        
          console.log('Setting form data:', formattedData);
          setFormData(formattedData);

          // Set certifications based on niche
          if (jobData.certifications) {
            console.log('Setting certifications:', jobData.certifications);
            switch (jobData.niche) {
              case 'epic':
                setSelectedEpicCertifications(jobData.certifications.epic || []);
                break;
              case 'peoplesoft':
                setSelectedPeoplesoftModules(jobData.certifications.peoplesoft || []);
                break;
              case 'salesforce':
                setSelectedSalesforceCertifications(jobData.certifications.salesforce || []);
                break;
              case 'other':
                setCustomCertifications(jobData.certifications.custom || []);
                break;
            }
          }
        }
      } catch (error) {
        console.error('Error fetching initial data:', error);
        setErrorMessage(`Failed to load data: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, [jobId, supabaseUrl]); // Only depend on jobId and supabaseUrl

  // Add this effect to load custom certifications if editing a job
  useEffect(() => {
    if (jobId && jobType.niche === 'other' && !customCertifications.length) {
      const fetchCustomCertifications = async () => {
        try {
          const { data: { session } } = await supabase.auth.getSession();
          const response = await fetch(`${supabaseUrl}/functions/v1/job-form-operations`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${session?.access_token}`
            },
            body: JSON.stringify({
              action: 'fetchCustomCertifications',
              userId: session.user.id,
              jobId
            })
          });

          if (!response.ok) throw new Error('Failed to fetch custom certifications');
          const { data } = await response.json();
          
          if (data && data.length > 0) {
            const formattedCertifications = data.map(cert => ({
              value: cert.certification_id,
              label: cert.name,
              name: cert.name
            }));
            
            setCustomCertifications(formattedCertifications);
          }
        } catch (error) {
          console.error('Error fetching custom certifications:', error);
        }
      };
      fetchCustomCertifications();
    }
  }, [jobId, jobType.niche, customCertifications.length]);



  // Add this new useEffect to fetch certifications when niche changes
useEffect(() => {
  const fetchCertifications = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) throw new Error("No active session");

      const response = await fetch(`${supabaseUrl}/functions/v1/job-form-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.access_token}`
        },
        body: JSON.stringify({
          action: 'fetchCertificationsByNiche',
          userId: user?.user_id,
          data: {
            niche: jobType.niche
          }
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }

      const { data } = await response.json();
      
      // Update the appropriate state based on niche
      switch (jobType.niche) {
        case 'epic':
          setEpicApplications(data);
          break;
        case 'peoplesoft':
          setPeoplesoftModules(data);
          break;
        case 'salesforce':
          setSalesforceCertifications(data);
          break;
      }
    } catch (error) {
      console.error('Error fetching certifications:', error);
    }
  };

  // Only fetch if we have a user and a non-empty niche
  if (user?.user_id && jobType.niche) {
    fetchCertifications();
  }
}, [user?.user_id, jobType.niche, supabaseUrl]); // Dependencies include user ID and niche

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    let updatedFormData = {
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    };
  
    // Handle referral bonus based on duration
    if (name === 'duration') {
      let bonus = 0;
      if (value === '3 Months') bonus = 1000;
      else if (value === '6 Months') bonus = 2000;
      else if (value === '12 Months') bonus = 3000;
      updatedFormData.referral_bonus = bonus;
    }
  
    setFormData(updatedFormData);
    
    // Validate form after each change
    validateForm(updatedFormData);
  
  };
  
  const handlePeoplesoftModuleChange = (selected) => {
    setSelectedPeoplesoftModules(selected);
    if (formData.job_id) {
      debouncedSavePeoplesoftModules(formData.job_id, selected.map(item => item.value));
    }
    const updatedFormData = { ...formData, peoplesoftModules: selected };
    validateForm(updatedFormData);
  };
  
  const handleSalesforceCertificationChange = (selected) => {
    setSelectedSalesforceCertifications(selected);
    if (formData.job_id) {
      debouncedSaveSalesforceCertifications(formData.job_id, selected.map(item => item.value));
    }
    const updatedFormData = { ...formData, salesforceCertifications: selected };
    validateForm(updatedFormData);
  };

  const handleEpicCertificationChange = (selected) => {
    setSelectedEpicCertifications(selected);
    if (formData.job_id) {
      debouncedSaveEpicCertifications(formData.job_id, selected.map(item => item.value));
    }
    const updatedFormData = { ...formData, epicCertifications: selected };
    validateForm(updatedFormData);
  };
  
  const debouncedSavePeoplesoftModules = debounce(async (jobId, modules) => {
    try {
      setIsSaving(true);
      const { data: { session } } = await supabase.auth.getSession();
      const response = await fetch(`${supabaseUrl}/functions/v1/job-form-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session?.access_token}`
        },
        body: JSON.stringify({
          action: 'savePeoplesoftModules',
          userId: user.user_id,
          jobId,
          data: { 
            jobId,
            modules 
          }
        })
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }
    } catch (error) {
      console.error('Error saving modules:', error);
      setErrorMessage('Failed to save modules. Changes will not be preserved.');
    } finally {
      setIsSaving(false);
    }
  }, 500);

const debouncedSaveSalesforceCertifications = debounce(async (jobId, certifications) => {
  try {
    setIsSaving(true);
    const { data: { session } } = await supabase.auth.getSession();
    const response = await fetch(`${supabaseUrl}/functions/v1/job-form-operations`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${session?.access_token}`
      },
      body: JSON.stringify({
        action: 'saveSalesforceCertifications',
        userId: user.user_id,
        jobId,
        data: { certifications }
      })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }
  } catch (error) {
    console.error('Error saving certifications:', error);
    setErrorMessage('Failed to save certifications. Changes will not be preserved.');
  } finally {
    setIsSaving(false);
  }
}, 500);

  const debouncedSaveEpicCertifications = debounce(async (jobId, certifications) => {
    try {
      setIsSaving(true);
      const { data: { session } } = await supabase.auth.getSession();
      const response = await fetch(`${supabaseUrl}/functions/v1/job-form-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session?.access_token}`
        },
        body: JSON.stringify({
          action: 'saveEpicCertifications',
          userId: user.user_id,
          jobId,
          data: { epicCertifications: certifications }
        })
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }
    } catch (error) {
      console.error('Error saving certifications:', error);
      setErrorMessage('Failed to save certifications. Changes will not be preserved.');
    } finally {
      setIsSaving(false);
    }
  }, 500);
  
  const validateForm = (data) => {
    let isValid = true;
    
    // Required fields validation
    const requiredFields = ['title', 'description', 'ideal_start_date', 'travel'];
    
    // Add duration requirement only for consultant positions
    if (jobType.employmentType === 'consultant') {
      requiredFields.push('duration', 'extension_possibility');
    }
    
    for (const field of requiredFields) {
      if (!data[field] || data[field].trim() === '') {
        isValid = false;
        break;
      }
    }
  
    // Location validation for non-remote jobs
    if (data.travel !== '100% Remote') {
      if (!data.city || !data.state) {
        isValid = false;
      }
    }
  
    // Certification validation based on niche
    switch (jobType.niche) {
      case 'epic':
        if (!selectedEpicCertifications || selectedEpicCertifications.length === 0) {
          isValid = false;
        }
        break;
      case 'peoplesoft':
        if (!selectedPeoplesoftModules || selectedPeoplesoftModules.length === 0) {
          isValid = false;
        }
        break;
      case 'salesforce':
        if (!selectedSalesforceCertifications || selectedSalesforceCertifications.length === 0) {
          isValid = false;
        }
        break;
      case 'other':
        if (!customCertifications || customCertifications.length === 0) {
          isValid = false;
        }
        break;
      default:
        let certificationData = {};
        break;
    }  

    setFormValid(isValid);
    return isValid;
  };

  const handleRichTextChange = (content, editor) => {
    const name = editor.getEditor().container.dataset.name;
    const updatedFormData = {
      ...formData,
      [name]: content,
    };
    setFormData(updatedFormData);
  
    // Trigger validation on description change
    validateForm(updatedFormData);
  };

  const handleAddQuestion = () => {
    if (newQuestion.trim()) {
      const analytics = getAnalytics();
      logEvent(analytics, 'add_job_question', {
        question_count: questions.length + 1
      });
  
      const updatedQuestions = [...questions, newQuestion.trim()];
      setQuestions(updatedQuestions);
      setNewQuestion('');
  
      // Trigger validation
      validateForm({ ...formData, questions: updatedQuestions });
    }
  };

  const handleRemoveQuestion = (index) => {
    const analytics = getAnalytics();
    logEvent(analytics, 'remove_job_question', {
      question_count: questions.length - 1
    });
  
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
  
    // Trigger validation
    validateForm({ ...formData, questions: updatedQuestions });
  };

  const handleQuestionChange = (index, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = value;
    setQuestions(updatedQuestions);
  
    // Trigger validation
    validateForm({ ...formData, questions: updatedQuestions });
  };

  const checkMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const generateDescriptionWithFetchAI = async () => {
    setIsGenerating(true); // Set loading state
    try {
      const {
        title,
        duration,
        travel,
        extension_possibility,
        ideal_start_date,
        description,  
      } = formData;
  
      if (!title || !duration || !travel || !extension_possibility) {
        alert("Please fill out the required fields before generating a description.");
        setIsGenerating(false);
        return;
      }
  
      // Retrieve session and access token
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        throw new Error("No active session found");
      }
      const accessToken = session.access_token;
  
      // Prepare selected certifications as skills
      const skills = selectedEpicCertifications.map((cert) => cert.label);
  
      // Make API call to the Edge Function
      const response = await fetch(`${supabaseUrl}/functions/v1/generate-job-description`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          jobTitle: title,
          skills,
          descriptionContext: `The role is ${duration} long, has ${travel} travel, and ${extension_possibility} extension possibility. Ideal start date: ${ideal_start_date}.`,
          userDescription: description, // Include current description field value
        }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to generate job description");
      }
  
      // Process streaming response
      const reader = response.body?.getReader();
      const decoder = new TextDecoder("utf-8");
      let newDescription = "";
  
      const processStream = async ({ done, value }) => {
        if (done) {
          setGeneratedDescription(newDescription); // Store the generated HTML
          setShowModal(true); // Show modal for preview
          setIsGenerating(false); // Stop loading state
          return;
        }
      
        const chunk = decoder.decode(value, { stream: true });
        const lines = chunk.split("\n").filter((line) => line.trim() !== "");
      
        for (const line of lines) {
          if (line === "[DONE]") {
            // End of stream, gracefully exit
            setNewQuestion(newQuestion.trim());
            setIsGeneratingQuestion(false);
            return;
          }

          if (line.startsWith("data:")) {
            try {
              const jsonData = JSON.parse(line.replace(/^data: /, ""));
              const content = jsonData.choices?.[0]?.delta?.content || "";
              newDescription += content;
            } catch (error) {
              console.error("Error parsing JSON line:", line, error);
            }
          }
        }
        return reader?.read().then(processStream);
      };
  
      await reader?.read().then(processStream);
    } catch (error) {
      console.error("Error generating job description:", error);
      alert("An error occurred while generating the job description. Please try again.");
    } finally {
      setIsGenerating(false);
    }
  };

  const generateQuestionWithFetchAI = async () => {
    setIsGeneratingQuestion(true); // Start loading
    try {
      const { title, description } = formData;
  
      if (!title || !description) {
        alert("Please provide a job title and description before generating a question.");
        setIsGeneratingQuestion(false);
        return;
      }
  
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        throw new Error("No active session found");
      }

      const accessToken = session.access_token;
  
      const response = await fetch(`${supabaseUrl}/functions/v1/generate-interview-questions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          jobTitle: title,
          descriptionContext: description,
          userDescription: description, // Include current description field value
        }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to generate a question");
      }
  
      const reader = response.body?.getReader();
      const decoder = new TextDecoder("utf-8");
      let newQuestion = "";
  
      const processStream = async ({ done, value }) => {
        if (done) {
          // Trim and remove surrounding quotes from the final question
          setNewQuestion(newQuestion.trim().replace(/^"|"$/g, ""));
          setIsGeneratingQuestion(false); // Stop loading
          return;
        }
      
        const chunk = decoder.decode(value, { stream: true });
        const lines = chunk.split("\n").filter((line) => line.trim() !== "");
      
        for (const line of lines) {
          if (line === "[DONE]") {
            // End of stream, gracefully exit
            setNewQuestion(newQuestion.trim());
            setIsGeneratingQuestion(false);
            return;
          }
      
          if (line.startsWith("data:")) {
            try {
              const jsonData = JSON.parse(line.replace(/^data: /, ""));
              newQuestion += jsonData.choices?.[0]?.delta?.content || "";
            } catch (error) {
              console.error("Error parsing JSON line:", line, error);
            }
          }
        }
      
        return reader?.read().then(processStream);
      };
  
      await reader?.read().then(processStream);
    } catch (error) {
      console.error("Error generating question:", error);
      alert("An error occurred while generating the question. Please try again.");
      setIsGeneratingQuestion(false);
    }
  };

  const renderCertificationSection = () => {
    console.log('Rendering certification section:', {
      niche: jobType.niche,
      epicApplications,
      peoplesoftModules,
      salesforceCertifications,
      customCertifications
    });
  
    switch (jobType.niche) {
      case 'epic':
        return (
          <div>
            <label htmlFor="epic_certifications" className="fetch-label">
              Epic Certification(s) Required<span className="fetch-required">*</span>
            </label>
            <MultiSelect
              options={epicApplications.map(app => ({ 
                label: app.name, 
                value: app.epic_application_id 
              }))}
              value={selectedEpicCertifications}
              onChange={handleEpicCertificationChange}
              labelledBy="Select Epic Certifications"
              className="fetch-multiselect"
              hasSelectAll={false}
            />
          </div>
        );
      
      case 'peoplesoft':
        return (
          <div>
            <label htmlFor="peoplesoft_modules" className="fetch-label">
              PeopleSoft Module(s) Required<span className="fetch-required">*</span>
            </label>
            <MultiSelect
              options={peoplesoftModules.map(module => ({ 
                label: module.name, 
                value: module.module_id 
              }))}
              value={selectedPeoplesoftModules}
              onChange={handlePeoplesoftModuleChange}
              labelledBy="Select PeopleSoft Modules"
              className="fetch-multiselect"
              hasSelectAll={false}
            />
          </div>
        );
      
      case 'salesforce':
        return (
          <div>
            <label htmlFor="salesforce_certifications" className="fetch-label">
              Salesforce Certification(s) Required<span className="fetch-required">*</span>
            </label>
            <MultiSelect
              options={salesforceCertifications.map(cert => ({ 
                label: cert.name, 
                value: cert.certification_id 
              }))}
              value={selectedSalesforceCertifications}
              onChange={handleSalesforceCertificationChange}
              labelledBy="Select Salesforce Certifications"
              className="fetch-multiselect"
              hasSelectAll={false}
            />
          </div>
        );
      
      case 'other':
        return (
          <div>
            <label className="fetch-label">
              Required Certifications/Qualifications
              <span className="fetch-required">*</span>
            </label>
            <CustomCertificationsInput
              certifications={customCertifications}
              onChange={setCustomCertifications}
            />
          </div>
        );
      
      default:
        return null;
    }
  };

  const handleSubmit = async (e, isDraft = false) => {
    e.preventDefault();
    if (!isDraft && !formValid) return;
  
    try {
      setIsLoading(true);
      setErrorMessage('');

      console.log('Submitting form data:', formData); // Add this log
      
      const { data: { session } } = await supabase.auth.getSession();
      const accessToken = session?.access_token;

      // Capture the current state of questions, including the new question if it's not empty
      const currentQuestions = newQuestion.trim()
        ? [...questions, newQuestion.trim()]
        : questions;

      let certificationData = {};
      
      // Prepare certification data based on niche
      switch (jobType.niche) {
        case 'epic':
          certificationData = {
            epic: selectedEpicCertifications.map(cert => cert.value)
          };
          break;
        case 'peoplesoft':
          certificationData = {
            peoplesoft: selectedPeoplesoftModules.map(module => module.value)
          };
          break;
        case 'salesforce':
          certificationData = {
            salesforce: selectedSalesforceCertifications.map(cert => cert.value)
          };
          break;
        case 'other':
          certificationData = {
            custom: customCertifications
          };
          break;
        default:
          certificationData = {};
          break;
      }
  
      const jobData = {
        ...formData,
        niche: jobType.niche,
        employment_type: jobType.employmentType,
        custom_niche_name: jobType.customNicheName,
        status: formData.status && jobId ? formData.status : (isDraft ? 'Draft' : 'Pending TC'),
        video_questions: currentQuestions,
        certifications: certificationData,
        client_id: user.employer,
        response_type: responseType,
      };
  
      // Remove city and state if travel is 100% Remote
      if (jobData.travel === '100% Remote') {
        delete jobData.city;
        delete jobData.state;
      }
  
      console.log('Submitting job data:', {
        action: jobId ? 'updateJob' : 'saveJob',
        jobId,
        jobData,
        certifications: certificationData, // Changed from epicCertifications
      });
  
      const response = await fetch(`${supabaseUrl}/functions/v1/job-form-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          action: 'updateJob',
          userId: user.user_id,
          jobId,
          data: {
            jobData,
            certifications: certificationData, // Changed from epicCertifications
            questions: currentQuestions,
            isDraft
          }
        })
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }
  
      const { data } = await response.json();
      
      // Track analytics
      const analytics = getAnalytics();
      logEvent(analytics, jobId ? 'update_job' : 'create_job', {
        isDraft,
        job_id: data.job_id
      });
  
      if (!isDraft) {
        if (jobStatus !== 'Active') {
          navigate(`/app/client-add-job-terms-and-conditions/${data.job_id}`);
        } else {
          alert('Job updated successfully!');
          navigate('/app/client-dashboard');
        }
      } else {
        alert('Draft saved successfully!');
        navigate('/app/client-dashboard');
      }
    } catch (error) {
      console.error('Error saving job:', error);
      setErrorMessage(`Error saving job: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Add handler for job type selection
  const handleJobTypeSelect = (selection) => {
    setJobType(selection);
    setShowJobTypeModal(false);
  };

  return (
    <>
      <Helmet>
        <title>
          {jobId && (jobStatus !== 'Draft' && jobStatus !== 'Deleted' && jobStatus !== 'Paused') ? 'Update Contract' : 'Post Contract'} | fetchConsultant
        </title>
        <meta name="description" content={jobId && jobStatus !== 'Draft'
          ? "Update an existing contract opportunity for Epic consultants on fetchConsultant."
          : "Post a contract opportunity for Epic consultants on fetchConsultant. Find the best healthcare IT talent for your organization."} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="noindex, nofollow" />
        <meta property="og:title" content="Client Onboarding - Personal Information | fetchConsultant" />
        <meta property="og:description" content="Complete your personal profile as a healthcare organization on fetchConsultant. Connect with top Epic consultants for your healthcare IT projects." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://fetchconsultant.com/app/client-onboarding-personal" />
        <meta property="og:image" content="https://fetchconsultant.com/app/path-to-image.jpg" />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ClientHamburger user={user} currentPage="addjob" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">
                {jobId && (jobStatus !== 'Draft' && jobStatus !== 'Deleted' && jobStatus !== 'Paused') ? 'Update Contract' : 'Post Contract'}
              </h1>
            </div>
            <div className="flex items-center">
              {isSaving && (
                <span className="mr-4 text-sm text-gray-500">Saving...</span>
              )}
              <NotificationBell />
            </div>
          </header>
          <main className="flex-grow container mx-auto px-4 py-8">
            {isLoading ? (
              <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-brandPrimary"></div>
              </div>
            ) : (
              <div className="fetch-card">
                {errorMessage && (
                  <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                        </svg>
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-red-700">{errorMessage}</p>
                      </div>
                    </div>
                  </div>
                )}

                <form onSubmit={(e) => handleSubmit(e, false)} className="fetch-form">

            <div>
                <label htmlFor="title" className="fetch-label">Job Title<span className="fetch-required">*</span></label>
                <input
                  type="text"
                  name="title"
                  id="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  className="fetch-input"
                  placeholder="e.g. Ambulatory PM"
                  required
                />
              </div>

              {jobType.employmentType === 'fte' && (
                <div>
                  <label htmlFor="salary_range" className="fetch-label">Salary Range</label>
                  <input
                    type="text"
                    name="salary_range"
                    id="salary_range"
                    value={formData.salary_range || ''}
                    onChange={handleInputChange}
                    className="fetch-input"
                    placeholder="e.g. $80,000 - $100,000"
                  />
                </div>
              )}

              <div>
                <label
                  htmlFor="description"
                  className="fetch-label flex items-center justify-between"
                >
                  <div className="flex items-center space-x-2 relative">  {/* Added relative positioning */}
                    <span>Job Description</span>
                    <span className="fetch-required">*</span>
                    <div className="relative inline-block">  {/* Added container for tooltip */}
                      <Info
                        className="text-blue-500 cursor-pointer"
                        size={18}
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                      />
                      {showTooltip && (
                        <div className="absolute z-50 w-64 p-2 text-sm text-white bg-gray-800 rounded shadow-lg -left-20 -top-20">  {/* Adjusted positioning */}
                          Be as brief and informal as you would like. Great consultants will
                          understand the need. Remember, there is no account manager gatekeeper
                          who may lack domain expertise who this must first filter through.
                        </div>
                      )}
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={generateDescriptionWithFetchAI}
                    className={`bg-blue-500 text-white px-3 py-1 rounded-md hover:bg-blue-600 ${
                      isGenerating ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    disabled={isGenerating}
                  >
                    {isGenerating ? "Generating..." : "Create with fetchAI"}
                  </button>
                </label>
                <ReactQuill
                  theme="snow"
                  value={formData.description}
                  onChange={(content) =>
                    handleRichTextChange(content, {
                      getEditor: () => ({ container: { dataset: { name: "description" } } }),
                    })
                  }
                  rows="4"
                  className="fetch-input tall-editor"
                  data-name="description"
                />
              </div>

              <div>
                <label htmlFor="ideal_start_date" className="fetch-label">Ideal Start Date<span className="fetch-required">*</span></label>
                <div className="relative">
                  <input
                    type="date"
                    name="ideal_start_date"
                    id="ideal_start_date"
                    value={formData.ideal_start_date}
                    onChange={handleInputChange}
                    className="fetch-input"
                    required
                  />
                </div>
              </div>

              {jobType.employmentType === 'consultant' && (
                <div>
                  <label htmlFor="duration" className="fetch-label">Duration<span className="fetch-required">*</span></label>
                  <select
                    id="duration"
                    name="duration"
                    value={formData.duration}
                    onChange={handleInputChange}
                    className="fetch-select"
                    required
                  >
                    <option value="">Select duration</option>
                    <option value="3 Months">3 Months</option>
                    <option value="6 Months">6 Months</option>
                    <option value="12 Months">12 Months</option>
                  </select>
                </div>
              )}

              <div>
                <label htmlFor="extension_possibility" className="fetch-label">Extension Possibility<span className="fetch-required">*</span></label>
                <select
                  id="extension_possibility"
                  name="extension_possibility"
                  value={formData.extension_possibility}
                  onChange={handleInputChange}
                  className="fetch-select"
                  required
                >
                  <option value="">Select possibility</option>
                  <option value="Likely">Likely</option>
                  <option value="Not Likely">Not Likely</option>
                </select>
              </div>

              <div>
                <label htmlFor="travel" className="fetch-label">Travel Requirement<span className="fetch-required">*</span></label>
                <select
                  id="travel"
                  name="travel"
                  value={formData.travel}
                  onChange={handleInputChange}
                  className="fetch-select"
                  required
                >
                  <option value="">Select requirement</option>
                  <option value="100% Remote">100% Remote</option>
                  <option value="Hybrid">Hybrid</option>
                  <option value="100% Onsite">100% Onsite</option>
                </select>
              </div>

              {formData.travel !== '100% Remote' && (
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label htmlFor="city" className="fetch-label">City<span className="fetch-required">*</span></label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      value={formData.city}
                      onChange={handleInputChange}
                      className="fetch-input"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="state" className="fetch-label">State<span className="fetch-required">*</span></label>
                    <select
                      id="state"
                      name="state"
                      value={formData.state}
                      onChange={handleInputChange}
                      className="fetch-select"
                      required
                    >
                      <option value="">Select state</option>
                      {/* Add options for all states */}
                      <option value="TX">TX</option>
                      {/* ... other states ... */}
                    </select>
                  </div>
                </div>
              )}

                {renderCertificationSection()}

                <div>
                  <label htmlFor="experience" className="fetch-label">Minimum Experience</label>
                  <ReactQuill
                    theme="snow"
                    value={formData.experience}
                    onChange={(content) => handleRichTextChange(content, { getEditor: () => ({ container: { dataset: { name: "experience" } } }) })}
                    className="fetch-input tall-editor"
                    data-name="experience"
                  />
                </div>

                <div>
                  <label htmlFor="education" className="fetch-label">Education Required</label>
                  <select
                    id="education"
                    name="education"
                    value={formData.education}
                    onChange={handleInputChange}
                    className="fetch-select"
                  >
                    <option value="">Select education</option>
                    <option value="Bachelor's Degree">Bachelor's Degree</option>
                    <option value="Master's Degree">Master's Degree</option>
                    <option value="None">None</option>
                  </select>
                </div>

                <div>
                  <label htmlFor="referral_bonus" className="fetch-label flex items-center relative group">Referral Bonus
                    <Info
                      className="ml-2 text-blue-500 cursor-pointer"
                      size={18}
                      onMouseEnter={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                      onClick={() => setShowTooltip(!showTooltip)}
                    />
                    {showTooltip && (
                      <div className="tooltip">
                        fetchConsultant covers this cost to incentivize consultants to refer qualified colleagues. This maximizes exposure and minimizes fullfillment time.
                      </div>
                    )}
                  </label>

                  <p className="fetch-input text-left" style={{ padding: '10px' }}>
                    {formData.referral_bonus === 1000 && '$1,000'}
                    {formData.referral_bonus === 2000 && '$2,000'}
                    {formData.referral_bonus === 3000 && '$3,000'}
                  </p>
                </div>

                <div>
                  <label htmlFor="response_type" className="fetch-label flex items-center relative group">
                    Application Questions Response Type<span className="fetch-required">*</span>
                    <Info
                      className="ml-2 text-blue-500 cursor-pointer"
                      size={18}
                      onMouseEnter={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                      onClick={() => setShowTooltip(!showTooltip)}
                    />
                    {showTooltip && (
                      <div className="tooltip">
                        Within the first five minutes of an interview you know whether a consultant has potential. Save time by having them provide two-minute video responses or written text responses to your top questions when they apply. Use Create with fetchAI to automagically create questions based off the Job Title and Description.
                      </div>
                    )}
                  </label>
                  <select
                    id="response_type"
                    name="response_type"
                    value={responseType}
                    onChange={(e) => setResponseType(e.target.value)}
                    className="fetch-select"
                    required
                  >
                    <option value="text">Text</option>
                    <option value="video">Video</option>
                    <option value="either">Either</option>
                  </select>
                </div>

                <div className="mb-6">
                  <h3 className="fetch-label flex items-center justify-between mb-1">
                    Application Questions
                    <button
                      type="button"
                      onClick={generateQuestionWithFetchAI}
                      className={`bg-blue-500 text-white px-3 py-1 rounded-md hover:bg-blue-600 ${
                        isGeneratingQuestion ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                      disabled={isGeneratingQuestion}
                    >
                      {isGeneratingQuestion ? "Generating..." : "Create with fetchAI"}
                    </button>
                  </h3>
                  {questions.map((question, index) => (
                    <div key={index} className="flex items-center mb-2">
                      <textarea
                        value={question}
                        onChange={(e) => handleQuestionChange(index, e.target.value)}
                        className="fetch-input flex-grow mr-2 min-h-[60px] resize-y"  // Added min-height and resize
                        style={{ lineHeight: '1.5' }}  //* Improved readability
                      />
                      <button
                        type="button"
                        onClick={() => handleRemoveQuestion(index)}
                        className="text-red-500 hover:text-red-700 self-start mt-2"  // Aligned to top
                      >
                        <Trash2 size={20} />
                      </button>
                    </div>
                  ))}
                  <div className="flex items-start">  {/* Changed to items-start*/}
                    <textarea
                      value={newQuestion}
                      onChange={(e) => setNewQuestion(e.target.value)}
                      placeholder="Enter a new question."
                      className="fetch-input flex-grow mr-2 min-h-[60px] resize-y"
                      style={{ lineHeight: '1.5' }}
                    />
                    <button
                      type="button"
                      onClick={handleAddQuestion}
                      className="bg-brandPrimary text-white p-2 rounded-md hover:bg-brandHover self-start mt-2"
                    >
                      <Plus size={20} />
                    </button>
                  </div>
                </div>

                <div className="flex justify-between items-center space-x-4">
                  {/* Cancel Button */}
                  <button
                    type="button"
                    onClick={() => navigate(-1)} // Adjust if `handleCancel` logic differs
                    className="inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    disabled={isLoading}
                  >
                    Cancel
                  </button>

                  {/* Save Draft and Post/Update Contract Buttons */}
                  <div className="flex space-x-2">
                    {/* Save Draft */}
                    {jobStatus !== 'Active' && (
                      <button
                        type="button"
                        onClick={(e) => handleSubmit(e, true)}
                        className={`inline-flex items-center justify-center rounded-md bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-400 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={isLoading}
                      >
                        {isLoading ? 'Saving...' : 'Save Draft'}
                      </button>
                    )}

                    {/* Post/Update Contract */}
                    <button
                      type="submit"
                      className={`inline-flex items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 ${(!formValid || isLoading) ? 'opacity-50 cursor-not-allowed' : ''}`}
                      disabled={!formValid || isLoading}
                    >
                      {isLoading ? 'Saving...' : (jobId && jobStatus !== 'Draft' && jobStatus !== 'Deleted' && jobStatus !== 'Paused' ? 'Update Contract' : 'Post Contract')}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            )}
          </main>
        </div>
      </div>
      <GeneratedDescriptionModal
        isVisible={showModal}
        generatedDescription={generatedDescription} // Rich HTML
        isGenerating={isGenerating}
        onAccept={() => {
          setFormData((prev) => ({
            ...prev,
            description: generatedDescription, // Set HTML into the form data
          }));
          setShowModal(false); // Close the modal
        }}
        onCancel={() => setShowModal(false)} // Close the modal without saving
      />
      <JobTypeModal 
        isOpen={showJobTypeModal}
        onClose={() => setShowJobTypeModal(false)}
        onSelect={handleJobTypeSelect}
      />
    </>
  );
};

export default AddJob;