import { getAnalytics, logEvent } from "firebase/analytics";
import { Menu, Info } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ClientHamburger from './ClientHamburger';
import NotificationBell from './NotificationBell';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Modal from "./ui/Modal.js";

const ClientOfferContract = () => {
  const { userJobId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [applicant, setApplicant] = useState(null);
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [clientSignerInfoLoaded, setClientSignerInfoLoaded] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [validationError, setValidationError] = useState('');

  // Get extension data from navigation state
  const isExtension = location.state?.isExtension || false;
  const existingEngagement = location.state?.existingEngagement;

  const [formData, setFormData] = useState({
    contract_rate: '',
    start_date: '',
    end_date: '',
    job_title: '',
    job_description: '',
    signer_name: '',
    signer_email: ''
  });

  const modules = {
    toolbar: [
      [{ list: 'bullet' }], // Enable bullet points
    ],
  };
  
  const formats = [
    'list', // Allow bullet point formatting
  ];

  const addOneDay = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1);
    return date.toISOString().split('T')[0];
  };

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: isExtension ? 'Extend Contract' : 'Offer Contract',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUserData();
    if (isExtension && existingEngagement) {
      // Pre-fill form with existing engagement data for extensions
      setFormData((prev) => ({
        ...prev,
        contract_rate: existingEngagement.contract_rate,
        start_date: addOneDay(existingEngagement.end_date),
        end_date: '',
        job_title: existingEngagement.job_title || existingEngagement.job?.title,
        job_description: existingEngagement.job_description,
        // We will fetch signer info from the client shortly, so leave signer_name and signer_email blank for now
      }));
      // Set applicant data from existing engagement
      setApplicant({
        user_id: existingEngagement.consultant_id,
        job: {
          ...existingEngagement.job,
          client_id: existingEngagement.client_id
        },
        users: { name: existingEngagement.consultant_name }
      });
      setLoading(false);
      // Fetch and populate signer details after user/employer is known
    } else {
      fetchApplicantDetails();
    }
  }, [isExtension, existingEngagement]);

  const fetchUserData = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const fetchApplicantDetails = async () => {
    if (isExtension) return; // Skip for extensions as we already have the data
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('user_jobs')
        .select(`
          *,
          users!user_jobs_user_id_fkey (
            name,
            login_email
          ),
          job:job_id (
            title,
            description,
            client_id,
            duration,
            created_by
          )
        `)
        .eq('user_job_id', userJobId)
        .single();
  
      if (error) throw error;
      setApplicant(data);
      setFormData(prevData => ({
        ...prevData,
        contract_rate: data.consultant_rate,
        start_date: data.available_start_date,
        job_title: data.job.title,
        job_description: data.job.description
      }));
    } catch (error) {
      console.error('Error fetching applicant details:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch client signer details once we know who the client is
  useEffect(() => {
    if (!user || (!applicant && !existingEngagement)) return;
    const clientId = isExtension ? existingEngagement.client_id : applicant?.job?.client_id;
    if (!clientId) return;
    fetchClientSignerInfo(clientId);
  }, [user, applicant, existingEngagement, isExtension]);

  const fetchClientSignerInfo = async (clientId) => {
    try {
      const { data: clientData, error: clientError } = await supabase
        .from('clients')
        .select('msa, signer_name, signer_email')
        .eq('client_id', clientId)
        .single();
  
      if (clientError || !clientData) {
        console.error('Error fetching client data for signer info:', clientError);
        setClientSignerInfoLoaded(true);
        return;
      }

      // Populate form fields with signer defaults if not already set
      setFormData(prevData => ({
        ...prevData,
        signer_name: prevData.signer_name || clientData.signer_name || '',
        signer_email: prevData.signer_email || clientData.signer_email || ''
      }));
      setClientSignerInfoLoaded(true);
    } catch (error) {
      console.error('Error fetching client signer info:', error);
      setClientSignerInfoLoaded(true);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'end_date') {
      if (formData.start_date && value < formData.start_date) {
        setValidationError('End date must be after start date');
        return;
      } else {
        setValidationError(''); // Clear error when valid
      }
    }
    
    if (name === 'start_date') {
      if (formData.end_date && formData.end_date < value) {
        setFormData(prevData => ({
          ...prevData,
          [name]: value,
          end_date: ''
        }));
        return;
      }
      setValidationError(''); // Clear error when changing start date
    }

    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleDescriptionChange = (content) => {
    setFormData(prevData => ({
      ...prevData,
      job_description: content
    }));
  };

  // Utility function to validate email format
  const isValidEmail = (email) => {
    // Basic validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Fetch client's MSA status
      const clientId = isExtension ? existingEngagement.client_id : applicant.job.client_id;
      const { data: clientData, error: clientError } = await supabase
        .from('clients')
        .select('msa')
        .eq('client_id', clientId)
        .single();
  
      if (clientError || !clientData) {
        alert('Error fetching client data. Please try again.');
        return;
      }
  
      if (clientData.msa !== 'Complete') {
        alert('Please complete the MSA before offering a contract.');
        return;
      }
  
      const engagementData = {
        user_job_id: userJobId,
        consultant_id: isExtension ? existingEngagement.consultant_id : applicant.user_id,
        client_id: clientId,
        job_id: isExtension ? existingEngagement.job.job_id : applicant.job_id,
        contract_rate: formData.contract_rate,
        start_date: formData.start_date,
        end_date: formData.end_date,
        status: 'Offered',
        job_title: formData.job_title,
        job_description: formData.job_description,
        decision_maker_id: isExtension ? (existingEngagement.decision_maker_id || existingEngagement.job.created_by) : applicant.job.created_by,
        signer_name: formData.signer_name,
        signer_email: formData.signer_email
      };
  
      if (isExtension) {
        engagementData.contract_extension = true;
        engagementData.parent_engagement_id = existingEngagement.id;
      }
  
      const { data: newEngagement, error: engagementError } = await supabase
        .from('engagements')
        .insert(engagementData)
        .select()
        .single();
  
      if (engagementError) throw engagementError;
  
      // Update user_jobs status if not an extension
      if (!isExtension) {
        await supabase
          .from('user_jobs')
          .update({ status: 'Contract Offered' })
          .eq('user_job_id', userJobId);
      }
  
      const { data: { session } } = await supabase.auth.getSession();
      
      // Generate SOW but don't get signing link yet
      const sowResponse = await fetch(`${process.env.REACT_APP_SUPABASE_FUNCTIONS_URL}/generateSOW`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session?.access_token}`
        },
        body: JSON.stringify({
          engagementId: newEngagement.id,
          isExtension,
          signerName: formData.signer_name,
          signerEmail: formData.signer_email 
        }),
      });
  
      const sowResult = await sowResponse.json();
      if (!sowResult.success || !sowResult.documentId) {
        throw new Error(sowResult.error || 'Failed to generate SOW.');
      }

      const analytics = getAnalytics();
      logEvent(analytics, isExtension ? 'contract_extension_offered' : 'contract_offered', {
        engagement_id: newEngagement.id,
        is_extension: isExtension,
        workflow: 'ica_first'
      });

      // Show success modal
      setSuccessModalOpen(true);
      
    } catch (error) {
      console.error('Error sending contract offer:', error);
      alert('Failed to send contract offer. Please try again.');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (!applicant) return <div>Applicant not found</div>;

  return (
    <>
      <Helmet>
        <title>{isExtension ? 'Extend Contract' : 'Offer Contract'} | fetchConsultant</title>
        <meta 
          name="description" 
          content={isExtension ? 
            "Extend an existing contract with a consultant on fetchConsultant." : 
            "Offer a contract to a consultant on fetchConsultant."} 
        />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ClientHamburger user={user} currentPage="offer-contract" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">Offer Contract</h1>
            </div>
            <div className="flex items-center">
              <NotificationBell />
            </div>
          </header>
          <main className="flex-grow container mx-auto px-4 py-8">
            <div className="fetch-card">
            <h2 className="text-2xl font-semibold mb-4">
              {isExtension ? 'Extend Contract for ' : 'Offer Contract to '} 
              {applicant?.users?.name}
            </h2>
            {isExtension && (
              <div className="mb-4 p-4 bg-blue-50 text-blue-700 rounded-md">
                <p>You are extending an existing contract. The new contract will start after the current contract ends.</p>
              </div>
            )}
              <form onSubmit={handleSubmit} className="fetch-form">
                <div className="relative">
                  <label htmlFor="job_title" className="fetch-label flex items-center relative group">
                    Job Title<span className="fetch-required">*</span>
                    <Info
                      className="ml-2 text-blue-500 cursor-pointer"
                      size={18}
                      onMouseEnter={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                      onClick={() => setShowTooltip(!showTooltip)}
                    /> 
                    {showTooltip && (
                      <div className="tooltip">
                        This title will appear in the Statement of Work.
                      </div>
                    )}
                  </label>
                </div>
                <input
                  type="text"
                  name="job_title"
                  value={formData.job_title}
                  onChange={handleInputChange}
                  className="fetch-input"
                  required
                />
                <div className="relative">
                  <label htmlFor="job_description" className="fetch-label flex items-center relative group">
                    Job Description<span className="fetch-required">*</span>
                    <Info
                      className="ml-2 text-blue-500 cursor-pointer"
                      size={18}
                      onMouseEnter={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                      onClick={() => setShowTooltip(!showTooltip)}
                    />
                    {showTooltip && (
                      <div className="tooltip">
                        This will appear in the Statement of Work.
                      </div>
                    )}
                  </label>
                </div>
                  <ReactQuill
                    value={formData.job_description}
                    onChange={handleDescriptionChange}
                    className="fetch-input"
                    modules={modules}
                    formats={formats}
                  />
                <div className="relative">
                  <label htmlFor="contract_rate" className="fetch-label flex items-center relative group">
                    Contract Rate ($/hour)<span className="fetch-required">*</span>
                    <Info
                      className="ml-2 text-blue-500 cursor-pointer"
                      size={18}
                      onMouseEnter={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                      onClick={() => setShowTooltip(!showTooltip)}
                    />
                    {showTooltip && (
                      <div className="tooltip">
                        This defaults to the hourly rate the consultant indicated when applying. A 4.5% platform fee will be added to rate when invoiced.
                      </div>
                    )}
                  </label>
                </div>
                  <input
                    type="number"
                    name="contract_rate"
                    value={formData.contract_rate}
                    onChange={handleInputChange}
                    className="fetch-input"
                    required
                  />
                <div className="relative">
                  <label className="fetch-label flex items-center relative group">
                    Contract Dates
                    <Info
                      className="ml-2 text-blue-500 cursor-pointer"
                      size={18}
                      onMouseEnter={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                      onClick={() => setShowTooltip(!showTooltip)}
                    />
                    {showTooltip && (
                      <div className="tooltip">
                        The start date defaults to the date the consultant said was their first available date. Note, you indicated a duration of {isExtension ? existingEngagement?.job?.duration : applicant?.job?.duration}.
                      </div>
                    )}
                  </label> 
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label htmlFor="start_date" className="fetch-label flex items-center relative group">Start Date<span className="fetch-required">*</span></label>
                    <input
                      type="date"
                      name="start_date"
                      value={formData.start_date}
                      onChange={handleInputChange}
                      className="fetch-input"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="end_date" className="fetch-label flex items-center relative group">End Date<span className="fetch-required">*</span></label>
                    <input
                      type="date"
                      name="end_date"
                      value={formData.end_date}
                      onChange={handleInputChange}
                      className="fetch-input"
                      required
                      min={formData.start_date}
                    />
                  </div>
                </div>
                {/* New Fields for SOW Signer */}
                <div className="relative">
                  <label htmlFor="sow_signer" className="fetch-label flex items-center relative group">
                    SOW Signer Name<span className="fetch-required">*</span>
                    <Info
                      className="ml-2 text-blue-500 cursor-pointer"
                      size={18}
                      onMouseEnter={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                      onClick={() => setShowTooltip(!showTooltip)}
                    />
                    {showTooltip && (
                      <div className="tooltip">
                        The name of the person who will sign the SOW. This can be reassigned later. You will also be cc'd on the email to keep tabs of it.
                      </div>
                    )}
                  </label>
                </div>
                  <input
                    type="text"
                    name="signer_name"
                    value={formData.signer_name}
                    onChange={handleInputChange}
                    className="fetch-input"
                    required
                  />

                <div className="relative">
                  <label htmlFor="sow_signer_email" className="fetch-label flex items-center relative group">
                    SOW Signer Email<span className="fetch-required">*</span>
                    <Info
                      className="ml-2 text-blue-500 cursor-pointer"
                      size={18}
                      onMouseEnter={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                      onClick={() => setShowTooltip(!showTooltip)}
                    />
                    {showTooltip && (
                      <div className="tooltip">
                        The email address of the person responsible for signing the SOW. They can reassign it to someone else.
                      </div>
                    )}
                  </label>
                </div>
                  <input
                    type="email"
                    name="signer_email"
                    value={formData.signer_email}
                    onChange={handleInputChange}
                    className="fetch-input"
                    required
                  />
                <div className="flex justify-between mt-6">
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="fetch-button bg-gray-500 hover:bg-gray-600"
                  >
                    Cancel
                  </button>
                  <div className="flex flex-col items-end">
                    {validationError && (
                      <p className="text-red-500 text-sm mb-2">{validationError}</p>
                    )}
                    <button
                      type="submit"
                      className="fetch-button"
                      disabled={loading || !clientSignerInfoLoaded || validationError}
                    >
                      Send Contract Offer
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </main>
        </div>
      </div>
      <Modal
        isOpen={successModalOpen}
        onClose={() => {
          setSuccessModalOpen(false);
          navigate('/app/client-engagement-management');
        }}
        title="Contract Offer Sent Successfully"
      >
        <div className="p-6">
          <div className="mb-6">
            <div className="flex items-center justify-center mb-4">
              <svg
                className="h-12 w-12 text-green-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <p className="text-center text-lg font-medium">
              Contract offer has been sent to {applicant?.users?.name}.
            </p>
            <p className="text-center text-gray-600 mt-2">
              Once the consultant accepts the contract, they will sign their Independent Contractor Agreement (ICA) with fetchConsultant.
              Once completed, fetchConsultant will sign the Statement of Work and then {formData.signer_name} will be emailed to countersign (with you cc'd).
            </p>
            <p className="text-center text-gray-600 mt-2">
              You will now be redirected to confirm onboarding requirements so the consultant can get started on them immediately after signing the ICA.
            </p>
          </div>
          <div className="flex justify-center">
            <button
              onClick={() => {
                setSuccessModalOpen(false);
                navigate('/app/client-onboarding-requirements');
              }}
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              Go to Onboarding Requirements
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ClientOfferContract;