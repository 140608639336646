import { getAnalytics, logEvent } from "firebase/analytics";
import { Menu } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { supabase } from '../supabaseClient';
import ClientHamburger from './ClientHamburger';
import NotificationBell from "./NotificationBell";
import { requestForToken } from '../firebaseConfig';

// Import slick carousel styles
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const WelcomeClient = () => {
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Welcome Client',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error.message);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleContinue = async () => {
    try {
      await requestForToken();
      console.log('Notification permission requested');
    } catch (err) {
      console.log('Failed to request notification permission:', err);
    }
    navigate('/app/client-dashboard');
  };

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <>
      <Helmet>
        <title>Welcome to fetchConsultant | Client Dashboard</title>
        <meta name="description" content="Welcome to fetchConsultant. Learn about our features and start posting jobs to find top Epic consultants." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex flex-col">
        <ClientHamburger user={user} currentPage="welcome" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <header className="bg-white p-4 flex justify-between items-center md:ml-64">
          <div className="flex items-center ml-16 md:ml-64">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
            </div>
              <NotificationBell />
          </header>
          <main className="flex-grow container mx-auto p-4 sm:p-6 md:p-8 ml-16 md:ml-64">
          <div className="bg-white text-left rounded-lg shadow-xl overflow-hidden">
            <div className="p-6 sm:p-8">
              <header className="text-2xl sm:text-3xl font-bold text-gray-800 mb-4">Welcome aboard!</header>
              <p className="text-base sm:text-lg text-gray-600 mb-6">
                  We're excited to have you join fetchConsultant. Here's what you can expect:
                </p>
                <ul className="list-disc list-inside mb-6 text-gray-600">
                  <li>Post jobs and reach hundreds of qualified Epic consultants</li>
                  <li>Streamlined hiring process with video responses and integrated scheduling</li>
                  <li>Direct communication with consultants, cutting out the middleman</li>
                  <li>Significant cost savings compared to traditional staffing agencies</li>
                  <li>Full support and contract management through fetchConsultant</li>
                </ul>
                <p className="text-lg text-gray-600 mb-6">
                  You've been subscribed to notifications via email, push, and text. You can adjust these anytime in Settings.
                </p>
                <button
                  onClick={handleContinue}
                  className="bg-brandPrimary text-white px-6 py-3 rounded-full text-base sm:text-lg font-bold hover:bg-brandHover transition duration-300 w-full sm:w-auto"
                  >
                  Continue to Dashboard
                </button>
              </div>
              <div className="p-6 sm:p-8 bg-gray-50">
              <h1 className="text-xl sm:text-2xl font-bold text-gray-800 mb-4">Why Choose fetchConsultant?</h1>
              <Slider {...carouselSettings} className="fetchCarousel">
                <div className="px-4">
                    <h2 className="text-xl font-semibold mb-2">Privacy and Control</h2>
                    <p>Your information remains private on fetchConsultant. Your profile is only shared when you choose to post jobs, giving you full control over your data.</p>
                  </div>
                  <div className="px-4">
                    <h2 className="text-xl font-semibold mb-2">Disrupting the Consulting Space</h2>
                    <p>We're changing the game by connecting you directly with top Epic consultants, eliminating unnecessary overhead and markup.</p>
                  </div>
                  <div className="px-4">
                    <h2 className="text-xl font-semibold mb-2">Fair Economics</h2>
                    <p>Our model ensures fair rates for both clients and consultants, aligning incentives and maximizing value for all parties involved.</p>
                  </div>
                </Slider>
              </div>
            </div>
          </main>
        </div>
    </>
  );
};

export default WelcomeClient;