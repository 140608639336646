import React, { useState, useEffect } from 'react';
import { getCachedStorageUrl } from '../../utils/storageHelpers';
import PhotoModal from './PhotoModal';

const Avatar = ({ photo, name, className, clickable = false }) => {
  const [photoUrl, setPhotoUrl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const loadPhoto = async () => {
      if (photo) {
        const url = await getCachedStorageUrl('photos', photo);
        setPhotoUrl(url);
      }
    };

    loadPhoto();
  }, [photo]);

  const handleClick = () => {
    if (clickable && photoUrl) {
      setIsModalOpen(true);
    }
  };

  if (!photoUrl) {
    // Fallback to initials if no photo or while loading
    return (
      <div 
        className={`${className} bg-gray-200 rounded-full flex items-center justify-center ${clickable ? 'cursor-pointer' : ''}`}
        onClick={handleClick}
      >
        <span className="text-gray-600 font-medium">
          {name ? name.charAt(0).toUpperCase() : '?'}
        </span>
      </div>
    );
  }

  return (
    <>
      <img
        src={photoUrl}
        alt={name || 'User avatar'}
        className={`${className} rounded-full object-cover ${clickable ? 'cursor-pointer' : ''}`}
        onClick={handleClick}
        onError={(e) => {
          e.target.onerror = null;
          setPhotoUrl(null); // Fallback to initials on error
        }}
      />
      <PhotoModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        photoUrl={photoUrl}
        name={name}
      />
    </>
  );
};

export default Avatar;