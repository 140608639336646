import { getAnalytics, logEvent } from "firebase/analytics";
import { ArrowLongLeftIcon, CheckIcon, HandThumbUpIcon, HomeIcon, UserIcon } from '@heroicons/react/20/solid';
import { Download, Menu, PlayCircle, Star } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ClientHamburger from './ClientHamburger';
import SystemAdminHamburger from './SystemAdminHamburger';
import NotificationBell from './NotificationBell';
import { format, formatDistanceToNow } from 'date-fns';
import NoteModal from './ui/NoteModal';
import Avatar from "./ui/Avatar.js";
import AssessCandidate from "./AssessCandidate.js";
import ApplicantWorkHistoryModal from "./ui/ApplicantWorkHistoryModal";
import { getStorageUrl } from '../utils/storageHelpers';

const truncateText = (text, maxLength) => {
  if (!text) return '';
  return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
};

const eventTypes = {
  applied: { icon: UserIcon, bgColorClass: 'bg-gray-400' },
  advanced: { icon: HandThumbUpIcon, bgColorClass: 'bg-blue-500' },
  completed: { icon: CheckIcon, bgColorClass: 'bg-green-500' },
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const renderStars = (rating) => {
  const stars = [];
  const roundedRating = Math.round(rating * 2) / 2; // Round to nearest 0.5
  for (let i = 1; i <= 5; i++) {
    let starFill = Math.min(Math.max(roundedRating - (i - 1), 0), 1);
    if (starFill === 1) {
      // Full star
      stars.push(<Star key={i} size={20} fill="gold" stroke="gold" />);
    } else if (starFill > 0) {
      // Partial star
      stars.push(
        <div key={i} className="relative">
          <Star size={20} fill="none" stroke="gold" />
          <div
            className="absolute top-0 left-0 overflow-hidden"
            style={{ width: `${starFill * 100}%` }}
          >
            <Star size={20} fill="gold" stroke="gold" />
          </div>
        </div>
      );
    } else {
      // Empty star
      stars.push(<Star key={i} size={20} fill="none" stroke="gold" />);
    }
  }
  return stars;
};

const ApplicantDetails = () => {
  const [applicant, setApplicant] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reviews, setReviews] = useState([]);
  const [interviewNotes, setInterviewNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [videoLoadError, setVideoLoadError] = useState(null);
  const [timeline, setTimeline] = useState([]);
  const [newNote, setNewNote] = useState('');
  const { userJobId } = useParams();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [comments, setComments] = useState([]);
  const [formattedReviews, setFormattedReviews] = useState([]);
  const [formattedResponses, setFormattedResponses] = useState([]);
  const [formattedInterviewNotes, setFormattedInterviewNotes] = useState([]);
  const [workHistory, setWorkHistory] = useState([]);
  const [selectedWorkHistory, setSelectedWorkHistory] = useState(null);
  const [isWorkHistoryModalOpen, setIsWorkHistoryModalOpen] = useState(false);

  // Add this helper function after state declarations:
  const formatInterviewNotes = (notes) => {
    const now = new Date();
    return notes.map(note => {
      const noteDate = new Date(note.created_at);
      const monthsDiff = (now - noteDate) / (1000 * 60 * 60 * 24 * 30);
      
      // Calculate time-based weight
      let timeWeight = 1.0;
      if (monthsDiff <= 1) timeWeight = 1.3;
      else if (monthsDiff <= 3) timeWeight = 1.2;
      else if (monthsDiff <= 6) timeWeight = 1.1;
      
      // Calculate type-based weight
      const typeWeight = note.note_type === 'Interview' ? 1.5 : 1.0;
      
      // Calculate final weight
      const totalWeight = timeWeight * typeWeight;
      
      return {
        id: note.id,
        type: note.note_type,
        content: note.content,
        feedbackScore: note.feedback_score,
        writer: note.notewriter?.name || 'System',
        date: note.created_at,
        weight: totalWeight
      };
    });
  };

  // Single initial data fetch
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        setLoading(true);
        const analytics = getAnalytics();
        logEvent(analytics, 'page_view', {
          page_title: 'Applicant Details',
          page_location: window.location.href,
          page_path: window.location.pathname,
        });

        // Fetch user data
        const { data: { user: currentUser } } = await supabase.auth.getUser();
        if (!currentUser) throw new Error("No authenticated user found");

        const { data: userData, error: userError } = await supabase
          .from('users')
          .select('*, user_type_id')
          .eq('user_id', currentUser.id)
          .single();

        if (userError) throw userError;
        setUser(userData);
        console.log('User data:', userData);

        // Fetch applicant details
        const { data: applicantData, error: applicantError } = await supabase
        .from('user_jobs')
        .select(`
          *,
          text_responses,
          video_responses,
          response_type,
          applicant:client_applicant_view!user_jobs_user_id_fkey(
            user_id,
            name,
            about,
            photo,
            city,
            state,
            linkedin_url,
            former_epic,
            resume,
            current_project_end_date,
            average_rating,
            average_peer_rating,
            average_manager_rating,
            user_epic_certifications ( epic_applications (name) )
          ),
          job:job_id ( title, description, status, video_questions ),
          referrer:client_applicant_view!user_jobs_referred_by_fkey( user_id, name )
        `)
        .eq('user_job_id', userJobId)
        .single();

        if (applicantError) throw applicantError;
        setApplicant(applicantData);
        console.log('Applicant data:', applicantData);
        if (applicantData) {
          console.group('Response Data Debug');
          console.log('Text responses:', applicantData.text_responses);
          console.log('Response type:', applicantData.response_type);
          console.log('Video questions:', applicantData.job?.video_questions);
          console.log('Text responses type:', typeof applicantData.text_responses);
          console.groupEnd();
        
          // If we still don't see the video questions, let's check the raw job_id
          console.log('Job ID:', applicantData.job_id);
        }

        // After fetching applicant data:
        if (applicantData.job?.video_questions) {
          console.group('Response Formatting Debug');
          console.log('Raw response data:', {
            video_questions: applicantData.job.video_questions,
            text_responses: applicantData.text_responses,
            response_type: applicantData.response_type
          });
          
          const formatted = applicantData.job.video_questions.map((question, index) => {
            const response = applicantData.response_type === 'video' && applicantData.video_responses
              ? 'Video response provided'
              : applicantData.text_responses?.[index.toString()] || 'No response';
              
            const formattedResponse = {
              question,
              response,
              type: applicantData.response_type
            };
            
            console.log(`Formatted response ${index}:`, formattedResponse);
            return formattedResponse;
          });
          
          console.log('All formatted responses:', formatted);
          console.groupEnd();
          setFormattedResponses(formatted);
        } else {
          console.log('No video questions available:', applicantData?.job);
        }

        // Update last viewed
        await supabase
          .from('user_jobs')
          .update({ last_viewed_by_client: new Date().toISOString() })
          .eq('user_job_id', userJobId);

        // Fetch reviews
        const { data: reviewsData, error: reviewsError } = await supabase
          .from('reviews')
          .select(`
            *,
            reviewer:reviewer_details!reviews_reviewer_id_fkey1(
              name, user_id, work_email, login_email, phone, linkedin_url
            )
          `)
          .eq('consultant_id', applicantData.applicant.user_id)
          .order('created_at', { ascending: false });

        if (reviewsError) throw reviewsError;
        setReviews(reviewsData);

        // After fetching reviews data:
        if (reviewsData) {
          console.group('Reviews Formatting Debug');
          console.log('Raw reviews data:', reviewsData);
          
          const formatted = reviewsData.map(review => {
            const formattedReview = {
              type: review.review_type,
              rating: review.rating,
              text: review.review_text,
              reviewer: review.reviewer?.name,
              date: review.created_at
            };
            
            console.log('Individual formatted review:', formattedReview);
            return formattedReview;
          });
          
          console.log('All formatted reviews:', formatted);
          console.groupEnd();
          setFormattedReviews(formatted);
        } else {
          console.log('No reviews data available:', reviewsData);
        }
        
        // Fetch interview notes
        let notesQuery = supabase
          .from('interview_notes')
          .select(`
            *,
            notewriter:reviewer_details!interview_notes_duplicate_notewriter_id_fkey(
              name, employer, photo
            )
          `)
          .eq('consultant_id', applicantData.applicant.user_id)
          .order('created_at', { ascending: false });

        // Only apply employer filter if not system admin
        if (userData?.user_type_id !== 5) { // 5 is system admin type
          notesQuery = notesQuery.eq('notewriter.employer', userData.employer);
        }

        const { data: notesData, error: notesError } = await notesQuery;

        if (notesError) throw notesError;

        if (notesData) {
        // Set raw interview notes
        setInterviewNotes(notesData);

        // Format notes for AI assessment
        const formattedNotes = notesData.map(note => {
          const noteDate = new Date(note.created_at);
          const now = new Date();
          const monthsDiff = (now - noteDate) / (1000 * 60 * 60 * 24 * 30);
          
          // Calculate time-based weight
          let timeWeight = 1.0;
          if (monthsDiff <= 1) timeWeight = 1.3;
          else if (monthsDiff <= 3) timeWeight = 1.2;
          else if (monthsDiff <= 6) timeWeight = 1.1;
          
          // Calculate type-based weight
          const typeWeight = note.note_type === 'Interview' ? 1.5 : 1.0;
          
          return {
            id: note.id,
            type: note.note_type,
            content: note.content,
            feedbackScore: note.feedback_score,
            writer: note.notewriter?.name || 'System',
            date: note.created_at,
            weight: timeWeight * typeWeight
          };
        });

        console.log('Formatted interview notes:', formattedNotes);
        setFormattedInterviewNotes(formattedNotes);
        }

        if (notesData) {
          const formattedNotes = formatInterviewNotes(notesData);
          setFormattedInterviewNotes(formattedNotes);
          setInterviewNotes(notesData);
        }

        console.log('No interviewnotes available:', notesData);

        // Fetch combined work history (job_history and engagements)
        const { data: jobHistoryData, error: jobHistoryError } = await supabase
          .from('job_history')
          .select(`
            *,
            reviews (
              id,
              rating,
              review_text,
              share_contact_info,
              review_type,
              created_at,
              relationship_details,
              job_history_id,
              engagement_id,
              type,
              context_description,
              reviewer:reviewer_details!reviews_reviewer_id_fkey1(
                user_id,
                name,
                job_title,
                employer,
                client:employer (
                  name
                ),
                work_email,
                login_email,
                phone,
                linkedin_url
              ),
              verification_status,
              verified_at
            )
          `)
          .eq('consultant_id', applicantData.applicant.user_id)
          .order('start_date', { ascending: false });

        if (jobHistoryError) throw jobHistoryError;

        const { data: engagementsData, error: engagementsError } = await supabase
          .from('engagements')
          .select(`
            *,
            job:job_id (
              title,
              client:client_id (
                name
              )
            ),
            reviews (
              id,
              rating,
              review_text,
              share_contact_info,
              review_type,
              created_at,
              relationship_details,
              job_history_id,
              engagement_id,
              type,
              context_description,
              reviewer:reviewer_details!reviews_reviewer_id_fkey1(
                user_id,
                name,
                job_title,
                employer,
                client:employer (
                  name
                ),
                work_email,
                login_email,
                phone,
                linkedin_url
              ),
              verification_status,
              verified_at
            )
          `)
          .eq('consultant_id', applicantData.applicant.user_id)
          .order('start_date', { ascending: false });

        // Update the combinedWorkHistory mapping
        const combinedWorkHistory = [
          ...jobHistoryData.map(job => ({
            ...job,
            type: 'job_history',
            job_title: job.job_title,
            company_name: job.company_name,
            reviews: job.reviews // Include full reviews array
          })),
          ...engagementsData.map(engagement => ({
            ...engagement,
            type: 'engagement',
            job_title: engagement.job?.title,
            company_name: engagement.job?.client?.name,
            reviews: engagement.reviews // Include full reviews array
          }))
        ].sort((a, b) => new Date(b.start_date) - new Date(a.start_date));

        setWorkHistory(combinedWorkHistory);

        // Fetch timeline
        const { data: timelineData, error: timelineError } = await supabase
          .from('user_job_statuses')
          .select(`
            id,
            status,
            changed_at,
            changed_by,
            users:reviewer_details(name)
          `)
          .eq('user_job_id', userJobId)
          .order('changed_at', { ascending: false });

        if (timelineError) throw timelineError;

        const formattedTimeline = timelineData.map(event => ({
          id: event.id,
          type: eventTypes.advanced,
          content: `Status changed to ${event.status}`,
          target: event.status,
          date: formatDistanceToNow(new Date(event.changed_at), { addSuffix: true }),
          datetime: event.changed_at,
          changedBy: event.users?.name || 'System'
        }));

        setTimeline(formattedTimeline);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, [userJobId]);

  // Comments-specific useEffect
  useEffect(() => {
    const fetchComments = async () => {
      // Make sure we have both the applicant ID and user data
      if (!applicant?.applicant?.user_id || !user) return;
      
      try {
        let query = supabase
          .from('interview_notes')
          .select(`
            *,
            notewriter:reviewer_details!interview_notes_duplicate_notewriter_id_fkey(
              name, photo, employer
            )
          `)
          .eq('consultant_id', applicant.applicant.user_id)
          .eq('note_type', 'Comment');

        // Only apply employer filter if not system admin
        if (user.user_type_id !== 5) {
          query = query.eq('notewriter.employer', user.employer);
        }

        const { data, error } = await query.order('created_at', { ascending: false });

        if (error) throw error;
        setComments(data || []);
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    };

    fetchComments();
  }, [applicant?.applicant?.user_id, user]);

  const handleAddNote = async () => {
    try {
      const insertData = {
        consultant_id: applicant.applicant.user_id,
        content: newNote,
        notewriter_id: user.user_id,
        note_type: 'Comment',
      };

      const { data, error } = await supabase
        .from('interview_notes')
        .insert(insertData)
        .select('*, notewriter:reviewer_details!interview_notes_duplicate_notewriter_id_fkey(name, photo)')
        .single();

      if (error) throw error;

      setComments(prevComments => [data, ...prevComments]);
      setNewNote('');
    } catch (error) {
      console.error('Error adding note:', error);
    }
  };

  const handleResumeDownload = async () => {
    const signedUrl = await getStorageUrl('resumes', applicant.applicant?.resume);
    if (signedUrl) {
      window.open(signedUrl, '_blank');
    } else {
      console.error('Failed to get resume URL');
      // Optionally add user notification about error
    }
  };

  const handleVideoView = async (index, filePath) => {
    try {
      // First log the view in the new video_views table
      const { error: viewError } = await supabase
        .from('video_views')
        .insert({
          user_job_id: userJobId,
          viewer_id: user.user_id,
          question_index: index,
          user_agent: navigator.userAgent,
        });
  
      if (viewError) throw viewError;
  
      // Get signed URL using our utility
      const signedUrl = await getStorageUrl('video_responses', filePath);
      
      if (!signedUrl) {
        throw new Error('Failed to get video URL');
      }

      window.open(signedUrl, '_blank');
      
      // Track event in Firebase Analytics
      const analytics = getAnalytics();
      logEvent(analytics, 'view_video_response', {
        user_job_id: userJobId,
        question_index: index,
        viewer_id: user.user_id
      });

    } catch (error) {
      console.error("Error handling video view:", error.message);
      setVideoLoadError(error.message);
    }
  }; 

  const handleReject = async () => {
    try {
      const { data } = await supabase
        .from('user_jobs')
        .select('status')
        .eq('user_job_id', userJobId)
        .single();

      const newStatus = data.status === 'Applied' ? 'Interview Rejected' : 'Contract Not Offered';

      const { error } = await supabase
        .from('user_jobs')
        .update({ status: newStatus })
        .eq('user_job_id', userJobId);

      if (error) throw error;
      navigate(-1);
    } catch (error) {
      console.error('Error rejecting applicant:', error);
    }
  };

  const handleInterview = () => {
    navigate(
      applicant.status === 'Interview Scheduled'
        ? '/app/request-second-interview/' + userJobId
        : '/app/schedule-interview/' + userJobId
    );
  };

  const formatDate = (date) => {
    if (!date) return 'Present';
    try {
      return new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
    } catch {
      return '';
    }
  };

  const startChat = async (reviewerId) => {
    try {
      const { data: existingChat, error: fetchError } = await supabase
        .from('chats')
        .select('id')
        .contains('participants', [user.user_id, reviewerId])
        .single();
  
      if (fetchError && fetchError.code !== 'PGRST116') {
        throw fetchError;
      }
  
      let chatId;
      if (existingChat) {
        chatId = existingChat.id;
      } else {
        const { data: newChat, error: insertError } = await supabase
          .from('chats')
          .insert({
            participants: [user.user_id, reviewerId],
          })
          .select()
          .single();
  
        if (insertError) throw insertError;
        chatId = newChat.id;
      }
  
      navigate(`/app/chats`, { state: { newChatUserId: reviewerId } });
    } catch (error) {
      console.error('Error starting chat:', error);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="text-gray-500">Loading...</div>
      </div>
    );
  }

  if (!applicant) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="text-gray-500">Applicant not found</div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`${applicant?.applicant?.name || 'Applicant'} - Details | fetchConsultant`}</title>
        <meta name="description" content={`View details for applicant ${applicant?.applicant?.name || ''} for the ${applicant?.job?.title || 'job'} position.`} />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        {/* Display ConsultantHamburger or ClientHamburger based on user_type_id */}
        {user && user.user_type_id === 5 && (
          <SystemAdminHamburger user={user} currentPage="notifications" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        )}
        {user && user.user_type_id === 4 && (
          <ClientHamburger user={user} currentPage="notifications" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        )}      
      <div className="flex-1 md:ml-64 flex flex-col">
        <header className="bg-white shadow-md p-4 flex items-center justify-between">
          <div className="flex items-center">
            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
              <Menu size={24} />
            </button>

            {/* Use isMenuOpen to conditionally render breadcrumb or back arrow */}
            {isMenuOpen ? (
              <div className="flex items-center cursor-pointer" onClick={() => navigate(-1)}>
                <ArrowLongLeftIcon className="h-6 w-6 text-gray-500" />
                <span className="text-gray-500">Back to Applicants</span>
              </div>
            ) : (
              <nav className="flex items-center text-sm space-x-2">
                <HomeIcon className="h-4 w-4 text-gray-500 cursor-pointer" onClick={() => navigate('/app/client-dashboard')} aria-hidden="true" />
                <span className="text-gray-400">/</span>
                <span className="text-gray-500 cursor-pointer" onClick={() => navigate(-1)}>Applicants</span>
              </nav>
            )}
          </div>

          <div className="flex items-center">
            <NotificationBell />
          </div>
        </header>
      <main className="flex-grow container mx-auto px-4 py-8">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex items-center space-x-5">
                <div className="flex-shrink-0">
                  <Avatar className="h-16 w-16" photo={applicant.applicant?.photo} name={applicant.applicant?.name} clickable={true} />
                </div>
                <div>
                <div className="flex items-center justify-between">
                  {/* User's Name */}
                  <h1 className="text-2xl text-left font-bold text-gray-900">{applicant.applicant?.name}</h1>

                  {/* Badges container */}
                  <div className="ml-4 flex items-center space-x-2">
                    {/* Ex-Epic Badge */}
                    {applicant.applicant?.former_epic && (
                      <span className="bg-green-100 text-green-800 text-m font-semibold mr-2 px-2.5 py-0.5 rounded relative group">
                        Ex-Epic
                        {/* Tooltip for desktop */}
                        <span className="hidden lg:block absolute top-full left-1/2 transform -translate-x-1/2 mt-2 w-64 p-2 bg-gray-800 text-white text-left text-xs rounded-md opacity-0 group-hover:opacity-100 transition-opacity z-10">
                          This consultant has previous experience as an Epic employee
                        </span>
                      </span>
                    )}

                    {/* Fetch Recommended Badge */}
                    {applicant?.fetch_recommended && (
                      <div className="ml-4">
                        <span className="bg-blue-100 text-blue-800 text-m font-semibold mr-2 px-2.5 py-0.5 rounded relative group">
                          fC Recommended
                          {/* Tooltip for desktop */}
                          <span className="hidden lg:block absolute top-full left-1/2 transform -translate-x-1/2 mt-2 w-64 p-2 bg-gray-800 text-white text-left text-xs rounded-md opacity-0 group-hover:opacity-100 transition-opacity z-10">
                            fC Recommended means a certified expert reviewed your job posting and the consultant's application and deemed them a recommend match.
                            {applicant.fetch_recommended_comments && (
                              <p className="mt-2 border-t border-gray-600 pt-2">
                                <span className="font-semibold">fC Comments: </span>
                                {applicant.fetch_recommended_comments}
                              </p>
                            )}
                          </span>
                        </span>

                        {/* Superscript 'i' for mobile */}
                        <sup className="lg:hidden ml-1 cursor-pointer" title={`fC Recommended means a certified expert reviewed your job posting and the consultant's application and deemed them a recommend match.${applicant.fetch_recommended_comments ? '\n\nfC Comments: ' + applicant.fetch_recommended_comments : ''}`}>
                          <i className="text-s text-gray-500">i</i>
                        </sup>
                      </div>
                    )}
                  </div>
                </div>

                <p className="text-sm text-left font-medium text-gray-500">
                  Applied for <a href="#" className="text-gray-900">{applicant.job?.title}</a> on <time dateTime={applicant.submitted_at}>{new Date(applicant.submitted_at).toLocaleDateString()}</time>
                </p>
                </div>
              </div>
              <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
              {!['Interview Rejected'].includes(applicant.status) && (
                <button 
                  type="button" 
                  onClick={handleReject}
                  className="inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Reject
                </button>
              )}
                <button 
                  type="button" 
                  onClick={handleInterview}
                  className="inline-flex items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                  {applicant.status === 'Interview Scheduled' ? 'Request Second Interview' : 'Schedule Interview'}
                </button>
                {!['Contract Offered', 'Contract Accepted', 'Executed'].includes(applicant.status) && (
                  <button 
                    type="button" 
                    onClick={() => navigate('/app/client-offer-contract/' + userJobId)}
                    className="inline-flex items-center justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                  >
                    Offer Contract
                  </button>
                )}
              </div>
            </div>
            <div className="mx-auto mt-8 max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="space-y-6 text-left">
                {/* Applicant Information */}
                <section aria-labelledby="applicant-information-title">
                  <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
                      <div>
                        <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
                          Applicant Information
                        </h2>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and application.</p>
                      </div>
                      <AssessCandidate 
                        applicant={{
                          ...applicant,
                          work_history: workHistory
                        }}
                        formattedReviews={formattedReviews}
                        formattedResponses={formattedResponses}
                        formattedInterviewNotes={formattedInterviewNotes}
/>                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                      <div className="sm:col-span-2">
                          <dt className="text-sm font-medium text-gray-500">About</dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            <ReactQuill
                              value={applicant.applicant?.about || 'No information provided'}
                              readOnly={true}
                              theme="bubble"
                              modules={{ toolbar: false }}
                              style={{
                                '.ql-tooltip.ql-hidden': { display: 'none' },
                                '.ql-toolbar': { display: 'none' },
                              }}
                            />
                          </dd>
                        </div>
                        <div className="sm:col-span-2">
                          <dt className="text-sm font-medium text-gray-500">Submission Notes</dt>
                          <dd className="mt-1 text-sm text-gray-900">
                           <ReactQuill
                              value={applicant.consultant_notes || 'No information provided'}
                              readOnly={true}
                              theme="bubble"
                              modules={{ toolbar: false }}
                              style={{
                                '.ql-tooltip.ql-hidden': { display: 'none' },
                                '.ql-toolbar': { display: 'none' },
                              }}
                            />
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">Location</dt>
                          <dd className="mt-1 text-sm text-gray-900">{applicant.applicant?.city}, {applicant.applicant?.state}</dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">Consultant Rate</dt>
                          <dd className="mt-1 text-sm text-gray-900">${applicant.consultant_rate}/hr</dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">Current Project End Date</dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {applicant.applicant?.current_project_end_date
                              ? format(new Date(applicant.applicant.current_project_end_date), 'MM/dd/yyyy')
                              : 'N/A'}
                          </dd>                        
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">Available Start Date</dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {applicant.available_start_date && format(new Date(applicant.available_start_date), 'MM/dd/yyyy')}
                          </dd>
                        </div>
                        <div className="sm:col-span-2 grid grid-cols-2 gap-4">
                          <div>
                            <dt className="text-sm font-medium text-gray-500">LinkedIn</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              <a href={applicant.applicant?.linkedin_url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-900">
                                View Profile
                              </a>
                            </dd>
                          </div>
                          <div>
                            <dt className="text-sm font-medium text-gray-500">Resume</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                            <button onClick={handleResumeDownload} className="text-blue-600 hover:text-blue-900">
                              <Download className="inline-block w-5 h-5 mr-1" />
                              Download Resume
                            </button>
                            </dd>
                          </div>
                        </div>
                        <div className="sm:col-span-2">
                          <dt className="text-sm font-medium text-gray-500">Epic Certifications</dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            <div className="flex flex-wrap">
                              {applicant.applicant?.user_epic_certifications.map((cert, index) => (
                                <span key={index} className="bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                                  {cert.epic_applications.name}
                                </span>
                              ))}
                            </div>
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </section>

                {/* Application Responses */}
                {applicant.job?.video_questions?.length > 0 && (
                  <section aria-labelledby="responses-title">
                    <div className="bg-white shadow sm:rounded-lg">
                      <div className="px-4 py-5 sm:px-6">
                        <h2 id="responses-title" className="text-lg font-medium leading-6 text-gray-900">
                          {applicant.response_type === 'video' ? 'Video Responses' : 'Text Responses'}
                        </h2>
                      </div>
                      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                        {applicant.job.video_questions.map((question, index) => (
                          <div key={index} className="mb-4">
                            <h3 className="text-sm font-medium text-gray-500">{question}</h3>
                            {applicant.response_type === 'video' ? (
                              // Video response display
                              applicant.video_responses && applicant.video_responses[index] ? (
                                <div>
                                  <button
                                    className="mt-1 text-blue-600 hover:text-blue-900 flex items-center"
                                    onClick={async () => handleVideoView(index, applicant.video_responses[index])}
                                  >
                                    <PlayCircle className="w-5 h-5 mr-1" />
                                    Watch Response
                                  </button>
                                  {videoLoadError && (
                                    <div className="text-red-600 text-sm mt-2">
                                      Error loading video: {videoLoadError}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <p className="mt-1 text-sm text-gray-500">No response uploaded for this question</p>
                              )
                            ) : (
                              // Text response display
                              applicant.text_responses && applicant.text_responses[index] ? (
                                <div className="mt-2 text-sm text-gray-900 bg-gray-50 rounded-md p-4">
                                  {applicant.text_responses[index]}
                                </div>
                              ) : (
                                <p className="mt-1 text-sm text-gray-500">No response provided for this question</p>
                              )
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </section>
                )}

                {/* Work History Section */}
                <section aria-labelledby="work-history-title">
                  <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                      <h2 id="work-history-title" className="text-lg font-medium leading-6 text-gray-900">
                        Work History
                      </h2>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                      <div className="overflow-x-auto">
                      <table className="min-w-full">
                        <thead className="bg-gray-50">
                          <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Position</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Company</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Duration</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Reviews</th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {workHistory.map((job) => {
                            const verifiedCount = job.reviews?.filter(r => r.verification_status === 'verified').length || 0;
                            const avgRating = job.reviews?.length > 0
                              ? job.reviews.reduce((sum, r) => sum + r.rating, 0) / job.reviews.length
                              : 0;
                            
                            return (
                              <tr 
                                key={job.id} 
                                onClick={() => {
                                  setSelectedWorkHistory(job);
                                  setIsWorkHistoryModalOpen(true);
                                }}
                                className="relative hover:bg-gray-50 cursor-pointer group"
                              >
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="text-sm text-gray-900">{job.job_title}</div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{job.company_name}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                  {formatDate(job.start_date)} - {formatDate(job.end_date)}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  {verifiedCount > 0 ? (
                                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-blue-600 text-white">
                                      Verified ({verifiedCount})
                                    </span>
                                  ) : (
                                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
                                      Unverified
                                    </span>
                                  )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  {avgRating > 0 ? (
                                    <div className="flex items-center">
                                      {renderStars(avgRating)}
                                      <span className="ml-2 text-sm text-gray-900">
                                        ({job.reviews.length})
                                      </span>
                                    </div>
                                  ) : (
                                    <span className="text-sm text-gray-500">No Reviews</span>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      </div>
                    </div>
                  </div>
                </section>

                {/* Work History Modal */}
                <ApplicantWorkHistoryModal
                  isOpen={isWorkHistoryModalOpen}
                  onClose={() => {
                    setIsWorkHistoryModalOpen(false);
                    setSelectedWorkHistory(null);
                  }}
                  workHistory={selectedWorkHistory}
                  onStartChat={startChat}
                />

                {/* Notes Section */}
                <section aria-labelledby="notes-title" className="mt-8">
                  <div className="bg-white shadow sm:rounded-lg">
                    <div className="divide-y divide-gray-200">
                      <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
                        <h2 id="notes-title" className="text-lg font-medium text-gray-900">Notes</h2>
                        <button
                          onClick={() => navigate('/app/create-interview-note', { state: { consultantId: applicant.applicant.user_id, consultantName: applicant.applicant.name } })}
                          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                          Create Note
                        </button>
                      </div>
                      <div className="px-4 py-6 sm:px-6 overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Notewriter</th>
                              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Rating</th>
                              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {interviewNotes.filter(note => note.note_type === 'Interview' || note.note_type === 'AI Summary' || note.note_type === 'General').map((note) => (
                              <tr 
                                key={note.id} 
                                onClick={() => setSelectedNote(note)}
                                className="relative hover:bg-gray-50 cursor-pointer group"
                              >
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                  {note.notewriter?.name ?? 'AI Summary'}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                  {note.note_type}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                  {note.feedback_score ? (
                                    <div className="flex">
                                      {[1, 2, 3, 4, 5].map((star) => (
                                        <Star
                                          key={star}
                                          size={16}
                                          className={star <= note.feedback_score ? 'text-yellow-400' : 'text-gray-300'}
                                          fill={star <= note.feedback_score ? 'currentColor' : 'none'}
                                        />
                                      ))}
                                    </div>
                                  ) : 'N/A'}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                  {new Date(note.created_at).toLocaleDateString('en-US', {
                                    month: '2-digit',
                                    day: '2-digit',
                                    year: 'numeric'
                                  })}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <NoteModal note={selectedNote} onClose={() => setSelectedNote(null)} />
                </section>


                {/* Comments Section */}
                <section aria-labelledby="comments-title">
                  <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                    <div className="divide-y divide-gray-200">
                      <div className="px-4 py-5 sm:px-6">
                        <h2 id="comments-title" className="text-lg font-medium text-gray-900">Comments</h2>
                      </div>
                      <div className="px-4 py-6 sm:px-6">
                        <ul role="list" className="space-y-8">
                          {comments.map((comment) => (
                            <li key={comment.id}>
                              <div className="flex space-x-3">
                                <div className="flex-shrink-0">
                                  <Avatar 
                                    className="h-10 w-10" 
                                    photo={comment.notewriter?.photo} 
                                    name={comment.notewriter?.name || 'Unknown User'}
                                    clickable={true}
                                  />
                                </div>
                                <div className="flex items-start justify-between w-full">
                                  <div className="flex-grow">
                                    <div className="text-sm">
                                      <a href="#" className="font-medium text-gray-900">
                                        {comment.notewriter?.name || 'Unknown User'}
                                      </a>
                                    </div>
                                    <div className="mt-1 text-sm text-gray-900">
                                      <p>{comment.content}</p>
                                    </div>
                                  </div>
                                  <div className="flex-shrink-0 ml-4">
                                    <span className="text-sm text-gray-500 whitespace-nowrap">
                                      {new Date(comment.created_at).toLocaleString()}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                        
                        {/* Add comment form */}
                        <div className="mt-6">
                          <div className="flex space-x-3">
                            <div className="flex-shrink-0">
                              <Avatar className="h-10 w-10" photo={user?.photo} name={user?.name} clickable={true} />
                            </div>
                            <div className="min-w-0 flex-1">
                              <form onSubmit={(e) => { e.preventDefault(); handleAddNote(); }}>
                                <div>
                                  <label htmlFor="comment" className="sr-only">Add a comment</label>
                                  <textarea
                                    id="comment"
                                    name="comment"
                                    rows={3}
                                    className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"
                                    placeholder="Add a comment"
                                    value={newNote}
                                    onChange={(e) => setNewNote(e.target.value)}
                                  />
                                </div>
                                <div className="mt-3 flex items-center justify-between">
                                  <button
                                    type="submit"
                                    className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                  >
                                    Comment
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                {/* Timeline Section */}
                <section aria-labelledby="timeline-title">
                  <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                      <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
                        Timeline
                      </h2>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                      {/* Activity Feed */}
                      <div className="flow-root">
                        <ul role="list" className="-mb-8">
                          {timeline.map((item, itemIdx) => (
                            <li key={item.id}>
                              <div className="relative pb-8">
                                {itemIdx !== timeline.length - 1 ? (
                                  <span
                                    className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                    aria-hidden="true"
                                  />
                                ) : null}
                                <div className="relative flex space-x-3">
                                  <div>
                                    <span
                                      className={classNames(
                                        item.type.bgColorClass,
                                        'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                                      )}
                                    >
                                      <item.type.icon className="h-5 w-5 text-white" aria-hidden="true" />
                                    </span>
                                  </div>
                                  <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                    <div>
                                      <p className="text-sm text-gray-500">
                                        {item.content}{' '}
                                        <span className="font-medium text-gray-900">
                                          {item.target}
                                        </span>
                                      </p>
                                      <p className="text-xs text-gray-400">
                                        Changed by: {item.changedBy}
                                      </p>
                                    </div>
                                    <div className="text-right text-sm whitespace-nowrap text-gray-500">
                                      <time dateTime={item.datetime}>{item.date}</time>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ApplicantDetails;