// Update imports if needed
import { getAnalytics, logEvent } from "firebase/analytics";
import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import { toast } from 'react-toastify';
import WelcomeStep from './WelcomeStep';
import VerificationStep from './VerificationStep';
import ReviewStep from './ReviewStep';
import SummaryStep from './SummaryStep';
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;

const STEPS = {
  WELCOME: 0,
  VERIFY: 1,
  REVIEW: 2,
  SUMMARY: 3
};

const JobVerificationWizard = () => {
  const [currentStep, setCurrentStep] = useState(STEPS.WELCOME);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [consultant, setConsultant] = useState(null);
  const [verificationType, setVerificationType] = useState(null);
  const [detailsToVerify, setDetailsToVerify] = useState(null);
  const [jobDetails, setJobDetails] = useState(null);
  const [tokenData, setTokenData] = useState(null);
  const [existingVerifications, setExistingVerifications] = useState([]);
  const [formData, setFormData] = useState({
    isVerified: null,
    verificationNotes: '',
    rating: null,
    reviewText: '',
    reviewType: '',
    relationshipDetails: '',
    type: '',
    shareContactInfo: true
  });

  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Job Verification Wizard',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  
    fetchUserAndToken();
  }, [token]);
  
  // Add this new useEffect right after
  useEffect(() => {
    if (tokenData) {
      setFormData(prev => ({
        ...prev,
        type: tokenData.job_history_id ? 'job_history' : 'engagement'
      }));
    }
  }, [tokenData]);

const fetchUserAndToken = useCallback(async () => {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) {
      navigate(`/app/review-benefits/${token}`);
      return;
    }
    setUser(user);

    // First fetch the token data and user data
    const [tokenResponse, userData] = await Promise.all([
      supabase
        .from('review_tokens')
        .select(`
          token,
          consultant_id,
          job_history_id,
          engagement_id,
          review_type,
          relationship_details,
          used_at,
          reviewer_email,
          reviewer_phone
        `)
        .eq('token', token)
        .single(),
      supabase
        .from('users')
        .select('login_email, work_email, phone')
        .eq('user_id', user.id)
        .single()
    ]);

    if (tokenResponse.error) throw tokenResponse.error;
    if (userData.error) throw userData.error;

    // Check if token has already been used
    if (tokenResponse.data.used_at) {
      throw new Error('This verification link has already been used');
    }

    // Check if the current user is the intended reviewer
    const isAuthorizedReviewer = 
      (tokenResponse.data.reviewer_email && (
        tokenResponse.data.reviewer_email === userData.data.login_email || 
        tokenResponse.data.reviewer_email === userData.data.work_email
      )) ||
      (tokenResponse.data.reviewer_phone && tokenResponse.data.reviewer_phone === userData.data.phone);

    if (!isAuthorizedReviewer) {
      throw new Error('You are not authorized to use this verification link');
    }

    // Check for existing review
    const { data: existingReview, error: existingReviewError } = await supabase
      .from('reviews')
      .select('id')
      .eq('reviewer_id', user.id)
      .eq('consultant_id', tokenResponse.data.consultant_id)
      .eq(tokenResponse.data.job_history_id ? 'job_history_id' : 'engagement_id', 
          tokenResponse.data.job_history_id || tokenResponse.data.engagement_id)
      .single();

    if (existingReviewError && existingReviewError.code !== 'PGRST116') { // PGRST116 is "no rows returned"
      throw existingReviewError;
    }

    if (existingReview) {
      throw new Error('You have already submitted a review for this verification');
    }

    // Check if user is trying to review themselves
    if (user.id === tokenResponse.data.consultant_id) {
      throw new Error('You cannot verify or review your own profile');
    }

    setTokenData(tokenResponse.data);

    // Then fetch the consultant info from our new view
    const { data: consultantData, error: consultantError } = await supabase
      .from('consultant_basic_info')
      .select('*')
      .eq('consultant_id', tokenResponse.data.consultant_id)
      .single();

    if (consultantError) throw consultantError;
    setConsultant(consultantData);
    
    if (tokenResponse.data.job_history_id) {
      setVerificationType('job_history');
      const { data: jobHistoryData, error: jobHistoryError } = await supabase
        .from('job_history')
        .select(`
          id,
          job_title,
          company_name,
          start_date,
          end_date,
          job_description,
          work_type,
          location_type,
          job_type,
          firm_name
        `)
        .eq('id', tokenResponse.data.job_history_id)
        .single();

      if (jobHistoryError) throw jobHistoryError;
      setDetailsToVerify(jobHistoryData);
      setJobDetails({
        job_title: jobHistoryData.job_title,
        company_name: jobHistoryData.company_name,
        start_date: jobHistoryData.start_date,
        end_date: jobHistoryData.end_date,
        description: jobHistoryData.job_description,
        work_type: jobHistoryData.work_type,
        location_type: jobHistoryData.location_type,
        job_type: jobHistoryData.job_type,
        firm_name: jobHistoryData.firm_name
      });

      // Fetch existing verifications with specific columns
      const { data: existingJobVerifications, error: verificationError } = await supabase
        .from('reviews')
        .select(`
          id,
          verification_status,
          verified_at,
          job_history_id,
          reviewer_id
        `)
        .eq('job_history_id', tokenResponse.data.job_history_id)
        .eq('verification_status', 'verified')
        .order('verified_at', { ascending: false });

      if (verificationError) throw verificationError;

      // Fetch reviewer info separately
      const reviewerIds = existingJobVerifications.map(v => v.reviewer_id);
      const { data: reviewerData, error: reviewerError } = await supabase
        .from('reviewer_basic_info')
        .select('*')
        .in('reviewer_id', reviewerIds);

      if (reviewerError) throw reviewerError;

      // Combine the data
      const combinedVerifications = existingJobVerifications.map(verification => ({
        ...verification,
        reviewer: reviewerData.find(r => r.reviewer_id === verification.reviewer_id)
      }));

      setExistingVerifications(combinedVerifications || []);

    } else if (tokenResponse.data.engagement_id) {
      setVerificationType('engagement');
      const { data: engagementData, error: engagementError } = await supabase
        .from('engagement_verification_data')
        .select('*')
        .eq('id', tokenResponse.data.engagement_id)
        .single();

      if (engagementError) throw engagementError;
      setDetailsToVerify(engagementData);
      setJobDetails({
        job_title: engagementData.job_title,
        company_name: engagementData.client_name,
        start_date: engagementData.start_date,
        end_date: engagementData.end_date,
        description: engagementData.consultant_description,
        work_type: engagementData.work_type || 'full-time',
        location_type: engagementData.location_type || 'remote',
        job_type: engagementData.job_type || 'contract',
        firm_name: engagementData.firm_name || 'fetchConsultant'
      });

      // Fetch existing verifications with specific columns
      const { data: existingEngagementVerifications, error: verificationError } = await supabase
        .from('reviews')
        .select(`
          id,
          verification_status,
          verified_at,
          engagement_id,
          reviewer_id
        `)
        .eq('engagement_id', tokenResponse.data.engagement_id)
        .eq('verification_status', 'verified')
        .order('verified_at', { ascending: false });

      if (verificationError) throw verificationError;

      // Fetch reviewer info separately
      const reviewerIds = existingEngagementVerifications.map(v => v.reviewer_id);
      const { data: reviewerData, error: reviewerError } = await supabase
        .from('reviewer_basic_info')
        .select('*')
        .in('reviewer_id', reviewerIds);

      if (reviewerError) throw reviewerError;

      // Combine the data
      const combinedVerifications = existingEngagementVerifications.map(verification => ({
        ...verification,
        reviewer: reviewerData.find(r => r.reviewer_id === verification.reviewer_id)
      }));

      setExistingVerifications(combinedVerifications || []);
    }

  } catch (error) {
    console.error('Error fetching review data:', error);
    toast.error('Failed to load verification details');
    setError(error.message);
  } finally {
    setIsLoading(false);
  }
}, [token, navigate]);

  // Update useEffect with fetchUserAndToken dependency
  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Job Verification Wizard',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUserAndToken();
  }, [fetchUserAndToken]); // Add fetchUserAndToken as dependency

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      if (!detailsToVerify?.id || !user?.id) {
        throw new Error('Missing required data');
      }
  
      // Fetch user type
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('user_type_id')
        .eq('user_id', user.id)
        .single();
  
      if (userError) throw userError;
      const userType = userData.user_type_id;
  
      const isFirstVerification = !existingVerifications.length;
  
      // Set a default review_type when disputing
      const reviewPayload = {
        consultant_id: tokenData.consultant_id,
        reviewer_id: user.id,
        type: verificationType,
        // Set a default review_type when disputing, or use the existing one for verifications
        review_type: formData.isVerified ? formData.reviewType : 'other',
        rating: formData.rating,
        review_text: formData.reviewText,
        relationship_details: formData.reviewType === 'other' ? formData.relationshipDetails : null,
        share_contact_info: formData.shareContactInfo,
        verification_status: formData.isVerified ? 'verified' : 'disputed',
        verification_notes: formData.verificationNotes,
        verified_at: formData.isVerified ? new Date().toISOString() : null,
        [verificationType === 'job_history' ? 'job_history_id' : 'engagement_id']: detailsToVerify.id
      };
  
      // If this is first verification and it's being verified, include and lock description
      if (isFirstVerification && formData.isVerified) {
        reviewPayload.context_description = detailsToVerify.description || detailsToVerify.consultant_description;
      }
  
      const { error: reviewError } = await supabase
        .from('reviews')
        .insert(reviewPayload);
  
      if (reviewError) throw reviewError;
  
      // Update review token
      const { error: tokenError } = await supabase
        .from('review_tokens')
        .update({ 
          used_at: new Date().toISOString(),
          status: 'completed',
        })
        .eq('token', token);
  
      if (tokenError) throw tokenError;
  
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.access_token) {
        throw new Error('No valid session found');
      }
      // Send notification
      const notificationData = {
        userId: tokenData.consultant_id,
        content: formData.isVerified 
          ? `Your ${verificationType === 'job_history' ? 'employment' : 'engagement'} has been verified${isFirstVerification ? ' for the first time' : ''}.`
          : `Your ${verificationType === 'job_history' ? 'employment' : 'engagement'} has been disputed. Please review the feedback provided.`,
        senderName: user.name,
        verificationNotes: formData.verificationNotes,
        [verificationType === 'job_history' ? 'job_history_id' : 'engagement_id']: detailsToVerify.id,
        verification_type: verificationType,
        verification_status: formData.isVerified ? 'verified' : 'disputed',
        reviewToken: tokenData.token  // Change this from 'token' to 'tokenData.token'
      };

      console.log('Sending notification with data:', notificationData); // Add this debug log

      // Then update the fetch call:
      await fetch(`${supabaseUrl}/functions/v1/process-notifications`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.access_token}`
        },
        body: JSON.stringify({
          type: formData.isVerified ? 'verification_verified' : 'verification_disputed',
          data: notificationData
        })
      });
  
      const analytics = getAnalytics();
      logEvent(analytics, 'verification_completed', {
        type: verificationType,
        was_verified: formData.isVerified,
        included_review: formData.rating > 0 || formData.reviewText.trim().length > 0,
      });
  
      toast.success('Thank you for your verification and review!');
  
      // Redirect based on user type
      if (userType === 2) {
        navigate('/app/consultant-dashboard');
      } else if (userType === 4) {
        navigate('/app/client-dashboard');
      } else {
        navigate('/app'); // Default fallback
      }
    } catch (error) {
      console.error('Error submitting verification:', error);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case STEPS.WELCOME:
        return (
          <WelcomeStep 
            consultantName={consultant?.name}
            onNext={handleNext}
            onBack={handleBack}
            existingVerifications={existingVerifications}
          />
        );
      case STEPS.VERIFY:
        return (
          <VerificationStep
            jobDetails={jobDetails}
            formData={formData}
            onChange={updateFormData}
            onBack={handleBack}
            onNext={handleNext}
            existingVerifications={existingVerifications}
          />
        );
      case STEPS.REVIEW:
        return (
          <ReviewStep
            formData={formData}
            onChange={updateFormData}
            onBack={handleBack}
            onNext={handleNext}
            tokenData={tokenData}
            verificationType={verificationType}
            detailsToVerify={detailsToVerify}
            existingVerifications={existingVerifications}
          />
        );
      case STEPS.SUMMARY:
        return (
          <SummaryStep
            formData={formData}
            verificationType={verificationType}
            detailsToVerify={detailsToVerify}
            onBack={handleBack}
            onSubmit={handleSubmit}
            isSubmitting={isLoading}
            existingVerifications={existingVerifications}
          />
        );
      default:
        return null;
    }
  };

  const handleNext = () => {
    setCurrentStep(prev => prev + 1);
  };

  const handleBack = () => {
    // If we're on the summary step and the person is disputing
    if (currentStep === STEPS.SUMMARY && !formData.isVerified) {
      // Go back to verification step
      setCurrentStep(STEPS.VERIFY);
    } else {
      // Otherwise just go back one step
      setCurrentStep(prev => prev - 1);
    }
  };

  const updateFormData = (updates) => {
    setFormData(prev => ({
      ...prev,
      ...updates
    }));
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <div className="p-4 text-red-600">
        Error: {error}
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Verify Employment History | fetchConsultant</title>
        <meta 
          name="description" 
          content="Verify employment history and provide a review for an Epic consultant. Help maintain high standards in healthcare IT consulting." 
        />
      </Helmet>

      <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl text-left mx-auto">
          {/* Progress Bar */}
          <div className="mb-8">
            <div className="flex justify-between items-center mb-2">
              {Object.values(STEPS).map((step) => (
                <div 
                  key={step}
                  className={`w-8 h-8 rounded-full flex items-center justify-center ${
                    currentStep === step 
                      ? 'bg-blue-600 text-white' 
                      : currentStep > step
                        ? 'bg-green-500 text-white'
                        : 'bg-gray-200 text-gray-600'
                  }`}
                >
                  {step + 1}
                </div>
              ))}
            </div>
            <div className="relative">
              <div className="absolute top-0 left-0 h-1 bg-gray-200 w-full" />
              <div 
                className="absolute top-0 left-0 h-1 bg-blue-600 transition-all duration-300"
                style={{ width: `${(currentStep / (Object.keys(STEPS).length - 1)) * 100}%` }}
              />
            </div>
          </div>

          {/* Step Content */}
          <div className="bg-white shadow rounded-lg">
            {renderStepContent()}
          </div>
        </div>
      </div>
    </>
  );
};

export default JobVerificationWizard;