// src/components/ui/DisputeDetailsModal.js

import { getAnalytics, logEvent } from "firebase/analytics";
import React, { useState } from 'react';
import Modal from './Modal';
import { supabase } from '../../supabaseClient';
import { toast } from 'react-toastify';
import { AlertTriangle, Calendar, Briefcase, MapPin, Building } from 'lucide-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const DisputeDetailsModal = ({ 
  isOpen, 
  onClose, 
  jobData,
  type // 'engagement' or 'job_history'
}) => {

  const formatDate = (date) => {
    if (!date) return 'Present';
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const isEngagement = type === 'engagement';
  const title = isEngagement ? jobData.job.title : jobData.job_title;
  const organization = isEngagement ? jobData.client.name : jobData.company_name;
  const description = isEngagement ? 
    (jobData.consultant_description || jobData.job.description) : 
    jobData.job_description;

  // Check if there are any verified reviews
  const hasVerifiedReviews = jobData.reviews?.some(review => 
    review.verification_status === "verified" && review.verified_at !== null
  );

  // Log for debugging
  console.log('Has verified reviews:', hasVerifiedReviews);
  console.log('Reviews verification status:', jobData.reviews?.map(r => ({
    status: r.verification_status,
    verified_at: r.verified_at
  })));

  // Use this condition to disable editing
  const isEditingDisabled = hasVerifiedReviews;

  console.log('jobData:', jobData);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Disputed Employment Details"
      size="lg"
    >
      <div className="p-6">
        {/* Dispute Status Banner */}
        <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4 mb-6">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <AlertTriangle className="h-6 w-6 text-yellow-400" />
            </div>
            <div className="ml-3">
              <h3 className="text-yellow-800 font-medium">Employment Details Disputed</h3>
              <p className="text-yellow-700 text-sm mt-1">
                Please review the feedback below and update your work history accordingly.
              </p>
            </div>
          </div>
        </div>

        {isEditingDisabled && (
          <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-yellow-700">
                  This record has verified reviews and cannot be edited. Your only option is to delete this work history record (along with the associated reviews) and to create a new one.
                </p>
              </div>
            </div>
          </div>
        )}

        {/* Employment Details */}
        <div className="bg-white rounded-lg border border-gray-200 mb-6">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Current Employment Details</h3>
            <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
              <div className="flex items-center">
                <Briefcase className="h-5 w-5 text-gray-400 mr-2" />
                <div>
                  <dt className="text-sm font-medium text-gray-500">Position</dt>
                  <dd className="mt-1 text-sm text-gray-900">{jobData.job_title}</dd>
                </div>
              </div>
              
              <div className="flex items-center">
                <Building className="h-5 w-5 text-gray-400 mr-2" />
                <div>
                  <dt className="text-sm font-medium text-gray-500">Company</dt>
                  <dd className="mt-1 text-sm text-gray-900">{jobData.company_name}</dd>
                </div>
              </div>

              <div className="flex items-center">
                <Calendar className="h-5 w-5 text-gray-400 mr-2" />
                <div>
                  <dt className="text-sm font-medium text-gray-500">Duration</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {formatDate(jobData.start_date)} - {formatDate(jobData.end_date)}
                  </dd>
                </div>
              </div>

              <div className="flex items-center">
                <MapPin className="h-5 w-5 text-gray-400 mr-2" />
                <div>
                  <dt className="text-sm font-medium text-gray-500">Work Type</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {jobData.work_type} • {jobData.location_type} • {jobData.job_type}
                  </dd>
                </div>
              </div>
            </dl>
          </div>
        </div>

        {/* Manager's Feedback */}
        <div className="bg-white rounded-lg border border-gray-200 mb-6">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Reviewer's Feedback</h3>
            <div className="bg-gray-50 rounded-lg p-4">
              <ReactQuill
                value={jobData.reviews?.find(review => review.verification_status === "disputed")?.verification_notes || ''}
                readOnly={true}
                theme="bubble"
                modules={{ toolbar: false }}
              />
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DisputeDetailsModal;