import { getAnalytics, logEvent } from "firebase/analytics";
import { Menu } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { supabase } from '../supabaseClient';
import ClientHamburger from './ClientHamburger';
import NotificationBell from "./NotificationBell";
import Modal from './ui/Modal';

const ClientRequests = () => {
  const [requests, setRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Client Requests',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUser();
  }, []);

  useEffect(() => {
    if (user) {
      fetchRequests();
    }
  }, [user]);

  useEffect(() => {
    const hasPendingRequests = requests.length > 0;
    localStorage.setItem('hasClientRequests', hasPendingRequests.toString());
  }, [requests]);

  const fetchUser = async () => {
    try {
      const { data: { user: authUser } } = await supabase.auth.getUser();
      if (!authUser) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', authUser.id)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  };

  const fetchRequests = async () => {
    try {
      setIsLoading(true);
      const { data: { user } } = await supabase.auth.getUser();

      // Get user's employer (client_id)
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('employer')
        .eq('user_id', user.id)
        .single();

      if (userError) throw userError;

      // Fetch engagements ready for SOW signing
      const { data, error } = await supabase
        .from('engagements')
        .select(`
          *,
          consultant:users!consultant_id(
            name,
            photo
          )
        `)
        .eq('client_id', userData.employer)
        .in('ica_status', ['Executed', 'Consultant Signed'])
        .eq('sow_status', 'fC Signed')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setRequests(data);
    } catch (error) {
      console.error('Error fetching requests:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignSOW = async (engagement) => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      
      const response = await fetch(`${process.env.REACT_APP_SUPABASE_FUNCTIONS_URL}/getSigningLink`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session?.access_token}`
        },
        body: JSON.stringify({
          documentId: engagement.sow_document_id,
          recipientEmail: engagement.signer_email,
          recipientId: 'Customer'
        }),
      });

      const data = await response.json();
      if (!data.success || !data.signingLink) {
        throw new Error('Failed to get signing link');
      }

      const analytics = getAnalytics();
      logEvent(analytics, 'sow_signing_initiated', {
        engagement_id: engagement.id
      });

      window.location.href = data.signingLink;
    } catch (error) {
      console.error('Error getting signing link:', error);
      alert('Failed to generate signing link. Please try again.');
    }
  };

  const handleViewSOW = async (engagement) => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      
      const response = await fetch(
        `${process.env.REACT_APP_SUPABASE_URL}/functions/v1/getDocumentViewingLink`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${session.access_token}`
          },
          body: JSON.stringify({
            documentId: engagement.sow_document_id
          }),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to get document');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
      
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 100);

    } catch (error) {
      console.error('Error viewing document:', error);
      alert('Failed to open document. Please try again.');
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    });
  };

  return (
    <>
      <Helmet>
        <title>Sign SOW Requests | fetchConsultant</title>
        <meta 
          name="description" 
          content="View and sign Statement of Work (SOW) documents for your consultants on fetchConsultant." 
        />
      </Helmet>

      <div className="min-h-screen bg-gray-100 flex">
        <ClientHamburger 
          user={user} 
          currentPage="requests" 
          isOpen={isMenuOpen} 
          onClose={() => setIsMenuOpen(false)}
          hasRequests={requests.length > 0}
        />
        
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">SOW Signing Requests</h1>
            </div>
            <div className="flex items-center">
              <NotificationBell />
            </div>
          </header>

          <main className="flex-1 p-4">
            <div className="max-w-7xl text-left mx-auto">
              {isLoading ? (
                <div className="text-center py-8">Loading requests...</div>
              ) : requests.length === 0 ? (
                <div className="text-center py-8">No pending SOW signing requests.</div>
              ) : (
                <div className="space-y-4">
                  {requests.map((request) => (
                    <div key={request.id} className="bg-white rounded-lg shadow p-6">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-4">
                          <img
                            src={request.consultant?.photo || '/default-avatar.png'}
                            alt={request.consultant?.name}
                            className="h-12 w-12 rounded-full"
                          />
                          <div>
                            <h2 className="text-lg font-semibold">
                              {request.job_title}
                            </h2>
                            <p className="text-gray-600">
                              Consultant: {request.consultant?.name}
                            </p>
                          </div>
                        </div>
                        <div className="flex space-x-4">
                          <button
                            onClick={() => handleViewSOW(request)}
                            className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded"
                          >
                            View SOW
                          </button>
                          <button
                            onClick={() => handleSignSOW(request)}
                            className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded"
                          >
                            Sign SOW
                          </button>
                        </div>
                      </div>
                      <div className="mt-4 grid grid-cols-1 gap-2 text-sm">
                        <div>
                          <span className="text-gray-500">Start Date:</span>{' '}
                          {formatDate(request.start_date)}
                        </div>
                        <div>
                          <span className="text-gray-500">End Date:</span>{' '}
                          {formatDate(request.end_date)}
                        </div>
                        <div>
                          <span className="text-gray-500">Contract Rate:</span>{' '}
                          ${request.contract_rate}/hr
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </main>
        </div>
      </div>

      <Modal
        isOpen={isViewModalOpen}
        onClose={() => setIsViewModalOpen(false)}
        title="View SOW"
      >
        {/* Modal content here if needed */}
      </Modal>
    </>
  );
};

export default ClientRequests;