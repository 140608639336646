import { getAnalytics, logEvent } from "firebase/analytics";
import { Apple } from 'lucide-react';
import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { 
  checkEmailExists,
  createUserRecord,
  signInWithEmail, 
  signInWithGoogle, 
  signUpWithEmail, 
  fetchReferralDetails 
} from '../supabaseAuth';

const AuthScreen = () => {
  // Use refs to maintain values across re-renders
  const emailRef = useRef(null);
  const referralTokenRef = useRef(null);
  const isInitializedRef = useRef(false);

  const [isSignUp, setIsSignUp] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [referralData, setReferralData] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const analytics = getAnalytics();

  // Separate effect for Firebase-related operations
  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_title: isSignUp ? 'Sign Up' : 'Sign In',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }, [analytics, isSignUp]);

  // Main initialization effect
  useEffect(() => {
    const initializeAuth = async () => {
      if (isInitializedRef.current) return;
      isInitializedRef.current = true;

      const params = new URLSearchParams(location.search);
      const referralTokenParam = params.get('referral');
      const reviewTokenParam = params.get('token');
      const emailParam = params.get('email');

      // Store values in refs and state
      if (emailParam) {
        emailRef.current = emailParam;
        setEmail(emailParam);
        // Also store in sessionStorage as backup
        sessionStorage.setItem('auth_email', emailParam);
      }

      if (referralTokenParam) {
        referralTokenRef.current = referralTokenParam;
        localStorage.setItem('pending_referral_token', referralTokenParam);
        setIsSignUp(true);

        try {
          const data = await fetchReferralDetails(referralTokenParam);
          if (data) {
            setReferralData(data);
            if (data.referred_email && !emailParam) {
              emailRef.current = data.referred_email;
              setEmail(data.referred_email);
              sessionStorage.setItem('auth_email', data.referred_email);
            }
          }
        } catch (error) {
          console.error('Error fetching referral details:', error);
          setError('Invalid or expired referral link.');
        }
      }

      if (reviewTokenParam) {
        localStorage.setItem('pending_review_token', reviewTokenParam);
      }
    };

    initializeAuth();

    // Cleanup function
    return () => {
      isInitializedRef.current = false;
    };
  }, [location]);

  // Effect to handle email persistence
  useEffect(() => {
    const persistedEmail = sessionStorage.getItem('auth_email');
    if (persistedEmail && !email) {
      setEmail(persistedEmail);
      emailRef.current = persistedEmail;
    }

    // Check Supabase session
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (session?.user) {
        navigate('/app/auth/callback', { 
          state: { 
            email: emailRef.current || sessionStorage.getItem('auth_email'),
            referralToken: referralTokenRef.current 
          } 
        });
      }
    });

    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN') {
        handleSuccessfulAuth(session.user);
      }
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [navigate]);

  const handleSuccessfulAuth = async (user) => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      const accessToken = session?.access_token;
      const finalEmail = emailRef.current || sessionStorage.getItem('auth_email');

      // First try to handle through auth-success endpoint
      try {
        const response = await fetch(`${process.env.REACT_APP_SUPABASE_URL}/functions/v1/auth-success`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          },
          body: JSON.stringify({
            userId: user.id
          })
        });

        if (response.ok) {
          const { redirectPath } = await response.json();
          navigate(redirectPath, { 
            state: { 
              email: finalEmail,
              referralToken: referralTokenRef.current 
            },
            replace: true 
          });
          return;
        }
      } catch (error) {
        console.error('Error in auth-success endpoint:', error);
      }

      // Fallback to default navigation if auth-success fails
      navigate('/app/auth/callback', { 
        state: { 
          email: finalEmail,
          referralToken: referralTokenRef.current 
        }
      });
    } catch (error) {
      console.error('Error in auth:', error);
      setError(error.message);
      // Final fallback
      navigate('/app/client-or-consultant', { 
        state: { 
          email: email || emailRef.current || sessionStorage.getItem('auth_email') 
        }
      });
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: `${window.location.origin}/app/auth/callback`,
        },
      });
  
      if (error) throw error;
    } catch (error) {
      console.error('Google sign-in error:', error);
      setError(error.message);
    }
  };

  const handleAppleSignIn = async () => {
    try {
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'apple',
        options: {
          redirectTo: `${window.location.origin}/app/auth/callback`,
        },
      });
  
      if (error) throw error;
    } catch (error) {
      console.error('Apple sign-in error:', error);
      setError(error.message);
    }
  };

  const handleEmailAuth = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const emailExists = await checkEmailExists(email);
      const params = new URLSearchParams(location.search);
      const referralToken = params.get('referral');

      if (isSignUp && !emailExists) {
        console.log('Starting sign up process');
        
        // Sign up user with extra metadata including referral token
        const { data, error: signUpError } = await supabase.auth.signUp({
          email,
          password,
          options: {
            emailRedirectTo: `${window.location.origin}/app/auth/callback`,
            data: {
              referralToken: referralToken || null
            }
          }
        });

        if (signUpError) throw signUpError;
        console.log('Sign up successful:', data);

        logEvent(analytics, 'sign_up', { method: 'email' });

        // If user was created successfully
        if (data.user) {
          console.log('Creating user record');
          try {
            const userRecord = await createUserRecord(data.user.id, email, referralToken);
            console.log('User record created:', userRecord);

            // If email confirmation is required
            if (!data.session) {
              console.log('Email confirmation required');
              navigate('/app/verify-email', { state: { email } });
              return;
            }

            // If no email confirmation needed
            if (data.session) {
              console.log('No email confirmation needed, proceeding to callback');
              navigate('/app/auth/callback', { state: { email } });
            }
          } catch (createError) {
            console.error('Error creating user record:', createError);
            setError('Failed to complete registration. Please try again.');
          }
        }
      } else {
        // Sign in flow remains the same
        const result = await signInWithEmail(email, password);
        logEvent(analytics, 'sign_in', { method: 'email' });

        if (result.user) {
          navigate('/app/auth/callback', { state: { email } });
        }
      }
    } catch (error) {
      console.error("Auth error:", error);

      if (error.message.includes("Invalid login credentials")) {
        setError("Invalid email or password. Please try again.");
      } else if (error.message.includes("Email not confirmed")) {
        setError("Please verify your email address before signing in.");
        navigate('/app/verify-email', { state: { email } });
      } else {
        setError(`An error occurred: ${error.message}`);
      }
    }
  };

  const toggleMode = () => setIsSignUp(!isSignUp);

  return (
    <div className="min-h-screen bg-gray-100 flex justify-center items-center">
      <Helmet>
        <title>{isSignUp ? 'Sign Up' : 'Sign In'} | fetchConsultant</title>
        <meta 
          name="description" 
          content={`${isSignUp ? 'Create an account' : 'Sign in to your account'} on fetchConsultant to connect with top healthcare IT opportunities.`}
        />
      </Helmet>
      
      <div className="flex w-full max-w-4xl shadow-lg">
        
        {/* Left section (form) */}
        <div className="bg-white p-8 w-full sm:max-w-md flex flex-col justify-center items-center">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-2 text-center text-3xl font-extrabold text-gray-900">
              {isSignUp ? 'Create your account' : 'Welcome back'}
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              {isSignUp ? 'Sign up to get started' : 'Sign in to your account'}
            </p>
          </div>
  
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            {referralTokenRef.current && (
                <div className="text-center text-sm text-green-600 mb-4">
                  You've been invited to join fetchConsultant. Please sign up to accept the invitation.
                </div>
              )}
              <div className="space-y-6">
                <button
                  onClick={handleAppleSignIn}
                  type="button"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                >
                  <Apple className="mr-2 h-5 w-5" />
                  Continue with Apple
                </button>
  
                <button
                  onClick={handleGoogleSignIn}
                  type="button"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  <svg className="mr-2 h-5 w-5" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M21.35,11.1H12.18V13.83H18.69C18.36,17.64 15.19,19.27 12.19,19.27C8.36,19.27 5,16.25 5,12C5,7.9 8.2,4.73 12.2,4.73C15.29,4.73 17.1,6.7 17.1,6.7L19,4.72C19,4.72 16.56,2 12.1,2C6.42,2 2.03,6.8 2.03,12C2.03,17.05 6.16,22 12.25,22C17.6,22 21.5,18.33 21.5,12.91C21.5,11.76 21.35,11.1 21.35,11.1V11.1Z"
                    />
                  </svg>
                  Continue with Google
                </button>
  
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300"></div>
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">or</span>
                  </div>
                </div>
  
                <form onSubmit={handleEmailAuth} className="space-y-6">
                  <div className="text-left">
                    <label htmlFor="email" className="fetch-label">
                      Email
                    </label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="fetch-input"
                    />
                  </div>
  
                  <div className="text-left">
                    <div className="flex justify-between items-center">
                      <label htmlFor="password" className="fetch-label">
                        Password
                      </label>
                      {!isSignUp && (
                        <Link to="/app/forgot-password" className="text-sm text-brandPrimary hover:text-indigo-500">
                          Forgot Password?
                        </Link>
                      )}
                    </div>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="fetch-input"
                    />
                  </div>
  
                  {error && (
                    <div className="text-red-600 text-sm">{error}</div>
                  )}
  
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-montserrat text-white bg-brandBlack hover:bg-brandDark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandPrimary"
                  >
                    {isSignUp ? 'Sign up' : 'Sign in'}
                  </button>
                </form>
              </div>
  
              <div className="mt-6">
                <p className="text-center text-sm text-gray-500">
                  {isSignUp ? 'Already have an account?' : "Don't have an account?"}{' '}
                  <button
                    onClick={toggleMode}
                    className="font-medium text-brandPrimary hover:text-indigo-500"
                  >
                    {isSignUp ? 'Sign in' : 'Sign up'}
                  </button>
                </p>
              </div>
  
              <div className="mt-6">
                <p className="text-center text-xs text-gray-500">
                  By continuing, you agree to fetchConsultant's{' '}
                  <Link to="/terms" className="underline">Terms of Service</Link>.
                </p>
              </div>
            </div>
          </div>
        </div>
        
        {/* Right section */}
        <div className="hidden md:block bg-brandPrimary text-white p-10 w-1/2 flex flex-col justify-between">
          <div className="flex flex-col justify-center flex-grow">
            <h2 className="text-center text-3xl font-extrabold mb-8">Why fetchConsultant</h2>
              <div className="flex justify-center">
                <ul className="space-y-6 flex flex-col">
                  <li>
                    <div className="flex items-start">
                      <svg className="h-6 w-6 mr-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-.01 2a5 5 0 10-4-2.9 4.978 4.978 0 003.5-1.1 5.024 5.024 0 002.5 1.4 5 5 0 104.5-6.9A5 5 0 0012 18z" />
                      </svg>
                      <h3 className="text-lg text-left font-semibold">Transparent, Fair Pricing</h3>
                    </div>
                    <p className="text-left ml-10">One rate with a simple 4.5% platform fee (per party). No hidden costs, no inflated rates. Fair for everyone.</p>
                  </li>
                  <li>
                    <div className="flex items-start mt-2">
                      <svg className="h-6 w-6 mr-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      <h3 className="text-lg text-left font-semibold">Real-Time Opportunities</h3>
                    </div>
                    <p className="text-left ml-10">Instant notifications for new jobs and applicants. Fill positions in days, not weeks.</p>
                  </li>
                  <li>
                    <div className="flex items-start mt-2">
                      <svg className="h-6 w-6 mr-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12v4m0-4v-2a4 4 0 00-8 0v2a2 2 0 100 4v1m0-5v-2a4 4 0 018 0v2" />
                      </svg>
                      <h3 className="text-lg text-left font-semibold">Streamlined Contracting Process</h3>
                    </div>
                    <p className="text-left ml-10">From job posting to Zoom interviews, our integrated platform accelerates every step of hiring.</p>
                  </li>
                </ul>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthScreen;