import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Include Quill styles

const GeneratedDescriptionModal = ({
  isVisible,
  generatedDescription,
  onAccept,
  onCancel,
  isGenerating,
}) => {
  if (!isVisible) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Generated Job Description</h2>
        <ReactQuill
          theme="bubble"
          value={generatedDescription}
          readOnly={true}
        />
        {isGenerating && <p className="loading">Generating...</p>}
        <div className="modal-actions">
          <button
            className="accept-button"
            onClick={onAccept}
            disabled={isGenerating}
          >
            Accept
          </button>
          <button
            className="cancel-button"
            onClick={onCancel}
            disabled={isGenerating}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default GeneratedDescriptionModal;