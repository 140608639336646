import { getAnalytics, logEvent } from "firebase/analytics";
import { Bell, Briefcase, Calendar, FileText, Settings, Mail, HelpCircle, LogOut, MessageSquare, NotebookPen, Timer, UserPen, Users, X, Proportions } from 'lucide-react';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import Avatar from "./ui/Avatar.js";

const ClientHamburger = ({ user, currentPage, isOpen, onClose }) => {
  const navigate = useNavigate();
  const analytics = getAnalytics();

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      logEvent(analytics, 'logout', {
        method: 'hamburger_menu',
        user_type: 'Verified Client'
      });
      navigate('/app/auth');
    } catch (error) {
      console.error('Error logging out:', error.message);
    }
  };

  const handleSupport = () => {
    window.location.href = 'mailto:support@fetchconsultant.com';
    logEvent(analytics, 'support_email_click', {
      method: 'hamburger_menu',
      user_type: 'client'
    });
  };

  const menuItems = [
    { icon: Briefcase, text: 'Dashboard', link: '/app/client-dashboard' },
    { icon: Calendar, text: 'Interviews', link: '/app/client-interviews' },
    { icon: NotebookPen, text: 'Notes', link: '/app/client-interview-notes' },
    { icon: UserPen, text: 'All Applicants', link: '/app/all-applicants' },
    { icon: Users, text: 'Referrals', link: '/app/client-referrals' },
    { icon: MessageSquare, text: 'Chats', link: '/app/chats' },
    { icon: Bell, text: 'Requests', link: '/app/client-requests', showBadge: Proportions.hasRequests },
    { icon: Briefcase, text: 'Engagements', link: '/app/client-engagement-management' },
    { icon: FileText, text: 'Invoices', link: '/app/client-invoices' },
    { icon: Timer, text: 'Approve Hours', link: '/app/client-approve-hours' },
    { icon: Settings, text: 'Settings', link: '/app/client-settings' },
  ];

  return (
    <div className={`bg-white w-64 h-screen shadow-lg flex flex-col fixed top-0 left-0 z-50 transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0`}>
      {/* Header section */}
      <div className="p-4 flex-shrink-0">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center">
            <img src="/images/fetch-logo.png" alt="Fetch Logo" className="h-8 mr-2" />
            <h2 className="sr-only">fetchConsultant</h2>
          </div>
          <button onClick={onClose} className="md:hidden">
            <X size={24} />
          </button>
        </div>
      </div>

      {/* Scrollable navigation section */}
      <div className="flex-1 overflow-y-auto">
        <nav className="p-4">
          <ul className="space-y-2">
            {menuItems.map((item) => (
              <li key={item.text}>
                <Link
                  to={item.link}
                  className={`flex items-center py-2 px-4 rounded-lg ${
                    currentPage === item.text.toLowerCase()
                      ? 'bg-red-100 text-black font-semibold'
                      : 'text-gray-700 hover:bg-gray-100'
                  }`}
                  onClick={() => onClose()}
                >
                  <item.icon size={20} className="mr-3" />
                  {item.text}
                  {currentPage === item.text.toLowerCase() && (
                    <span className="ml-auto w-2 h-2 bg-red-500 rounded-full"></span>
                  )}
                </Link>
              </li>
            ))}
            {/* Support button */}
            <li>
              <button
                onClick={() => { handleSupport(); onClose(); }}
                className="flex items-center py-2 px-4 rounded-lg text-gray-700 hover:bg-gray-100 w-full text-left"
              >
                <Mail size={20} className="mr-3" />
                Support
              </button>
            </li>
            {/* FAQ link */}
            <li>
              <a
                href="https://fetchconsultant.com/faq"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center py-2 px-4 rounded-lg text-gray-700 hover:bg-gray-100"
                onClick={() => onClose()}
              >
                <HelpCircle size={20} className="mr-3" />
                F.A.Q.
              </a>
            </li>
            {/* Logout button */}
            <li>
              <button
                onClick={() => { handleLogout(); onClose(); }}
                className="flex items-center py-2 px-4 rounded-lg text-gray-700 hover:bg-gray-100 w-full text-left"
              >
                <LogOut size={20} className="mr-3" />
                Logout
              </button>
            </li>
            {/* Edit Profile link */}
            {user && (
              <li className="border-t border-gray-200 mt-2 pt-2">
                <Link 
                  to="/app/client-edit-profile" 
                  state={{ isEditMode: true }} 
                  className="flex items-center py-2 px-4 rounded-lg text-gray-700 hover:bg-gray-100"
                  onClick={() => onClose()}
                >
                  <Avatar
                    photo={user.photo}
                    name={user.name}
                    className="w-5 h-5 mr-3"
                  />
                  Edit Profile
                </Link>
              </li>
            )}
          </ul>
        </nav>
      </div>

      {/* Footer version section */}
      <div className="p-4 text-sm text-gray-500 flex-shrink-0">
        Version 1.0.0
      </div>
    </div>
  );
};

export default ClientHamburger;