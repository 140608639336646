import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronLeft, Info } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { requestNotificationPermission } from '../firebase/fcm';
import { supabase } from '../supabaseClient';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ClientOnboardingProfessional = () => {
  const [formData, setFormData] = useState({
    employer: '',
    job_title: '',
    linkedin_url: 'https://www.linkedin.com/in/',
    work_email: '',
    employer_name: '',
  });
  const [initialWorkEmail, setInitialWorkEmail] = useState('');
  const [clients, setClients] = useState([]);
  const [showVerificationMessage, setShowVerificationMessage] = useState(false);
  const [formValid, setFormValid] = useState(false); // Track form validation
  const navigate = useNavigate();
  const location = useLocation();
  const isEditMode = location.state?.isEditMode || false;
  const [showTooltip, setShowTooltip] = useState(false);
  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: isEditMode ? 'Edit Profile - Professional' : 'Client Onboarding - Professional',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUserAndClients();
  }, [isEditMode]);

  const fetchUserAndClients = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      // Call the edge function to fetch user and clients data
      const { data, error } = await supabase.functions.invoke('fetch-client-onboarding-data', {
        body: { userId: user.id }
      });

      if (error) throw error;

      const { userData, clientsData } = data;
      setClients(clientsData);

      // Extract domain from work email if it exists
      const workEmailDomain = userData.work_email ? userData.work_email.split('@')[1] : null;

      // Check if a matching client exists for the form data setup
      const matchingClient = clientsData.find(client =>
        (workEmailDomain && (client.email_domain1 === workEmailDomain || client.email_domain2 === workEmailDomain))
      );

      setFormData({
        employer: matchingClient ? matchingClient.client_id : 'a179b115-8361-488c-840a-a3c6f8519b37',
        job_title: userData.job_title || '',
        linkedin_url: userData.linkedin_url || 'https://www.linkedin.com/in/',
        work_email: userData.work_email || '',
        employer_name: userData.employer === 'a179b115-8361-488c-840a-a3c6f8519b37' ? userData.employer_name : '',
      });

      setInitialWorkEmail(userData.work_email || '');

    } catch (error) {
      console.error('Error fetching user and clients:', error.message);
    }
  };

  useEffect(() => {
    // Extract the LinkedIn profile path (everything after /in/)
    const linkedinPath = formData.linkedin_url.replace('https://www.linkedin.com/in/', '').replace('/', '');

    const isFormValid = 
      (formData.employer || '').trim() !== '' &&
      (formData.employer === 'a179b115-8361-488c-840a-a3c6f8519b37' ? (formData.employer_name || '').trim() !== '' : true) &&
      (formData.job_title || '').trim() !== '' &&
      // Check that LinkedIn path is at least 5 characters long
      linkedinPath.length >= 5 &&
      formData.linkedin_url.startsWith('https://www.linkedin.com/in/');

    setFormValid(isFormValid);
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === "linkedin_url" && !value.startsWith("https://www.linkedin.com/in/")) {
      newValue = `https://www.linkedin.com/in/${value.replace("https://www.linkedin.com/in/", "")}`;
    }

    setFormData(prevData => ({
      ...prevData,
      [name]: newValue
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formValid) return;

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      // Call the edge function to handle all database operations
      const { data, error } = await supabase.functions.invoke('update-client-onboarding', {
        body: {
          userId: user.id,
          formData,
          isEditMode,
        }
      });

      if (error) throw error;

      // Handle response based on the edge function result
      if (data.showVerificationMessage) {
        setShowVerificationMessage(true);
        return;
      }

      // Navigate based on the response
      if (!isEditMode) {
        if (data.shouldNavigateToWelcome) {
          navigate('/app/welcome-client');
        } else {
          setShowVerificationMessage(true);
        }

        // Request notification permission
        await requestNotificationPermission();
      } else {
        navigate('/app/client-dashboard');
      }

    } catch (error) {
      console.error('Error updating user info:', error.message);
      alert(`Error updating profile: ${error.message}`);
    }
  };

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  if (showVerificationMessage) {
    return (
      <div className="min-h-screen bg-white flex flex-col items-center justify-center p-4">
        <div className="max-w-2xl w-full bg-white rounded-lg shadow-xl overflow-hidden">
          <div className="p-8">
            <div className="px-4">
              <h2 className="text-2xl font-bold mb-4 text-left">Thank you for registering</h2>
              {formData.employer === 'a179b115-8361-488c-840a-a3c6f8519b37' ? (
                // Message for unrecognized organizations
                <div>
                  <p className="mb-4 text-left">
                    We notice that your organization is not yet registered in our system. 
                    A member of the fetchConsultant team will contact you shortly to complete 
                    your organization's verification process.
                  </p>
                  <p className="mb-4 text-left">
                    In the meantime, we've sent a verification email to your work email address. 
                    Please check your inbox and verify your email.
                  </p>
                </div>
              ) : (
                // Original message for recognized organizations
                <p className="mb-4 text-left">
                  Please check your email to verify your client account.
                  Once verified, you'll be able to access the Client Dashboard.
                </p>
              )}
            </div>
            <div className="flex justify-center mb-8">
              <button
                onClick={() => navigate('/auth')}
                className="bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition duration-300"
              >
                Return to Login
              </button>
            </div>
            
            <Slider {...carouselSettings} className="fetchCarousel">
              <div className="px-4">
                <h3 className="text-xl font-semibold mb-2 text-left">Privacy and Control</h3>
                <p className="text-left">While LinkedIn generated $15.7 billion in 2023 from Talent Solutions, your information on fetchConsultant remains private. Your profile is never shared or sold. Oh, and no cringe news feed either!</p>
              </div>
              <div className="px-4">
                <h3 className="text-xl font-semibold mb-2 text-left">Disrupting the Consulting Space</h3>
                <p className="text-left">Accenture earned $64.1 billion in revenue in 2023. With fetchConsultant, we're disrupting this space by cutting out the middleman. This means more savings for clients and higher earnings for consultants.</p>
              </div>
              <div className="px-4">
                <h3 className="text-xl font-semibold mb-2 text-left">Fair Economics</h3>
                <p className="text-left">Traditional consulting firms have an incentive to charge clients as much as possible while paying consultants as little as possible. fetchConsultant aligns incentives, ensuring fair rates for both clients and consultants.</p>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 flex justify-center items-center">
      <Helmet>
        <title>{isEditMode ? 'Edit Profile - Professional Information' : 'Client Onboarding - Professional Information'} | fetchConsultant</title>
        <meta name="description" content={isEditMode ? "Update your professional profile as a healthcare organization on fetchConsultant." : "Complete your professional profile as a healthcare organization on fetchConsultant. Connect with top Epic consultants for your healthcare IT projects."} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="noindex, nofollow" />
        <meta property="og:title" content="Client Onboarding - Professional Information | fetchConsultant" />
        <meta property="og:description" content="Complete your professional profile as a healthcare organization on fetchConsultant. Connect with top Epic consultants for your healthcare IT projects." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://fetchconsultant.com/app/client-onboarding-professional" />
        <meta property="og:image" content="https://fetchconsultant.com/app/path-to-image.jpg" />
      </Helmet>
      
      {/* Split screen */}
      <div className="flex flex-col md:flex-row w-full max-w-7xl shadow-lg">
  
        {/* Left section */}
        <div className="bg-white p-4 sm:p-8 w-full md:w-1/2 flex flex-col justify-start sm:justify-center items-center min-h-screen sm:min-h-0 overflow-y-auto">
        <header className="fetch-header w-full">
            <ChevronLeft className="fetch-back-button" onClick={() => navigate(-1)} />
            <h1 className="fetch-page-title">{isEditMode ? 'Edit Profile' : 'Client Onboarding'}</h1>
          </header>
          <main className="fetch-container w-full">
            <div className="fetch-card">
              {!isEditMode && (
                <div className="mb-6 text-center">
                  <p className="fetch-secondary-text">Step 2 of 2</p>
                  <div className="fetch-progress-bar">
                    <div className="fetch-progress-bar-fill" style={{width: '100%'}}></div>
                  </div>
                </div>
              )}
              <form onSubmit={handleSubmit} className="fetch-form">
                <div>
                  <label htmlFor="employer" className="fetch-label">Employer<span className="fetch-required">*</span></label>
                  <div className="relative">
                    <select
                      id="employer"
                      name="employer"
                      value={formData.employer}
                      onChange={handleInputChange}
                      required
                      className="fetch-input"
                      disabled
                    >
                      {formData.employer === 'a179b115-8361-488c-840a-a3c6f8519b37' ? (
                        <option value="a179b115-8361-488c-840a-a3c6f8519b37">New Organization</option>
                      ) : (
                        clients.map(client => (
                          <option key={client.client_id} value={client.client_id}>
                            {client.name}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                </div>
                {formData.employer === 'a179b115-8361-488c-840a-a3c6f8519b37' && (
                <div>
                  <label htmlFor="employer_name" className="fetch-label flex items-center relative group">
                    Employer Name<span className="fetch-required">*</span>
                    <Info
                      className="ml-2 text-blue-500 cursor-pointer"
                      size={18}
                      onMouseEnter={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                      onClick={() => setShowTooltip(!showTooltip)}
                    />
                    {showTooltip && (
                      <div className="tooltip">
                        Please provide your employer's name
                      </div>
                    )}
                  </label>
                  <input
                    type="text"
                    id="employer_name"
                    name="employer_name"
                    value={formData.employer_name}
                    onChange={handleInputChange}
                    required
                    className="fetch-input"
                    placeholder="Enter your employer's name"
                  />
                </div>
                )}
                <div>
                  <label htmlFor="job_title" className="fetch-label">Job Title<span className="fetch-required">*</span></label>
                  <input
                    type="text"
                    id="job_title"
                    name="job_title"
                    value={formData.job_title}
                    onChange={handleInputChange}
                    required
                    className="fetch-input"
                    placeholder="Director of Epic Applications"
                  />
                </div>
                <div>
                <label htmlFor="linkedin_url" className="fetch-label">LinkedIn URL<span className="fetch-required">*</span></label>
                  <input
                    type="url"
                    id="linkedin_url"
                    name="linkedin_url"
                    value={formData.linkedin_url}
                    onChange={handleInputChange}
                    required
                    className="fetch-input"
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className={`fetch-button ${!formValid ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={!formValid}
                  >
                    {isEditMode ? 'Save Changes' : 'Finish'}
                  </button>
                </div>
              </form>
            </div>
          </main>
        </div>
  
        {/* Right section */}
            <div className="bg-brandPrimary text-white p-10 w-full md:w-1/2 flex flex-col justify-between overflow-y-auto">
              <div className="flex flex-col mt-2 justify-center flex-grow">
                <h2 className="text-center text-3xl mt-1 font-extrabold">Why fetchConsultant</h2>
              </div>
                <div className="flex flex-col justify-center flex-grow p-8 pt-16"> {/* Add padding to account for the absolute title */}
                <div className="flex justify-center">
                  <ul className="space-y-6 flex flex-col">
                      <li>
                        <div className="flex items-start">
                      <svg className="h-6 w-6 mr-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-.01 2a5 5 0 10-4-2.9 4.978 4.978 0 003.5-1.1 5.024 5.024 0 002.5 1.4 5 5 0 104.5-6.9A5 5 0 0012 18z" />
                      </svg>
                      <h3 className="text-lg text-left font-semibold">Transparent, Fair Pricing</h3>
                    </div>
                    <p className="text-left ml-10">One rate with a simple 4.5% platform fee (per party). No hidden costs, no inflated rates. Fair for everyone.</p>
                  </li>
                  <li>
                    <div className="flex items-start mt-2">
                      <svg className="h-6 w-6 mr-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      <h3 className="text-lg text-left font-semibold">Real-Time Opportunities</h3>
                    </div>
                    <p className="text-left ml-10">Instant notifications for new jobs and applicants. Fill positions in days, not weeks.</p>
                  </li>
                  <li>
                    <div className="flex items-start mt-2">
                      <svg className="h-6 w-6 mr-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12v4m0-4v-2a4 4 0 00-8 0v2a2 2 0 100 4v1m0-5v-2a4 4 0 018 0v2" />
                      </svg>
                      <h3 className="text-lg text-left font-semibold">Streamlined Contracting Process</h3>
                    </div>
                    <p className="text-left ml-10">From job posting to Zoom interviews, our integrated platform accelerates every step of hiring.</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

export default ClientOnboardingProfessional;