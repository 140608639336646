import React, { useState } from 'react';
import { X } from 'lucide-react';

// Update type definition
export type JobNiche = 'epic' | 'peoplesoft' | 'salesforce' | 'other';
export type EmploymentType = 'consultant' | 'fte';

interface JobTypeSelection {
  niche: JobNiche;
  employmentType: EmploymentType;
  customNicheName?: string;
}

interface JobTypeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (selection: JobTypeSelection) => void;
}

const employmentTypes = [
  { id: 'consultant' as EmploymentType, name: 'Consultant', description: 'Contract/temporary position' },
  { id: 'fte' as EmploymentType, name: 'Full-Time Employee', description: 'Permanent position' }
];

const JobTypeModal: React.FC<JobTypeModalProps> = ({ 
  isOpen,
  onClose,
  onSelect 
}: JobTypeModalProps) => {
  const [selectedNiche, setSelectedNiche] = useState<JobNiche>('epic');
  const [selectedType, setSelectedType] = useState<EmploymentType>('consultant');
  const [customNicheName, setCustomNicheName] = useState('');
  const [customNicheError, setCustomNicheError] = useState('');

  const niches = [
    { id: 'epic' as JobNiche, name: 'Epic', description: 'Healthcare IT Systems' },
    { id: 'peoplesoft' as JobNiche, name: 'PeopleSoft', description: 'HR & Financial Systems' },
    { id: 'salesforce' as JobNiche, name: 'Salesforce', description: 'CRM Platform' },
    { id: 'other' as JobNiche, name: 'Other', description: 'Specify another technology' }
  ];

  const handleNicheChange = (niche: JobNiche) => {
    setSelectedNiche(niche);
    if (niche !== 'other') {
      setCustomNicheName('');
      setCustomNicheError('');
    }
  };

  const handleSubmit = () => {
    if (selectedNiche === 'other' && !customNicheName.trim()) {
      setCustomNicheError('Please specify the technology niche');
      return;
    }

    onSelect({
      niche: selectedNiche,
      employmentType: selectedType,
      customNicheName: selectedNiche === 'other' ? customNicheName.trim() : undefined
    });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <div className="absolute top-0 right-0 pt-4 pr-4">
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500"
            >
              <X size={24} />
            </button>
          </div>

          <div className="sm:flex sm:items-start">
            <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
              <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                Create New Position
              </h3>

              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Select Technology Niche
                </label>
                <div className="space-y-2">
                  {niches.map(niche => (
                    <div key={niche.id}>
                      <div 
                        className={`p-3 border rounded-lg cursor-pointer transition-colors ${
                          selectedNiche === niche.id 
                            ? 'border-brandPrimary bg-indigo-50' 
                            : 'border-gray-200 hover:border-gray-300'
                        }`}
                        onClick={() => setSelectedNiche(niche.id)}
                      >
                        <div className="flex items-center">
                          <input
                            type="radio"
                            checked={selectedNiche === niche.id}
                            onChange={() => handleNicheChange(niche.id)}
                            className="h-4 w-4 text-brandPrimary"
                          />
                          <div className="ml-3">
                            <p className="font-medium text-gray-900">{niche.name}</p>
                            <p className="text-sm text-gray-500">{niche.description}</p>
                          </div>
                        </div>
                      </div>

                      {/* Show input field when "Other" is selected */}
                      {niche.id === 'other' && selectedNiche === 'other' && (
                        <div className="mt-2 ml-7">
                          <input
                            type="text"
                            value={customNicheName}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCustomNicheName(e.target.value)}
                            placeholder="Enter technology niche (e.g., 'Oracle EBS', 'SAP')"
                            className="fetch-input w-full"
                            required
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div className="mt-6">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Select Employment Type
                </label>
                <div className="space-y-2">
                  {employmentTypes.map(type => (
                    <div 
                      key={type.id}
                      className={`p-3 border rounded-lg cursor-pointer transition-colors ${
                        selectedType === type.id 
                          ? 'border-brandPrimary bg-indigo-50' 
                          : 'border-gray-200 hover:border-gray-300'
                      }`}
                      onClick={() => setSelectedType(type.id)}
                    >
                      <div className="flex items-center">
                        <input
                          type="radio"
                          checked={selectedType === type.id}
                          onChange={() => setSelectedType(type.id)}
                          className="h-4 w-4 text-brandPrimary"
                        />
                        <div className="ml-3">
                          <p className="font-medium text-gray-900">{type.name}</p>
                          <p className="text-sm text-gray-500">{type.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              onClick={handleSubmit}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-brandPrimary text-base font-medium text-white hover:bg-brandHover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandPrimary sm:ml-3 sm:w-auto sm:text-sm"
            >
              Continue
            </button>
            <button
              type="button"
              onClick={onClose}
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobTypeModal;