import { supabase } from "../supabaseClient.js";

export async function getAllPosts() {
  try {
    const { data: posts, error } = await supabase
      .from('blog_posts')
      .select('*')
      .order('published_at', { ascending: false });

    if (error) throw error;
    return posts.map(post => ({
      ...post,
      reading_time: calculateReadingTime(post.content || '')
    }));
  } catch (error) {
    console.error('Error loading posts:', error);
    return [];
  }
}

export async function getPostBySlug(slug) {
  try {
    const { data: posts, error } = await supabase
      .from('blog_posts')
      .select(`*`)
      .eq('slug', slug);

    if (error) throw error;

    if (!posts || posts.length === 0) {
      throw new Error('Post not found');
    }

    const post = posts[0];
    return {
      ...post,
      reading_time: calculateReadingTime(post.content || '')
    };
  } catch (error) {
    console.error('Error loading post:', error);
    throw error;
  }
}

export async function getPostsByCategory(category) {
  try {
    const { data: posts, error } = await supabase
      .from('blog_posts')
      .select('*')
      .contains('categories', [category])
      .order('published_at', { ascending: false });

    if (error) throw error;
    return posts.map(post => ({
      ...post,
      reading_time: calculateReadingTime(post.content || '')
    }));
  } catch (error) {
    console.error('Error loading posts:', error);
    return [];
  }
}

export async function getFeaturedPosts() {
  try {
    const { data: posts, error } = await supabase
      .from('blog_posts')
      .select('*')
      .eq('is_featured', true)
      .order('published_at', { ascending: false });

    if (error) throw error;
    return posts.map(post => ({
      ...post,
      reading_time: calculateReadingTime(post.content || '')
    }));
  } catch (error) {
    console.error('Error loading featured posts:', error);
    return [];
  }
}

export function calculateReadingTime(content) {
  const wordsPerMinute = 200;
  const words = content.split(/\s+/).length;
  const minutes = Math.ceil(words / wordsPerMinute);
  return `${minutes} Min`;
}

export async function getRelatedPosts(slug) {
  try {
    // First, get the current post to match against its categories and tags
    const currentPost = await getPostBySlug(slug);
    
    if (!currentPost) {
      throw new Error('Current post not found');
    }

    // Fetch posts that share categories or tags with the current post
    const { data: relatedPosts, error } = await supabase
      .from('blog_posts')
      .select('*')
      .neq('slug', slug)
      .or(`categories.cs.{${currentPost.categories?.join(',')}},tags.cs.{${currentPost.tags?.join(',')}}`)
      .order('published_at', { ascending: false })
      .limit(8); // Fetch extra posts for carousel

    if (error) throw error;

    return (relatedPosts || []).map(post => ({
      ...post,
      reading_time: calculateReadingTime(post.content || '')
    }));
  } catch (error) {
    console.error('Error loading related posts:', error);
    return [];
  }
}