import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronLeft, Star, Menu, MoreHorizontal } from 'lucide-react';
import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ClientHamburger from './ClientHamburger';
import NotificationBell from './NotificationBell';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Avatar from "./ui/Avatar.js";

const statusProgress = {
  'Applied': 1,
  'Interview Scheduled': 2,
  'Second Interview Requested': 3,
  'Second Interview Scheduled': 4,
  'Contract Offered': 5,
};

const Applicants = () => {
  // Combined state management
  const [data, setData] = useState({
    applicants: [],
    user: null,
    job: null,
    noApplicants: false
  });

  const [filters, setFilters] = useState({
    status: 'All',
    formerEpic: false,
    fetchRecommended: false,
    shortlisted: false,
  });

  const [sortConfig, setSortConfig] = useState({ 
    key: 'submitted_at', 
    direction: 'asc' 
  });

  const [uiState, setUiState] = useState({
    isMenuOpen: false,
    activeDropdown: null,
    isMobile: window.innerWidth < 768,
  });

  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const { jobId } = useParams();

  // Main setup effect
  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Applicants',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    const handleResize = () => {
      setUiState(prev => ({
        ...prev,
        isMobile: window.innerWidth < 768
      }));
    };

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setUiState(prev => ({
          ...prev,
          activeDropdown: null
        }));
      }
    };

    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);

    fetchInitialData();

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Data fetching effect
  useEffect(() => {
    if (data.user) {
      fetchApplicants();
    }
  }, [filters, sortConfig, jobId, data.user]);

  const fetchInitialData = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data: userData, error: userError } = await supabase
          .from('users')
          .select('*')
          .eq('user_id', user.id)
          .single();

        if (userError) throw userError;

        const { data: jobData, error: jobError } = await supabase
          .from('jobs')
          .select('title')
          .eq('job_id', jobId)
          .single();

        if (jobError) throw jobError;

        setData(prev => ({
          ...prev,
          user: userData,
          job: jobData,
        }));
      }
    } catch (error) {
      console.error('Error fetching initial data:', error);
    }
  };

  const fetchApplicants = async () => {
    try {
      let query = supabase
        .from('user_jobs')
        .select(`
          user_job_id,
          submitted_at,
          consultant_rate,
          status,
          shortlisted,
          fetch_recommended,
          users:user_jobs_user_id_fkey!inner (
            name,
            photo,
            linkedin_url,
            average_rating,
            former_epic,
            user_id,
            review_count
          )
        `)
        .eq('job_id', jobId)
        .order(sortConfig.key, { ascending: sortConfig.direction === 'asc' });

      // Apply the status filter if it's not "All"
      if (filters.status !== 'All') {
        query = query.eq('status', filters.status);
      }

      // Apply the formerEpic filter
      if (filters.formerEpic) {
        query = query.eq('users.former_epic', true);
      }

      // Apply the fetchRecommended filter
      if (filters.fetchRecommended) {
        query = query.eq('fetch_recommended', true);
      }

      // Apply the shortlisted filter
      if (filters.shortlisted) {
        query = query.eq('shortlisted', true);
      }

      const { data: applicantsData, error } = await query;

      if (error) throw error;

      setData(prev => ({
        ...prev,
        applicants: applicantsData || [],
        noApplicants: !applicantsData || applicantsData.length === 0
      }));
    } catch (error) {
      console.error('Error fetching applicants:', error);
      setData(prev => ({
        ...prev,
        noApplicants: true
      }));
    }
  };

  // Handler functions
  const handleSort = (key) => {
    setSortConfig(prev => ({
      key,
      direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const toggleDropdown = (userJobId) => {
    setUiState(prev => ({
      ...prev,
      activeDropdown: prev.activeDropdown === userJobId ? null : userJobId
    }));
  };

  const handleRowClick = (userJobId) => {
    navigate(`/app/applicant/${userJobId}`);
  };

  const renderStars = (rating) => {
    if (!rating) {
      return <span>No ratings yet</span>;
    }

    const stars = [];
    const roundedRating = Math.round(rating * 2) / 2; // Round to nearest 0.5
    for (let i = 1; i <= 5; i++) {
      let starFill = Math.min(Math.max(roundedRating - (i - 1), 0), 1);
      if (starFill === 1) {
        // Full star
        stars.push(<Star key={i} size={20} fill="gold" stroke="gold" />);
      } else if (starFill > 0) {
        // Partial star
        stars.push(
          <div key={i} className="relative">
            <Star size={20} fill="none" stroke="gold" />
            <div
              className="absolute top-0 left-0 overflow-hidden"
              style={{ width: `${starFill * 100}%` }}
            >
              <Star size={20} fill="gold" stroke="gold" />
            </div>
          </div>
        );
      } else {
        // Empty star
        stars.push(<Star key={i} size={20} fill="none" stroke="gold" />);
      }
    }
    return stars;
  };

  const handleReject = async (userJobId) => {
    try {
      const { data: currentStatus } = await supabase
        .from('user_jobs')
        .select('status')
        .eq('user_job_id', userJobId)
        .single();

      const newStatus = currentStatus.status === 'Applied' ? 'Interview Rejected' : 'Contract Not Offered';

      const { error: updateError } = await supabase
        .from('user_jobs')
        .update({ status: newStatus })
        .eq('user_job_id', userJobId);

      if (updateError) throw updateError;

      fetchApplicants();
      toast.success('Applicant rejected');
    } catch (error) {
      console.error('Error rejecting applicant:', error);
      toast.error('Failed to reject applicant');
    }
  };

  const handleViewNotes = (applicant) => {
    navigate('/app/client-interview-notes', { 
      state: { 
        applicantId: applicant.users?.user_id,
        applicantName: applicant.users?.name
      } 
    });
  };

  const handleCreateNote = (applicant, e) => {
    e.stopPropagation();
    navigate('/app/create-interview-note', { 
      state: { 
        consultantId: applicant.user_id,
        consultantName: applicant.users.name
      } 
    });
  };

  const startChat = async (applicantId) => {
    try {
      const { data: existingChat, error: fetchError } = await supabase
        .from('chats')
        .select('id')
        .contains('participants', [data.user.user_id, applicantId])
        .single();

      if (fetchError && fetchError.code !== 'PGRST116') {
        throw fetchError;
      }

      let chatId;
      if (existingChat) {
        chatId = existingChat.id;
      } else {
        const { data: newChat, error: insertError } = await supabase
          .from('chats')
          .insert({
            participants: [data.user.user_id, applicantId],
          })
          .select()
          .single();

        if (insertError) throw insertError;
        chatId = newChat.id;
      }

      navigate(`/app/chats`, { state: { newChatUserId: applicantId } });
    } catch (error) {
      console.error('Error starting chat:', error);
    }
  };

  const renderProgressDots = (status) => {
    const progress = statusProgress[status] || 1;
    return (
      <div className="flex items-center space-x-1">
        {Array(5).fill(0).map((_, index) => (
          <span
            key={index}
            className={`h-2 w-2 rounded-full ${index + 1 <= progress ? 'bg-brandPrimary' : 'bg-gray-300'}`}
          />
        ))}
      </div>
    );
  };

  const handleShortlistToggle = async (userJobId, isShortlisted) => {
    try {
      const { error } = await supabase
        .from('user_jobs')
        .update({ shortlisted: isShortlisted })
        .eq('user_job_id', userJobId);
      if (error) throw error;

      // Update local state
      setData(prev => ({
        ...prev,
        applicants: prev.applicants.map((applicant) =>
          applicant.user_job_id === userJobId
            ? { ...applicant, shortlisted: isShortlisted }
            : applicant
        )
      }));

    } catch (error) {
      console.error('Error updating shortlist status:', error.message);
    }
  };

  const renderApplicantsCards = () => (
    <div className="grid grid-cols-1 gap-4">
      {data.applicants.map((applicant) => (
        <div
          key={applicant.user_job_id}
          className="relative bg-white text-left shadow-md rounded-lg p-4 hover:shadow-lg transition-shadow cursor-pointer"
          onClick={() => handleRowClick(applicant.user_job_id)}
        >
          {/* Ex-Epic and Recommended Labels */}
          <div className="absolute top-0 left-0 flex space-x-2">
            {applicant.users.former_epic && (
              <div className="bg-green-100 text-green-800 text-xs font-semibold px-3 py-1 rounded-tr-lg rounded-br-lg">
                Ex-Epic
              </div>
            )}
            {applicant.fetch_recommended && (
              <div className="bg-blue-100 text-blue-800 text-xs font-semibold px-3 py-1 rounded-tr-lg rounded-br-lg">
                Recommended
              </div>
            )}
          </div>

          {/* Add Shortlist Star */}
          <div className="absolute top-2 right-2">
            <Star
              size={24}
              fill={applicant.shortlisted ? 'gold' : 'none'}
              stroke="gold"
              className="cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                handleShortlistToggle(applicant.user_job_id, !applicant.shortlisted);
              }}
            />
          </div>

          {/* Applicant Name */}
          <div className="mt-2 mb-2">
            <h2 className="text-xl font-bold text-gray-900">{applicant.users.name}</h2>
          </div>

          {/* Applicant Details */}
          <div className="text-sm text-gray-500 mb-2">
            <p>Applied: {new Date(applicant.submitted_at).toLocaleDateString()}</p>
            <p>Rate: ${applicant.consultant_rate}/hr</p>
            <p>Status: {applicant.status}</p>
            <div className="flex items-center">
              <p className="mr-2">Rating:</p>
              <div className="flex items-center">
                <div className="flex">{renderStars(applicant.users.average_rating)}</div>
                {applicant.users?.average_rating > 0 && (
                  <span className="ml-2 text-sm text-gray-500">
                    ({applicant.users?.review_count || 0} {applicant.users?.review_count === 1 ? 'review' : 'reviews'})
                  </span>
                )}
              </div>
            </div>
          </div>

          {/* MoreHorizontal Menu */}
          <div className="absolute bottom-4 right-4">
            <MoreHorizontal
              size={24}
              className="cursor-pointer"
              onClick={(e) => {
                e.stopPropagation(); // Prevent card click
                toggleDropdown(applicant.user_job_id); // Toggle dropdown
              }}
            />
            {uiState.activeDropdown === applicant.user_job_id && (
              <div
                className="absolute right-0 text-left mt-2 w-48 bg-white rounded-md shadow-lg z-10 cursor-pointer"
                onClick={(e) => e.stopPropagation()}
              >
                <div
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  onClick={() => window.open(applicant.users.linkedin_url, '_blank')}
                >
                  👤 LinkedIn Profile
                </div>
                <div
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleViewNotes(applicant);
                  }}
                >
                  📝 View Notes
                </div>
                <div
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                  onClick={(e) => handleCreateNote(applicant, e)}
                  >
                  📝 Create Note
                </div>
                <div
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    startChat(applicant.users.user_id);
                  }}
                  >
                  💬 Start Chat
                </div> 
                <div
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  onClick={() => handleReject(applicant.user_job_id)}
                >
                  ❌ Reject
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <>
      <Helmet>
        <title>Applicants for {data.job?.title} | fetchConsultant</title>
        <meta name="description" content={`View and manage applicants for ${data.job?.title} on fetchConsultant.`} />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ClientHamburger user={data.user} currentPage="applicants" isOpen={uiState.isMenuOpen} onClose={() => setUiState(prev => ({ ...prev, isMenuOpen: false }))} />
        <div className="flex-1 flex bg-gray-100 flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={() => setUiState(prev => ({ ...prev, isMenuOpen: !prev.isMenuOpen }))} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <ChevronLeft className="h-6 w-6 text-brandPrimary cursor-pointer" onClick={() => navigate('/app/client-dashboard')} />
              <h1 className="text-xl font-bold ml-4">Applicants for {data.job?.title}</h1>
            </div>
            <div className="flex items-center">
              <button
                onClick={() => navigate('/app/solicit-refer', { state: { jobId } })}
                className="bg-brandPrimary text-white px-4 py-2 rounded-full mr-4">
                Refer Candidate
              </button>
              <NotificationBell />
            </div>
          </header>

          <main className="p-4">
            <div className="mb-4 flex justify-between items-center">
              <div className="flex items-center">
                <select
                  id="statusFilter"
                  value={filters.status}
                  onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value }))}
                  className="fetch-select"
                >
                  <option value="All">All</option>
                  <option value="Applied">Applied</option>
                  <option value="Interview Scheduled">Interview Scheduled</option>
                  <option value="Second Interview Requested">Second Interview Requested</option>
                  <option value="Second Interview Scheduled">Second Interview Scheduled</option>
                  <option value="Contract Offered">Contract Offered</option>
                  <option value="Interview Rejected">Interview Rejected</option>
                  <option value="Contract Not Offered">Contract Not Offered</option>
                </select>
                <label className="ml-4 flex items-center whitespace-nowrap">
                  <input
                    type="checkbox"
                    checked={filters.formerEpic}
                    onChange={(e) => setFilters(prev => ({ ...prev, formerEpic: e.target.checked }))}
                    className="mr-2"
                  />
                  Ex-Epic
                </label>
                <label className="ml-4 flex items-center whitespace-nowrap">
                  <input
                    type="checkbox"
                    checked={filters.fetchRecommended}
                    onChange={(e) => setFilters(prev => ({ ...prev, fetchRecommended: e.target.checked }))}
                    className="mr-2"
                  />
                  fC Recommended
                </label>
                <label className="ml-4 flex items-center whitespace-nowrap">
                  <input
                    type="checkbox"
                    checked={filters.shortlisted}
                    onChange={(e) => setFilters(prev => ({ ...prev, shortlisted: e.target.checked }))}
                    className="mr-2"
                  />
                  Favorited
                </label>
              </div>
            </div>
            {data.applicants.length === 0 ? (
              <p className="text-gray-600 text-center">No applicants found for this job.</p>
            ) : uiState.isMobile ? (
              renderApplicantsCards()
            ) : (
              <div className="bg-white shadow-md rounded-lg">
                <table className="min-w-full table-auto divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      {/* Shortlist Column */}
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
                        <div onClick={() => handleSort('shortlisted')} className="cursor-pointer">
                        Fave {sortConfig.key === 'shortlisted' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
                        <div onClick={() => handleSort('users.name')} className="cursor-pointer">
                          Applicant {sortConfig.key === 'users.name' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
                        <div onClick={() => handleSort('submitted_at')} className="cursor-pointer">
                          Applied {sortConfig.key === 'submitted_at' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
                        <div onClick={() => handleSort('consultant_rate')} className="cursor-pointer">
                          Rate {sortConfig.key === 'consultant_rate' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
                        <div onClick={() => handleSort('status')} className="cursor-pointer">
                          Stage {sortConfig.key === 'status' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
                        <div onClick={() => handleSort('users.average_rating')} className="cursor-pointer">
                          Rating {sortConfig.key === 'users.average_rating' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                        </div>
                      </th>
                      <th className="px-6 py-3"></th>
                    </tr>
                  </thead>
                  <tbody className="bg-white text-left text-gray-500 text-sm font-medium divide-y divide-gray-200">
                    {data.applicants.map((applicant) => (
                      <tr key={applicant.user_job_id} onClick={() => handleRowClick(applicant.user_job_id)} className="hover:bg-gray-50 cursor-pointer">
                        {/* Shortlist Column */}
                        <td className="px-6 py-4 whitespace-nowrap text-center">
                          <Star
                            size={24}
                            fill={applicant.shortlisted ? 'gold' : 'none'}
                            stroke="gold"
                            className="cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleShortlistToggle(applicant.user_job_id, !applicant.shortlisted);
                            }}
                          />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <Avatar photo={applicant.users?.photo} name={applicant.users?.name} className="h-10 w-10 mr-4" clickable={true} />
                            <div>
                              <p className="font-medium">{applicant.users?.name}</p>
                              <div className="flex items-center mt-1">
                                {applicant.users?.former_epic && (
                                  <span className="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">Ex-Epic</span>
                                )}
                                {applicant.fetch_recommended && (
                                  <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">Recommended</span>
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <p>{new Date(applicant.submitted_at).toLocaleDateString()}</p>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <p>${applicant.consultant_rate}/hr</p>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex flex-col items-center">
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 mb-1">
                              {applicant.status}
                            </span>
                            <div className="mt-1">
                              {renderProgressDots(applicant.status)}
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex flex-col items-start">
                            <div className="flex items-center">
                              {renderStars(applicant.users?.average_rating || 0)}
                            </div>
                            {applicant.users?.average_rating > 0 && (
                              <span className="mt-1 text-sm text-gray-500">
                                ({applicant.users?.review_count || 0} {applicant.users?.review_count === 1 ? 'review' : 'reviews'})
                              </span>
                            )}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium relative">
                          <MoreHorizontal
                            size={24}
                            className="cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleDropdown(applicant.user_job_id);
                            }}
                          />

                          {/* Dropdown menu */}
                          {uiState.activeDropdown === applicant.user_job_id && (
                            <div
                              ref={dropdownRef}
                              className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10 cursor-pointer"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <div
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                onClick={() => window.open(applicant.users?.linkedin_url, '_blank')}
                              >
                                👤 LinkedIn Profile
                              </div>
                              <div
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleViewNotes(applicant);
                                }}
                              >
                                📝 View Notes
                              </div>
                              <div
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                                onClick={(e) => handleCreateNote(applicant, e)}
                                >
                                📝 Create Note
                              </div>
                              <div
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  startChat(applicant.users.user_id);
                                }}
                                >
                                💬 Start Chat
                              </div> 
                              <div
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                onClick={() => handleReject(applicant.user_job_id)}
                              >
                                ❌ Reject
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </main>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Applicants;