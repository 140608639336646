import { getAnalytics, logEvent } from "firebase/analytics";
import { Menu } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { supabase } from '../supabaseClient';
import ConsultantHamburger from './ConsultantHamburger';
import NotificationBell from './NotificationBell';
import NotificationPermissionWarning from './NotificationPermissionWarning';
import { requestForToken } from '../firebaseConfig';

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const WelcomeConsultant = () => {
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Welcome Consultant',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error.message);
    }
  };

  const handleContinue = async () => {
    try {
      await requestForToken();
      console.log('Notification permission requested');
    } catch (err) {
      console.log('Failed to request notification permission:', err);
    }
    navigate('/app/consultant-dashboard');
  };

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <>
      <Helmet>
        <title>Welcome to fetchConsultant | Consultant Dashboard</title>
        <meta name="description" content="Welcome to fetchConsultant. Discover exciting Epic consulting opportunities and maximize your earning potential." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex flex-col">
        <ConsultantHamburger user={user} currentPage="welcome" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white p-4 flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-lg md:text-xl font-bold">Welcome to fetchConsultant</h1>
            </div>
            <div className="flex items-center">
              <NotificationBell />
            </div>
          </header>
          <NotificationPermissionWarning />
          <main className="flex-grow container mx-auto px-4 py-4 sm:py-8 md:px-8">
            <div className="bg-white text-left rounded-lg shadow-xl overflow-hidden">
              <div className="p-4 sm:p-6 md:p-8">
                <header className="text-lg sm:text-2xl font-bold text-gray-800 mb-4">Welcome aboard!</header>
                <p className="text-sm sm:text-base text-gray-600 mb-4 sm:mb-6">
                  We're excited to have you join fetchConsultant. Here's what you can expect:
                </p>
                <ul className="list-disc list-inside mb-4 sm:mb-6 text-gray-600 text-sm sm:text-base">
                  <li>Access to exclusive Epic consulting opportunities</li>
                  <li>Higher earning potential with direct client connections</li>
                  <li>Streamlined application process with video responses</li>
                  <li>Real-time notifications for new job postings</li>
                  <li>Full support throughout your contract engagements</li>
                </ul>
                <p className="text-sm sm:text-base text-gray-600 mb-4 sm:mb-6">
                  You've been subscribed to notifications via email, push, and text. You can adjust these settings anytime in your profile.
                </p>
                <button
                  onClick={handleContinue}
                  className="bg-brandPrimary text-white px-4 py-2 sm:px-6 sm:py-3 rounded-full text-sm sm:text-base font-bold hover:bg-brandHover transition duration-300 w-full sm:w-auto"
                >
                  Continue to Dashboard
                </button>
              </div>
              <div className="p-4 sm:p-6 bg-gray-50">
                <h1 className="text-lg sm:text-xl font-bold text-gray-800 mb-4">Why Choose fetchConsultant?</h1>
                <Slider {...carouselSettings} className="fetchCarousel">
                  <div className="px-2 sm:px-4">
                    <h2 className="text-base sm:text-lg font-semibold mb-2">Maximize Your Earnings</h2>
                    <p className="text-sm sm:text-base">With our transparent fee structure, you keep more of what you earn. No hidden costs or inflated markups.</p>
                  </div>
                  <div className="px-2 sm:px-4">
                    <h2 className="text-base sm:text-lg font-semibold mb-2">Direct Client Connections</h2>
                    <p className="text-sm sm:text-base">Bypass traditional staffing agencies and connect directly with healthcare organizations for more meaningful engagements.</p>
                  </div>
                  <div className="px-2 sm:px-4">
                    <h2 className="text-base sm:text-lg font-semibold mb-2">Flexibility and Control</h2>
                    <p className="text-sm sm:text-base">Choose the contracts that align with your skills, preferences, and career goals. You're in control of your consulting journey.</p>
                  </div>
                </Slider>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default WelcomeConsultant;