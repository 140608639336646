import React from 'react';

const FileExplorer = ({ files, onSelect }) => {
    const renderFiles = (fileList) =>
        fileList.map((file) => (
            <div key={file.name} onClick={() => onSelect(file.name)}>
                {file.name}
            </div>
        ));

    return <div>{renderFiles(files)}</div>;
};

export default FileExplorer;