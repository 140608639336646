import React, { useState, useEffect } from 'react';
import { Plus } from 'lucide-react';
import Modal from './ui/Modal';
import { supabase } from '../supabaseClient';
import { getAnalytics, logEvent } from "firebase/analytics";

const InvoicePayments = ({ invoice, onPaymentAdded, isReadOnly = false }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [payments, setPayments] = useState([]);
  const [amount, setAmount] = useState('');
  const [paymentDate, setPaymentDate] = useState(new Date().toISOString().split('T')[0]);
  const [paymentMethod, setPaymentMethod] = useState('ach');
  const [paymentNotes, setPaymentNotes] = useState('');
  const [referenceNumber, setReferenceNumber] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  useEffect(() => {
    fetchPayments();
  }, [invoice.id]);

  const fetchPayments = async () => {
    const { data, error } = await supabase
      .from('invoice_payments')
      .select(`
        *,
        created_by:users(name)
      `)
      .eq('invoice_id', invoice.id)
      .order('payment_date', { ascending: false });

    if (error) {
      console.error('Error fetching payments:', error);
      return;
    }

    setPayments(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
  
    try {
      // Get user's ID from the auth session
      const { data: { session } } = await supabase.auth.getSession();
      const userId = session?.user?.id;
  
      if (!userId) {
        throw new Error('No authenticated user');
      }
  
      // Add payment record
      const { data: payment, error: paymentError } = await supabase
        .from('invoice_payments')
        .insert({
          invoice_id: invoice.id,
          amount: parseFloat(amount),
          payment_date: paymentDate,
          payment_method: paymentMethod,
          payment_notes: paymentNotes,
          reference_number: referenceNumber,
          created_by: userId
        })
        .select(`
          *,
          created_by:users!created_by(name)
        `)
        .single();
  
      if (paymentError) throw paymentError;
  
      // Update invoice total_paid and status
      const { error: invoiceError } = await supabase
        .from('invoices')
        .update({
          total_paid: invoice.total_paid + parseFloat(amount),
          status: invoice.amount <= (invoice.total_paid + parseFloat(amount)) ? 'Paid' : 'Partial',
          updated_at: new Date().toISOString()
        })
        .eq('id', invoice.id);
  
      if (invoiceError) throw invoiceError;
  
      const analytics = getAnalytics();
      logEvent(analytics, 'payment_added', {
        invoice_id: invoice.id,
        amount: amount,
        payment_method: paymentMethod
      });
  
      setIsModalOpen(false);
      if (onPaymentAdded) {
        await onPaymentAdded(); // Ensure this is awaited
      }
      await fetchPayments(); // Ensure this is awaited
      
      // Reset form
      setAmount('');
      setPaymentDate(new Date().toISOString().split('T')[0]);
      setPaymentMethod('ach');
      setPaymentNotes('');
      setReferenceNumber('');
  
    } catch (error) {
      console.error('Error adding payment:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="mt-6">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold">Payment History</h3>
        {!isReadOnly && (
          <button
            onClick={() => setIsModalOpen(true)}
            className="flex items-center px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
          >
            <Plus size={16} className="mr-2" />
            Add Payment
          </button>
        )}
      </div>

      {payments.length === 0 ? (
        <p className="text-gray-500 italic">No payments recorded</p>
      ) : (
        <div className="bg-white rounded-lg shadow overflow-hidden w-full" style={{ minWidth: '800px' }}>
          <table className="min-w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Amount
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Method
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Reference
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Recorded By
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {payments.map((payment) => (
                <tr key={payment.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {new Date(payment.payment_date).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    ${payment.amount.toFixed(2)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {payment.payment_method.toUpperCase()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {payment.reference_number}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {payment.created_by?.name || 'Unknown User'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Add Payment"
      >
        <div onClick={(e) => e.stopPropagation()}>
          <form onSubmit={handleSubmit} className="p-6">
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Outstanding Balance
                </label>
                <div className="mt-1 text-gray-900 font-semibold">
                  ${(invoice.amount - invoice.total_paid).toFixed(2)}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Payment Amount
                </label>
                <input
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  className="fetch-input mt-1"
                  step="0.01"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Payment Date
                </label>
                <input
                  type="date"
                  value={paymentDate}
                  onChange={(e) => setPaymentDate(e.target.value)}
                  className="fetch-input mt-1"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Payment Method
                </label>
                <select
                  value={paymentMethod}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                  className="fetch-select mt-1"
                  required
                >
                  <option value="ach">ACH</option>
                  <option value="check">Check</option>
                  <option value="wire">Wire Transfer</option>
                  <option value="other">Other</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Reference Number
                </label>
                <input
                  type="text"
                  value={referenceNumber}
                  onChange={(e) => setReferenceNumber(e.target.value)}
                  className="fetch-input mt-1"
                  placeholder="Check number, ACH reference, etc."
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Notes
                </label>
                <textarea
                  value={paymentNotes}
                  onChange={(e) => setPaymentNotes(e.target.value)}
                  className="fetch-input mt-1"
                  rows={3}
                />
              </div>
            </div>

            <div className="mt-6 flex justify-end space-x-4">
              <button
                type="button"
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 border border-gray-300 rounded-md"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Adding Payment...' : 'Add Payment'}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default InvoicePayments;