import { getAnalytics, logEvent } from "firebase/analytics";
import { Briefcase, Calendar, Handshake, HelpCircle, LogOut, Mail, MessageSquare, Settings, Star, Timer, User, Users, X } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';  // '../' to go up one level to access supabaseAuth.js
import Avatar from "./ui/Avatar.js";

const ConsultantHamburger = ({ user, currentPage, isOpen, onClose }) => {
  const [hasRequests, setHasRequests] = useState(false);
  const navigate = useNavigate();
  const analytics = getAnalytics();
  

  useEffect(() => {
    const fetchRequests = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      
      // Fetch regular requests
      const { data: jobRequests, error: jobError } = await supabase
        .from('user_jobs')
        .select('status')
        .eq('user_id', user.id)
        .in('status', ['Interview Requested', 'Second Interview Requested', 'Contract Offered']);
    
      // Fetch extension requests
      const { data: extensionRequests, error: extensionError } = await supabase
        .from('engagements')
        .select('id')
        .eq('consultant_id', user.id)
        .eq('status', 'Offered')
        .eq('contract_extension', true);
    
      if (!jobError && !extensionError) {
        const totalRequests = (jobRequests?.length || 0) + (extensionRequests?.length || 0);
        setHasRequests(totalRequests > 0);
      }
    };

    fetchRequests();
  }, []);
  
  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      logEvent(analytics, 'logout', {
        method: 'hamburger_menu',
        user_type: 'consultant'
      });
      navigate('/app/auth');
    } catch (error) {
      console.error('Error logging out:', error.message);
    }
  };

  const handleSupport = () => {
    window.location.href = 'mailto:support@fetchconsultant.com';
    logEvent(analytics, 'support_email_click', {
      method: 'hamburger_menu',
      user_type: 'consultant'
    });
  };

  const menuItems = [
    { icon: Briefcase, text: 'Dashboard', link: '/app/consultant-dashboard' },
    { icon: Star, text: 'My Applications', link: '/app/consultant-jobs-applied' },
    { icon: Calendar, text: 'Interviews', link: '/app/consultant-interviews' },
    { icon: MessageSquare, text: 'Chats', link: '/app/chats' },
    { icon: Timer, text: 'Timesheet', link: '/app/consultant-timesheet' },
    { icon: Star, text: 'Reviews', link: '/app/reviews' },
    { icon: Star, text: 'Work History', link: '/app/work-history-management' },
    { icon: Users, text: 'Referrals', link: '/app/refer' },
    { icon: Handshake, text: 'Engagements', link: '/app/engagement-management' },
    { icon: Briefcase, text: 'Requests', link: '/app/consultant-requests' },
    { icon: Settings, text: 'Settings', link: '/app/consultant-settings' },
  ];

  return (
    <div className={`bg-white w-64 h-screen shadow-lg flex flex-col fixed top-0 left-0 z-50 transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0`}>
      {/* Header section */}
      <div className="p-4 flex-shrink-0">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center">
            <img src="/images/fetch-logo.png" alt="Fetch Logo" className="h-8 mr-2" />
            <h2 className="sr-only">fetchConsultant</h2>
          </div>
          <button onClick={onClose} className="md:hidden">
            <X size={24} />
          </button>
        </div>
      </div>

      {/* Scrollable navigation section */}
      <div className="flex-1 overflow-y-auto">
        <nav className="p-4">
          <ul className="space-y-2">
            {menuItems.map((item) => (
              <li key={item.text}>
                <Link
                  to={item.link}
                  className={`flex items-center py-2 px-4 rounded-lg ${
                    currentPage === item.text.toLowerCase()
                      ? 'bg-red-100 text-black font-semibold'
                      : 'text-gray-700 hover:bg-gray-100'
                  } ${item.text === 'Requests' && hasRequests ? 'glowing' : ''}`}
                  onClick={() => onClose()}
                >
                  <item.icon size={20} className="mr-3" />
                  {item.text}
                  {currentPage === item.text.toLowerCase() && (
                    <span className="ml-auto w-2 h-2 bg-red-500 rounded-full"></span>
                  )}
                </Link>
              </li>
            ))}
            {/* Support button */}
            <li>
              <button
                onClick={() => { handleSupport(); onClose(); }}
                className="flex items-center py-2 px-4 rounded-lg text-gray-700 hover:bg-gray-100 w-full text-left"
              >
                <Mail size={20} className="mr-3" />
                Support
              </button>
            </li>
            {/* FAQ link */}
            <li>
              <a
                href="https://fetchconsultant.com/faq"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center py-2 px-4 rounded-lg text-gray-700 hover:bg-gray-100"
                onClick={() => onClose()}
              >
                <HelpCircle size={20} className="mr-3" />
                F.A.Q.
              </a>
            </li>
            {/* Logout button */}
            <li>
              <button
                onClick={() => { handleLogout(); onClose(); }}
                className="flex items-center py-2 px-4 rounded-lg text-gray-700 hover:bg-gray-100 w-full text-left"
              >
                <LogOut size={20} className="mr-3" />
                Logout
              </button>
            </li>
            {/* Edit Profile link - Moved inside nav for better mobile visibility */}
            {user && (
              <li className="border-t border-gray-200 mt-2 pt-2">
                <Link 
                  to="/app/consultant-edit-profile" 
                  state={{ isEditMode: true }} 
                  className="flex items-center py-2 px-4 rounded-lg text-gray-700 hover:bg-gray-100"
                  onClick={() => onClose()}
                >
                  <Avatar
                    photo={user.photo}
                    name={user.name}
                    className="w-5 h-5 mr-3"
                  />
                  Edit Profile
                </Link>
              </li>
            )}
          </ul>
        </nav>
      </div>

      {/* Footer version section */}
      <div className="p-4 text-sm text-gray-500 flex-shrink-0">
        Version 1.0.0
      </div>
    </div>
  );
};

export default ConsultantHamburger;