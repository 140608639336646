import React from 'react';
import { ChevronLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import InvoiceStatusBadge from './ui/InvoiceStatusBadge';
import InvoicePayments from './InvoicePayments';

const InvoiceDetail = ({ invoice, onPaymentAdded, isReadOnly }) => {
  const navigate = useNavigate();

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center mb-6">
        <ChevronLeft 
          className="h-6 w-6 text-gray-400 cursor-pointer" 
          onClick={() => navigate(-1)} 
        />
        <h2 className="text-2xl font-bold ml-2">Invoice {invoice.invoice_number}</h2>
      </div>

      <div className="grid md:grid-cols-2 gap-6">
        <div>
          <h3 className="text-lg font-semibold mb-2">Invoice Details</h3>
          <div className="space-y-2">
            <div className="flex justify-between">
              <span className="text-gray-600">Status:</span>
              <InvoiceStatusBadge status={invoice.status} />
            </div>
            <div className="flex justify-between">
              <span className="text-gray-600">Invoice Date:</span>
              <span>{new Date(invoice.sent_date).toLocaleDateString()}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-600">Due Date:</span>
              <span>{new Date(invoice.due_date).toLocaleDateString()}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-600">Amount:</span>
              <span>${invoice.amount.toFixed(2)}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-600">Paid:</span>
              <span>${invoice.total_paid.toFixed(2)}</span>
            </div>
            <div className="flex justify-between font-semibold">
              <span className="text-gray-600">Balance:</span>
              <span>${invoice.balance.toFixed(2)}</span>
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-lg font-semibold mb-2">Client Information</h3>
          <div className="space-y-2">
            <div>
              <span className="text-gray-600">Name:</span>
              <span className="ml-2">{invoice.client_name}</span>
            </div>
            <div>
              <span className="text-gray-600">Consultant:</span>
              <span className="ml-2">{invoice.consultant_name}</span>
            </div>
            {invoice.purchase_order && (
              <div>
                <span className="text-gray-600">PO Number:</span>
                <span className="ml-2">{invoice.purchase_order}</span>
              </div>
            )}
          </div>
        </div>
      </div>

      <InvoicePayments 
        invoice={invoice} 
        onPaymentAdded={onPaymentAdded}
        isReadOnly={isReadOnly}
      />
    </div>
  );
};

export default InvoiceDetail;