import { getAnalytics, logEvent } from "firebase/analytics";
import React, { useEffect, useState } from 'react';
import { supabase } from '../../supabaseClient';
import Avatar from "./Avatar.js";
import { useNavigate } from 'react-router-dom';


const StatusBadge = ({ status }) => {
  const getStatusColor = (status) => {
    const statusColors = {
      'Applied': 'bg-blue-100 text-blue-800',
      'Interview Scheduled': 'bg-purple-100 text-purple-800',
      'Interview Requested': 'bg-yellow-100 text-yellow-800',
      'Interview Rejected': 'bg-red-100 text-red-800',
      'Contract Offered': 'bg-green-100 text-green-800',
      'Contract Accepted': 'bg-green-800 text-white',
      'Contract Declined': 'bg-red-800 text-white',
      'Application Withdrawn': 'bg-gray-100 text-gray-800'
    };
    return statusColors[status] || 'bg-gray-100 text-gray-600';
  };

  return (
    <span className={`px-2 py-1 text-xs font-medium rounded-full ${getStatusColor(status)}`}>
      {status}
    </span>
  );
};

const JobExpandedDetails = ({ jobId }) => {
  const [applicants, setApplicants] = useState([]);
  const [referrals, setReferrals] = useState([]);
  const [viewHistory, setViewHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('applicants');
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'job_details_view', {
      job_id: jobId,
      tab: activeTab
    });

    fetchData();
  }, [jobId, activeTab]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      // Fetch data based on active tab
      if (activeTab === 'applicants') {
        const { data, error } = await supabase
          .from('user_jobs')
          .select(`
            user_job_id,
            status,
            submitted_at,
            consultant_rate,
            users!user_jobs_user_id_fkey (
              name,
              photo,
              average_rating
            )
          `)
          .eq('job_id', jobId)
          .order('submitted_at', { ascending: false });

        if (error) throw error;
        setApplicants(data);
      } 
      else if (activeTab === 'referrals') {
        const { data, error } = await supabase
          .from('referrals')
          .select(`
            id,
            created_at,
            status,
            users!referrer_id (
              name,
              photo
            ),
            referred_users:users!referred_user_id (
              name,
              photo
            )
          `)
          .eq('referred_job', jobId)
          .order('created_at', { ascending: false });

        if (error) throw error;
        setReferrals(data);
      }
      else if (activeTab === 'views') {
        const { data, error } = await supabase
          .from('job_views')
          .select(`
            id,
            viewed_at,
            users (
              name,
              photo
            )
          `)
          .eq('job_id', jobId)
          .order('viewed_at', { ascending: false });

        if (error) throw error;
        setViewHistory(data);
      }
    } catch (error) {
      console.error('Error fetching job details:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleApplicantClick = (userJobId) => {
    const analytics = getAnalytics();
    logEvent(analytics, 'applicant_row_clicked', {
      user_job_id: userJobId,
      job_id: jobId
    });
    
    navigate(`/app/applicant/${userJobId}`);
  };
  
  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="flex justify-center items-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-brandPrimary"></div>
        </div>
      );
    }

    switch (activeTab) {
      case 'applicants':
        return (
          <div className="divide-y divide-gray-200">
            {applicants.map(applicant => (
              <div 
                key={applicant.user_job_id} 
                className="py-4 flex items-center justify-between hover:bg-gray-50 cursor-pointer transition-colors duration-150"
                onClick={() => handleApplicantClick(applicant.user_job_id)}
              >
                <div className="flex items-center">
                  <Avatar className="h-16 w-16" photo={applicant.users?.photo} name={applicant.users?.name} />
                  <div>
                    <div className="font-medium">{applicant.users.name}</div>
                    <div className="text-sm text-gray-500">
                      Applied {new Date(applicant.submitted_at).toLocaleDateString()}
                    </div>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <div className="text-sm text-gray-900">
                    ${applicant.consultant_rate}/hr
                  </div>
                  <StatusBadge status={applicant.status} />
                </div>
              </div>
            ))}
          </div>
        );

      case 'referrals':
        return (
          <div className="divide-y divide-gray-200">
            {referrals.map(referral => (
              <div key={referral.id} className="py-4 flex items-center justify-between">
                <div className="flex items-center">
                    <Avatar className="h-16 w-16" photo={referral.users?.photo} name={referral.users?.name} />
                  <div>
                    <div className="font-medium">{referral.users.name}</div>
                    <div className="text-sm text-gray-500">
                      Referred {new Date(referral.created_at).toLocaleDateString()}
                    </div>
                  </div>
                </div>
                <StatusBadge status={referral.status} />
              </div>
            ))}
          </div>
        );

      case 'views':
        return (
          <div className="divide-y divide-gray-200">
            {viewHistory.map(view => (
              <div key={view.id} className="py-4 flex items-center">
                  <Avatar className="h-16 w-16" photo={view.users?.photo} name={view.users?.name} />
                <div>
                  <div className="font-medium">{view.users?.name}</div>
                  <div className="text-sm text-gray-500">
                    Viewed {new Date(view.viewed_at).toLocaleString()}
                  </div>
                </div>
              </div>
            ))}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="p-6">
      <div className="border-b border-gray-200 mb-6">
        <nav className="-mb-px flex space-x-8">
          {['applicants', 'referrals', 'views'].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`
                py-4 px-1 border-b-2 font-medium text-sm
                ${activeTab === tab
                  ? 'border-brandPrimary text-brandPrimary'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}
              `}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </nav>
      </div>
      {renderContent()}
    </div>
  );
};

export default JobExpandedDetails;